<template>
  <div :class="{ transparent, 'is-advertiser': isAdvertiser }" class="app-header">
    <nav class="app-header__nav">
      <ul class="app-header__nav-wrapper tw-relative">
        <transition-group :name="transition" tag="div" class="tw-w-full">
          <div v-if="!isSearchEnabled" key="icon" class="list-item-container tw-flex tw-justify-between">
            <li class="app-header__nav-item" @click="$emit('logo-click')">
              <menu-icon class="hamburger md:tw-hidden tw-text-jb-grey-400 tw-w-6 tw-h-6" />
              <logo-type ref="logo" class="logotype md:tw-block tw-hidden" />
              <jb-logo-mobile class="md:tw-hidden" />
            </li>
            <div v-if="!isSearchEnabled && !isGeneral" key="search2" class="campaign-search-list-wrapper xsm:tw-flex tw-mx-layout-2 md:tw-mx-auto tw-items-center tw-justify-center tw-hidden tw-w-full">
              <campaign-search-list @click="handleViewCampaign" />
            </div>
            <div class="nav-item-controls tw-flex tw-items-center tw-cursor-pointer">
              <li class="app-header__nav-item xsm:tw-hidden" @click="toggleCampaignSearchBar">
                <search-icon class="app-header__nav-item-search tw-bg-grey-white-ter tw-p-space-2 xsm:tw-hidden tw-text-jb-grey-400 hover:tw-text-jb-indigo hover:tw-text-opacity-75 tw-ring-transparent tw-ring-2 hover:tw-ring-jb-indigo hover:tw-ring-opacity-25 focus-within:!tw-ring-opacity-100 focus-within:!tw-text-jb-indigo focus-within:!tw-ring-jb-indigo focus-within:!tw-text-opacity-100 tw-w-10 tw-h-10 tw-transition tw-rounded" />
              </li>
              <li v-if="$can($permissions.PAYMENT_HISTORY_VIEW) || $can($permissions.PAYMENT_SETUP_VIEW)" class="app-header__nav-item tw-group xsm:tw-ml-0 tw-cursor-pointer">
                <el-popover
                  v-model="showPaymentRoutes"
                  :offset="-80"
                  :visible-arrow="false"
                  popper-class="app-header__nav-payment-popper"
                  placement="bottom"
                  width="200"
                  trigger="click">
                  <div class="app-header-payment-cont">
                    <app-text v-if="$can($permissions.PAYMENT_SETUP_VIEW)" @click="$router.push({name:'payment-setup'})">
                      Payment Setup
                    </app-text>
                    <app-text v-if="$can($permissions.PAYMENT_HISTORY_VIEW)" @click="$router.push({name:'payment-history'})">
                      Payment History
                    </app-text>
                  </div>
                  <wallet-icon
                    slot="reference"
                    class="app-header__nav-item-wallet tw-bg-grey-white-ter tw-p-space-2 tw-text-jb-grey-400 hover:tw-text-jb-indigo hover:tw-text-opacity-75 tw-ring-transparent tw-ring-2 hover:tw-ring-jb-indigo hover:tw-ring-opacity-25 focus-within:!tw-ring-opacity-100 focus-within:!tw-text-jb-indigo focus-within:!tw-ring-jb-indigo focus-within:!tw-text-opacity-100 tw-h-10 tw-w-10 tw-transition tw-rounded"
                    :class="[ isActive ? 'tw-text-jb-indigo' : 'tw-text-jb-grey-400']"
                    @click="isActive = !isActive"
                    @blur="isActive = false" />
                </el-popover>
              </li>
              <li class="app-header__nav-item">
                <el-popover
                  :value="showSurveyPopover && !showNotificationPopover && !npsSurveyVisible && !npsSurveySeen && !disableNpsSurvey"
                  :reference="$refs.logo"
                  :placement="surveyPopperDirection"
                  trigger="manual"
                  popper-class="app-header__nav-survey">
                  <survey-popup v-if="surveyId" :survey-id="surveyId" @survey-action="showSurveyPopover = false" />
                </el-popover>
                <el-popover
                  v-if="shouldRequestNotificationPermission"
                  placement="left"
                  title="Push Notifications"
                  :reference="$refs.notifications"
                  :width="popoverWidth"
                  trigger="manual"
                  :value="showNotificationPopover"
                  class="app-header__nav-notifications"
                  popper-class="dark">
                  Enable notifications to be alerted to critical events, as they happen. <div class="app-header__nav-notifications--buttons">
                    <app-button
                      size="micro"
                      state="text"
                      class="app-header__nav-notifications--light"
                      @click="handleDismissNotificationClick">
                      No Thanks
                    </app-button>
                    <app-button size="micro" state="primary" @click="handleAllowNotificationClick">
                      Enable
                    </app-button>
                  </div>
                </el-popover>
                <el-popover
                  v-if="inAppNotificationsEnabled"
                  popper-class="app-header__notifications-list"
                  placement="top-end"
                  trigger="click"
                  class="app-header__nav-notifications-list"
                  :offset="80"
                  :arrow-offset="6"
                  :value="showNotificationListPopover"
                  @show="showNotificationListPopover = true"
                  @hide="showNotificationListPopover = false">
                  <app-notification
                    :visible="showNotificationListPopover"
                    :organization="organization"
                    @notification-click="handleNotificationClick"
                    @notifications-update="handleNotificationsUpdate" />
                  <el-badge
                    slot="reference"
                    :hidden="!unseenNotifications"
                    :value="unseenNotifications"
                    type="primary">
                    <notifications-icon
                      ref="notifications"
                      class="tw-w-6 tw-h-5"
                      :class="{ active: showNotificationListPopover }"
                      @click="showNotificationPopover = false" />
                  </el-badge>
                </el-popover>
              </li>
              <li class="app-header__nav-item">
                <el-popover
                  :key="organization && organization.id"
                  v-model="showUserDropdown"
                  popper-class="app-header__user-popover popover-user-dropdown"
                  placement="bottom-start"
                  :width="288"
                  trigger="click"
                  @after-enter="focusSearch">
                  <app-user-dropdown-menu
                    ref="dropdown-menu"
                    :user="user"
                    :organization="organization"
                    @close-dropdown="handleCloseDropdown" />
                  <app-profile-picture
                    slot="reference"
                    :full-name="userFullName"
                    class="app-header_profilePicture" />
                </el-popover>
              </li>
            </div>
          </div>
          <div v-else key="search" class="tw-absolute tw-top-0 tw-bottom-0 tw-left-0 tw-right-0 tw-flex tw-items-center">
            <left-icon class="tw-mr-space-5 tw-text-jb-grey-400 tw-w-layout-2 tw-h-layout-2 tw-cursor-pointer" @click="toggleCampaignSearchBar" />
            <campaign-search-list @click="handleViewCampaign" />
          </div>
        </transition-group>
      </ul>
    </nav>
    <portal-target
      v-scrollable:x
      :class="{ 'app-header__portal--has-content': hasSubcontent }"
      :multiple="true"
      class="app-header__portal"
      name="app-header-subcontent" />
  </div>
</template>

<script>
import { Wormhole } from 'portal-vue';
import AppButton from '@/components/AppButton';
import AppNotification from '@/components/AppNotification';
import AppProfilePicture from '@/components/AppProfilePicture';
import AppUserDropdownMenu from '@/components/AppUserDropdownMenu';
import Breakpoints from '@/mixins/Breakpoints';
import SearchIcon from '@/assets/svg/search-icon.svg';
import WalletIcon from '@/assets/svg/wallet-icon.svg';
import NotificationsIcon from '@/assets/svg/notifications-icon.svg';
import JbLogoMobile from '@/assets/svg/jb-logo-mobile.svg';
import MenuIcon from '@/assets/svg/menu-icon.svg';
import LeftIcon from '@/assets/svg/left-icon.svg';
import LogoType from '@/assets/svg/logotype.svg';
import OneSignalService from '@/services/one-signal';
import SurveyPopup from '@/components/nps/SurveyPopup';
import CampaignSearchList from '@/components/campaigns/CampaignSearchList';
import AppText from '@/components/AppText';
export default {
  name: 'AppHeader',
  components: {
    AppText,
    AppButton,
    AppUserDropdownMenu,
    AppProfilePicture,
    LogoType,
    MenuIcon,
    AppNotification,
    SurveyPopup,
    CampaignSearchList,
    JbLogoMobile,
    SearchIcon,
    WalletIcon,
    NotificationsIcon,
    LeftIcon
  },
  mixins: [Breakpoints([410, 768])],
  props: {
    user: {
      type: Object,
      required: true
    },
    organization: {
      type: Object,
      default: undefined
    },
    transparent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: false,
      showNotificationPopover: false,
      showNotificationListPopover: false,
      showUserDropdown: false,
      unseenNotifications: 0,
      showSurveyPopover: false,
      isSearchEnabled: false,
      showPaymentRoutes: false
    };
  },
  computed: {
    transition() {
      return this.isSearchEnabled ? 'slide-left' : 'slide-right';
    },
    showSearchBar() {
      return this.windowBreakpoint >= 768;
    },
    isAdvertiser() {
      return this.$store.getters.userIsAdvertiser;
    },
    isGeneral() {
      return this.$store.getters.userIsGeneral;
    },
    hasSubcontent() {
      return Wormhole.hasContentFor('app-header-subcontent');
    },
    userFullName() {
      return `${this.user.first_name} ${this.user.last_name}`;
    },
    popoverWidth() {
      return this.windowBreakpoint >= 410 ? 280 : 200;
    },
    shouldRequestNotificationPermission() {
      return this.$store.getters.shouldRequestNotificationPermission;
    },
    inAppNotificationsEnabled() {
      return process.env.VUE_APP_JB_NOTIFICATION_IN_APP_ENABLE === 'true' && !this.isGeneral;
    },
    pushNotificationsPopupDismissed() {
      return this.$store.getters.pushNotificationsPopupDismissed;
    },
    userHasNpsSurvey() {
      return this.$store.getters.userHasNpsSurvey;
    },
    npsSurveyVisible() {
      return this.$store.getters.npsSurveyVisible;
    },
    npsSurveySeen() {
      return this.$store.getters.npsSurveySeen;
    },
    surveyId() {
      return this.$store.getters.userHasNpsSurvey ? this.$store.getters.user.nps_survey.nps_survey_id : null;
    },
    surveyPopperDirection() {
      return this.windowBreakpoint >= '768' ? 'left' : 'bottom';
    },
    disableNpsSurvey() {
      return process.env.NODE_ENV === 'test' || process.env.VUE_APP_DISABLE_TRANSITIONS;
    },
    campaignId() {
      return this.$route.params.id;
    }
  },
  watch: {
    shouldRequestNotificationPermission: {
      immediate: true,
      handler(value) {
        if (!this.pushNotificationsPopupDismissed) {
          setTimeout(() => {
            this.showNotificationPopover = value;
            this.showSurveyPopover = false;
          }, 200);
        }
      }
    },
    userHasNpsSurvey: {
      immediate: true,
      handler(value) {
        // Only delay for showing the survey
        if (value) {
          setTimeout(() => {
            if (this.pushNotificationsPopupDismissed || !this.shouldRequestNotificationPermission) {
              this.showSurveyPopover = value;
            }
          }, 5000);
        } else {
          this.showSurveyPopover = value;
        }
      }
    },
    $route(to, from) {
      this.showPaymentRoutes = false;
    }
  },
  methods: {
    toggleCampaignSearchBar() {
      this.isSearchEnabled = !this.isSearchEnabled;
    },
    focusSearch() {
      try {
        this.$refs['dropdown-menu'].$el.querySelector('.app-user-dropdown-menu__orgs input').focus();
      } catch (e) {}
    },
    handleNotificationsUpdate({ unseen }) {
      this.unseenNotifications = parseInt(unseen);
    },
    handleNotificationClick({ notification, organizations }) {
      let notificationUrl = notification.url.replace(/^.*\/\/[^/]+/, '');
      // Todo: show confirmation to the user if we need to change org?
      if (organizations) {
        notificationUrl += `&orgs=${organizations.map(o => o.id).join(',')}`;
      }
      this.$router.push(notificationUrl);
      this.showNotificationListPopover = false;
    },
    handleAllowNotificationClick() {
      OneSignalService.askPermission();
      this.showNotificationPopover = false;
    },
    handleDismissNotificationClick() {
      this.showNotificationPopover = false;
      this.$store.dispatch('dismissPushNotificationPopup');
    },
    handleCloseDropdown() {
      setTimeout(() => {
        this.showUserDropdown = false;
      }, 200);
    },
    handleViewCampaign(campaign) {
      this.$router.push(
        this.campaignId && new RegExp(`/${this.campaignId}/`).test(this.$route.path)
          ? this.$route.fullPath.replace(this.campaignId, campaign.id)
          : `/campaign/${campaign.id}`
      );
    }
  }
};
</script>

<style lang="scss">
.app-header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: $--clb-app-header-height;
  background: $--clb-color-primary__white;

  @apply tw-border-b tw-border-solid tw-border-jb-grey-50 md:tw-border-b-0;

  &__nav {
    width: 100%;
    position: relative;

    &-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: $--clb-app-header-height;
      margin: 0 $--clb-mobile-padding;

      @include xs-up {
        margin: 0 $--clb-mobile-padding;
      }

      @include sm-up {
        margin: 0 $--clb-space-6;
        height: $--clb-app-header-height;
      }
    }

    &-item {
      transition: margin $--clb-transition-time ease, padding $--clb-transition-time ease;
      display: flex;
      align-items: center;
      justify-content: center;

      @apply tw-ml-space-2 sm:tw-ml-space-4;

      &:first-child {
        @apply tw-ml-0;
      }
    }

    #campaign-search-list {
      .campaign-search-list-wrapper {
        width: calc(100% - 261px);
        max-width: 564px;
      }

      .app-search-list-v2 {
        &__input {
          @apply tw-mt-0;
        }

        .el-scrollbar {
          height: 100%;
          min-height: 300px;

          @apply tw-absolute tw-top-full tw-left-0 tw-right-0 tw-mt-space-2;
        }
      }
    }

    .el-popover__reference-wrapper {
      @apply tw-flex;
    }

    &-survey {
      padding: $--clb-space-4;
      width: 380px;
      min-width: 100%;
      max-width: calc(100vw - 10px);
    }

    &-notifications-list {
      border-right: $--clb-border-divider;

      .el-popover__reference-wrapper {
        display: inline;
        background: $--clb-color-grey__white-ter;
        border-radius: $--clb-border-radius;
        padding: $--clb-space-2;

        @apply tw-text-jb-grey-400 hover:tw-text-jb-indigo hover:tw-text-opacity-75 tw-ring-transparent tw-ring-2 hover:tw-ring-jb-indigo hover:tw-ring-opacity-25 tw-transition tw-rounded;
        @apply focus-within:tw-ring-opacity-100 focus-within:tw-text-jb-indigo focus-within:tw-ring-jb-indigo focus-within:tw-text-opacity-100 #{!important};
      }
    }

    &-notifications,
    &-notifications-list {
      font-size: 24px;
      margin-top: 1px;
      cursor: pointer;

      @apply tw-pr-space-2 sm:tw-pr-space-4;

      .el-badge__content--primary {
        background-color: $--clb-color-red-darker;
      }

      &--buttons {
        margin-top: $--clb-layout-1;
        display: flex;
        justify-content: flex-end;
      }

      &--light {
        color: #e6e6e6 !important;

        &:hover {
          color: #fff !important;
        }
      }

      .el-badge {
        margin-top: $--clb-space-1;
        user-select: none;
      }

      .el-icon-message-solid {
        transition: color 0.3s ease;

        &:hover,
        &.active {
          color: $--clb-color__headings;
        }
      }
    }
  }

  &.transparent {
    background: transparent;
  }

  .logotype {
    width: 160px;
    color: $--color-primary;
  }

  .hamburger {
    margin-right: $--clb-space-4;
  }

  .app-header_profilePicture {
    cursor: pointer;
    background-color: $--clb-color-secondary__dark;
  }

  &__portal {
    background-color: $--clb-color-primary__white;
    width: 100%;
    display: flex;
    align-self: flex-end;
    flex-direction: column;

    &--has-content {
      border-top: 1px solid rgba(28, 24, 7, 0.1);
    }

    > * {
      border-bottom: 1px solid rgba(28, 24, 7, 0.1);

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .app-header__notification-icon {
    color: $--clb-color-pink !important;
  }
}

.el-popover.app-header__user-popover {
  width: 300px;
  padding: 0;
  border: none;

  .app-user-dropdown-menu {
    border-radius: $--clb-border-radius;
    overflow: hidden;
  }

  .popper__arrow::after {
    border-bottom-color: $--clb-color-primary;
  }

  &.popover-user-dropdown {
    @apply tw-mr-space-3;

    .popper__arrow::after {
      @apply tw-border-grey-selected tw-border-b tw-border-none;
    }
  }
}

.el-popover.app-header__notifications-list {
  padding: 0;
  border: none;

  .app-notification {
    border-radius: $--clb-border-radius;
    overflow: hidden;
  }

  .popper__arrow::after {
    border-bottom-color: $--clb-color-primary !important;
  }

  .app-search-list__wrapper {
    max-height: 260px;
  }
}

@media (max-width: 310px) {
  .el-popover.app-header__user-popover {
    width: calc(100vw - 10px);
  }
}

@media (min-width: $--md) {
  .app-header {
    &__portal {
      position: relative;
      width: calc(100% - #{$--clb-app-left-sidebar-width});
    }

    &.is-advertiser {
      .app-header__portal {
        width: 100%;
      }
    }
  }
}

.app-header__nav-payment-popper {
  top: 40px !important;
  padding: 0 !important;

  .app-header-payment-cont {
    @apply tw-flex tw-flex-col;

    .app-text {
      @apply tw-text-jb-ink tw-cursor-pointer tw-p-space-2 hover:tw-bg-grey-hover tw-font-semibold;

      &:hover {
        @apply tw-font-medium;
      }
    }
  }
}
</style>
