// RESIZING
const params = function(data) {
  return Object.keys(data)
    .map(key => `${key}=${encodeURIComponent(data[key])}`)
    .join('&');
};

const resizeImage = (url, options) => {
  return `${url}?${params(options)}`;
};

// PLACEHOLDER
const hashCode = function(str) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
};

const intToRGB = function(i) {
  var c = (i & 0x00FFFFFF)
    .toString(16)
    .toUpperCase();

  return '00000'.substring(0, 6 - c.length) + c;
};

const placeholderImage = (string, options) => {
  return resizeImage('https://cdn.jbxroute.com/discover/none.gif', Object.assign({
    h: 350,
    w: 3500,
    fm: 'png',
    fit: 'fill',
    bg: intToRGB(hashCode(string))
  }, options || {}));
};

const toHexCode = function(string) {
  return intToRGB(hashCode(string));
};

// PRELOADING
const imagePreload = (images = []) => {
  return new Promise((resolve, reject) => {
    if (images.length === 0) resolve(true);
    setTimeout(reject, 10000);

    let imagesLoaded = 0;
    images.map(item => {
      const img = new Image();
      img.onload = img.onerror = () => {
        if (++imagesLoaded >= images.length) resolve(true);
      };
      img.src = item;
    });
  });
};

export default {
  resizeImage,
  imagePreload,
  placeholderImage,
  toHexCode
};
