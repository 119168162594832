export const MINIMUM_COLUMN_COUNT_ON_PERFORMANCE_REPORT_TABLE = 9;

export const TOOLTIP_CROWD_HELPER_TEXT = 'The constraints that you add to your crowd will serve as the top-most user filters. They narrow the pool of users that will be available when you create audiences. Use them to set the focus of your crowd.';
export const TOOLTIP_CROWD_ATTRIBUTES_HELPER_TEXT = 'Adding additional constraints to your crowd will make it easier to create relevant audiences; however, adding too many may prevent you from discovering broader insights. Add just enough to filter the noise.';
export const TOOLTIP_AUDIENCE_HELPER_TEXT = 'Your crowd is ready! The "Baseline" audience represents the aggregate metrics of your crowd. Add additional audiences to compare against to unlock insights about your traffic.';
export const TOOLTIP_AUDIENCE_ATTRIBUTES_HELPER_TEXT = 'Try to build audiences around attributes that you can target or influence. This will help you identify profitable traffic cohorts and optimize towards them.';

export const TABLE_DATA_LABELS = {
  users: 'Users',
  view_count: 'View Count',
  time_on_page: 'Time On Page',
  viewports_consumed: 'Viewports Consumed',
  scroll_depth: 'Scroll Depth',
  viewport_depth: 'Viewport Depth',
  percent_ctr: 'Percent CTR',
  continue_rate: 'Continue Rate',
  time_elapsed: 'Time Elapsed',
  epc: 'Earnings Per Click',
  AdView: 'Ad View',
  ViewContent: 'View Content',
  InitiateCheckout: 'Initiate Checkout',
  AddPaymentInfo: 'Add Payment Info',
  CompleteRegistration: 'Complete Registration',
  AgeWithGender: 'Age With Gender',
  65: '65+',
  '13_17': '13 - 17',
  '18_24': '18 - 24',
  '25_34': '25 - 34',
  '35_44': '35 - 44',
  '45_54': '45 - 54',
  '55_64': '55 - 64',
  male: 'Male',
  female: 'Female',
  unknown: 'Unknown'
};

export const ATTRIBUTE_LABELS = {
  advertiser_id: 'Advertiser',
  affiliate_id: 'Affiliate',
  campaign_id: 'Campaign',
  campaign_landing_page: 'Funnel',
  country_code: 'Country',
  device_type: 'Device Type',
  lead_source: 'Lead Source',
  c1: 'C1',
  c2: 'C2',
  c3: 'C3',
  iOS: 'iOS',
  XX: 'Other'
};

export const ATTRIBUTE_FORMATTING = {
  users: [true, ''],
  time_on_page: [false, '', 's'],
  viewports_consumed: [true, '', ''],
  scroll_depth: [true, '', '%'],
  viewport_depth: [true, '', '%'],
  continue_rate: [true, '', '%'],
  time_elapsed: [false, '', 's'],
  epc: [true, '$'],
  demographics: [true, '', '%'],
  Male: [true, '', '%'],
  Female: [true, '', '%']
};
