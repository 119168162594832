<template>
  <app-modal
    class="phone-verify-modal"
    v-bind="$attrs"
    cancel-button-text="Discard"
    confirm-button-text="Submit"
    :destroy-on-close="true"
    :loading="loadingVerify"
    v-on="$listeners"
    @confirm="submitVerificationCode">
    <template #title>
      <app-text weight="semi-bold" class="tw-text-jb-ink">
        Enter Your Phone Verification Code
      </app-text>
    </template>
    <template #message>
      <app-text size="sm" weight="medium" class="tw-text-jb-grey-700">
        Your phone number will not be updated if the verification code is not entered.
      </app-text>
      <div class="tw-flex tw-mt-3">
        <app-link class="tw-text-jb-indigo" @click="handleSendAgain">
          Send code again?
        </app-link>
        <!-- TODO: Replace with icons -->
        <template v-if="loadingSend">
          <app-text size="xs" class="tw-text-jb-yellow tw-ml-4">
            Sending Verification...
          </app-text>
        </template>
        <template v-else-if="confirmSent">
          <app-text size="xs" class="tw-text-jb-green tw-ml-4">
            Verification Sent!
          </app-text>
        </template>
      </div>
      <div class="tw-flex tw-justify-center tw-my-4">
        <app-input
          v-for="(digit, index) in verificationCode"
          ref="input"
          :key="index"
          v-model.number="verificationCode[index]"
          maxlength="1"
          min="0"
          max="9"
          type="text"
          class="phone-verify-modal__input tw-mx-space-4 tw-max-w-layout-4 tw-text-center"
          @input="focusNextInput(index, $event)"
          @keyup.delete.native.prevent="focusPreviousInput(index)" />
      </div>
    </template>
  </app-modal>
</template>

<script>
import AppModal from '@/components/AppModal';
import AppLink from '@/components/AppLink';
import AppText from '@/components/AppText';
import AppInput from '@/components/AppInput';

export default {
  name: 'PhoneVerifyModal',
  components: { AppModal, AppLink, AppText, AppInput },
  props: {
    confirmSent: {
      type: Boolean,
      required: true
    },
    loadingSend: {
      type: Boolean,
      required: true
    },
    loadingVerify: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      verificationCode: ['', '', '', '']
    };
  },
  methods: {
    focusNextInput(currentInputIndex, input) {
      let nextInputIndex = currentInputIndex + 1;
      if (nextInputIndex > 3) nextInputIndex = 3;
      if (/\d/.test(input)) {
        this.$refs.input[nextInputIndex].$refs.input.focus();
      } else {
        this.verificationCode[currentInputIndex] = '';
      }
    },
    focusPreviousInput(key) {
      let prevKey = key - 1;
      if (prevKey < 0) prevKey = 0;
      this.verificationCode[key] = '';
      if (!this.$refs.input[key].$refs.input.value) this.$refs.input[prevKey].$refs.input.focus();
    },
    handleSendAgain() {
      this.$emit('send-again');
    },
    submitVerificationCode() {
      const code = this.verificationCode.join('');
      if (code.length === 4) {
        this.$emit('submit-code', code);
      }
    }
  }
};
</script>

<style lang="scss">
.phone-verify-modal {
  &__input {
    .app-input .el-input__inner {
      @apply tw-text-center;
    }
  }
}
</style>
