/**
 * Campaign Discover Route Group
 * @param page
 * @return {function(): *}
 */
export default page => {
  return () => {
    const pages = {
      report: import(/* webpackChunkName: "CampaignAffiliateReporting" */'@/views/campaign/reporting/CampaignAffiliateReporting'),
      daily: import(/* webpackChunkName: "CampaignAffiliateReportingDaily" */'@/views/campaign/reporting/CampaignAffiliateReportingDaily'),
      metrics: import(/* webpackChunkName: "CampaignAffiliateReportingMetrics" */'@/views/campaign/reporting/CampaignAffiliateReportingMetrics')
    };
    return pages[page];
  };
};
