export default {
  /**
   * Prepare the question answer to be used on the front end
   * @param {Object} question
   * @return {*}
   */
  getAnswer(question) {
    return question.answer === null ? [] : question.answer;
  },
  /**
   * Get the question answer summary
   * @param {Object} question
   * @param {Object} options
   * @return {*}
   */
  getAnswerSummary(question) {
    return `<ol>${question.answer.map(a => `<li>${a}</li>`).join('')}</ol>`;
  }
};
