
export const VALID_TABS = ['pending', 'approved', 'revoked', 'settings', 'waiting_approval', 'waiting_revision'];

export const AD_APPROVAL_TAB_DATA = {
  affiliate: [
    { id: 'pending', name: 'Pending' },
    { id: 'waiting_revision', name: 'Needs Revision' },
    { id: 'approved', name: 'Approved' },
    { id: 'revoked', name: 'Revoked' }
  ],
  advertiser: [
    { id: 'pending', name: 'Pending' },
    { id: 'approved', name: 'Approved' },
    { id: 'revoked', name: 'Revoked' }
  ],
  employee: [
    { id: 'pending', name: 'Pending' },
    { id: 'approved', name: 'Approved' },
    { id: 'waiting_approval', name: 'Awaiting Approval' },
    { id: 'waiting_revision', name: 'Awaiting Revision' },
    { id: 'revoked', name: 'Revoked' }
  ],
  network: [
    { id: 'pending', name: 'Pending' },
    { id: 'approved', name: 'Approved' },
    { id: 'waiting_approval', name: 'Awaiting Approval' },
    { id: 'waiting_revision', name: 'Awaiting Revision' },
    { id: 'revoked', name: 'Revoked' }
  ]
};

export const CONTENT_TYPES = ['headline', 'body', 'visual'];
export const CONTENT_REQUEST_STATUSES = ['pending', 'approved', 'revoked'];
