export default [
  /**
   * FUNNEL EVENTS
   */
  {
    name: 'Lead',
    type: 'funnel',
    requiredFor: ['shopify']
  },
  {
    name: 'ViewContent',
    type: 'funnel',
    requiredFor: ['shopify']
  },
  {
    name: 'InitiateCheckout',
    type: 'funnel',
    requiredFor: []
  },
  {
    name: 'AddPaymentInfo',
    type: 'funnel',
    requiredFor: []
  },
  {
    name: 'Purchase',
    type: 'funnel',
    requiredFor: []
  },
  {
    name: 'Upsell',
    type: 'funnel',
    requiredFor: []
  },
  {
    name: 'CompleteRegistration',
    type: 'funnel',
    requiredFor: []
  },
  /**
   * DATA EVENTS
   */
  {
    name: 'AddToCart',
    type: 'data',
    requiredFor: []
  },
  {
    name: 'Decline',
    type: 'data',
    requiredFor: []
  },
  {
    name: 'Chargeback',
    type: 'data',
    requiredFor: []
  }
];
