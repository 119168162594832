import { AFFILIATE, ADVERTISER } from '@/constants/orgTypes';
/**
 * Run Funnel middleware
 * @param {Object} context
 * @param {Store} context.store
 * @param {Function} context.next
 */
export default async function({ next, store, router, to }) {
  if (![AFFILIATE, ADVERTISER].includes(store.getters.organization.type)) {
    return router.replace({ name: 'dashboard-campaign-details', params: { id: to.params.id } });
  }
  return next();
}
