import ErrorHelper from '@/helpers/ErrorHelper';

/**
 *
 * @param { JBXApi } jbxApi
 * @param { RequestCaching } requestCaching
 */
export default ({ jbxApi, requestCaching }) => {
  const state = {};
  const getters = {};
  const actions = {

    /**
     * Fetch available ab tests
     * @param {Object}context
     * @param {Object} payload
     * @param {Number} payload.orgId
     * @return {Promise<never>}
     */
    async fetchAbTests(context, { orgId }) {
      return jbxApi
        .get(`/v2/org/${orgId}/ab-tests`)
        .then(response => {
          return response.data.data;
        })
        .catch(e => {
          return Promise.reject(ErrorHelper(e));
        });
    },

    /**
     * Fetch a specific ab test
     * @param {Object}context
     * @param {Object} payload
     * @param {Number} payload.orgId
     * @param {Number} payload.testId
     * @return {Promise<never>}
     */
    async fetchAbTest(context, { orgId, testId }) {
      return jbxApi
        .get(`/v2/org/${orgId}/ab-test/${testId}`)
        .then(response => {
          return response.data.data;
        })
        .catch(e => {
          return Promise.reject(ErrorHelper(e));
        });
    },

    /**
     * Save the details of a test.
     * It will correctly use post or put depending on the case
     * @param {Object} context
     * @param {Number} payload.orgId
     * @param {Number} payload.campaignId
     * @param {Object} payload.test
     * @return {Promise<void|*>}
     */
    async saveAbTest(context, { orgId, testId, test }) {
      const options = testId
        ? { method: 'PUT', url: `/v2/org/${orgId}/ab-test/${testId}` }
        : { method: 'POST', url: `/v2/org/${orgId}/ab-test` };

      return jbxApi.request({
        method: options.method,
        url: options.url,
        data: test
      })
        .then(response => {
          return response.data.data;
        })
        .catch(e => {
          return Promise.reject(ErrorHelper(e));
        });
    }
  };
  const mutations = {};

  return {
    state,
    getters,
    actions,
    mutations
  };
};
