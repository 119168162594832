import MiddlewareError from '@/routing/middleware-error';

/**
 * Run verify Email middleware
 * @param {Route} context.to
 * @param {Function} context.next
 * @param {api} context.api
 * @param {Store} context.store
 * @param {vm} context.vm
 */
export default async function({ to, next, api, store, vm }) {
  try {
    await store.dispatch('signout');
    store.dispatch('addPlatformLoader', { key: 'verify-email', text: 'Verifying email...' });
    await api.account.verifyEmail({
      params: {
        token: to.params.token,
        email_hash: to.query.email_hash
      }
    });
    vm.$message({ message: 'Your email has been updated', type: 'success' });
    return next();
  } catch (e) {
    if (e.hasOwnProperty('response')) {
      throw new MiddlewareError('Invalid token', '/signin');
    }
    return Promise.reject(e);
  } finally {
    store.dispatch('removePlatformLoader', 'verify-email');
  }
}
