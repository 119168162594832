import {

  Container,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
  Main,
  Header,
  Button,
  ButtonGroup,
  Form,
  FormItem,
  Input,
  InputNumber,
  Card,
  Divider,
  Loading,
  Select,
  Option,
  OptionGroup,
  RadioGroup,
  RadioButton,
  Radio,
  Alert,
  Message,
  MessageBox,
  Badge,
  Collapse,
  CollapseItem,
  Dialog,
  Switch,
  Progress,
  Popover,
  DatePicker,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  InfiniteScroll,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Tag,
  Tabs,
  TabPane,
  Steps,
  Step,
  Pagination,
  Drawer,
  Avatar
} from 'element-ui';
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';

locale.use(lang);

// Fixes an issue with filters not working on mobile
Select.computed.readonly = function() {
  return !(this.filterable || this.multiple) && !this.visible;
};

export default function(VueInstance) {
  VueInstance.use(Breadcrumb);
  VueInstance.use(BreadcrumbItem);
  VueInstance.use(Button);
  VueInstance.use(ButtonGroup);
  VueInstance.use(Divider);
  VueInstance.use(Header);
  VueInstance.use(Main);
  VueInstance.use(Container);
  VueInstance.use(Row);
  VueInstance.use(Col);
  VueInstance.use(Dropdown);
  VueInstance.use(DropdownMenu);
  VueInstance.use(DropdownItem);
  VueInstance.use(Form);
  VueInstance.use(FormItem);
  VueInstance.use(Input);
  VueInstance.use(InputNumber);
  VueInstance.use(Checkbox);
  VueInstance.use(CheckboxButton);
  VueInstance.use(CheckboxGroup);
  VueInstance.use(Card);
  VueInstance.use(Select);
  VueInstance.use(Option);
  VueInstance.use(OptionGroup);
  VueInstance.use(RadioGroup);
  VueInstance.use(RadioButton);
  VueInstance.use(Radio);
  VueInstance.use(Alert);
  VueInstance.use(Badge);
  VueInstance.use(Switch);
  VueInstance.use(Collapse);
  VueInstance.use(CollapseItem);
  VueInstance.use(CollapseTransition);
  VueInstance.use(Progress);
  VueInstance.use(Popover);
  VueInstance.use(Loading.directive);
  VueInstance.use(DatePicker);
  VueInstance.use(InfiniteScroll);
  VueInstance.use(Menu);
  VueInstance.use(Submenu);
  VueInstance.use(MenuItem);
  VueInstance.use(MenuItemGroup);
  VueInstance.use(Tag);
  VueInstance.use(Tabs);
  VueInstance.use(TabPane);
  VueInstance.use(Steps);
  VueInstance.use(Step);
  VueInstance.use(Pagination);
  VueInstance.use(Drawer);
  VueInstance.use(Avatar);
  VueInstance.use(Dialog);

  VueInstance.prototype.$msgbox = MessageBox;
  VueInstance.prototype.$loading = Loading.service;
  VueInstance.prototype.$message = Message;
  VueInstance.prototype.$alert = MessageBox.alert;
  VueInstance.prototype.$confirm = MessageBox.confirm;
  VueInstance.prototype.$prompt = MessageBox.prompt;

  VueInstance.component(CollapseTransition.name, CollapseTransition);
}
