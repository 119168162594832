import AdApprovalHelper from '@/helpers/AdApprovalHelper';

function baseOrganizationStateFactory() {
  return {
    adAffiliates: [],
    adBannedWords: {},
    adTriggerFetchContents: false
  };
}

export default {
  state: baseOrganizationStateFactory(),
  getters: {
    adBannedWords: (state) => state.adBannedWords,
    adTriggerFetchContents: (state) => state.adTriggerFetchContents,
    adAffiliates: (state) => state.adAffiliates
  },
  actions: {
    fetchCampaignAffiliateListSuccess({ commit }, { response, payload }) {
      commit('SET_CAMPAIGN_AFFILIATES', { response, payload });
    },
    setAdBannedWordsSuccess({ commit }, creativeSettings) {
      commit('SET_AD_BANNED_WORDS', AdApprovalHelper.convertToObject(creativeSettings.response.data.banned_words));
    },
    newContentRequestSuccess({ commit }) {
      commit('SET_AD_TRIGGER_FETCH_CONTENTS', true);
    },
    putContentSuccess({ commit }) {
      commit('SET_AD_TRIGGER_FETCH_CONTENTS', true);
    },
    deleteContentSuccess({ commit }) {
      commit('SET_AD_TRIGGER_FETCH_CONTENTS', true);
    },
    fetchContentsSuccess({ commit }) {
      commit('SET_AD_TRIGGER_FETCH_CONTENTS', false);
    }
  },
  mutations: {
    SET_ACTIVE_ORGANIZATION(state, org) {
      state = baseOrganizationStateFactory();
    },
    CLEAR_STORE(state) {
      state = baseOrganizationStateFactory();
    },
    SET_AD_BANNED_WORDS(state, adBannedWords) {
      state.adBannedWords = adBannedWords;
    },
    SET_AD_TRIGGER_FETCH_CONTENTS(state, adTriggerFetchContents) {
      state.adTriggerFetchContents = adTriggerFetchContents;
    },
    SET_CAMPAIGN_AFFILIATES(state, { response, payload }) {
      state.adAffiliates = response.data.find(campaign => campaign.campaign_id === payload.campaignId)?.affiliates || [];
    }
  }
};
