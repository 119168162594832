<template>
  <AppIframe
    :key="src"
    loading-text="Loading Tipalti Payments"
    :src="src"
    :load-delay="1500"
    :iframe-height="iframeHeight"
    class="payment-payments" />
</template>

<script>
import AppIframe from '@/components/AppIframe.vue';
import config from '@/helpers/config';

export default {
  name: 'Payments',
  components: {
    AppIframe
  },
  props: {
    endpoint: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      iframeHeight: undefined
    };
  },
  computed: {
    orgId() {
      return this.$store.getters.organization.id;
    },
    sessionId() {
      return this.$store.getters.user.session_id;
    },
    src() {
      const domain = config.VUE_APP_PLATFORM_API_URL;
      const path = `/v2/org/${this.orgId}${this.endpoint}?api-token=${this.sessionId}`;
      return domain + path;
    }
  },
  created() {
    window.addEventListener('message', this.handleMessage, false);
  },
  destroyed() {
    window.removeEventListener('message', this.handleMessage, false);
  },
  methods: {
    handleMessage({ data }) {
      if (data && data.TipaltiIframeInfo) {
        this.iframeHeight = parseInt(data.TipaltiIframeInfo.height, 10) + 48 + 'px';
      }
    }
  }
};
</script>

<style lang="scss">
.payment-payments {
  transition: padding $--clb-transition-time ease;
  padding: $--clb-layout-1 $--clb-mobile-padding 0 $--clb-mobile-padding;
  background: $--clb-color-primary__white;
  border-radius: $--clb-border-radius;
}

@media (min-width: $--sm) {
  .payment-payments {
    padding: $--clb-layout-1 $--clb-layout-4 0 $--clb-layout-4;
  }
}

@media (min-width: $--md) {
  .payment-payments {
    transition: margin $--clb-transition-time ease, padding $--clb-transition-time ease;
    max-width: calc(100% - #{$--clb-mobile-padding * 2});
    margin: $--clb-layout-3 $--clb-mobile-padding 0 $--clb-mobile-padding;
    padding: $--clb-layout-1 $--clb-layout-2 0 $--clb-layout-2;
  }
}

@media (min-width: $--lg) {
  .payment-payments {
    margin-top: $--clb-layout-5;
    padding: $--clb-layout-1 $--clb-layout-4 0 $--clb-layout-4;
  }
}
</style>
