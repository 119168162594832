import money from '@/services/money';
import _get from 'lodash/get';

const moneyOptionMap = {
  conversion_rate: [true, '', '%'],
  conversion_rates: [true, '', '%'],
  epc: [true, '$'],
  money: [true, '$'],
  sales: [false, ''],
  clicks: [false, ''],
  tests: [false, '']
};

const keysToFormat = Object.keys(moneyOptionMap);

/**
 * Prepares the data for a specific overview metric
 * @param key
 * @param data
 * @return {*}
 */
const formatOverviewMetric = function(key, data) {
  if (!keysToFormat.includes(key)) {
    return data;
  }

  const formattedData = {};
  ['avg', 'sum'].forEach(sumKey => {
    if (data.hasOwnProperty(sumKey)) {
      formattedData.sum = money(data[sumKey], ...moneyOptionMap[key]);
    }
  });

  if (data.hasOwnProperty('values')) {
    formattedData.values = data.values.map(v => parseFloat(v));
    formattedData.formatted_values = data.values.map(v => money(v, ...moneyOptionMap[key]));
  }

  if (data.hasOwnProperty('rankings')) {
    formattedData.rankings = Object.assign(data.rankings, {
      list: data.rankings.list.map(l => {
        l.value = parseFloat(l.value);
        l.formatted_value = money(l.value, ...moneyOptionMap[key]);
        return l;
      })
    });
  }

  return formattedData;
};

/**
 * Prepares the data a single campaign detailed metrics
 * @param data
 * @return {*}
 */
const formatCampaignMetric = function(data) {
  return data.map(item => {
    return Object.keys(item).reduce((formattedItem, key) => {
      if (keysToFormat.includes(key)) {
        formattedItem[key] = parseFloat(item[key]);
        formattedItem.formatted_values[key] = money(item[key], ...moneyOptionMap[key]);
      } else {
        formattedItem[key] = item[key];
      }
      return formattedItem;
    }, { formatted_values: {} });
  });
};

export default {
  getFormattedReportingCampaigns(response) {
    if (!_get(response, 'data.data', []).length) { return []; }
    const initialKeys = Object.keys(response.data.data[0]);
    return response.data.data.map(campaign => {
      campaign.formatted_values = {};
      initialKeys.forEach(key => {
        if (keysToFormat.includes(key)) {
          campaign.formatted_values[`${key}`] = money(campaign[key], ...moneyOptionMap[key]);
        }
      });
      campaign.performance = campaign.performance.map(p => parseFloat(p));
      return campaign;
    });
  },

  /**
   * Prepares an Campaign Metrics response with formatted values
   * @param responseData
   * @return {Object}
   */
  getFormattedCampaignMetrics(responseData) {
    return Object.keys(responseData).reduce((formattedResponse, domain) => {
      formattedResponse[domain] = formatCampaignMetric(responseData[domain]);
      return formattedResponse;
    }, {});
  },

  /**
   * Prepares an Overview response with formatted values
   * @param responseData
   * @return {Object}
   */
  getFormattedReportingOverview(responseData) {
    return Object.keys(responseData).reduce((formattedResponse, key) => {
      formattedResponse[key] = formatOverviewMetric(key, responseData[key]);
      return formattedResponse;
    }, {});
  }
};
