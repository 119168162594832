<template>
  <portal v-if="rightSidebarVisible" to="app-right-sidebar">
    <div class="nps-survey-form">
      <Morph v-if="windowBreakpoint < 768" />
      <a
        v-if="formData.status !== 'complete' && windowBreakpoint < 768 && currentQuestionId > 1"
        class="back-link"
        href="#"
        @click.prevent="goBack">
        <i class="el-icon-back" />
      </a>
      <div v-if="formData.status !== 'complete'" class="nps-survey-form__questions">
        <app-loader
          v-if="fetchingSurveyQuestions"
          :loading="fetchingSurveyQuestions"
          :loaders="[{ text: 'Loading Feedback Survey' }]" />
        <form v-else>
          <h2 class="nps-survey-form__header hidden-xs-and-down">
            <span>Great!</span> Please rate your experiences from 1 to 10
          </h2>
          <transition-group :name="transition" tag="div">
            <div
              v-for="question in activeSurveyQuestions"
              :key="question.id"
              class="nps-survey-form__section"
              :class="[currentQuestionId == question.id ? '' : 'hidden-xs-and-down']">
              <div v-if="question.type == 'rating'">
                <h6 class="nps-survey-form__section-breadcrumb">
                  Question {{ question.index }} of {{ ratingsCount }}
                </h6>
                <h5 class="nps-survey-form__section-title">
                  {{ question.title }}
                </h5>
                <p class="nps-survey-form__section-question">
                  {{ question.question }}
                </p>
                <app-number-picker :value="question.answer" @input="handlePickerInput(question, $event)" />
                <div class="nps-survey-form__section-scale">
                  <span>lowest</span>
                  <span>highest</span>
                </div>
              </div>
              <div v-else class="nps-survey-form__section nps-survey-form__section-desktop">
                <h5 class="nps-survey-form__section-title">
                  {{ question.title }}
                </h5>
                <p class="nps-survey-form__section-question">
                  {{ question.question }}
                </p>
                <textarea v-model.lazy="question.answer" class="nps-survey-form__section-textarea" placeholder="We appreciate any feedback you can provide." />
                <div
                  class="nps-survey-form__controls"
                  :class="[isLastQuestion?'':'hidden-xs-and-down']">
                  <label>
                    <el-checkbox v-model="surveyData.feedback_consent" />
                    I would like the customer success team to reach out and learn more about how they can improve my experience
                  </label>
                  <app-button class="nps-survey-form__controls-button" @click="finishSurvey">
                    Submit <i class="el-icon-arrow-right" />
                  </app-button>
                </div>
              </div>
            </div>
          </transition-group>
        </form>
      </div>
      <transition-group name="survey-thank-you">
        <div v-if="formData.status === 'complete'" key="thank-you" class="nps-survey-form__thank-you">
          <h2 class="nps-survey-form__header">
            Thank you!
          </h2>
          <p v-if="surveyAverage < 8">
            Thanks for your feedback. We highly value all ideas and suggestions from our customers, whether they're positive or critical.
          </p>
          <p v-else>
            Thanks for your feedback. Our goal is to create the best possible product, and your thoughts, ideas, and suggestions play a major role in helping us identify opportunities to improve.
          </p>
          <app-lottie-animation
            :animation-data="animationData"
            :height="400"
            :width="400"
            class="nps-survey-form__thank-you-lottie" />
        </div>
      </transition-group>
    </div>
  </portal>
</template>

<script>
import AppButton from '@/components/AppButton';
import AppLoader from '@/components/AppLoader';
import AppNumberPicker from '@/components/AppNumberPicker';
import Breakpoints from '@/mixins/Breakpoints';
import Morph from '@/components/Morph';
import * as animationData from '@/assets/lottie/celebration.json';

export default {
  name: 'SurveyForm',
  components: {
    AppNumberPicker,
    AppButton,
    AppLoader,
    Morph,
    AppLottieAnimation: () => import('@/components/AppLottieAnimation')
  },
  mixins: [Breakpoints([768])],
  props: {
    surveyId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      surveyData: null,
      fetchingSurveyQuestions: true,
      manualNavigation: false,
      manualQuestionId: null,
      transition: 'questions-next',
      animationData: animationData.default,
      closeNpsSurveyTimeout: null
    };
  },
  computed: {
    surveyQuestions() {
      return (this.surveyData && this.surveyData.questions) || [];
    },
    activeSurveyQuestions() {
      return this.surveyQuestions.filter(question => this.windowBreakpoint >= 768 || question.id === this.currentQuestionId);
    },
    ratingsCount() {
      return this.surveyQuestions.filter(question => question.type === 'rating').length;
    },
    formData() {
      const formData = {};
      if (this.surveyQuestions.length) {
        // Construct the formData object to be a subset of surveyData that needs to be sent back to the API.
        formData.status = this.surveyData.status;
        formData.questions = [];
        this.surveyQuestions.forEach(function(val, key) {
          formData.questions.push({ id: val.id, answer: val.answer });
        });
        formData.feedback_consent = this.surveyData.feedback_consent;
        formData.id = this.surveyData.id;
      }
      return formData;
    },
    surveyAverage() {
      let answers = 0;
      const score = this.surveyQuestions.reduce((score, question) => {
        if (question.type === 'rating' && Number.isInteger(question.answer)) {
          ++answers;
          return score + question.answer;
        }

        return score;
      }, 0);

      return answers ? score / answers : 0;
    },
    currentQuestionId() {
      // If manual navigation mode, return manualQuestionID
      if (this.manualNavigation) {
        return String(this.manualQuestionId);
      }
      // If not, return the first unanswered question
      for (const question of this.surveyQuestions) {
        if (question.answer == null) {
          return question.id;
        }
      }
      // If all questions are answered, return the last question
      return this.surveyQuestions[this.surveyQuestions.length - 1].id;
    },
    isLastQuestion() {
      const questions = this.surveyQuestions;
      return this.currentQuestionId === questions[questions.length - 1].id;
    },
    rightSidebarVisible() {
      return this.$store.getters.rightSidebarVisible;
    }
  },
  watch: {
    formData: {
      deep: true,
      handler() {
        const postData = JSON.parse(JSON.stringify(this.formData));
        if (postData.status !== 'complete') delete postData.status;
        this.$api.npsSurvey.sendSurveyData(this.surveyId, postData);
      }
    },
    rightSidebarVisible(visible) {
      // If sidebar is closed, we destroy the survey
      if (!visible) {
        this.closeSurveyForm();
      }
    }
  },
  created() {
    // When this component is created, fetch the relevant surveyData
    this.getSurveyData();
  },
  methods: {
    goBack() {
      this.transition = 'questions-back';
      this.manualQuestionId = Math.max(this.currentQuestionId - 1, 1);
      this.manualNavigation = true;
    },
    getSurveyData() {
      // Get survey data from the API
      this.fetchingSurveyQuestions = true;
      this.$api.npsSurvey
        .getSurveyData(this.surveyId)
        .then(response => {
          response.data.questions = response.data.questions.map((question, i) => {
            question.index = i + 1;
            return question;
          });
          this.surveyData = response.data;
        })
        .finally(() => {
          this.fetchingSurveyQuestions = false;
        });
    },
    handlePickerInput(target, value) {
      this.transition = 'questions-next';
      target.answer = value;
      if (this.manualNavigation) {
        this.manualQuestionId += 1;
      }
    },
    closeSurveyForm() {
      clearTimeout(this.closeNpsSurveyTimeout);
      this.$store.dispatch('setNpsSurveyVisibility', false);
    },
    finishSurvey() {
      // Set survey status as complete. This will trigger an API call automatically.
      this.surveyData.status = 'complete';
      this.closeNpsSurveyTimeout = setTimeout(() => {
        this.closeSurveyForm();
      }, 6000);
    }
  }
};
</script>

<style lang="scss" scoped>
.questions-next-enter,
.questions-back-leave-to,
.survey-thank-you-enter {
  transform: translate3d(100vw, 0, 0);
}

.questions-next-enter-to,
.questions-back-enter-to,
.survey-thank-you-enter-to {
  transform: translate3d(0, 0, 0);
}

.questions-next-leave-to,
.questions-back-enter,
.survey-thank-you-leave-to {
  transform: translate3d(-100vw, 0, 0);
}

.nps-survey-form {
  padding: $--clb-layout-1 $--clb-mobile-padding $--clb-layout-2 $--clb-mobile-padding;
  margin: 0 auto;
  margin-top: -84px;
  max-width: 550px;
  min-height: calc(100vh - 10px);
  min-height: calc(100 * var(--vh, 1vh) - 10px);
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include sm-up {
    padding: 0 $--clb-space-6 $--clb-layout-3 $--clb-space-6;
    max-width: 800px;
  }

  .app-loader {
    position: relative;
    height: 90vh;
  }

  .morph {
    z-index: 0;
  }

  &__header,
  &__questions,
  &__thank-you {
    position: relative;
    transition: transform 0.2s cubic-bezier(0.23, 1, 0.32, 1);
    z-index: 2;

    @include sm-up {
      transition: none;
      display: block;
    }
  }

  &__questions,
  &__thank-you {
    min-height: 60vh;
    display: flex;

    @include sm-up {
      margin-top: 0;
    }
  }

  form {
    display: flex;
    flex-direction: column;
  }

  &__header {
    margin-bottom: $--clb-layout-3;

    span {
      border-bottom: 2px solid $--clb-color-primary;
    }
  }

  &__section {
    transition: transform 0.8s cubic-bezier(0.23, 1, 0.32, 1);
    position: absolute;
    right: 10px;
    left: 10px;

    @include sm-up {
      transition: none;
      position: relative;
      right: 0;
      left: 0;

      &-desktop {
        position: relative;
        left: 0;
        top: 0;
      }
    }

    &-breadcrumb {
      font-size: $--clb-font-size-xs;
      font-weight: $--clb-font-weight__normal;
      margin: 0;

      @include sm-up {
        display: none;
      }
    }

    &-title {
      margin: $--clb-space-4 0 $--clb-space-2 0;
      font-size: $--clb-font-size-lg;

      @include md-up {
        font-size: $--clb-h3__font-size;
        margin: $--clb-space-6 0 $--clb-space-2 0;
      }
    }

    &-scale {
      font-size: $--clb-font-size-xs;
      font-weight: $--clb-font-weight__normal;
      display: flex;
      justify-content: space-between;

      @include sm-up {
        display: none;
      }
    }

    &-textarea {
      width: 100%;
      min-height: 100px;
      padding: $--clb-space-2;
      border: 1px solid $--clb-color-grey__grey-light;
      margin-top: $--clb-space-4;
      border-radius: $--clb-border-radius;
      background-color: $--clb-color-primary__white;

      &::placeholder {
        color: $--clb-color-grey__grey-light;
      }
    }
  }

  &__controls {
    margin-top: $--clb-space-4;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;

    label {
      margin: 0 0 $--clb-space-4 0;
      display: flex;
      font-size: $--clb-font-size-xs;

      @include sm-up {
        margin-bottom: $--clb-layout-2;
        font-size: $--clb-font-size-sm;
      }

      .el-checkbox {
        margin-right: $--clb-space-4;
      }
    }

    &-button {
      min-width: 163px;
    }
  }

  &__thank-you {
    padding: 0 $--clb-space-2;
    font-weight: $--clb-font-weight__normal;
    flex-direction: column;

    @include sm-up {
      margin-top: $--clb-space-6;

      p {
        max-width: 68ch;
      }
    }

    &-title {
      font-size: $--clb-h1__font-size;
      margin: 0;
      padding: 0;
    }

    &-lottie {
      z-index: 0;
      position: relative;
      left: -64px;
      top: -150px;

      @include sm-up {
        top: 0;
        left: 0;
      }
    }
  }
}

.back-link {
  display: block;
  align-items: center;
  background: $--jb-bg-accent-color;
  border: none;
  border-radius: 50%;
  color: $--clb-color-primary__white;
  font-size: 15px;
  height: 40px;
  width: 40px;
  justify-content: space-around;
  left: 40px;
  padding: $--jb-backlink-padding;
  position: absolute;
  text-decoration: none;
  top: 20px;
  transition: 0.3s ease;
  z-index: 99;

  .el-icon-back {
    position: absolute;
    height: 15px;
    width: 13px;
    display: inline-block;
    left: 13px;
    top: 12px;
  }

  &:hover {
    background: $--jb-light-primary-color;
    box-shadow: $--jb-box-shadow-base;
    color: $--jb-bg-primary-color;
    transform: scale(1.1);
  }

  @media (max-width: $--sm) {
    left: 16px;
    top: 50px;
  }

  @media (min-width: $--sm) {
    top: 95px;
  }
}
</style>
