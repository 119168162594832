
import { VALID_TABS, AD_APPROVAL_TAB_DATA } from '@/constants/adApproval';
import { CAROUSEL } from '@/constants/orgTypes';
/**
 * Run adApproval middleware
 * @param {Function} next
 * @param {Route} to
 * @param {Store} store
 * @param {VueRouter} router
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
export default async function({ next, to, store, router }) {
  // Prevent invalid affiliate or employee type to access the feature
  if (store.getters.userSubType && store.getters.userSubType !== CAROUSEL) {
    return router.replace('/');
  }

  // Prevent invalid org type
  if (!AD_APPROVAL_TAB_DATA[store.getters.organization.type] && to.params.id) {
    return router.replace(`/campaign/${to.params.id}`);
  }

  // Prevent invalid tab route
  if (to.params.tab !== 'settings' && !AD_APPROVAL_TAB_DATA[store.getters.organization.type].find((tab) => tab.id === to.params.tab)) {
    return router.push({ name: to.name, params: { ...to.params, tab: 'pending' }, query: to.query });
  }

  // Prevent invalid status on campaign routes
  if (to.params.campaignId && !VALID_TABS.includes(to.params.tab)) {
    return router.push({ name: to.name, params: { ...to.params, tab: 'pending' }, query: to.query });
  }

  return next();
}
