import storage from '@/services/storage';

/**
 * Creates a new cache entry that can be added to the storage
 * @param key
 * @param data
 * @param lifetime
 * @return {{data: *, key: *, expiresAt: null, timestamp: number}}
 */
const cacheEntryFactory = function(key, data, lifetime = null) {
  return {
    key,
    data,
    expiresAt: lifetime === null ? null : Date.now() + lifetime,
    timestamp: Date.now()
  };
};

/**
 * Get the most relevant expiration for the cache entry
 * @param entry
 * @param lifetime
 * @return {null}
 */
const getFinalExpiresAt = function(entry, lifetime = null) {
  let expiresAt = lifetime === null ? null : entry.timestamp + lifetime;
  if (entry.expiresAt !== null) {
    expiresAt = Math.min(entry.expiresAt, expiresAt || entry.expiresAt);
  }
  return expiresAt;
};

/**
 * Checks that the cache entry structure is a valid one
 * @param entry
 * @return {boolean}
 */
const hasValidCacheEntryStructure = function(entry) {
  return entry.hasOwnProperty('data') && entry.hasOwnProperty('key') && entry.hasOwnProperty('expiresAt');
};

/**
 * Checks that a given cache entry hasn't expired
 * @param entry
 * @param lifetime
 * @return {boolean}
 */
const cacheEntryHasExpired = function(entry, lifetime = null) {
  const expiresAt = getFinalExpiresAt(entry, lifetime);
  return expiresAt !== null && expiresAt < Date.now();
};

/**
 * Checks that a given cache entry exists and hasn't expired
 * @param entry
 * @param lifetime
 * @return {boolean|*}
 */
const isValidCacheEntry = function(entry, lifetime = null) {
  if (!entry || !hasValidCacheEntryStructure(entry)) {
    return false;
  }

  const cacheExpired = cacheEntryHasExpired(entry, lifetime);
  if (cacheExpired) {
    removeCacheEntry(entry.key);
  }

  return !cacheExpired;
};

/**
 * Removes an entry from the cache
 * @param key
 */
const removeCacheEntry = function(key) {
  storage.removeItem(key);
};

/**
 * Cache Service
 */
export default {
  /**
   * Get the data of a cache entry from the storage
   * @param key
   * @param lifetime
   * @return {*}
   */
  get(key, lifetime = null) {
    const entry = storage.getObject(key);
    return isValidCacheEntry(entry, lifetime) ? entry.data : null;
  },
  /**
   * Set a cache entry on the storage
   * @param key
   * @param value
   * @param lifetime
   */
  set(key, value, lifetime = null) {
    const entry = cacheEntryFactory(key, value, lifetime);
    storage.setObject(key, entry);
  },
  /**
   * Removes a cache entry from the storage
   * @param key
   */
  remove(key) {
    removeCacheEntry(key);
  },
  /**
   * Checks if a cache entry exists and is valid
   * @param key
   * @param lifetime
   * @return {boolean|*}
   */
  exists(key, lifetime = null) {
    const entry = storage.getObject(key);
    return isValidCacheEntry(entry, lifetime);
  }
};
