/**
 * @param page
 * @return {function(): *}
 */
export default (page) => {
  return () => {
    const pages = {
      referralMarketingLayout: import('@/views/referral/referralMarketingLayout.vue'),
      referralLayout: import('@/views/referral/referralLayout.vue'),
      referralManagement: import('@/views/referral/referralManagement.vue'),
      referralPayments: import('@/views/referral/ReferralPayments.vue')
    };
    return pages[page];
  };
};
