import store from '@/store';
import _cloneDeep from 'lodash/cloneDeep';

const EVENT_TRACK = 'event-track';

function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

// eslint-disable-next-line complexity,sonarjs/cognitive-complexity
export function getEventTrackData(el) {
  let platforms = ['segment'];
  let category = 'Misc';
  let action = 'Generic Click';
  let value = 0;
  let props = {};

  const vueEl = el.__vue__;
  const nodeName = el.nodeName.toLowerCase();
  const nodeAttributes = el.attributes && el.attributes.length ? [...el.attributes].reduce((acc, curr) => { acc[curr.name] = curr.value; return acc; }, {}) : {};
  const vueAttributes = vueEl && (vueEl.$attrs || vueEl.$options.propsData) ? { ...(vueEl.$attrs || {}), ...(vueEl.$options.propsData || {}) } : {};
  const attributes = { ...nodeAttributes, ...vueAttributes };
  if (attributes[EVENT_TRACK] && isJsonString(attributes[EVENT_TRACK])) attributes[EVENT_TRACK] = JSON.parse(attributes[EVENT_TRACK]);
  if (attributes['event-track-props'] && isJsonString(attributes['event-track-props'])) attributes['event-track-props'] = JSON.parse(attributes['event-track-props']);

  // Generic button/link click
  if (nodeName === 'button') action = 'Button Click';
  if (nodeName === 'a') action = 'Link Click';

  // Get values from `event-track` attribute
  if (attributes[EVENT_TRACK]) {
    if (attributes[EVENT_TRACK].platforms) platforms = attributes[EVENT_TRACK].platforms;
    if (attributes[EVENT_TRACK].category) category = attributes[EVENT_TRACK].category;
    if (attributes[EVENT_TRACK].action) action = attributes[EVENT_TRACK].action;
    if (attributes[EVENT_TRACK].value) value = attributes[EVENT_TRACK].value;
    if (attributes[EVENT_TRACK].props) props = { ...props, ...attributes[EVENT_TRACK].props };
  }

  // Get values from individual attributes
  if (attributes['event-track-platforms']) platforms = attributes['event-track-platforms'].replace(/ /g, '').split(',').filter(x => !!x);
  if (attributes['event-track-category']) category = attributes['event-track-category'];
  if (attributes['event-track-action']) action = attributes['event-track-action'];
  if (attributes['event-track-value']) value = parseFloat(attributes['event-track-value']);
  if (attributes['event-track-props']) props = { ...props, ...attributes['event-track-props'] };

  // Default properties
  props.tag = nodeName;
  if (el.innerText) props.innerText = el.innerText;
  if (vueEl && vueEl.$vnode.tag) props.vnodeTag = vueEl.$vnode.tag;
  if (vueEl && vueEl.$parent.$vnode.tag) props.parentVNodeTag = vueEl.$parent.$vnode.tag;

  // Attributes
  Object.entries(attributes)
    .map(([key, val]) => [key.replace('event-track-', '').replace('data-', ''), val])
    .filter(([key]) => !['event-track', 'category', 'action', 'value', 'platforms'].includes(key))
    .forEach(([key, val]) => { props[key] = val; });

  return { platforms, category, action, props, value };
}

/**
 * Get user and org context
 * @returns {{}}
 */
export function getContextProps() {
  const userProps = store.getters.user ? Object.fromEntries(Object.entries(_cloneDeep(store.getters.user)).map(([key, val]) => [`user_${key}`, val])) : {};
  const orgProps = store.getters.organization ? Object.fromEntries(Object.entries(_cloneDeep(store.getters.organization)).map(([key, val]) => [`org_${key}`, val])) : {};
  return { ...userProps, ...orgProps };
}

/**
 * Track an event
 * @param {Object} options
 * @param {String[]} options.platforms
 * @param {String} options.category
 * @param {String} options.action
 * @param {{}} options.props
 * @param {Number} options.value
 */
// eslint-disable-next-line complexity,sonarjs/cognitive-complexity
export function eventTrack({ platforms = ['segment'], category = '', action = '', props = {}, value = 0 } = {}) {
  if (platforms.length === 0 || !action) return;

  // get global event properties for user / org context
  props = { ...props, ...getContextProps() };

  // get event name from category and action
  const event = category && action ? `${category} - ${action}` : action;

  // pass value into props
  if (value) props.value = value;

  // track the event on all specified platforms
  if (platforms.includes('segment') && window.analytics) {
    window.analytics.track(event, props);
  }
}

export default eventTrack;
