/**
 * Custom error object to better handle middleware errors
 */
class MiddlewareError extends Error {
  /**
   * @param {string} message
   * @param {string} path
   */
  constructor(message, path = undefined) {
    super(message);
    this.name = 'MiddlewareError';
    this.path = path;
  }
}

export default MiddlewareError;
