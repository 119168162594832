export default function(VueInstance) {
  VueInstance.prototype.$supportsPassive = (() => {
    let supportsPassive = false;
    try {
      const opts = Object.defineProperty({}, 'passive', {
        get: function() {
          supportsPassive = true;
        }
      });
      window.addEventListener('test', null, opts);
    } catch (e) { }
    return supportsPassive;
  })();

  VueInstance.prototype.$supportsTransitions = (() => {
    const b = document.body || document.documentElement;
    const s = b.style;
    let p = 'transition';
    if (typeof s[p] === 'string') {
      return true;
    }
    // Tests for vendor specific prop
    var v = ['Moz', 'webkit', 'Webkit', 'Khtml', 'O', 'ms'];
    p = p.charAt(0).toUpperCase() + p.substr(1);
    for (var i = 0; i < v.length; i++) {
      if (typeof s[v[i] + p] === 'string') {
        return true;
      }
    }
    return false;
  })();

  VueInstance.prototype.$supportsTouch = 'ontouchstart' in document.documentElement;
}
