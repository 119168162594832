<template>
  <div class="app-phone tw-relative">
    <el-tel-input
      id="phone-input-element"
      ref="input"
      class="app-input"
      default-country="CA"
      :preferred-countries="['CA', 'US', 'CN']"
      :detect-country-code-from-ip="false"
      v-bind="$attrs"
      :value="phoneNumberDetails"
      v-on="$listeners"
      @input-details="handleElTelInput">
      <template slot="prefix">
        <slot name="prefix" />
      </template>

      <template slot="suffix">
        <slot name="suffix" />
      </template>

      <template slot="append">
        <slot name="append" />
      </template>
    </el-tel-input>
  </div>
</template>

<script>
import ElTelInput from '@jumbleberry/el-tel-input';

export default {
  name: 'AppPhoneInput',
  components: { ElTelInput },
  props: {
    phoneNumberDetails: {
      type: Object,
      default: () => {
        return {
          countryCallingCode: '',
          country: '',
          nationalNumber: '',
          number: '',
          isValid: false
        };
      }
    }
  },
  methods: {
    handleElTelInput(value) {
      this.$emit('input-details', value);
    }
  }
};
</script>

<style lang="scss">
  @import '~@jumbleberry/el-tel-input/dist/elTelInput.css';

  #phone-input-element.app-input {
    @include el-input;

    .el-input-group--prepend {
      .el-input-group__prepend {
        @apply tw-border-none tw-p-0 tw-w-auto tw-bg-transparent;

        .el-select {
          @apply tw-m-0;

          .el-input__inner {
            @apply tw-shadow-none tw-border-jb-grey-100;

            &:focus {
              @apply tw-pl-10 tw-bg-grey-hover;
            }

            &:hover {
              @apply tw-shadow-none tw-border-jb-grey-100 tw-bg-grey-hover tw-text-jb-indigo;
            }
          }
        }
      }
    }
  }
</style>
