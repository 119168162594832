export const AVAILABLE_RESOURCE_PERMISSIONS = {
  // CAMPAIGN APPLICATION
  CAMPAIGN_APPLICATION_VIEW: 'campaign_application_view',
  CAMPAIGN_APPLICATION_EDIT: 'campaign_application_edit',
  CAMPAIGN_APPLICATION_SUBMIT: 'campaign_application_submit',

  // CAMPAIGN DETAILS
  CAMPAIGN_DETAILS_VIEW: 'campaign_details_view',
  CAMPAIGN_DETAILS_ADD: 'campaign_details_add',
  CAMPAIGN_DETAILS_EDIT: 'campaign_details_edit',

  // CAMPAIGN FUNNELS
  CAMPAIGN_FUNNELS_VIEW: 'campaign_funnel_view',
  CAMPAIGN_FUNNELS_ADD: 'campaign_funnel_add',
  CAMPAIGN_FUNNELS_EDIT: 'campaign_funnel_edit',

  // CAMPAIGN PIXELS
  CAMPAIGN_PIXELS_VIEW: 'campaign_pixel_view',
  CAMPAIGN_PIXELS_ADD: 'campaign_pixel_add',
  CAMPAIGN_PIXELS_EDIT: 'campaign_pixel_edit',
  CAMPAIGN_PIXELS_DELETE: 'campaign_pixel_delete',

  // CREATIVES RAW
  CAMPAIGN_CREATIVES_VIEW: 'creatives_raw_view_raw_creatives',
  CAMPAIGN_CREATIVES_ADD: 'creatives_raw_add',
  CAMPAIGN_CREATIVES_EDIT: 'creatives_raw_edit',

  // CAMPAIGN FINANCIAL REPORTS
  CAMPAIGN_REPORT_VIEW_OWN: 'financial_reports_campaign_report_view_own',
  CAMPAIGN_REPORT_VIEW_COMPANY: 'financial_reports_campaign_report_view_company',

  // CAMPAIGN SUPPRESSION
  CAMPAIGN_SUPPRESSION_VIEW: 'campaign_suppression_view',

  // CREATIVES APPROVALS
  CREATIVES_APPROVALS_PENDING_VIEW_REQUEST: 'creatives_approvals_pending_view_request',
  CREATIVES_APPROVALS_PENDING_CREATE_REQUEST: 'creatives_approvals_pending_create_request',
  CREATIVES_APPROVALS_PENDING_CREATE_APPROVED_REQUEST: 'creatives_approvals_pending_create_approved_request',
  CREATIVES_APPROVALS_PENDING_EDIT_REQUEST: 'creatives_approvals_pending_edit_request',
  CREATIVES_APPROVALS_PENDING_APPROVE_REQUEST: 'creatives_approvals_pending_approve_request',
  CREATIVES_APPROVALS_PENDING_APPROVE_WITH_EDITS_REQUEST: 'creatives_approvals_pending_approve_with_edits_request',
  CREATIVES_APPROVALS_PENDING_REJECT_REQUEST: 'creatives_approvals_pending_reject_request',
  CREATIVES_APPROVALS_PENDING_REVOKE_REQUEST: 'creatives_approvals_pending_revoke_request',
  CREATIVES_APPROVALS_NEEDS_REVISION_VIEW_REQUEST: 'creatives_approvals_needs_revision_view_request',
  CREATIVES_APPROVALS_NEEDS_REVISION_EDIT_REQUEST: 'creatives_approvals_needs_revision_edit_request',
  CREATIVES_APPROVALS_NEEDS_REVISION_REVOKE_REQUEST: 'creatives_approvals_needs_revision_revoke_request',
  CREATIVES_APPROVALS_APPROVED_VIEW_REQUEST: 'creatives_approvals_approved_view_request',
  CREATIVES_APPROVALS_APPROVED_REVOKE_REQUEST: 'creatives_approvals_approved_revoke_request',
  CREATIVES_APPROVALS_AWAITING_REVISIONS_VIEW_REQUEST: 'creatives_approvals_awaiting_revisions_view_request',
  CREATIVES_APPROVALS_AWAITING_APPROVAL_VIEW_REQUEST: 'creatives_approvals_awaiting_approval_view_request'
};

export const ALL_AVAILABLE_PERMISSIONS = {
  ...AVAILABLE_RESOURCE_PERMISSIONS,

  // FINANCIAL PAYMENTS
  PAYMENT_HISTORY_VIEW: 'financial_payment_history_view',
  PAYMENT_SETUP_VIEW: 'financial_payment_setup_view',
  PAYMENT_SETUP_ADD: 'financial_payment_setup_add',
  PAYMENT_SETUP_EDIT: 'financial_payment_setup_edit',

  // FINANCIAL REPORTS
  CAMPAIGN_DASHBOARD_VIEW_OWN: 'financial_reports_campaign_dashboard_view_own',
  CAMPAIGN_DASHBOARD_VIEW_COMPANY: 'financial_reports_campaign_dashboard_view_company',

  // SUB IDs
  SUB_IDS_VIEW: 'campaign_sub_ids_view',
  SUB_IDS_ADD: 'campaign_sub_ids_add',

  // FINANCIAL REPORTS SUB ID
  SUB_ID_VIEW_OWN: 'financial_reports_sub_id_view_own',
  SUB_ID_VIEW_COMPANY: 'financial_reports_sub_id_view_company',

  // FINANCIAL CAMPAIGN
  EDITING_BASE_CAMPAIGN_CPA: 'financial_campaign_editing_base_campaign_cpa',

  // MARKETPLACE CAMPAIGN DISCOVER
  CAMPAIGN_DISCOVER_VIEW: 'marketplace_campaign_discover_view',

  // ORGANIZATION DASHBOARD
  DASHBOARD_VIEW: 'organization_dashboard_dashboard_view',

  // ORGANIZATION MANAGEMENT
  TRANSFER_ORG_OWNERSHIP: 'organization_management_transfer_org_ownership',
  VIEW_ORGS_USER_LIST: 'organization_management_view_orgs_user_list',
  INVITE_USER_TO_ORG: 'organization_management_invite_user_to_org',
  DELETE_USER_FROM_ORGANIZATION: 'organization_management_delete_user_from_organization',
  VIEW_USER_ROLE_IN_ORGS_USER_LIST: 'organization_management_view_user_role_in_orgs_user_list',
  MODIFY_USERS_ROLE: 'organization_management_modify_user_s_role',
  VIEW_COMPANY_PARTNERSHIP_LIST: 'organization_management_view_company_partnership_list',
  VIEW_OWN_PARTNERSHIP_LIST: 'organization_management_view_own_partnership_list',
  MODIFY_USERS_ON_PARTNERSHIPS: 'organization_management_modify_users_on_partnerships',
  CREATE_NEW_PARTNERSHIP_BETWEEN_ORGS: 'organization_management_create_new_partnership_between_orgs',
  MODIFY_PARTNERSHIP_PERMISSIONS: 'organization_management_modify_partnership_permissions_resource_',

  // REPORTING PERFORMANCE
  // CSC: Clicks Sales Conversion
  CAMPAIGN_DASHBOARD_VIEW_CSC: 'reporting_performance_campaign_dashboard_view_clicks_sales_conve',
  CAMPAIGN_REPORT_VIEW_CSC: 'reporting_performance_campaign_report_view_clicks_sales_conversi',
  SUB_ID_REPORT_VIEW_CSC: 'reporting_performance_sub_id_report_view_clicks_sales_conversion'
};

export default ALL_AVAILABLE_PERMISSIONS;
