import MiddlewareError from '@/routing/middleware-error';

/**
 * Run email verification middleware
 * @param {Object} context
 * @param {Route} context.to
 * @param {VueRouter} context.router
 * @param {Store} context.store
 */
export default async function({ to, store, router }) {
  try {
    await store.dispatch('verifyEmailToken', { token: to.params.token });
    store.dispatch('showMessage', {
      content: 'Your account has been verified!',
      type: 'success',
      duration: 0
    });
    return router.replace('/signin');
  } catch (e) {
    if (e.hasOwnProperty('response')) {
      throw new MiddlewareError('Invalid verification token', '/signin');
    }
    return Promise.reject(e);
  }
}
