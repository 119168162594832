import MiddlewareError from '@/routing/middleware-error';
import { SUB_TYPES } from '@/constants/programs';

const orgMap = {
  advertiser: 'merchant',
  affiliate: 'affiliate'
};

/**
 * Get the correct type for the given sub-type
 * @param subType
 */
const getSubTypeProgram = function(subType) {
  for (const i in SUB_TYPES) {
    if (subType === SUB_TYPES[i]) {
      return i;
    }
  }
};

/**
 * Check if the password details has a sub-type already on it
 * @param passwordDetails
 * @return {*|boolean}
 */
const inviteWithSubType = function(passwordDetails) {
  return passwordDetails.org_sub_type && Object.values(SUB_TYPES).includes(passwordDetails.org_sub_type);
};

/**
 * Run invitation middleware
 * @param {Object} context
 * @param {Route} context.to
 * @param {VueRouter} context.router
 * @param {Store} context.store
 * @param {Function} context.next
 */
export default async({ next, store, to, router }) => {
  if (to.params.param1 && !Object.values(orgMap).includes(to.params.param1)) {
    throw new MiddlewareError('Invalid Organization', '/signin');
  }

  try {
    if (store.getters.isAuthenticated) {
      await store.dispatch('signout');
    }

    let passwordDetails = store.getters.passwordResetDetails;

    if (passwordDetails === null) {
      passwordDetails = await store.dispatch('getPasswordTokenDetails', { token: to.params.token });
    }

    if (inviteWithSubType(passwordDetails) && to.name !== 'signup-invitation-with-program') {
      const program = getSubTypeProgram(passwordDetails.org_sub_type);
      const url = `/signup/${orgMap[passwordDetails.org_type]}/${program}/invite/${passwordDetails.token}`;
      return router.replace(url);
    } else if (!to.name.includes('signup-invitation')) {
      return router.replace(`/signup/${orgMap[passwordDetails.org_type]}/invite/${passwordDetails.token}`);
    }

    return next();
  } catch (e) {
    if (e.hasOwnProperty('response')) {
      throw new MiddlewareError('There was an error validating the invitation', '/signin');
    }
    return Promise.reject(e);
  }
};
