const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const months = ['January', 'Febuary', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'Decemeber'];
const monthsAbbrv = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

export default {
  getMonthName(date) {
    return months[date.getMonth()];
  },
  getMonthNameAbbrv(date) {
    return monthsAbbrv[date.getMonth()];
  },
  getDayName(date) {
    return days[date.getDay()];
  },
  getDateOrdinal(date) {
    date = date.getDate();
    if (date > 3 && date < 21) return date + 'th';
    switch (date % 10) {
      case 1: return date + 'st';
      case 2: return date + 'nd';
      case 3: return date + 'rd';
      default: return date + 'th';
    }
  },
  parseISOString(s) {
    const b = s.split(/\D+/);
    return new Date(Date.UTC(b[0], --b[1], b[2]));
  }
};
