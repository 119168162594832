import CampaignReportingGroup from '@/router/groups/reporting';

export default (breadcrumb) => [
  {
    path: ':reportType',
    name: `${breadcrumb}-reports`,
    component: CampaignReportingGroup('report'),
    meta: {
      middleware: ['affiliateReportOptions', 'reportNavigationStack'],
      tab: 'reports'
    },
    children: [
      {
        path: 'daily',
        name: `${breadcrumb}-affiliate-reporting-daily`,
        component: CampaignReportingGroup('daily'),
        meta: {
          middleware: ['rightSidebarContent'],
          reportSubType: 'daily',
          tab: 'reports'
        }
      },
      {
        path: ':reportSubType',
        name: `${breadcrumb}-affiliate-reporting-subType`,
        component: CampaignReportingGroup('metrics'),
        meta: {
          middleware: ['rightSidebarContent'],
          disableReportNavigationStack: true,
          tab: 'reports'
        }
      }
    ]
  }
];
