import qs from 'qs';

/**
 * Parse or stringify a set of keys
 * @param {object} object
 * @param {array} keysToPrepare
 * @param {string} action
 */
const prepareReportOptions = function(object, action) {
  const parsed = {};

  ['report', 'subReport'].forEach((report) => {
    try {
      object[report] = action === 'parse' ? JSON.parse(object[report]) : object[report];
    } catch (err) {
      object[report] = undefined;
    }

    if (!object[report] || !Object.keys(object[report]).length) {
      return;
    }

    Object.keys(object[report]).forEach(key => {
      if (!isNaN(parseFloat(object[report][key])) && isFinite(object[report][key])) {
        object[report][key] = parseInt(object[report][key]);
      } else if (object[report][key] === undefined) {
        delete object[report][key];
      }
    });

    object[report] = Object.assign({ startDate: undefined, endDate: undefined }, object[report]);
    parsed[report] = action === 'stringify' ? JSON.stringify(object[report]) : object[report];
  });

  return parsed;
};

const prepareJsonOptions = function(object, keysToPrepare, action) {
  keysToPrepare.forEach((key) => {
    if (object.hasOwnProperty(key)) {
      object[key] = JSON[action](object[key]);
    }
  });
  return object;
};

/**
 * Service to wrap default qs library to parse/stringify query string parameters
 */
export default {
  parse(queryString) {
    return prepareReportOptions(qs.parse(queryString), 'parse');
  },
  stringify(options) {
    return qs.stringify(prepareReportOptions(options, 'stringify'));
  },
  apify(options, jsonOptions) {
    return qs.stringify(prepareJsonOptions(options, jsonOptions, 'stringify'));
  }
};
