import StringTemplate from '@/helpers/StringTemplate';

const STATUS_REDIRECTS = {
  incomplete: '/signup/splash',
  complete: '/signup/survey/confirm',
  confirmed: '/signup/survey/finish'
};

/**
 * Check if the new answer is different from the one currently on the api
 * @param newAnswer
 * @param oldAnswer
 */
const hasAnswerChanged = function(newAnswer, oldAnswer) {
  return JSON.stringify(newAnswer) !== JSON.stringify(oldAnswer);
};

/**
 * Checks if a question was already answered
 * @param questionKey
 * @param questions
 * @return {boolean}
 */
const questionHasAnswer = function(questionKey, questions) {
  if (questions[questionKey].type === 'group') {
    return Object.keys(questions[questionKey].questions).some(key => {
      return (
        questions[questionKey].questions[key].answer !== null && questions[questionKey].questions[key].answer !== ''
      );
    });
  } else {
    return (
      questions[questionKey].hasOwnProperty('answer') &&
      (questions[questionKey].answer instanceof Object && !(questions[questionKey].answer instanceof Array)
        ? Object.keys(questions[questionKey].answer).some(subkey => questions[questionKey].answer[subkey] !== null)
        : questions[questionKey].answer !== null && questions[questionKey].answer !== '')
    );
  }
};

/* eslint-disable camelcase */
/**
 * Checks if a question matches an "skip_if" rule
 * @param question
 * @param questions
 * @return {boolean}
 */
const questionSkipMatches = function({ skip_if }, questions) {
  if (!skip_if) return false;

  const skipCondition = StringTemplate.compile(skip_if.key, questions);
  return skipCondition.includes(skip_if.value);
};

/**
 * Checks if a question matches an "ask_if" rule
 * @param question
 * @param questions
 */
const questionAskMatches = function({ ask_if }, questions) {
  if (!ask_if) return true;

  const showCondition = StringTemplate.compile(ask_if.key, questions);
  return ('value' in ask_if && showCondition.includes(ask_if.value)) || ('not_value' in ask_if && !showCondition.includes(ask_if.not_value));
};
/* eslint-enable camelcase */

/**
 * Check if the next question should be skipped
 * ---------------------------------
 * Reasons to skip a question:
 * | Question has a "skip_if" condition matching another question answer
 * | Question has a "ask_if" condition that does not match
 * | Question was already answered
 * ---------------------------------
 * @param questionKey
 * @param state
 * @return {boolean}
 */
const shouldSkipQuestion = function(questionKey, state) {
  return (
    questionHasAnswer(questionKey, state.questions) ||
    questionSkipMatches(state.questions[questionKey], state.questions) ||
    !questionAskMatches(state.questions[questionKey], state.questions)
  );
};

/**
 * Check if the next question must be shown event if already answered or the survey is complete
 * This is useful for validation type questions
 * @param questionKey
 * @param state
 * @returns {boolean}
 */
const shouldForceNextQuestion = function(questionKey, state) {
  const question = state.questions[questionKey];
  return question.ask_if && questionAskMatches(question, state.questions);
};

/**
 * Get the previous question for the navigation
 * @param currentKey
 * @param state
 * @return {any}
 */
const getPreviousQuestionKey = function(currentKey, state) {
  const questionKeys = Object.keys(state.questions);
  const currentKeyIndex = currentKey === null ? -1 : questionKeys.indexOf(currentKey);
  const previousQuestionKey = questionKeys.hasOwnProperty(currentKeyIndex - 1)
    ? questionKeys[currentKeyIndex - 1]
    : null;

  if (previousQuestionKey !== null &&
        (questionSkipMatches(state.questions[previousQuestionKey], state.questions) || !questionAskMatches(state.questions[previousQuestionKey], state.questions))) {
    return getPreviousQuestionKey(previousQuestionKey, state);
  }
  return previousQuestionKey;
};

/**
 * Check if a question should be shown in the survey summary page
 * @param question
 * @param state
 * @returns {boolean}
 */
const shouldShowQuestionInSummary = function(question, state) {
  return !question.hide_summary && (!questionSkipMatches(question, state.questions) && questionAskMatches(question, state.questions));
};

const shouldReloadQuestions = function(questionKey, state) {
  const question = state.questions.hasOwnProperty(questionKey) ? state.questions[questionKey] : null;
  if (!question || !question.hasOwnProperty('reload_if')) {
    return false;
  }
  const reloadCondition = StringTemplate.compile(question.reload_if.key, state.questions);
  return reloadCondition === question.reload_if.value;
};

/**
 * Get the next question for the navigation
 * @param currentKey
 * @param state
 * @param allowForceNextQuestion
 * @return {any}
 */
const getNextQuestionKey = function(currentKey, state, allowForceNextQuestion = false) {
  const questionKeys = Object.keys(state.questions);
  const currentKeyIndex = currentKey === null ? -1 : questionKeys.indexOf(currentKey);
  const nextQuestionKey = questionKeys.hasOwnProperty(currentKeyIndex + 1) ? questionKeys[currentKeyIndex + 1] : null;

  if (nextQuestionKey === null) {
    return nextQuestionKey;
  }

  if (!allowForceNextQuestion && shouldForceNextQuestion(nextQuestionKey, state)) {
    return nextQuestionKey;
  }

  if (shouldSkipQuestion(nextQuestionKey, state)) {
    return getNextQuestionKey(nextQuestionKey, state, true);
  }

  return nextQuestionKey;
};

const QuestionNavigationHelper = {
  getNextQuestionKey,
  shouldShowQuestionInSummary,
  hasAnswerChanged,
  getPreviousQuestionKey,
  questionSkipMatches,
  shouldReloadQuestions
};

export default QuestionNavigationHelper;

export { STATUS_REDIRECTS };
