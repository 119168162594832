import { formatReferralStatsFromApi, formatReferralTimelineFromApi, formatReferralDetailsFromApi } from '@/mappers/referral/format';

export default {
  state: {
    referralStats: formatReferralStatsFromApi(),
    referralTimeline: {},
    referralDetails: [],
    referralLink: '',
    isSetupComplete: false,
    referralTermsIsChecked: false
  },
  getters: {
    referralStats: (state) => state.referralStats,
    referralTimeline: (state) => state.referralTimeline,
    referralDetails: (state) => state.referralDetails,
    referralLink: (state) => state.referralLink,
    isSetupComplete: (state) => state.isSetupComplete,
    referralTermsIsChecked: (state) => state.referralTermsIsChecked
  },
  mutations: {
    SET_USER(state, user) {
      if (user.payment_enabled) state.isSetupComplete = true;
      state.referralLink = `https://partners.jumbleberry.com/signup/referral/${user.referral_code}`;
    },
    SET_REFERRAL_STATS(state, data = {}) {
      state.referralStats = data;
    },
    SET_REFERRAL_TIMELINE(state, data = {}) {
      state.referralTimeline = data;
    },
    SET_REFERRAL_DETAILS(state, data = []) {
      state.referralDetails = data;
    },
    SET_COMPLETE_SETUP(state, data) {
      state.isSetupComplete = data;
    },
    REFERRAL_TERMS_SET_CHECK(state, data) {
      state.referralTermsIsChecked = data;
    }
  },
  actions: {
    setupComplete({ commit }, data) {
      commit('SET_COMPLETE_SETUP', data);
    },
    fetchReferralSummarySuccess({ commit, getters }, { response }) {
      const { summary, payments } = response.data;
      const paymentEnabled = getters.user.payment_enabled;
      const stats = formatReferralStatsFromApi(summary);
      const timeline = formatReferralTimelineFromApi(payments, paymentEnabled, summary.pending_credits);
      commit('SET_REFERRAL_STATS', stats);
      commit('SET_REFERRAL_TIMELINE', timeline);
      return { stats, timeline };
    },
    fetchReferralDetailsSuccess({ commit }, { response }) {
      const details = formatReferralDetailsFromApi(response.data);
      commit('SET_REFERRAL_DETAILS', details);
      return details;
    },
    setReferralCheck({ commit }, data) {
      commit('REFERRAL_TERMS_SET_CHECK', data);
    }
  }
};
