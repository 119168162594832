
export const MANAGERS = {
  'shelley-gu': {
    name: 'Shelley Gu',
    orgType: 'affiliate',
    program: 'carousel',
    title: 'Partner Manager'
  },
  'dane-armstrong': {
    name: 'Dane Armstrong',
    orgType: 'affiliate',
    program: 'carousel',
    title: 'Jr. Partner Manager'
  },
  'alleya-khan': {
    name: 'Alleya Khan',
    orgType: 'affiliate',
    program: 'carousel',
    title: 'Agency Partner Coordinator'
  },
  'daniel-roberts': {
    name: 'Daniel Roberts',
    orgType: 'affiliate',
    program: 'carousel',
    title: 'Agency Partner Coordinator'
  },
  'sandra-woo': {
    name: 'Sandra Woo',
    orgType: 'merchant',
    program: 'carousel'
  },
  'heather-kelly': {
    name: 'Heather Kelly',
    orgType: 'merchant',
    program: 'carousel'
  },
  'dane-vanden-heuvel': {
    name: 'Dane Vanden Heuvel',
    orgType: 'affiliate',
    program: 'pipeline'
  },
  'min-chang': {
    name: 'Min Chang',
    orgType: 'affiliate',
    program: 'carousel'
  },
  'mina-yoon': {
    name: 'Mina Yoon',
    orgType: 'affiliate',
    program: 'pipeline'
  },
  'meg-david': {
    name: 'Meg David',
    orgType: 'affiliate',
    program: 'pipeline'
  },
  'hulland-bui': {
    name: 'Hulland Bui',
    orgType: 'affiliate',
    program: 'pipeline'
  },
  'cameron-o\'neill': {
    name: 'Cameron O\'Neill',
    orgType: 'affiliate',
    program: 'pipeline'
  },
  'fraser-maclachlan': {
    name: 'Fraser Maclachlan',
    orgType: 'affiliate',
    program: 'carousel'
  },
  'senth-sivakumaran': {
    name: 'Senth Sivakumaran',
    orgType: 'affiliate',
    program: 'carousel'
  },
  'fred-jean-bart': {
    name: 'Fred Jean Bart',
    orgType: 'affiliate',
    program: 'carousel'
  },
  'cooper-voskuil': {
    name: 'Cooper Voskuil',
    orgType: 'merchant',
    program: 'carousel'
  },
  'aaron-paul': {
    name: 'Aaron Paul',
    orgType: 'merchant',
    program: 'carousel'
  },
  'samir-azizi': {
    name: 'Samir Azizi',
    orgType: 'affiliate',
    program: 'pipeline',
    title: 'Client Success Manager'
  },
  'lisa-hill': {
    name: 'Lisa Hill',
    orgType: 'affiliate',
    program: 'pipeline',
    title: 'Jr. Affiliate Manager'
  },
  'greg-fitzgerald': {
    name: 'Greg Fitzgerald',
    orgType: 'merchant',
    program: 'pipeline'
  },
  'conor-kieran': {
    name: 'Conor Kieran',
    orgType: 'merchant',
    program: 'pipeline'
  },
  'giancarlo-rios': {
    name: 'Giancarlo Rios',
    orgType: 'merchant',
    program: 'pipeline'
  }
};
