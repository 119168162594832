import redirectService from '@/services/redirect';

export default {
  props: ['org'],
  render: function(createElement) {
    return createElement('div');
  },
  created: function() {
    redirectService({
      router: this.$router,
      api: this.$api,
      store: this.$store,
      to: this.$route
    })
      .then(redirectUrl => {
        if (this.org) {
          this.$store.dispatch('selectOrganization', this.org);
        }
        this.$router.replace(redirectUrl);
      })
      .catch(e => {
        this.$router.replace('/');
      });
  }
};
