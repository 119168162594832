export default {
  /**
   * Get the question answer summary
   * @param {Object} question
   * @param {Object} options
   * @return {*}
   */
  getAnswerSummary(question, options) {
    const key = question.key === 'country' ? 'name' : 'id';
    const selectedOption = options.find(o => o[key] === question.answer);
    if (!selectedOption) {
      return 'Not found';
    }
    return selectedOption.name;
  }
};
