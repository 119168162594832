import CampaignLayout from '@/views/campaign/CampaignLayout';
import RouterViewLayout from '@/views/RouterViewLayout';
import CampaignDetailsGroup from '@/router/groups/campaign-details';
import CampaignReportingGroup from '@/router/groups/reporting';
import ReportRoutes from '@/router/sections/reports';

const noScroll = true;
export default [
  {
    path: ':id',
    component: CampaignLayout,
    meta: {
      middleware: ['campaign']
    },
    children: [
      {
        path: '/',
        name: 'dashboard-campaign-details',
        redirect: 'marketing',
        meta: {
          noScroll
        }
      },
      {
        path: 'apply',
        name: 'dashboard-campaign-setup-apply',
        meta: {
          noScroll,
          middleware: ['affiliate', 'campaignStatus'],
          campaignStatus: 'unapproved',
          tab: 'apply'
        },
        component: CampaignDetailsGroup('application')
      },
      {
        path: 'marketing',
        name: 'dashboard-campaign-setup-marketing',
        meta: {
          noScroll,
          tab: 'marketing'

        },
        component: CampaignDetailsGroup('marketing')
      },
      {
        path: 'performance',
        name: 'dashboard-campaign-reporting',
        component: CampaignDetailsGroup('performance'),
        meta: {
          noScroll,
          middleware: ['campaignStatus'],
          tab: 'performance'
        }
      },
      {
        path: 'pixels',
        name: 'dashboard-campaign-setup-pixels',
        meta: {
          noScroll,
          middleware: ['affiliate', 'campaignStatus'],
          tab: 'pixels'

        },
        component: CampaignDetailsGroup('pixels')
      },
      {
        path: 'funnels',
        name: 'dashboard-campaign-setup-funnels',
        meta: {
          noScroll,
          middleware: ['campaignStatus', 'funnels'],
          tab: 'funnels'
        },
        component: CampaignDetailsGroup('links')
      },
      {
        path: 'suppression',
        name: 'dashboard-campaign-setup-suppression',
        meta: {
          noScroll,
          tab: 'suppression',
          middleware: ['affiliate', 'campaignStatus']
        },
        component: CampaignDetailsGroup('suppression')
      },
      {
        path: 'creatives',
        name: 'dashboard-campaign-setup-creatives',
        meta: {
          noScroll,
          middleware: ['campaignStatus'],
          tab: 'creatives'
        },
        component: CampaignDetailsGroup('creatives')
      },
      {
        path: 'reports',
        name: 'reports-layout',
        component: RouterViewLayout,
        redirect: to => ({
          name: 'dashboard-campaign-setup-reports',
          params: { id: to.params.id, reportType: 'mtd' }
        }),
        children: [...ReportRoutes('dashboard-campaign-setup')]
      },
      {
        path: 'approvals',
        name: 'approval-router-view',
        redirect: to => ({
          name: 'dashboard-campaign-setup-approvals',
          params: { id: to.params.id, tab: 'pending' }
        }),
        component: RouterViewLayout,
        children: [
          {
            path: ':tab',
            name: 'dashboard-campaign-setup-approvals',
            component: CampaignDetailsGroup('adApprovalLayout'),
            meta: {
              middleware: ['adApproval', 'campaignStatus'],
              tab: 'approvals'
            }
          }
        ]
      }
    ]
  }
];
