export default function(VueInstance) {
  const capitalize = (value) => {
    if (!value) return '';
    value = value.toString().toLowerCase();
    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  VueInstance.filter('capitalize', capitalize);
  VueInstance.filter('capitalizeAll', (value) => {
    if (!value) return '';
    return value.split(' ').map(capitalize).join(' ');
  });
}
