<template>
  <div class="app-card" :class="{'app-card__hover': hover}" @click="$emit('click')">
    <div v-if="hasHeaderSlot" class="app-card__header">
      <slot name="header" />
    </div>
    <div class="app-card__body" :class="{'app-card__body__hover': hover, 'tw-bg-white': background === 'white'}">
      <slot />
    </div>
    <div v-if="hasFooterSlot" class="app-card__footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppCard',
  props: {
    hover: {
      type: Boolean,
      default: true
    },
    background: {
      type: String,
      default: 'transparent'
    }
  },
  computed: {
    hasHeaderSlot() {
      return !!this.$slots.header;
    },
    hasFooterSlot() {
      return !!this.$slots.footer;
    }
  }
};
</script>

<style lang="scss">
.app-card {
  width: 100%;
  border: none;

  &__hover {
    transition: $--clb-hover-transition;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    transition-duration: 0.3s;

    &:hover {
      border: 1px solid $--clb-color-primary;
    }
  }

  .app-card__body {
    overflow: hidden;
    position: relative;
    border-radius: $--clb-border-radius $--clb-border-radius 0 0;

    &__hover {
      transition: $--clb-hover-transition;
      transition-duration: 0.3s;
      transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
  }

  .app-card__footer {
    position: relative;
  }
}

@media (min-width: $--sm) {
  .app-card {
    &__hover {
      will-change: transform;

      &:hover {
        transform: translateY(-6px) !important;
        cursor: pointer;
      }
    }
  }
}
</style>
