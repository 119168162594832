/* globals rg4js, analytics */
import _get from 'lodash/get';
import _uniqBy from 'lodash/uniqBy';
import storage from '@/services/storage';
import jbxApi from '@/services/jbx-api';
import ErrorHelper from '@/helpers/ErrorHelper';
import OneSignalService from '@/services/one-signal';
import { EMPLOYEE, GENERAL, AFFILIATE, ADVERTISER, NETWORK, ADMIN, SUPERADMIN } from '@/constants/orgTypes';

/**
 * Handle the response of an auth request
 * @param response
 * @returns {user, redirect}
 */
const handleAuthUserResponse = async function(response) {
  if (!response.data._meta.success) {
    return Promise.reject(
      ErrorHelper({
        message: null,
        response
      })
    );
  }
  return Promise.resolve({
    user: response.data.data.user
  });
};

const state = {
  resetEmail: null,
  user: storage.getObject(process.env.VUE_APP_JB_USER_STORAGE_KEY, null),
  manager: storage.getObject(process.env.VUE_APP_JB_MANAGER_STORAGE_KEY, null),
  subType: null,
  orgId: storage.getItem(process.env.VUE_APP_JB_ORGANIZATION_STORAGE_KEY, null),
  passwordResetDetails: null,
  pushNotificationsEnabled: false,
  pushNotificationsSupported: false,
  pushNotificationsPermission: 'default',
  pushNotificationsPopupDismissed: storage.getItem(process.env.VUE_APP_JB_NOTIFICATION_POPUP_DISMISSED_STORAGE_KEY) === '1',
  pushNotificationsExternalUserId: null,
  orgHasActiveCampaigns: false,
  referralCode: null
};

const getters = {
  /**
   * Check user is logged in the application
   * @param {Object} state
   * @returns {boolean}
   */
  isAuthenticated(state) {
    return state.user !== null;
  },
  /**
   * Check if the user account is still on the sign up process
   * @param state
   * @param getters
   * @return {boolean|*}
   */
  isAccountSigningUp(state, getters) {
    return getters.user.status === 'in progress' && getters.organization;
  },
  /**
   * Checks that the user session is already restored
   * @param state
   * @param getters
   * @return {getters.isAuthenticated|(function(Object): boolean)|default.computed.isAuthenticated|boolean}
   */
  userSessionRestored(state, getters) {
    return getters.isAuthenticated && getters.user.sessionRestored === true;
  },
  /**
   * Get authenticated user
   * @param {Object} state
   * @returns {Object}
   */
  user(state) {
    return state.user;
  },

  /**
   * Checks if the auth user is an affiliate
   */
  userIsAffiliate(state, getters) {
    return getters.userType === AFFILIATE;
  },

  /**
   * Checks if the auth user is an advertiser
   */
  userIsAdvertiser(state, getters) {
    return getters.userType === ADVERTISER;
  },

  /**
   * Checks if the auth user is a network
   */
  userIsNetwork(state, getters) {
    return getters.userType === NETWORK;
  },

  /**
   * Checks if the auth user is an internal user
   */
  userIsInternal(state, getters) {
    return getters.userType === EMPLOYEE;
  },

  /**
   * Checks if the auth user is an admin user
   */
  userIsAdmin(state, getters) {
    return getters.userRole.includes(ADMIN);
  },
  /**
   * Checks if the auth user is an superadmin user
   */
  userIsSuperAdmin(state, getters) {
    return getters.userRole.includes(SUPERADMIN);
  },

  /**
   * Checks if the auth user is a general user
   */
  userIsGeneral(state, getters) {
    return getters.userType === GENERAL;
  },

  /**
   * Gets the user role
   */
  userRole(state, getters) {
    return getters.user.role;
  },
  /**
   * User organization type (a1, a2)
   */
  userSubType(state, getters) {
    return _get(getters.organization, 'sub_type', state.subType);
  },
  /**
   * Check if the user status is 'approved'
   */
  userApproved(state) {
    return state.user && state.user.status === 'approved';
  },
  /**
   * Get the type of auth user
   */
  userType(state, getters) {
    if (!getters.userSessionRestored || !getters.organization) {
      return null;
    }

    return getters.organization.type;
  },
  /**
   * Check if the user has an active NpsSurvey
   * @param state
   * @param getters
   * @return {boolean}
   */
  userHasNpsSurvey(state, getters) {
    return getters.userIsAffiliate && state.user.nps_survey !== null &&
      (state.user.nps_survey.org_id === null || state.user.nps_survey.org_id === getters.organizationId);
  },
  /**
   * Get the current organization id
   */
  organizationId(state, getters) {
    return (getters.organization || {}).id;
  },
  /**
   * Get the auth user active organization
   */
  organization(state, getters) {
    if (!getters.userSessionRestored || !state.orgId) {
      return null;
    }
    return state.user.orgs.find(o => o.id === state.orgId);
  },
  /**
   * Get a list of organizations available to a user
   */
  organizations(state, getters) {
    return _get(state, 'user.orgs', []);
  },
  /**
   * Get the manager for the onboarding
   * @param state
   * @return {object}
   */
  manager(state) {
    return state.manager || { alias: null, type: null };
  },
  /**
   * Get the invitation details
   * @param state
   * @return {getters.passwordResetDetails|(function(*))|null|*}
   */
  passwordResetDetails(state) {
    return state.passwordResetDetails;
  },
  /**
   * Email to reset
   * @param state
   */
  resetEmail(state) {
    return state.resetEmail;
  },
  /**
   * Check if we can safely ask the user for push notification permission
   * @param state
   */
  shouldRequestNotificationPermission(state, getters) {
    return state.orgHasActiveCampaigns &&
      ['default', 'granted'].includes(state.pushNotificationsPermission) &&
      state.pushNotificationsSupported &&
      !getters.hasNotificationPermission;
  },
  hasNotificationPermission(state) {
    return state.pushNotificationsSupported && state.pushNotificationsEnabled && state.pushNotificationsExternalUserId === state.user.user_id;
  },
  pushNotificationsPopupDismissed(state) {
    return state.pushNotificationsPopupDismissed;
  },
  pushNotificationsExternalUserId(state) {
    return state.pushNotificationsExternalUserId;
  },
  referralCode(state) {
    return state.referralCode;
  }
};

const actions = {
  /**
   * Register new user in the platform
   * @param {dispatch}
   * @param credentials
   * @returns {Promise<{user: {email: (null|*|RegExp|u.email|email|string), session_id: string}}>}
   */
  async register({ dispatch }, credentials) {
    try {
      return await dispatch(
        'restoreSession',
        await handleAuthUserResponse(await jbxApi.post('/v2/user/signup', credentials))
      );
    } catch (e) {
      dispatch('removePlatformLoader', 'user');
      return Promise.reject(e);
    }
  },

  async acceptInvite({ dispatch }, credentials) {
    try {
      return await dispatch(
        'restoreSession',
        await handleAuthUserResponse(await jbxApi.post('/v2/user/accept-invite', credentials))
      );
    } catch (e) {
      dispatch('removePlatformLoader', 'user');
      return Promise.reject(e);
    }
  },

  /**
   * Login an existing user to the platform
   * @param {dispatch}
   * @param credentials
   * @returns {Promise<{user: {email: (null|*|RegExp|u.email|email|string), session_id: string}}>}
   */
  async signin({ dispatch }, credentials) {
    try {
      return await dispatch('restoreSession', await handleAuthUserResponse(await jbxApi.post('/v2/auth', credentials)));
    } catch (e) {
      dispatch('removePlatformLoader', 'user');
      return Promise.reject(e);
    }
  },

  /**
   * Resets user password
   * @param {Object} context
   * @param {Object} credentials
   * @return {Promise<void>}
   */
  async resetPassword({ dispatch }, credentials) {
    try {
      return await dispatch(
        'restoreSession',
        await handleAuthUserResponse(await jbxApi.post('/v2/user/reset-password', credentials))
      );
    } catch (e) {
      dispatch('removePlatformLoader', 'user');
      return Promise.reject(e);
    }
  },

  /**
   * Send a reset password request to the api
   * @param {Object} context
   * @param {Object} credentials
   * @return {Promise<void>}
   */
  async sendResetPasswordToken(context, credentials) {
    return jbxApi.post('/v2/auth/forgot-password', {
      email: credentials.email
    });
  },
  /**
   * Check the invitation token against the api
   * @param {Object} context
   * @param {Function} context.dispatch
   * @param {Object} payload
   * @param {String} payload.token
   * @return {Promise<void>}
   */
  async verifyEmailToken({ dispatch }, { token }) {
    dispatch('addPlatformLoader', { key: 'email-verification', text: 'Verifying email token...' });
    try {
      const response = await jbxApi.post(`/v2/token/${token}/activate`, { token });
      const verificationTokenDetails = Object.assign({ token }, _get(response, 'data.data', {}));
      dispatch('removePlatformLoader', 'email-verification');
      return Promise.resolve(verificationTokenDetails);
    } catch (e) {
      dispatch('removePlatformLoader', 'email-verification');
      return Promise.reject(e);
    }
  },
  /**
   * Check the password token against the api
   * @param {Object} context
   * @param {Function} context.commit
   * @param {Object} payload
   * @param {String} payload.token
   * @return {Promise<void>}
   */
  async getPasswordTokenDetails({ commit, dispatch }, { token }) {
    dispatch('addPlatformLoader', { key: 'password-reset', text: 'Validating token...' });
    try {
      const response = await jbxApi.get('/v2/user/reset-password', { token });
      const passwordResetDetails = Object.assign({ token }, _get(response, 'data.data', {}));
      commit('SET_PASSWORD_RESET_DETAILS', passwordResetDetails);
      dispatch('removePlatformLoader', 'password-reset');
      return Promise.resolve(passwordResetDetails);
    } catch (e) {
      dispatch('removePlatformLoader', 'password-reset');
      return Promise.reject(e);
    }
  },

  /**
   * Set use active organization
   * @param commit
   * @param org
   */
  selectOrganization({ commit, dispatch }, org) {
    commit('SET_ACTIVE_ORGANIZATION', org);
    dispatch('fetchPermissions');
  },

  /**
   * Set use active organization
   * @param {Object} context
   * @param {Function } context.commit
   * @param {Object} context.state
   * @param {Object} payload
   * @param {String} payload.q
   * @param {Number} payload.page
   * @param {Array} payload.ids
   */
  async getOrganizations({ state, commit }, { q, page, ids = [] }) {
    try {
      const result = await jbxApi.request({
        method: 'get',
        url: '/v2/orgs',
        params: {
          search: JSON.stringify({
            q
          }),
          page,
          filter: ids.length ? JSON.stringify({
            id: ids
          }) : undefined
        },
        cancellationKey: 'GET_ORGS'
      });
      commit('SET_ORGANIZATIONS', result.data.data);
      return Promise.resolve(result);
    } catch (e) {
      return Promise.reject(e);
    }
  },

  /**
   * Update the manager
   * @param { commit }
   * @param { object } manager
   * @param { string } manager.alias
   * @param { string } manager.type
   */
  updateManager({ commit }, manager) {
    commit('SET_MANAGER', manager);
  },

  /**
   * Update the sub brand
   * @param { commit }
   * @param { string } subType
   */
  updateSubType({ commit }, subType) {
    commit('SET_SUB_TYPE', subType);
  },

  /**
   * Signout user from application
   * @param commit
   * @param dispatch
   */
  async signout({ commit, dispatch }) {
    commit('CLEAR_USER', null);
    commit('CLEAR_ACTIVE_ORGANIZATION');
    commit('CLEAR_CACHE');
    commit('CLEAR_STORE');
    dispatch('clearIntegrationsUser');
    dispatch('clearReferralCode');
    return Promise.resolve();
  },

  /**
   * Restore user session with an api token
   * @param {commit, dispatch}
   * @param apiToken
   * @returns {Promise<*>}
   */
  async signinWithApiToken({ commit, state, dispatch }, apiToken) {
    dispatch('addPlatformLoader', { key: 'user', text: 'Loading user details...' });
    try {
      jbxApi.setAuthToken(apiToken);
      const endpoint = '/v2/me' + (state.orgId ? `?org=${state.orgId}` : '');
      return await dispatch('restoreSession', await handleAuthUserResponse(await jbxApi.get(endpoint)));
    } catch (e) {
      dispatch('removePlatformLoader', 'user');
      commit('CLEAR_USER', e);
      commit('CLEAR_ACTIVE_ORGANIZATION');
      return Promise.reject(e);
    }
  },

  /**
   * Restore user session
   * @param {commit} context
   * @param {Object} user
   */
  async restoreSession({ state, commit, dispatch }, { user }) {
    user.sessionRestored = true;
    commit('SET_USER', user);

    if (user.orgs && user.orgs.length === 1) {
      dispatch('selectOrganization', user.orgs[0]);
    }

    dispatch('setIntegrationsUser', user);
    dispatch('removePlatformLoader', 'user');
    return Promise.resolve({ user });
  },
  /**
   * Saves the current email to be used as the reset email
   * @param commit
   * @param email
   */
  setResetEmail({ commit }, email) {
    commit('SET_RESET_EMAIL', email);
  },
  /**
   * Set auth user information for raygun error reporting
   * @param context
   * @param user
   */
  setIntegrationsUser({ getters, dispatch }, user) {
    window.analytics && analytics.identify(user.user_id, user);
    window.rg4js && rg4js('setUser', {
      identifier: user.user_id,
      isAnonymous: false,
      email: user.email,
      firstName: user.first_name,
      fullName: user.last_name
    });

    OneSignalService.init(window.OneSignal, { getters, dispatch });
  },
  /**
   * Clears current user for raygun error reporting
   */
  clearIntegrationsUser() {
    window.rg4js && rg4js('setUser', '');
    window.smartlook && smartlook('pause');
  },
  /**
   * Sets the sate of the push notification settings
   * @param {object} context
   * @param {object} context.getters
   * @param {function} context.commit
   * @param {Object} settings
   * @param {boolean} settings.initialLoad
   * @param {boolean} settings.pushNotificationsEnabled
   * @param {boolean} settings.pushNotificationsSupported
   * @param {string} settings.pushNotificationsPermission
   * @param {number} settings.externalUserId
   */
  setNotificationSettings({ getters, commit }, settings) {
    if (settings.pushNotificationsPermission === 'granted' && !settings.initialLoad) {
      settings.externalUserId = getters.user.user_id;
      OneSignalService.setUserId(settings.externalUserId);
    }

    commit('SET_PUSH_NOTIFICATIONS_SETTINGS', settings);
  },
  /**
   * Updates push notification preferences on the backend
   * @param context
   * @param {Object} settings
   * @param {string} settings.permission
   * @param {string} settings.browserId
   */
  updatePushNotificationUserSettings(context, { pushNotificationsPermission, browserId }) {
    jbxApi.post('/v2/notifications/preference', { browser_id: browserId, permission: pushNotificationsPermission });
  },
  /**
   * Set the pushNotificationsPopupDismissed flag to true on local storage, so the user won't see the popup again
   */
  dismissPushNotificationPopup({ commit }) {
    commit('SET_PUSH_NOTIFICATIONS_POPUP_DISMISSED', true);
  },
  /**
  * Set the user.nps_survey object manually. NOTE: for e2e test purposes only.
  */
  setUserNpsSurvey({ commit }, survey) {
    commit('SET_USER_NPS_SURVEY', survey);
  },
  /**
   * Set a given referral code on the store to be later used during signup
   */
  setReferralCode({ commit }, code) {
    commit('SET_REFERRAL_CODE', code);
  },
  /**
   * Clears any existing referral code from the store
   */
  clearReferralCode({ commit }) {
    commit('SET_REFERRAL_CODE', null);
  }
};
const mutations = {
  SET_USER(state, user) {
    state.user = user;

    // Sets full user object on mobile app
    const persistedUserObject = Object.assign({}, process.env.BUILD_TARGET === 'mobile' ? user : { session_id: user.session_id }, { sessionRestored: false });

    storage.setObject(process.env.VUE_APP_JB_USER_STORAGE_KEY, persistedUserObject);
    jbxApi.setAuthToken(user.session_id);
  },
  SET_USER_SESSION_ID(state, sessionId) {
    if (state.user && state.user.session_id && sessionId) {
      state.user.session_id = sessionId;
      jbxApi.setAuthToken(sessionId);
    }
  },
  SET_USER_STATUS(state, status) {
    if (state.user && state.user.status) {
      state.user.status = status;
    }
  },
  SET_USER_FIRST_NAME(state, firstName) {
    if (state.user && state.user.first_name) {
      state.user.first_name = firstName;
    }
  },
  SET_USER_LAST_NAME(state, lastName) {
    if (state.user && state.user.last_name) {
      state.user.last_name = lastName;
    }
  },
  SET_USER_NPS_SURVEY(state, survey) {
    if (state.user) {
      state.user.nps_survey = survey;
    }
  },
  CLEAR_USER(state) {
    state.user = null;
    jbxApi.clearAuthToken();
  },
  CLEAR_CACHE() {
    storage.clear();
  },
  CLEAR_ACTIVE_ORGANIZATION(state) {
    state.orgId = null;
    state.managerId = null;
  },
  SET_MANAGER(state, manager) {
    state.manager = manager;
    storage.setObject(process.env.VUE_APP_JB_MANAGER_STORAGE_KEY, manager);
  },
  SET_SUB_TYPE(state, subType) {
    state.subType = subType;
    storage.setObject(process.env.VUE_APP_JB_SUB_TYPE_STORAGE_KEY, subType);
  },
  SET_ORGANIZATIONS(state, orgs) {
    state.user.orgs = _uniqBy([...state.user.orgs, ...orgs], 'id');
  },
  SET_ACTIVE_ORGANIZATION(state, org) {
    state.user.orgs = _uniqBy([org, ...state.user.orgs], 'id');
    state.orgId = org.id;
    state.manager = _get(state.user, 'managers', []).find((m) => m.id === org.manager_id) || null;
    storage.setItem(process.env.VUE_APP_JB_ORGANIZATION_STORAGE_KEY, org.id);
  },
  SET_PASSWORD_RESET_DETAILS(state, passwordResetDetails) {
    state.passwordResetDetails = passwordResetDetails;
  },
  SET_RESET_EMAIL(state, email) {
    state.resetEmail = email;
  },
  SET_PUSH_NOTIFICATIONS_SETTINGS(state, { pushNotificationsEnabled, pushNotificationsSupported, pushNotificationsPermission, externalUserId }) {
    state.pushNotificationsEnabled = pushNotificationsEnabled;
    state.pushNotificationsSupported = pushNotificationsSupported;
    state.pushNotificationsPermission = pushNotificationsPermission;
    state.pushNotificationsExternalUserId = externalUserId;
  },
  SET_PUSH_NOTIFICATIONS_POPUP_DISMISSED(state, dismissed) {
    state.pushNotificationsPopupDismissed = dismissed;
    storage.setItem(process.env.VUE_APP_JB_NOTIFICATION_POPUP_DISMISSED_STORAGE_KEY, dismissed ? '1' : '0');
  },
  FETCH_REPORTING_CAMPAIGNS_SUCCESS(state, reportingCampaigns) {
    state.orgHasActiveCampaigns = reportingCampaigns.length > 0;
  },
  SUBMIT_APPLICATION_FORM_SUCCESS(state) {
    state.orgHasActiveCampaigns = true;
  },
  SET_REFERRAL_CODE(state, code) {
    state.referralCode = code;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
