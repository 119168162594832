<template>
  <div>
    <slot />
  </div>
</template>
<script>
export default {
  name: 'SidebarLayoutSidebar',
  props: {
    width: {
      type: Number,
      default: 320
    },
    fixedWidth: {
      type: Number,
      default: 99999
    },
    parallax: {
      type: Number,
      default() { return this.width; }
    },
    drawer: {
      type: Boolean,
      default: false
    },
    overlay: {
      type: Boolean,
      default: true
    },
    overlayOpacityRange: {
      type: Array,
      default: function() {
        return [0, 0.5];
      },
      validator: function(value) {
        const [min, max] = value;
        return min < max && min >= 0 && max <= 1;
      }
    },
    threshold: {
      type: Number,
      default: 0.3
    },
    visible: {
      type: Boolean,
      default: true
    },
    zIndex: {
      type: Number,
      default: undefined
    }
  },
  computed: {
    isVisible() {
      return this.visible && this.$store.getters.windowWidth >= this.fixedWidth;
    }
  },
  watch: {
    $props: {
      handler() {
        this.sidebarUpdate();
      },
      immediate: true,
      deep: true
    },
    isVisible: {
      handler() {
        this.sidebarUpdate();
      },
      immediate: true
    }
  },
  beforeDestroy() {
    this.$parent.$emit('sidebar-destroy', this.$vnode);
  },
  methods: {
    sidebarUpdate() {
      this.$parent.$emit('sidebar-update', this.$vnode, Object.assign(
        {},
        this.$props,
        {
          position: 'fixed',
          visible: this.isVisible
        }
      ));
    }
  }
};
</script>
