/**
 * Checks that the survey question trying to access is a valid one
 * @param {Object} context
 * @param {Route} context.to
 * @param {Store} context.store
 * @param {Function} context.next
 */
export default async function({ store, next, to, router }) {
  if (!store.getters.questions.hasOwnProperty(to.params.id)) {
    store.dispatch('showErrorMessage', 'Invalid survey question');
    return router.replace('/signup/splash');
  }
  return next();
}
