<template>
  <div
    class="app-global-sidebar tw-max-w-screen-app-sidebar-width tw-flex tw-flex-col tw-w-full tw-h-full tw-max-h-full tw-bg-white">
    <div
      :class="{'tw-hidden': hideLeftMenuItems}"
      class="app-global-sidebar-wrapper tw-flex tw-flex-col tw-flex-1 tw-w-full tw-h-full tw-max-h-full">
      <LogoType ref="logo" class="tw-text-primary tw-mb-layout-1 tw-mx-layout-2 md:tw-hidden tw-pt-space-4 tw-flex-shrink-0 tw-block tw-w-40" />

      <org-menu key="org-menu" v-model="orgActiveName" />

      <div class="tw-pb-space-2 tw-px-layout-1 tw-border-jb-grey-50 tw-flex tw-flex-col tw-border-b tw-border-solid" style="min-height: max-content;">
        <Component
          :is="link.children ? 'app-global-sidebar-item-dropdown' : 'app-global-sidebar-item'"
          v-for="link in topLinks"
          :key="link.route ? JSON.stringify(link.route) : link.label"
          v-bind="link" />
      </div>

      <div v-if="!isGeneral" class="tw-pt-space-2 tw-flex tw-flex-col tw-h-full tw-max-h-full tw-overflow-hidden">
        <app-global-sidebar-item label="Campaigns" icon-component="megaphone-icon" class="tw-ml-layout-1" />

        <app-global-sidebar-list-accordion v-if="isLoading && firstLoad" value="active" class="tw-pointer-events-none">
          <app-global-sidebar-list-accordion-item title="Active" name="active">
            <app-global-sidebar-item
              v-for="i in 5"
              :key="i"
              class="tw-pl-layout-2">
              <skeleton-box :width="Math.floor(100 + (Math.random() * 60)) + 'px'" height="14px" class="tw-ml-0" />
            </app-global-sidebar-item>
          </app-global-sidebar-list-accordion-item>
        </app-global-sidebar-list-accordion>

        <app-text
          v-else-if="totalCampaigns === 0"
          size="sm"
          weight="medium"
          class="tw-p-layout-1 tw-text-jb-grey-400 tw-w-3/4 tw-mx-auto tw-text-center">
          There are no campaigns to show
        </app-text>

        <app-global-sidebar-list-accordion v-else v-model="activeList">
          <app-global-sidebar-list-accordion-item
            v-if="activeCampaigns.length"
            ref="accordion-item-active"
            name="active"
            title="Active"
            :show-scrollbar="true"
            :is-loading="isLoading"
            :has-more="hasMoreCampaigns"
            @load-more="handleLoadMoreCampaigns">
            <app-global-sidebar-item
              v-for="campaign in activeCampaigns"
              :key="campaign.id"
              :label="campaign.name"
              :route="getCampaignRoute(campaign)"
              :is-active="matchesRoute(getCampaignRoute(campaign))"
              class="!tw-rounded-none tw-pl-layout-2" />
          </app-global-sidebar-list-accordion-item>

          <app-global-sidebar-list-accordion-item
            v-if="pausedCampaigns.length"
            ref="accordion-item-paused"
            name="paused"
            title="Paused"
            :show-scrollbar="true"
            :is-loading="isLoading"
            :has-more="hasMoreCampaigns"
            @load-more="handleLoadMoreCampaigns">
            <app-global-sidebar-item
              v-for="campaign in pausedCampaigns"
              :key="campaign.id"
              :label="campaign.name"
              :route="getCampaignRoute(campaign)"
              :is-active="matchesRoute(getCampaignRoute(campaign))"
              class="!tw-rounded-none tw-pl-layout-2" />
          </app-global-sidebar-list-accordion-item>

          <app-global-sidebar-list-accordion-item
            v-if="expiredCampaigns.length"
            ref="accordion-item-expired"
            name="expired"
            title="Expired"
            :show-scrollbar="true"
            :is-loading="isLoading"
            :has-more="hasMoreCampaigns"
            @load-more="handleLoadMoreCampaigns">
            <app-global-sidebar-item
              v-for="campaign in expiredCampaigns"
              :key="campaign.id"
              :label="campaign.name"
              :route="getCampaignRoute(campaign)"
              :is-active="matchesRoute(getCampaignRoute(campaign))"
              class="!tw-rounded-none tw-pl-layout-2" />
          </app-global-sidebar-list-accordion-item>
        </app-global-sidebar-list-accordion>
      </div>

      <div class="tw-px-layout-1 tw-mt-auto">
        <Component
          :is="link.children ? 'app-global-sidebar-item-dropdown' : 'app-global-sidebar-item'"
          v-for="link in bottomLinks"
          :key="link.route ? JSON.stringify(link.route) : link.label"
          v-bind="link" />
      </div>

      <div class="tw-pl-layout-2 tw-pr-layout-2 tw-pb-space-4 tw-text-jb-grey-400 tw-text-xs tw-font-medium" :class="{'tw-hidden': hideLeftMenuItems}">
        <app-link class="!tw-text-current tw-inline-block" :href="PRIVACY_POLICY" size="xs" weight="medium" external>
          Privacy
        </app-link>
        &bull;
        <app-link class="!tw-text-current tw-inline-block" :href="TERMS_AND_CONDITIONS" size="xs" weight="medium" external>
          Terms &amp; Conditions
        </app-link>
        &bull;
        <app-link class="!tw-text-current tw-inline-block" :href="AD_POLICY" size="xs" weight="medium" external>
          Ad Policy
        </app-link>
        &bull;
        <app-link class="!tw-text-current tw-inline-block" :href="ACCESSIBILITY_POLICY" size="xs" weight="medium" external>
          Accessibility
        </app-link>
        &copy; {{ currentYear }}
      </div>
    </div>
  </div>
</template>

<script>
import SkeletonBox from '@/components/skeletons/SkeletonBox';
import AppText from './AppText.vue';
import AppLink from './AppLink.vue';
import AppGlobalSidebarItem from './AppGlobalSidebarItem.vue';
import AppGlobalSidebarItemDropdown from './AppGlobalSidebarItemDropdown.vue';
import AppGlobalSidebarListAccordion from './AppGlobalSidebarListAccordion.vue';
import AppGlobalSidebarListAccordionItem from './AppGlobalSidebarListAccordionItem.vue';
import LogoType from '@/assets/svg/logotype.svg';
import OrgMenu from '@/components/organizations/OrgMenu';

import { SIDEBAR_TOP_MENU_LINKS, SIDEBAR_BOTTOM_MENU_LINKS } from '@/constants/sidebar';
import { JUMBLEBERRY, TERMS_AND_CONDITIONS, PRIVACY_POLICY, AD_POLICY, ACCESSIBILITY_POLICY } from '@/constants/externalLinks';
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
import _uniqBy from 'lodash/uniqBy';

export default {
  name: 'AppGlobalSidebar',
  components: {
    SkeletonBox,
    AppText,
    AppLink,
    AppGlobalSidebarItem,
    AppGlobalSidebarItemDropdown,
    AppGlobalSidebarListAccordion,
    AppGlobalSidebarListAccordionItem,
    OrgMenu,
    LogoType
  },
  data() {
    return {
      campaigns: [],
      firstLoad: true,
      isLoading: false,
      page: 1,
      hasMoreCampaigns: false,
      activeList: 'active',
      JUMBLEBERRY,
      TERMS_AND_CONDITIONS,
      PRIVACY_POLICY,
      AD_POLICY,
      ACCESSIBILITY_POLICY,
      orgActiveName: ''
    };
  },
  computed: {
    orgId() {
      return this.$store.getters.organization.id;
    },
    isGeneral() {
      return this.$store.getters.userIsGeneral;
    },
    userType() {
      return this.$store.getters.userType;
    },
    userRole() {
      return this.$store.getters.userRole;
    },
    activeCampaigns() {
      return this.campaigns.filter((campaign) => campaign.status === 'active');
    },
    pausedCampaigns() {
      return this.campaigns.filter((campaign) => campaign.status === 'paused');
    },
    expiredCampaigns() {
      return this.campaigns.filter((campaign) => campaign.status === 'expired');
    },
    totalCampaigns() {
      return this.activeCampaigns.length + this.pausedCampaigns.length + this.expiredCampaigns.length;
    },
    currentYear() {
      return new Date().getFullYear();
    },
    topLinks() {
      return this.menuLinksFormatter(SIDEBAR_TOP_MENU_LINKS);
    },
    bottomLinks() {
      return this.menuLinksFormatter(SIDEBAR_BOTTOM_MENU_LINKS);
    },
    rightDrawerWidth() {
      return this.$store.getters.rightDrawerWidth;
    },
    hideLeftMenuItems() {
      return this.rightDrawerWidth > 100;
    }
  },
  watch: {
    activeList(activeList) {
      if (activeList) this.orgActiveName = '';
    },
    orgActiveName(orgActiveName) {
      if (orgActiveName) this.activeList = '';
    }
  },
  async mounted() {
    if (!this.isGeneral) {
      await this.fetchCampaigns();
    }
  },
  methods: {
    async fetchCampaigns() {
      try {
        this.isLoading = true;
        const response = await this.$api.campaigns.all({
          orgId: this.orgId,
          params: { page: this.page, perPage: 100 }
        });
        this.campaigns = _uniqBy([...this.campaigns, ...response.data], 'id');
        this.hasMoreCampaigns = this.page < response._meta.page_count;
      } catch (error) {
        if (this.$api.isCancel(error)) return;
        this.$message({ message: error.message, type: 'error' });
      } finally {
        this.setDefaultList();
        this.isLoading = false;
        this.firstLoad = false;
      }
    },
    handleLoadMoreCampaigns() {
      if (this.isLoading || !this.hasMoreCampaigns) return;
      this.page += 1;
      return this.fetchCampaigns();
    },
    setDefaultList() {
      if (this.activeList === 'active' && this.activeCampaigns.length === 0 && this.pausedCampaigns.length) {
        this.activeList = 'paused';
      }

      if (this.activeList === 'active' && this.activeCampaigns.length === 0 && this.pausedCampaigns.length === 0 && this.expiredCampaigns.length) {
        this.activeList = 'expired';
      }
    },
    menuLinksFormatter(links) {
      return _cloneDeep(links)
        .filter((item) => {
          if (!item.types) return true;
          return item.types.some((type) => {
            return type === this.userType || type.includes(this.userRole);
          });
        })
        .map((item) => {
          item.isActive = this.matchesRoute(item.route);
          if (item.children) item.children = this.menuLinksFormatter(item.children);
          return item;
        });
    },
    matchesRoute(route = {}) {
      if (route.match) return !!this.$route.path.match(route.match);
      if (route.params && route.name) return this.$route.name === route.name && _isEqual(this.$route.params, route.params);
      if (route.name) return this.$route.name === route.name;
      if (route.path) return this.$route.matched.some((matched) => route.path.match(matched.regex));
      if (route.path) return this.$route.path === route.path;
      return false;
    },
    getCampaignRoute(campaign = {}) {
      if (this.$route.path.includes('/campaign/')) {
        const parts = this.$route.path.split('/').slice(3).join('/');
        return { path: `/campaign/${campaign.id}/${parts}`, match: new RegExp(`/campaign/${campaign.id}/`) };
      }
      return { path: `/campaign/${campaign.id}`, match: new RegExp(`/campaign/${campaign.id}/`) };
    }
  }
};
</script>
