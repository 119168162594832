<template>
  <div class="account-settings tw-w-full">
    <app-heading class="tw-text-h2 tw-m-0">
      Account Settings
    </app-heading>
    <account-user-profile />
    <account-contact-info />
  </div>
</template>

<script>
import AppHeading from '@/components/AppHeading.vue';
import AccountUserProfile from '@/views/accountSettings/AccountUserProfile.vue';
import AccountContactInfo from '@/views/accountSettings/AccountContactInfo.vue';

export default {
  name: 'AccountSettingsView',
  components: { AppHeading, AccountUserProfile, AccountContactInfo }
};
</script>

<style lang="scss">
.account-settings {
  @include page-layout;
}
</style>
