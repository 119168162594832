import ErrorHelper from '@/helpers/ErrorHelper';

export default ({ jbxApi, requestCaching }) => {
  const state = {
    masterShopsList: [],
    shopDetails: null,
    updatingShopsList: false,
    savingShopsList: false,
    creatingShop: false
  };

  const getters = {
    shopDetails(state) {
      return state.shopDetails;
    },
    masterShopsList(state) {
      return state.masterShopsList;
    },
    creatingShop(state) {
      return state.creatingShop;
    }
  };

  const actions = {
    /**
     * Set the shop details when the app is embedded
     * @param commit
     * @param state
     * @param shopDetails
     */
    setShopDetails({ commit, state }, shopDetails) {
      if (state.shopDetails === null) {
        commit('SET_SHOP_DETAILS', shopDetails);
      }
    },
    /**
     * Creates a new shop on jbx
     * @param commit
     * @param shopDomain
     * @param orgId
     * @param shopDetails
     */
    async createShop({ commit, state }, { shopDomain, orgId }) {
      commit('CREATE_SHOP');
      const queryString = state.shopDetails.queryString;
      return await jbxApi
        .put(`/v2/shops/${shopDomain}${queryString}`, {
          org_id: orgId
        })
        .then(response => {
          requestCaching.removeCachedUrl(`/v2/org/${orgId}/shops`);
          commit('CREATE_SHOP_SUCCESS', response.data.data);
          return Promise.resolve(response.data.data);
        })
        .catch(e => {
          commit('CREATE_SHOP_FAIL');
          return Promise.reject(ErrorHelper(e));
        });
    },
    /**
     * Fetch a list shops for the given organization
     * @param {Object} context
     * @param {Function} context.commit
     * @param {Function} context.dispatch
     * @param orgId
     * @return {Promise<*>}
     */
    async fetchShops({ commit, dispatch }, orgId) {
      return requestCaching
        .get({
          url: `/v2/org/${orgId}/shops`,
          commit: response => {
            commit('SET_MASTER_SHOPS_LIST', {
              masterShopsList: response.data.data
            });
          },
          onRequestStart: () => {
            dispatch('addPlatformLoader', {
              key: 'available-shops',
              text: 'Loading available shops...'
            });
          },
          onRequestEnd: () => {
            dispatch('removePlatformLoader', 'available-shops');
          }
        })
        .then(response => {
          return Promise.resolve(response.data.data);
        })
        .catch(e => {
          return Promise.reject(ErrorHelper(e));
        });
    }
  };
  const mutations = {
    SET_SHOP_DETAILS(state, shopDetails) {
      state.shopDetails = shopDetails;
    },
    CREATE_SHOP(state) {
      state.creatingShop = true;
    },
    CREATE_SHOP_SUCCESS(state, shopDetails) {
      state.creatingShop = false;
      state.masterShopsList.push(shopDetails);
    },
    CREATE_SHOP_FAIL(state) {
      state.creatingShop = false;
    },
    SET_MASTER_SHOPS_LIST(state, { masterShopsList }) {
      state.masterShopsList = masterShopsList;
    }
  };

  return {
    state,
    getters,
    actions,
    mutations
  };
};
