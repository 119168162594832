<template>
  <div class="app-global-sidebar-item-dropdown" style="min-height: max-content;" @click="isOpen = !isOpen">
    <slot>
      <app-global-sidebar-item v-bind="itemProps" @click.prevent>
        <template slot="prepend">
          <caret-down-icon
            class="tw-w-space-2 tw-text-jb-grey-400 tw--left-space-1 tw-absolute tw-transition tw-duration-300 tw-origin-center tw-transform"
            :class="{['tw-rotate-180']: isOpen}" />
        </template>
      </app-global-sidebar-item>
    </slot>

    <slot name="children" :children="children">
      <el-collapse-transition>
        <div v-if="isOpen" class="app-global-sidebar-link-dropdown-children">
          <app-global-sidebar-item
            v-for="link in children"
            :key="link.route ? JSON.stringify(link.route) : link.label"
            :class="{['!tw-text-opacity-75']: !link.isActive}"
            v-bind="link"
            @click.native.stop />
        </div>
      </el-collapse-transition>
    </slot>
  </div>
</template>

<script>
import AppGlobalSidebarItem from './AppGlobalSidebarItem.vue';
import CaretDownIcon from '@/assets/svg/caret-down-icon.svg';

export default {
  name: 'AppGlobalSidebarItemDropdown',
  components: { AppGlobalSidebarItem, CaretDownIcon },
  props: {
    route: {
      type: Object,
      required: false,
      default: undefined
    },
    label: {
      type: String,
      required: true
    },
    iconComponent: {
      type: String,
      required: false,
      default: undefined
    },
    iconClass: {
      type: String,
      required: false,
      default: undefined
    },
    isActive: {
      type: Boolean,
      default: false
    },
    children: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isOpen: false
    };
  },
  computed: {
    itemProps() {
      const { children, ...props } = this.$props;
      return props;
    }
  }
};
</script>
