import _get from 'lodash/get';

/**
 * Sets the initial theme for the application
 * @param {Store} store
 * @param {Document} document
 */
const initializeTheme = function(store, document) {
  document.documentElement.className.split(/\s+/).forEach(themeClass => {
    if (themeClass.includes('theme-')) {
      store.dispatch('setActiveTheme', { document, themeClass });
    }
  });
};

/**
 * Sets the active theme for the application
 * @param {Route} to
 * @param {Store} store
 * @param {Document} document
 */
const setActiveTheme = function(to, store, document) {
  const themeName = to.matched.reduce((themeName, record) => {
    return _get(record, 'meta.theme', themeName);
  }, null);
  const themeClass = `theme-${themeName}`;
  if (themeClass !== store.getters.activeTheme) {
    store.dispatch('setActiveTheme', { document, themeClass });
  }
};

/**
 * Sets the correct theme for the application
 * @param {Object} context
 * @param {Route} context.to
 * @param {Store} context.store
 * @param {Function} context.next
 * @param {Document} context.document
 */
export default async({ to, store, next, document }) => {
  if (store.getters.activeTheme === null) {
    initializeTheme(store, document);
  }
  setActiveTheme(to, store, document);
  return next();
};
