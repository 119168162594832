export const ALLOWED_ORG_TYPES = ['merchant', 'affiliate', 'advertiser', 'employee'];
export const EMPLOYEE = 'employee';
export const NETWORK = 'network';
export const ADVERTISER = 'advertiser';
export const AFFILIATE = 'affiliate';
export const GENERAL = 'general';
export const ADMIN = 'admin';
export const SUPERADMIN = 'superadmin';
export const PIPELINE = 'a1';
export const CAROUSEL = 'a2';
export const ORG_SUB_TYPES = [PIPELINE, CAROUSEL];
