<template>
  <app-text
    :size="size"
    :weight="weight"
    :color="color"
    type="a"
    :href="href"
    class="app-link"
    :class="{ ['underline-' + underline] : underline}"
    :target="targetComputed"
    @click="click">
    <slot />
  </app-text>
</template>

<script>
import AppText from '@/components/AppText';

export default {
  name: 'AppLink',
  components: { AppText },
  props: {
    href: {
      type: String,
      default: ''
    },
    underline: {
      type: String,
      default: 'hover-on',
      validator: function(value) {
        // The underline must match one of these values
        // underline="hover-on" means the underline will appear on hover
        // underline="hover-off" will have underline all the time EXCEPT hover
        return ['hover-on', 'hover-off', 'none', 'always'].indexOf(value) !== -1;
      }
    },
    color: {
      type: String,
      default: 'primary'
    },
    weight: {
      type: String,
      default: 'normal'
    },
    size: {
      type: String,
      default: 'default'
    },
    external: {
      type: Boolean,
      default: false
    },
    target: {
      type: String,
      default: null
    }
  },
  computed: {
    targetComputed() {
      if (this.external) return '_blank';
      return '_self';
    }
  },
  methods: {
    click($e) {
      // if there's a click function on the component, prevent default
      if (this.$listeners.click) {
        $e.preventDefault();
        this.$emit('click');
      }
    }
  }
};
</script>

<style lang="scss">
.app-link {
  &.underline {
    &-hover-on {
      @apply tw-no-underline;
      &:hover { @apply tw-underline; }
    }

    &-hover-off {
      @apply tw-underline;
      &:hover { @apply tw-no-underline; }
    }

    &-always {
      @apply tw-underline;
      &:hover { @apply tw-underline; }
    }

    &-none {
      @apply tw-no-underline;
      &:hover { @apply tw-no-underline; }
    }
  }
}
</style>
