import jbxApi from '@/services/jbx-api';

/**
 * @param {ApiContext} context
 * @return {object}
 */
export default ({ fetch, store }) => {
  return {
    getSurveyData(surveyId) {
      return fetch(`/v2/nps-survey/${surveyId}`);
    },
    sendSurveyData(surveyId, data) {
      return jbxApi.post(`/v2/nps-survey/${surveyId}`, {
        ...data,
        org_id: store.getters.organizationId
      });
    }
  };
};
