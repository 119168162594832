<template>
  <div>
    <div :class="{ 'no-flow': isNotAuthenticated }" class="header">
      <ProgressBar
        v-if="showSignoutButton && isSignupRoute"
        :initial="isSplashRoute && $store.getters.getLatestSavedQuestionIndex() === 0? 0: 5"
        :total="$store.getters.getQuestionKeysWithSkip().length"
        :current="$store.getters.getLatestSavedQuestionIndex()"
        :fake-factor="5"
        class="survey-progress-bar" />
      <div class="jb-logo-cont">
        <transition :duration="isSignupRoute? 600: 0">
          <div class="logo-link-portal">
            <a key="logo-link" :href="JUMBLEBERRY_LINK" class="logo-link">
              <Logo class="logo" :class="logoColor" />
            </a>
          </div>
        </transition>
        <transition name="el-fade-in">
          <portal-target v-if="showBackButton" name="back-button-link">
            <a class="back-link" href="#" @click.prevent="goBack">
              <i class="el-icon-back" />
            </a>
          </portal-target>
        </transition>
        <span v-if="showSignoutButton" class="signout-link-container">
          <router-link :to="{ name: 'signout' }">{{ signoutButtonText }}</router-link>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import Logo from '@/assets/svg/logo.svg';
import ProgressBar from '@/components/signup/ProgressBar';
import Breakpoints from '@/mixins/Breakpoints';
import { JUMBLEBERRY } from '@/constants/externalLinks';

export default {
  name: 'SignupHeader',
  components: {
    Logo,
    ProgressBar
  },
  mixins: [Breakpoints([768])],
  props: {
    showSignoutButton: {
      type: Boolean,
      default: true
    },
    isNotAuthenticated: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      JUMBLEBERRY_LINK: JUMBLEBERRY
    };
  },
  computed: {
    logoColor() {
      if (this.isNotAuthenticated &&
        Object.keys(this.$route.params).length === 1 &&
        (this.$route.params.param1 === 'merchant' || this.windowBreakpoint < 768)) {
        return 'white';
      }
      return 'blue';
    },
    showBackButton() {
      if (this.$route.path === '/signup/survey/phone_verification_code') return this.$route.meta.showBackButton; // Always show on validation page
      return this.$route.meta.showBackButton && this.$store.getters.allowBackNavigation;
    },
    isSignupRoute() {
      return this.$route.path.startsWith('/signup') && this.$route.name !== 'signup-survey-finish';
    },
    isSplashRoute() {
      return this.$route.path.startsWith('/signup/splash');
    },
    signoutButtonText() {
      return this.isSignupRoute ? 'Save & Exit' : 'Exit';
    }
  },
  methods: {
    goBack() {
      if (this.$store.state.survey.surveyInitialized) {
        this.$store
          .dispatch('previousQuestion', {
            questionKey: this.$store.getters.getQuestionByKey(this.$route.params.id).key
          })
          .then(payload => {
            this.$router.push(payload.redirect);
          })
          .catch(() => {});
      } else {
        history.back();
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.header {
  height: 80px;
  transition: height 0.5s ease-in-out;
  width: 100%;
  z-index: 13;

  &.no-flow {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.signout-link-container {
  line-height: 40px;
  text-align: right;
  flex: auto;

  a {
    color: $--tooltip-color;
    font-weight: 700;
    padding: 0 20px;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
}

.logo-link {
  font-size: 0;

  .logo {
    color: $--jb-bg-primary-color;
    height: 40px;
    transition: color 500ms ease-out, transform 300ms ease-out;
    width: 40px;

    &:hover {
      color: #fdfdfd;
      transform: translate(-2px, -4px);
    }

    &.blue {
      color: $--jb-dark-primary-color;

      &:hover {
        color: $--jb-light-primary-color;
      }
    }
  }
}
</style>
