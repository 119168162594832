<template>
  <div id="app-search-list-v2" class="app-search-list-v2">
    <div v-if="enableSearch" class="app-search-list-v2__input">
      <el-input
        key="icon"
        v-model="search"
        :placeholder="searchPlaceholder"
        size="mini"
        class="searchbox-query"
        @focus="handleFocus"
        @blur="hideScrollBar" />
      <SearchIcon class="search-icon" />
      <CancelIcon v-if="hasSearch" class="cancel-icon" @click="clear" />
    </div>

    <el-scrollbar v-if="isListVisible" ref="scrollbar" class="el-scrollbar">
      <transition
        name="list-item"
        class="app-search-list-v2__wrapper"
        tag="div"
        mode="out-in">
        <slot name="pre-list" />
        <div v-if="loading && $slots.loading && !entries.length" key="loading">
          <slot name="loading" />
        </div>
        <template v-else-if="entries.length || loading">
          <div
            key="content"
            v-loading="loading"
            class="app-search-list-v2__entries">
            <slot name="header" />
            <div
              v-for="(entriesGroup, entriesGroupKey) in entriesGroups"
              :key="`entry-group-${entriesGroupKey}`"
              class="app-search-list-v2__entries-group">
              <h4 v-if="groupByHeaders[entriesGroupKey]" ref="groupHeader" class="app-search-list-v2__entries-group-header">
                {{ groupByHeaders[entriesGroupKey] }}
              </h4>
              <slot
                v-for="(entry, index) in entriesGroup"
                :index="index"
                :entry="entry">
                <div :key="index" class="app-search-list-v2__entry">
                  Item {{ index }}
                </div>
              </slot>
            </div>
            <div class="app-search-list-v2__entries-group">
              <slot name="inner-footer" class="scroll-to-load" />
            </div>
          </div>
        </template>
        <slot v-else name="no-content">
          <div key="no-content" class="app-search-list-v2__entry disabled" />
        </slot>
      </transition>
    </el-scrollbar>
  </div>
</template>

<script>

import _groupBy from 'lodash/groupBy';
import _debounce from 'lodash/debounce';
import { Scrollbar } from 'element-ui';
import SearchIcon from '@/assets/svg/search-icon.svg';
import CancelIcon from '@/assets/svg/cancel-icon.svg';

export default {
  name: 'AppSearchListV2',
  components: { 'el-scrollbar': Scrollbar, SearchIcon, CancelIcon },
  props: {
    entries: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    enableSearch: {
      type: Boolean,
      default: true
    },
    searchPlaceholder: {
      type: String,
      default: 'Search'
    },
    showContent: {
      type: Boolean,
      default: false
    },
    groupBy: {
      type: String,
      default: ''
    },
    groupByHeaders: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      search: '',
      isListVisible: false,
      debouncedScrollListener: _debounce(this.checkLoadMore, 100, { maxWait: 100, leading: true })
    };
  },
  computed: {
    hasSearch() {
      return this.search.length > 0;
    },
    hasGroupHeaders() {
      return Object.keys(this.groupByHeaders).length > 0;
    },
    entriesGroups() {
      const entriesGroups = _groupBy(this.entries, this.groupBy);
      if (this.hasGroupHeaders) {
        const orderedGroup = {};
        Object.keys(this.groupByHeaders).forEach(k => {
          if (entriesGroups[k] && entriesGroups[k].length) {
            orderedGroup[k] = entriesGroups[k];
          }
        });
        return orderedGroup;
      }
      return entriesGroups;
    }
  },
  watch: {
    search() {
      this.$emit('search', this.search);
    },
    showContent: {
      immediate: true,
      handler(showContent) {
        this.isListVisible = showContent;
      }
    },
    isListVisible: {
      immediate: true,
      handler(isListVisible) {
        this.$nextTick(() => {
          if (this.$refs.scrollbar) {
            if (!isListVisible) return this.$refs.scrollbar.$off('hook:updated');
            this.$refs.scrollbar.update();
            this.$refs.scrollbar.$on('hook:updated', this.debouncedScrollListener);
          }
        });
      }
    }
  },
  mounted() {
    if (this.$refs.scrollbar) {
      this.$refs.scrollbar.$on('hook:updated', this.debouncedScrollListener);
    }
  },
  updated() {
    if (this.$refs.scrollbar) {
      this.$refs.scrollbar.update();
    }
  },
  beforeDestroy() {
    if (this.$refs.scrollbar) {
      this.$refs.scrollbar.$off('hook:updated');
    }
  },
  methods: {
    handleFocus() {
      if (!this.showContent) this.isListVisible = !this.isListVisible;
    },
    hideScrollBar() {
      // Allows route change to kick off before closing scrollbar
      if (this.isListVisible && !this.showContent) {
        setTimeout(() => {
          this.isListVisible = false;
        }, 200);
      }
    },
    clear() {
      if (this.hasSearch) {
        this.search = '';
      }
    },
    checkLoadMore(e) {
      const wrap = this.$refs.scrollbar.wrap;
      const pos = wrap.scrollHeight - (wrap.scrollTop + wrap.clientHeight);
      if (wrap.scrollTop > 140 && pos < 20 && this.loading === false) this.$emit('load-more');
    },
    async querySearch(queryString, cb) {
      await this.$emit('search', queryString);
      cb(this.entries);
    }
  }
};
</script>

<style lang="scss">
.app-search-list-v2 {
  @apply tw-relative;

  &__input {
    @apply tw-relative;

    margin-top: 16px;

    .el-input--mini .el-input__inner {
      padding-left: theme('spacing.layout-3');

      @apply tw-pl-layout-3 tw-h-9 tw-border-transparent tw-text-sm tw-bg-jb-grey-50 tw-transition;

      &:focus {
        @apply tw-border-jb-indigo tw-bg-white;
      }
    }

    .search-icon {
      @apply tw-left-space-2 tw-w-space-4 tw-h-full tw-absolute tw-top-0 tw-text-jb-grey-400;
    }

    .cancel-icon {
      @apply tw-top-0 tw-right-space-4 tw-absolute tw-w-4 tw-h-full tw-text-jb-grey-400;
    }
  }

  &__entry {
    @apply tw-cursor-pointer tw-flex tw-p-layout-2 tw-m-0;

    transition: background-color 300ms ease-in-out;
  }

  .list-item-enter-active,
  .list-item-leave-active {
    transition: opacity 0.3s, transform 0.3s;
    transform-origin: left center;
  }

  .list-item-enter,
  .list-item-leave-to {
    opacity: 0;
  }

  .list-item-leave-active {
    transition: transform 0.3s;
    position: absolute;
    visibility: hidden;
    width: 100%;
  }

  .el-scrollbar {
    height: calc(100% - 53px);
    max-height: 340px;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.08);

    &__wrap {
      overscroll-behavior-y: contain;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;
      margin-bottom: 0 !important;
    }

    @apply tw-top-space-1 tw-relative tw-bg-white tw-rounded;

    &__bar.is-horizontal {
      @apply tw-hidden;
    }

    .app-search-list-v2__entries {
      max-height: 340px;

      &-group {
        &-header {
          position: sticky;
          top: 0;
        }
      }
    }
  }

  &__wrapper {
    border-radius: 0 0 4px 4px;

    /* IE SPECIFIC RULE */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      @apply tw-overflow-visible;
    }
  }
}
</style>
