import { defineRules } from '@/services/abilities';

const baseStateFactory = () => ({ orgs: {} });
const baseOrganizationStateFactory = () => ({ permissions: [], lastFetchedPermissions: 0 });

export default {
  state: baseStateFactory(),
  getters: {
    getPermissionsByOrgId: (state) => (orgId) => state.orgs[orgId] || []
  },
  actions: {
    async fetchPermissions({ commit, dispatch, getters }) {
      try {
        const orgId = getters.organizationId;
        const { permissions, lastFetchedPermissions } = getters.getPermissionsByOrgId(orgId);

        const ttl = 60000; // 1 minute
        const hasPermissions = permissions.length > 0;
        const arePermissionsStale = Date.now() > lastFetchedPermissions + ttl;
        if (!hasPermissions || arePermissionsStale) {
          if (getters.loading) dispatch('addPlatformLoader', { key: 'org-details', text: 'Loading organization details...' });
          const perms = await this._vm.$api.permissions.fetchPermissions({ orgId });
          commit('SET_PERMISSIONS', { permissions: perms, orgId });
          this._vm.$ability.update(defineRules(perms));
        } else {
          this._vm.$ability.update(defineRules(permissions));
        }
      } catch (error) {
        if (this._vm.$api.isCancel(error)) return;
        this._vm.$message({ message: error.message, type: 'error' });
      } finally {
        setTimeout(() => dispatch('removePlatformLoader', 'org-details'), 100);
      }
    },
    fetchPermissionsSuccess({ commit }, { response, payload }) {
      commit('SET_PERMISSIONS', { permissions: response.data, orgId: payload.orgId });
    }
  },
  mutations: {
    SET_ACTIVE_ORGANIZATION(state, org) {
      state.orgs[org.id] = state.orgs[org.id] || baseOrganizationStateFactory();
    },
    CLEAR_STORE(state) {
      state = baseStateFactory();
    },
    SET_PERMISSIONS(state, { permissions = [], orgId }) {
      state.orgs[orgId] = { permissions, lastFetchedPermissions: Date.now() };
    }
  }
};
