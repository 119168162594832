import storage from '@/services/storage';
import organizationDetails from '@/assets/data/organizationDetails';
import questionMapperFactory from '@/mappers/signup/questions';
import ErrorHelper from '@/helpers/ErrorHelper';
import StringTemplate from '@/helpers/StringTemplate';
import QuestionNavigationHelper from '@/helpers/survey/QuestionNavigationHelper';
import jbxApi from '@/services/jbx-api';
import config from '@/helpers/config';

const CancelToken = jbxApi.cancelToken;
let addressSuggestionCancel;

/**
 * Add compiled title to a question
 * @param {Object} question
 * @param {Object} questions
 * @returns {*}
 */
const addTitle = function(question, questions) {
  question.title = StringTemplate.compile(question.question, questions);
  return question;
};

/**
 * Prepare the question options
 * @param question
 * @return {*}
 */
const prepareOptions = function(question) {
  if (question.type === 'multiselect' && question.hasOwnProperty('options')) {
    question.options = question.options.map(o => {
      return typeof o === 'string' ? {
        id: o,
        name: o
      } : o;
    });
  }
  return question;
};

/**
 * Prepare the questions for the frontend
 * @param questions
 * @return {*}
 */
const preparedQuestions = function(questions) {
  for (const key in questions) {
    if (questions.hasOwnProperty(key)) {
      questions[key].key = key;
      questions[key] = addTitle(questions[key], questions);
      questions[key] = prepareOptions(questions[key]);
      if (questions[key].hasOwnProperty('questions')) {
        questions[key].questions = preparedQuestions(questions[key].questions);
      }
    }
  }
  return questions;
};

/**
 * Prepare question answer for the state
 * @param {Object} question
 * @param {*} answer
 */
const preparedAnswer = function(question, answer) {
  if (question.hasOwnProperty('questions')) {
    Object.keys(question.questions).forEach(key => {
      question.questions[key] = preparedAnswer(question.questions[key], answer[key]);
    });
  } else {
    question.answer = answer;
  }
  return question;
};

/**
 * Get a empty survey state
 */
const baseStateFactory = function() {
  return {
    status: 'incomplete',
    organizationDetails: organizationDetails,
    questions: {},
    questionsOptions: {},
    fetchingQuestions: false,
    fetchingQuestionOptions: false,
    confirmingSurvey: false,
    surveyInitialized: false,
    allowBackNavigation: true
  };
};

const state = Object.assign(baseStateFactory(), storage.getObject(process.env.VUE_APP_JB_ONBOARDING_SURVEY_KEY, {}));
state.organizationDetails = organizationDetails;
state.fetchingQuestions = false;
state.surveyInitialized = false;

const getters = {
  surveyStatus(state) {
    return state.status;
  },
  organizationDetails(state, getters) {
    const type = getters.userIsAdvertiser ? 'merchant' : getters.userType;
    return state.organizationDetails.hasOwnProperty(type) ? state.organizationDetails[type] : null;
  },
  surveySplashSubheader(state, getters) {
    return getters.organizationDetails ? getters.organizationDetails.splashSubheader : null;
  },
  surveySplashItems(state, getters) {
    return getters.organizationDetails ? getters.organizationDetails.splashItems : [];
  },
  surveyFinishDetails(state, getters) {
    return getters.organizationDetails ? getters.organizationDetails.surveyFinish : {};
  },
  firstQuestionKey(state) {
    return QuestionNavigationHelper.getNextQuestionKey(null, state, true);
  },
  questions(state) {
    return preparedQuestions(JSON.parse(JSON.stringify(state.questions)));
  },
  getQuestionByKey: (state, getters) => questionKey => {
    return addTitle(getters.questions[questionKey], getters.questions);
  },
  getQuestionKeysWithSkip: (state, getters) => () => {
    const questionKeys = [];
    for (const key in state.questions) {
      if (QuestionNavigationHelper.questionSkipMatches(state.questions[key], state.questions) === false) {
        questionKeys.push(key);
      }
    }
    return questionKeys;
  },
  getLatestSavedQuestionIndex: (state, getters) => () => {
    const questionKeys = getters.getQuestionKeysWithSkip();
    const nextQuestionIndex = questionKeys.indexOf(QuestionNavigationHelper.getNextQuestionKey(null, state, false));
    if (nextQuestionIndex === -1) {
      return questionKeys.length;
    } else {
      return nextQuestionIndex;
    }
  },
  getQuestionAnswer: (state, getters) => questionKey => {
    const question = getters.questions[questionKey];
    return questionMapperFactory(question.type).getAnswer(question);
  },
  getQuestionAnswerSummary: state => question => {
    const options = question.hasOwnProperty('endpoint') ? state.questionsOptions[question.endpoint] : null;
    return questionMapperFactory(question.type).getAnswerSummary(question, options);
  },
  visibleSummaryQuestions(state, getters) {
    const questions = getters.questions;
    const visibleQuestions = {};
    Object.keys(questions).map(q => {
      if (QuestionNavigationHelper.shouldShowQuestionInSummary(questions[q], state)) {
        visibleQuestions[q] = questions[q];
      }
    });
    return visibleQuestions;
  },
  questionOptions: state => endpoint => {
    if (state.questionsOptions.hasOwnProperty(endpoint)) {
      return state.questionsOptions[endpoint];
    }
    return [];
  },
  allowBackNavigation(state) {
    return state.allowBackNavigation;
  }
};
const actions = {
  /**
   * Restore the survey store from the api (questions, answers)
   * @param { commit, dispatch, state }
   * @param user
   * @return {Promise<void>}
   */
  async initializeSurveyState({ commit, dispatch, state, getters }, user) {
    if (state.surveyInitialized) {
      return Promise.resolve({
        user
      });
    }
    commit('SURVEY_STATUS', ['pending', 'approved'].includes(user.status) ? 'confirmed' : 'incomplete');
    try {
      dispatch('addPlatformLoader', {
        key: 'survey-details',
        text: 'Loading survey details...'
      });
      await dispatch('fetchQuestions', getters.organization.id);
      await dispatch('fetchAllQuestionsOptions', user);
      commit('SURVEY_INITIALIZED');
      dispatch('removePlatformLoader', 'survey-details');
      return Promise.resolve({
        user
      });
    } catch (e) {
      dispatch('removePlatformLoader', 'survey-details');
      return Promise.reject(e);
    }
  },
  async reloadSurveyState({ commit, dispatch, state }, user) {
    await dispatch('fetchQuestions', user.org_id);
    await dispatch('fetchAllQuestionsOptions', user);
  },
  /**
   * Mark the survey as completed
   * @param commit
   */
  surveyCompleted({ commit }) {
    commit('SURVEY_STATUS', 'complete');
  },
  /**
   * Mark the survey as incomplete
   * @param commit
   */
  surveyIncomplete({ commit }) {
    commit('SURVEY_STATUS', 'incomplete');
  },
  /**
   * Fetch the user questions from the jbx api
   * @param {ActionContext} context
   * @param {Commit} context.commit
   * @param {S} context.state
   * @param {String} orgId
   * @returns {Promise<*>}
   */
  async fetchQuestions({ state, commit, getters }, orgId) {
    if (state.fetchingQuestions) {
      return Promise.reject('fetching question in progress');
    }
    commit('FETCH_QUESTIONS');
    return jbxApi
      .get(`/v2/org/${orgId}/survey/answers`)
      .then(response => {
        commit('FETCH_QUESTIONS_SUCCESS', response.data.data);
        // If all the questions have been answered, the survey is completed
        if (getters.firstQuestionKey === null) {
          commit('SURVEY_STATUS', 'complete');
        }
        return Promise.resolve(response.data.data);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  },
  /**
   * Move the router to the next question or to the confirmation page
   * @param {ActionContext} context
   * @param {Commit} context.commit
   * @param {S} context.state
   * @param {Object} context.getters
   * @param {Object} payload
   * @param {Object} payload.answer
   * @return {Promise<{redirect: string}>}
   */
  async nextQuestion({ commit, state, getters, dispatch }, { question, answer }) {
    try {
      const answerHasChanged = QuestionNavigationHelper.hasAnswerChanged(answer, question.answer);
      if (answerHasChanged) {
        await jbxApi.post(
          `/v2/org/${getters.organization.id}/survey/answer`,
          questionMapperFactory(question.type).prepareApiPayload(question, answer)
        );
        commit('SAVE_QUESTION', {
          questionKey: question.key,
          answer
        });
      }
      if (question.key === 'first_name') {
        commit('SET_USER_FIRST_NAME', answer);
      }
      if (question.key === 'last_name') {
        commit('SET_USER_LAST_NAME', answer);
      }
      if (QuestionNavigationHelper.shouldReloadQuestions(question.key, state)) {
        await dispatch('reloadSurveyState', getters.user);
      }
      const nextKey = QuestionNavigationHelper.getNextQuestionKey(question.key, state);
      let redirect = null;
      if (nextKey !== null) {
        dispatch('surveyIncomplete');
        redirect = `/signup/survey/${nextKey}`;
      } else {
        dispatch('surveyCompleted');
        redirect = '/signup/survey/confirm';
      }
      return Promise.resolve({
        redirect
      });
    } catch (e) {
      return Promise.reject(ErrorHelper(e));
    }
  },
  /**
   * Set the answer for a specific question
   * @param commit
   * @param questionKey
   * @param answer
   * @returns {Promise<void>}
   */
  async setQuestionAnswer({ commit }, { questionKey, answer }) {
    commit('SAVE_QUESTION', {
      questionKey,
      answer
    });
  },
  /**
   * Move the router to the previous question or to the splash
   * @param {commit, state}
   * @param {questionKey, answer}
   * @return {Promise<{redirect: string}>}
   */
  async previousQuestion({ state }, { questionKey }) {
    const previousKey = QuestionNavigationHelper.getPreviousQuestionKey(questionKey, state);
    let redirect = null;
    if (previousKey !== null) {
      redirect = `/signup/survey/${previousKey}`;
    } else if (state.status === 'complete') {
      redirect = '/signup/survey/confirm';
    } else {
      redirect = '/signup/splash';
    }
    return Promise.resolve({
      redirect
    });
  },
  /**
   * Send all the answers of the survey for a final validation
   * @param {commit, state}
   */
  async confirmSurvey({ commit, getters }) {
    commit('CONFIRM_SURVEY');
    return jbxApi
      .post(`/v2/org/${getters.organization.id}/survey/finish`)
      .then(response => {
        if (response.status === 200 && response.data._meta.success) {
          commit('SET_USER_STATUS', response.data.data.status);
          commit('CONFIRM_SURVEY_SUCCESS');
          return Promise.resolve(response.data);
        } else {
          return Promise.reject(
            ErrorHelper({
              message: null,
              response
            })
          );
        }
      })
      .catch(e => {
        if (e.hasOwnProperty('response') && e.response.status === 403) {
          return Promise.resolve(e.response.data);
        }

        commit('CONFIRM_SURVEY_FAIL');
        return Promise.reject(ErrorHelper(e));
      });
  },
  /**
   * Fetch all the options from questions that need the fetch them from an api endpoint
   * @param state
   * @param dispatch
   * @return {Promise<any[]>}
   */
  async fetchAllQuestionsOptions({ getters, dispatch }) {
    return Promise.all(
      Object.keys(getters.questions)
        .filter(q => getters.questions[q].hasOwnProperty('endpoint'))
        .map(q => {
          return dispatch('fetchQuestionOptions', getters.questions[q]);
        })
    );
  },
  /**
   * Fetch questions options form it's {endpoint} property
   * @param {commit, state}
   * @param question
   * @return {Promise<*>}
   */
  async fetchQuestionOptions({ commit, state }, question) {
    if (state.questionsOptions.hasOwnProperty(question.endpoint)) {
      commit('FETCH_QUESTION_OPTIONS_SUCCESS');
      return Promise.resolve(state.questionsOptions[question.endpoint]);
    }
    commit('FETCH_QUESTION_OPTIONS', question.endpoint);
    return jbxApi.get(question.endpoint).then(response => {
      const options = response.data.data;
      commit('FETCH_QUESTION_OPTIONS_SUCCESS', {
        options,
        question
      });
      return options;
    });
  },
  /**
   * Get street address suggestion from jbx api
   * @param context
   * @param search
   * @return {Promise<Boolean>}
   */
  async getAddressSuggestions(context, search) {
    try {
      if (addressSuggestionCancel !== undefined) {
        addressSuggestionCancel('search cancelled');
      }
      const response = await jbxApi.request({
        method: 'get',
        url: `${config.VUE_APP_PLATFORM_API_URL}/v2/address/suggest?location=${search}`,
        cancelToken: new CancelToken(function(c) {
          addressSuggestionCancel = c;
        })
      });
      if (jbxApi.isCancel(response)) {
        return Promise.reject(
          Object.assign({
            isCancel: true
          },
          response
          )
        );
      }
      return Promise.resolve(response.data.data.slice(0, 4));
    } catch (e) {
      return Promise.resolve([]);
    }
  }
};
const mutations = {
  SAVE_QUESTION(state, { questionKey, answer }) {
    state.questions[questionKey] = preparedAnswer(JSON.parse(JSON.stringify(state.questions[questionKey])), answer);
    storage.setObject(process.env.VUE_APP_JB_ONBOARDING_SURVEY_KEY, state);
  },
  FETCH_QUESTIONS() {
    state.fetchingQuestions = true;
  },
  FETCH_QUESTIONS_SUCCESS(state, questions) {
    state.questions = questions;
    storage.setObject(process.env.VUE_APP_JB_ONBOARDING_SURVEY_KEY, state);
    state.fetchingQuestions = false;
  },
  FETCH_QUESTION_OPTIONS(state, endpoint) {
    state.questionsOptions[endpoint] = [];
    state.fetchingQuestionOptions = true;
  },
  FETCH_QUESTION_OPTIONS_SUCCESS(state, payload) {
    if (payload !== undefined) {
      state.questionsOptions[payload.question.endpoint] = payload.options;
      storage.setObject(process.env.VUE_APP_JB_ONBOARDING_SURVEY_KEY, state);
    }
    state.fetchingQuestionOptions = false;
  },
  SURVEY_INITIALIZED(state) {
    state.surveyInitialized = true;
  },
  SURVEY_STATUS(state, status) {
    // Don't move backwards from a confirmed state to a completed state
    if (state.status === 'confirmed' && status === 'complete') status = 'confirmed';

    state.status = status;

    // Once the survey is complete, disable the back navigation
    if (status === 'complete') state.allowBackNavigation = false;

    storage.setObject(process.env.VUE_APP_JB_ONBOARDING_SURVEY_KEY, state);
  },
  CONFIRM_SURVEY(state) {
    state.confirmingSurvey = true;
  },
  CONFIRM_SURVEY_SUCCESS(state) {
    state.confirmingSurvey = false;
    state.status = 'confirmed';

    storage.setObject(process.env.VUE_APP_JB_ONBOARDING_SURVEY_KEY, state);
  },
  CONFIRM_SURVEY_FAIL(state) {
    state.confirmingSurvey = false;
    state.status = 'complete';
  },
  CLEAR_STORE(state) {
    state = Object.assign(state, baseStateFactory());
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
