export default function(VueInstance) {
  const directions = ['x', 'y', 'xy'];
  const events = 'ontouchstart' in document.documentElement
    ? { down: 'touchstart', move: 'touchmove' }
    : { down: 'mousedown', move: 'mousemove' };

  const supressEvent = (event) => {
    event.stopPropagation();
  };

  const ignoreEvent = (event) => {
    event.__sidebarIgnore = true;
  };

  VueInstance.directive('scrollable', {
    inserted(el, { arg, modifiers }) {
      if (directions.includes(arg)) {
        modifiers.ignore
          ? el.addEventListener(events.down, ignoreEvent, { passive: false })
          : el.addEventListener(events.move, supressEvent, { passive: false });
      }
    },
    unbind(el) {
      el.removeEventListener(events.down, ignoreEvent, { passive: false });
      el.removeEventListener(events.move, supressEvent, { passive: false });
    }
  });
};
