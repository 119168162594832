<template>
  <div id="campaign-search-list" class="tw-relative">
    <app-search-list-V2
      :loading="loading"
      :current-page="currentPage"
      :total-pages="totalPages"
      :entries="entries"
      search-placeholder="Search Campaigns"
      @search="handleSearch"
      @load-more="handleSearchMore">
      <template v-if="firstLoad" v-slot:loading>
        <campaign-search-list-item-skeleton v-for="i in 5" :key="`campaign-search-list-item-${i}`" />
      </template>
      <template v-slot:header>
        <app-text v-if="entries.length && !search" weight="semi-bold" class="tw-py-space-2 tw-text-headings tw-mx-space-2 tw-border-jb-indigo tw-border-1 tw-border-bottom tw-pb-space-2 tw-uppercase tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-solid" type="p" size="sm">
          Top Performing
        </app-text>
      </template>
      <template v-slot:no-content>
        <div key="no-content" class="app-search-list__entry disabled !tw-p-space-4 tw-flex tw-flex-col tw-items-center">
          <app-text size="sm" class="tw-text-jb-ink tw-pb-space-2">
            No Results Found
          </app-text>
          <app-text size="sm" class="tw-text-jb-grey-700">
            Please refine your search query to see related campaigns
          </app-text>
        </div>
      </template>
      <app-campaign-search-list-item
        :key="row.entry.id"
        slot-scope="row"
        class="tw-border-jb-grey-50 tw-p-space-2 first:tw-mt-space-1 first:tw-mb-space-2 tw-border-t-0 tw-border-b tw-border-l-0 tw-border-r-0 tw-border-solid"
        :is-active="isActiveCampaign(row.entry)"
        :campaign="row.entry"
        :index="row.index"
        @click="handleCampaignClick">
        <template #id="{campaignid}">
          <app-text size="xs" weight="medium" class="tw-text-jb-grey-500">
            {{ campaignid }}
          </app-text>
          <app-text class="tw-px-space-1 tw-text-jb-grey-100">
            |
          </app-text>
        </template>
        <template #cap="{campaigncap}">
          <app-text size="xs" weight="medium" class="tw-text-jb-grey-500">
            CAP: {{ campaigncap }}
          </app-text>
        </template>
        <template #title="{campaignname}">
          <app-text size="sm" weight="medium" class="tw-text-jb-ink tw-mr-space-1">
            {{ campaignname }}
          </app-text>
        </template>
      </app-campaign-search-list-item>
    </app-search-list-v2>
  </div>
</template>

<script>

import AppSearchListV2 from '@/components/AppSearchListV2';
import AppText from '@/components/AppText';
import AppCampaignSearchListItem from '@/components/AppCampaignSearchListItem';
import CampaignSearchListItemSkeleton from '@/components/skeletons/campaigns/CampaignSearchListItemSkeleton';
import _uniqBy from 'lodash/uniqBy';
import { EMPLOYEE, ADVERTISER, AFFILIATE } from '@/constants/orgTypes';
import { NAVIGATION_STACKS } from '@/constants/navigationStacks';

const DEFAULT_TABS = [
  {
    label: 'All',
    name: 'all',
    apiEndpoint: 'all',
    storeAction: 'fetchCampaigns'
  },
  {
    label: 'Approved',
    name: 'approved',
    apiEndpoint: 'all',
    storeAction: 'fetchCampaigns'
  },
  NAVIGATION_STACKS.reporting
];
const CONTEXTUAL_TABS_MAP = {
  payment: NAVIGATION_STACKS.recommendations,
  setup: NAVIGATION_STACKS.inspirations,
  'discover-campaign-reporting': NAVIGATION_STACKS.inspirations,
  'dashboard-campaign-reporting': NAVIGATION_STACKS.inspirations,
  'performance-dashboard': NAVIGATION_STACKS.launches,
  'campaign-discover': NAVIGATION_STACKS.promotions
};

export default {
  name: 'CampaignSearchList',
  components: { CampaignSearchListItemSkeleton, AppSearchListV2, AppCampaignSearchListItem, AppText },
  data() {
    return {
      search: '',
      entries: [],
      firstLoad: true,
      loading: false,
      currentPage: 1,
      totalPages: 1,
      activeTab: 'all'
    };
  },
  computed: {
    hasAccessToCampaigns() {
      return [EMPLOYEE, ADVERTISER, AFFILIATE].includes(this.$store.getters.userType);
    },
    orgId() {
      return this.$store.getters.organization.id;
    },
    campaignId() {
      return this.$route.params.id;
    },
    navigationStack() {
      return this.$store.getters.navigationStack;
    },
    activeTabDetails() {
      return this.campaignTabs.find(tab => tab.name === this.activeTab);
    },
    campaignTabs() {
      // For now return default tabs only
      return DEFAULT_TABS;

      if (this.navigationStack && NAVIGATION_STACKS[this.navigationStack.name]) {
        return [...DEFAULT_TABS, NAVIGATION_STACKS[this.navigationStack.name]];
      }

      const routeName =
        this.$route.name.split('-')[0] === 'payment'
          ? 'payment'
          : this.$route.name.split('-')[2] === 'setup'
            ? 'setup'
            : this.$route.name;

      if (!CONTEXTUAL_TABS_MAP[routeName]) {
        return [...DEFAULT_TABS];
      }
      return [...DEFAULT_TABS, CONTEXTUAL_TABS_MAP[routeName]];
    }
  },
  watch: {
    campaignFilters(updatedFilters) {
      this.currentPage = 1;
      this.handleSearch(this.search);
    }
  },
  created() {
    if (this.hasAccessToCampaigns) this.handleSearch(this.search);
  },
  methods: {
    handleCampaignClick(campaign) {
      this.$store.dispatch('setNavigationStack', {
        apiCall: this.$api.campaigns[this.activeTabDetails.apiEndpoint],
        name: this.activeTabDetails.name,
        payload: {
          orgId: this.orgId,
          params: { page: this.currentPage }
        },
        totalPages: this.totalPages,
        items: this.entries.map(c => c.id),
        storeAction: this.activeTabDetails.storeAction
      });
      this.$emit('click', campaign);
    },
    async handleSearch(search, force = false) {
      let newSearch = false;
      if (search !== this.search || force) {
        newSearch = true;
        this.currentPage = 1;
      }

      this.loading = true;
      this.search = search;

      const apiOptions = {
        orgId: this.orgId,
        q: search,
        campaignId: this.campaignId,
        params: {
          page: this.currentPage,
          filter: Object.assign(
            this.activeTab === 'approved' ? { approval_status: ['approved'] } : {},
            this.campaignFilters
          ),
          dateRange: '7D'
        },
        storeAction: this.activeTabDetails.storeAction,
        cancellationKey: 'campaign-search-list'
      };

      try {
        const response = await this.$api.campaigns[this.activeTabDetails.apiEndpoint](apiOptions);

        if (newSearch) this.entries = [];
        this.entries = _uniqBy([...this.entries, ...response.data], 'id');
        this.totalPages = response._meta.page_count;
        this.currentPage = response._meta.page;
        this.firstLoad = false;
        this.loading = false;
        return this.entries;
      } catch (e) {
        if (e.message !== 'Request cancelled') {
          this.entries = [];
          this.loading = false;
          throw e;
        }
      };
    },
    handleSearchMore() {
      if (!this.loading && this.currentPage < this.totalPages) {
        this.currentPage += 1;
        this.handleSearch(this.search);
      }
    },
    isActiveCampaign(campaign) {
      return campaign.id === this.campaignId;
    }
  }
};
</script>

<style lang="scss">
#campaign-search-list {
  @apply tw-w-full tw-max-w-lg md:tw-max-w-full;

  @include md-up {
    width: theme('screens.mfw');
  }
}
</style>
