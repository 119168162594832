<template>
  <el-collapse
    class="app-global-sidebar-list-accordion tw-flex tw-flex-col tw-max-h-full tw-border-0"
    :style="{ height: `calc(100% - ${32 * length}px)` }"
    accordion
    v-bind="$attrs"
    v-on="$listeners">
    <slot />
  </el-collapse>
</template>

<script>
export default {
  name: 'AppGlobalSidebarListAccordion',
  data() {
    return {
      length: [...this.$slots.default].filter((el) => !!el.tag).length
    };
  },
  updated() {
    this.length = [...this.$slots.default].filter((el) => !!el.tag).length;
  }
};
</script>
