import Vue from 'vue';
import VueRouter from 'vue-router';
import MiddlewareFactory from '@/routing/middleware-factory';
import routes from './routes';
import store from '@/store/index';
import _debounce from 'lodash/debounce';
import { NavigationFailureType } from 'vue-router/src/util/errors';
import { getContextProps } from '@/services/event-track';

/*
 * Middleware handlers
 */
import queryParamSignIn from '@/routing/middlewares/query-param-signin';
import activeTheme from '@/routing/middlewares/active-theme';
import activePage from '@/routing/middlewares/active-page';
import affiliate from '@/routing/middlewares/affiliate';
import advertiser from '@/routing/middlewares/advertiser';
import auth from '@/routing/middlewares/auth';
import guest from '@/routing/middlewares/guest';
import emailVerification from '@/routing/middlewares/email-verification';
import passwordReset from '@/routing/middlewares/password-reset';
import survey from '@/routing/middlewares/survey';
import surveySummary from '@/routing/middlewares/survey-summary';
import surveyQuestion from '@/routing/middlewares/survey-question';
import organization from '@/routing/middlewares/organization';
import shop from '@/routing/middlewares/shop';
import leadSource from '@/routing/middlewares/lead-source';
import invitation from '@/routing/middlewares/invitation';
import campaign from '@/routing/middlewares/campaign';
import campaignStatus from '@/routing/middlewares/campaign-status';
import approvedAccount from '@/routing/middlewares/approved-account';
import internalUser from '@/routing/middlewares/internal-user';
import rightSidebarContent from '@/routing/middlewares/right-sidebar-content';
import affiliateReportOptions from '@/routing/middlewares/affiliate-report-options';
import reportNavigationStack from '@/routing/middlewares/report-navigation-stack';
import adApproval from '@/routing/middlewares/ad-approval';
import referralCode from '@/routing/middlewares/referral-code';
import referral from '@/routing/middlewares/referral';
import promptGuard from '@/routing/guards/promptGuard';
import generalUser from '@/routing/middlewares/general-user';
import funnels from '@/routing/middlewares/funnels';
import permissions from '@/routing/middlewares/permissions';
import verifyEmail from '@/routing/middlewares/verify-email';

/**
 * Object with registered middlewares
 * A middleware is a function that will take a context object
 * and should call the context.next function when finishes
 * or in case of failure, return router.replace with a new target route
 */
const MIDDLEWARES = {
  auth,
  guest,
  emailVerification,
  passwordReset,
  survey,
  surveySummary,
  surveyQuestion,
  organization,
  shop,
  leadSource,
  invitation,
  adApproval,
  internalUser,
  campaign,
  campaignStatus,
  affiliate,
  advertiser,
  approvedAccount,
  rightSidebarContent,
  affiliateReportOptions,
  reportNavigationStack,
  referralCode,
  referral,
  generalUser,
  funnels,
  permissions,
  verifyEmail
};

const GLOBAL_MIDDLEWARES = {
  queryParamSignIn,
  activeTheme,
  activePage
};

/**
 * Replace original push/replace function to handle NavigationDuplicated errors
 * @param originalHandler
 * @return {push}
 */
const safeNavigationDuplicatedHandler = function(originalHandler) {
  return function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalHandler.call(this, location, onResolve, onReject);
    return originalHandler.call(this, location).catch(err => {
      if (err && ![NavigationFailureType.duplicated, NavigationFailureType.redirected].includes(err.type)) {
        throw err;
      }
    });
  };
};
VueRouter.prototype.push = safeNavigationDuplicatedHandler(VueRouter.prototype.push);
VueRouter.prototype.replace = safeNavigationDuplicatedHandler(VueRouter.prototype.replace);

Vue.use(VueRouter);

const MiddlewareHandler = MiddlewareFactory(GLOBAL_MIDDLEWARES, MIDDLEWARES);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return to.meta && to.meta.noScroll
      ? null
      : savedPosition || { x: 0, y: 0 };
  }
});

/**
 * Handler for showing error message when there is a broken middleware
 */
const errorMessageHandler = _debounce((e, message = 'An unexpected error has occurred') => {
  console.error('middleware error:', e);
  store.dispatch('showErrorMessage', message);
}, 200);

/**
 * Global guards for routes
 * just using one guard for now
 */

router.beforeEach(async(to, from, next) => {
  return await promptGuard({ to, from, next, store, $confirm: Vue.prototype.$jbConfirm });
});

/**
 *  Middlewares for routes
 */
router.beforeEach(async(to, from, next) => {
  if (window.analytics) window.analytics.page(to.name, getContextProps());
  window.rg4js && window.rg4js('trackEvent', { type: 'pageView', path: to.path });
  return MiddlewareHandler.handleNavigationMiddleware({
    to,
    from,
    next,
    router,
    store,
    document,
    api: Vue.prototype.$api,
    ability: Vue.prototype.$ability,
    vm: Vue.prototype
  }).catch(e => {
    let { path, message } = e;
    path = path || to.path === '/' ? '/signout' : '/';
    errorMessageHandler(e, message);
    return next(path);
  });
});

export default router;
