/**
 * @typedef {Object} JBStore
 * @property {PlatformStore} platform
 */

import Vue from 'vue';
import Vuex from 'vuex';
import platform from './modules/platform';
import auth from './modules/auth';
import survey from './modules/survey';
import notifications from './modules/notifications';
import abTests from './modules/ab-tests';
import jbxApi from '@/services/jbx-api';
import campaigns from './modules/campaigns/index';
import crowds from './modules/crowds';
import organization from './modules/organization';
import adApprovals from './modules/adApprovals';
import referral from './modules/referral';
import permissions from './modules/permissions';

Vue.use(Vuex);

const vuexStore = new Vuex.Store({
  modules: {
    platform,
    auth,
    campaigns,
    survey,
    crowds,
    abTests: abTests({ jbxApi }),
    notifications,
    organization,
    adApprovals,
    referral,
    permissions
  }
});

/**
 *
 */
export default vuexStore;
