import { abilitiesPlugin, Can } from '@casl/vue';
import { buildAbility } from '@/services/abilities';
import { ALL_AVAILABLE_PERMISSIONS } from '@/constants/permissions';

export default function(VueInstance) {
  const ability = buildAbility();

  // expose values during dev
  if (process.env.NODE_ENV !== 'production') {
    window.$ability = ability;
    window.$permissions = ALL_AVAILABLE_PERMISSIONS;
  }

  VueInstance.prototype.$permissions = ALL_AVAILABLE_PERMISSIONS;
  VueInstance.use(abilitiesPlugin, ability);
  VueInstance.component('Can', Can);
}
