<template>
  <with-root :show="inline">
    <app-form ref="form" :model="computedFormData" @submit.prevent.native>
      <slot name="prepend" />

      <el-form-item ref="formItem" class="app-form-input" v-bind="formItemProps" :prop="inline ? 'value' : formItemProps.prop" v-on="formItemListeners">
        <label>
          <slot name="label">
            <app-text v-if="label" size="sm" class="tw-text-jb-grey-700 tw-block">
              {{ label }}
            </app-text>
          </slot>

          <slot name="input" :props="inputProps" :listeners="inputListeners">
            <app-input ref="input" v-bind="inputProps" v-on="inputListeners" />
          </slot>

          <slot name="help">
            <app-text v-if="help" size="xs" class="tw-text-jb-grey-700 tw-mt-space-1 tw-block">
              <slot name="helpIcon">
                <i v-if="helpIcon" :class="helpIcon" class="tw-mr-1" />
              </slot>
              {{ help }}
            </app-text>
          </slot>
        </label>

        <app-text slot="error" slot-scope="{ error }" size="sm" class="tw-text-jb-red-dark tw-mt-space-1">
          <slot name="error" :error="error">
            <slot name="errorIcon">
              <i v-if="errorIcon" :class="errorIcon" class="tw-mr-1" />
            </slot>
            {{ error }}
          </slot>
        </app-text>
      </el-form-item>

      <slot name="append" />
    </app-form>
  </with-root>
</template>

<script>
import AppForm from '@/components/AppForm.vue';
import AppInput from '@/components/AppInput.vue';
import AppText from '@/components/AppText.vue';

export default {
  name: 'AppFormInput',
  components: { AppForm, AppInput, AppText },
  props: {
    label: {
      type: String,
      default: undefined
    },
    inline: {
      type: Boolean,
      default: false
    },
    errorIcon: {
      type: [Boolean, String],
      default: 'el-icon-warning'
    },
    help: {
      type: String,
      default: undefined
    },
    helpIcon: {
      type: [Boolean, String],
      default: false
    }
  },
  computed: {
    computedFormData() {
      return { value: this.inputProps.value || '' };
    },
    formItemProps() {
      const { prop, label, labelWidth, required, rules, error, showMessage, inlineMessage, size } = this.$attrs;
      return this.filterObjectValues({ prop, label, labelWidth, required, rules, error, showMessage, inlineMessage, size });
    },
    inputProps() {
      return this.filterObjectValues({ ...this.$attrs });
    },
    formItemListeners() {
      const { resetField, clearValidate } = this.$listeners;
      return this.filterObjectValues({ resetField, clearValidate });
    },
    inputListeners() {
      const { resetField, clearValidate, ...rest } = this.$listeners;
      return this.filterObjectValues({ ...rest });
    }
  },
  methods: {
    filterObjectValues(obj = {}) {
      return Object.fromEntries(Object.entries(obj).filter(([key, val]) => typeof val !== 'undefined'));
    }
  }
};
</script>

<style lang="scss">
// FIXME: need some insane specificity to target el-input styles...
.el-form .app-form-input.is-error .el-input + .el-form-item__error {
  position: initial;
  display: inline-block;
  line-height: 1.5;
}
</style>
