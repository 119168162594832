/**
 * Check if a given route is a base report
 * @param {Route} route
 * @return {boolean}
 */
function isBaseReport(route) {
  return !(route.params.hasOwnProperty('reportSubType') || route.meta.hasOwnProperty('reportSubType'));
}

/**
 * Sync the query string params with the vuex state
 * @param {Function} next
 * @param {Route} to
 * @param {Route} from
 * @param {Store} store
 * @return {Promise<*>}
 */
export default async({ next, to, from, store }) => {
  if (isBaseReport(to)) {
    store.dispatch('clearReportNavigationStack');
  } else if ((from.fullPath.includes('/reporting') || from.fullPath.includes('/reports')) && to.path !== from.path && !from.meta.disableReportNavigationStack) {
    store.dispatch('pushReportNavigationStack', from.fullPath);
  }
  return next();
};
