import jbxApi from '@/services/jbx-api';
import requestCaching from '@/services/request-caching';

import campaigns from './campaigns';
import integration from './integrations';
import shops from './shops';
import metrics from './metrics';
import pixels from './pixels';
import links from './links';
import report from './report';

export default {
  modules: {
    campaigns: campaigns({ jbxApi, requestCaching }),
    integration: integration({ jbxApi, requestCaching }),
    shops: shops({ jbxApi, requestCaching }),
    metrics: metrics({ jbxApi, requestCaching }),
    pixels: pixels({ jbxApi, requestCaching }),
    links: links({ jbxApi, requestCaching }),
    report: report({ jbxApi, requestCaching })
  }
};
