var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{directives:[{name:"tippy",rawName:"v-tippy",value:({
    enabled: _vm.tippy,
    content: _vm.tippy,
    showOnInit: false,
    arrow: true,
    placement: 'bottom',
    boundary: 'window',
    followCursor: false
  }),expression:"{\n    enabled: tippy,\n    content: tippy,\n    showOnInit: false,\n    arrow: true,\n    placement: 'bottom',\n    boundary: 'window',\n    followCursor: false\n  }"}],staticClass:"app-button",class:( _obj = {
    'app-button--loading': _vm.loading
  }, _obj[_vm.state] = _vm.state, _obj[_vm.size] = _vm.size, _obj[_vm.type] = _vm.type, _obj ),attrs:{"type":_vm.type,"disabled":_vm.disabled},on:{"click":_vm.handleClick}},[(_vm.loading)?[(_vm.loading)?_c('div',{staticClass:"app-button__loader"},[_vm._t("loader",[(_vm.loading)?_c('div',{staticClass:"el-icon-loading"}):_vm._e()])],2):_vm._e()]:_vm._e(),(_vm.state=='text')?_c('span',[_c('app-text',{attrs:{"weight":_vm.textWeight,"color":_vm.textColor}},[_vm._t("default")],2)],1):_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }