<template>
  <div class="campaign-search-list-item-skeleton">
    <div class="campaign-search-list-item__header">
      <skeleton-box height="13px" width="20%" />
    </div>
    <div class="campaign-search-list-item__body">
      <skeleton-box height="18px" width="75%" />
    </div>
    <div class="campaign-search-list-item__footer">
      <div class="campaign-search-list-item__footer-item">
        <skeleton-box height="16px" width="80%" />
      </div>
      <div class="campaign-search-list-item__footer-item">
        <skeleton-box height="16px" width="80%" />
      </div>
      <div class="campaign-search-list-item__footer-item">
        <skeleton-box height="16px" width="80%" />
      </div>
    </div>
  </div>
</template>

<script>
import SkeletonBox from '@/components/skeletons/SkeletonBox';

export default {
  name: 'CampaignSearchListItemSkeleton',
  components: { SkeletonBox }
};
</script>

<style lang="scss">
.campaign-search-list-item-skeleton {
  display: flex;
  padding: $--clb-space-4 $--clb-space-3;
  flex-direction: column;
  border-bottom: 1px solid $--clb-border-color-lighter;

  .skeleton-box {
    margin: 0;
  }

  .campaign-search-list-item {
    &__body {
      margin: $--clb-space-1 + 2px $--clb-space-3 $--clb-space-5 0;
      font-size: $--clb-font-size-sm !important;
      line-height: 24px;
    }

    &__footer {
      display: flex;
      justify-content: space-between;

      &-item .skeleton-box {
        margin: 0;
      }
    }
  }
}
</style>
