/* eslint-disable no-template-curly-in-string */
import StringTemplate from '@/helpers/StringTemplate';
import _get from 'lodash/get';

const ALLOWED_OBJECTS = ['campaigns', 'orgs'];

export const URL_TYPE_TEMPLATES = {
  discover_campaigns: '/discover',
  campaign_launch: '/campaign/${{campaigns}}/marketing',
  campaign_status_change: '/campaign/${{campaigns}}/marketing',
  campaign_update: {
    landing_page_launch: '/campaign/${{campaigns}}/funnels',
    box_asset_change: '/campaign/${{campaigns}}/creatives'
  },
  cap_reached: '/campaign/${{campaigns}}/performance',
  // Ad Approval
  content_request_requested: '/campaign/${{campaigns}}/approvals/pending?affiliateId=${{orgs}}',
  content_request_internal_approved: '/campaign/${{campaigns}}/approvals/pending',
  response_time_elapsed: '/campaign/${{campaigns}}/approvals/pending',
  content_request_rejected: '/campaign/${{campaigns}}/approvals/waiting_revision',
  content_request_approved: '/campaign/${{campaigns}}/approvals/approved',
  content_request_revoked: '/campaign/${{campaigns}}/approvals/revoked',
  campaign_guidelines_updated: '/campaign/${{campaigns}}/approvals/settings'
};

/**
 * Get object ID from objects array
 * @param notification
 * @return {*}
 */
const extractObjectIds = function(notification) {
  return ALLOWED_OBJECTS.reduce((acc, type) => {
    acc[type] = _get(notification.objects, [type, 0]);
    return acc;
  }, {});
};

/**
 * Check if current notification has the selected organization
 * @param notification
 * @param organization
 * @return {boolean}
 */
const hasOrganization = function(notification, organization) {
  return notification.orgs && notification.orgs.find(o => parseInt(o.id) === parseInt(organization.id)) !== undefined;
};

/**
 * Get the url for multi-event notifications
 * It gets the url of the first matched event, so order of event names on URL_TYPE_TEMPLATES is important
 * @param notification
 * @return {string}
 */
const getMatchedNotificationEventUrl = function(notification, templateValues) {
  const matchedEvent = Object.keys(URL_TYPE_TEMPLATES[notification.type]).find(k => {
    return notification.events.includes(k);
  });
  return matchedEvent ? StringTemplate.compile(URL_TYPE_TEMPLATES[notification.type][matchedEvent], templateValues) : '/';
};

/**
 * Get the url for the specific notification type
 * @param notification
 * @return {string|any|undefined}
 */
const getMatchedNotificationUrl = function(notification) {
  // Match notification event
  if (URL_TYPE_TEMPLATES.hasOwnProperty(notification.type)) {
    const templateValues = extractObjectIds(notification);
    if (typeof URL_TYPE_TEMPLATES[notification.type] === 'string') {
      return StringTemplate.compile(URL_TYPE_TEMPLATES[notification.type], templateValues);
    } else if (Array.isArray(notification.events)) {
      return getMatchedNotificationEventUrl(notification, templateValues);
    }
  }

  return '/';
};

/**
 * Handle WebPush notifications redirect
 * Example QueryString: ?source=webpush&notification=1message=1
 * @param {Object} context
 * @param {Route} context.to
 * @param {Object} context.api
 * @param {Store} context.store
 */
const webPushHandler = async({ to, store, api }) => {
  // If we get the message query param, we need to notify the backend to mark it as read
  if (_get(to.query, 'message')) {
    // Ignore errors for this endpoint, so redirection can always be completed
    api.notifications.setWebPushNotificationAsSeen({
      notificationId: _get(to.query, 'notification'),
      messageId: _get(to.query, 'message')
    }).catch(e => { });
  }
};

/**
 * Get final redirect url for the notification
 * @param notification
 * @param currentOrg
 * @param allowedOrgs
 * @return {Promise<string|*>}
 */
const getRedirectUrl = async(notification, currentOrg, allowedOrgs) => {
  let redirectUrl = getMatchedNotificationUrl(notification);

  if (allowedOrgs.length > 1 || (!allowedOrgs.length && !hasOrganization(notification, currentOrg))) {
    allowedOrgs = (allowedOrgs.length ? allowedOrgs : notification.orgs.map(o => o.id)).join(',');
    redirectUrl = `/organizations?ids=${allowedOrgs}&from=${encodeURIComponent(redirectUrl)}`;
  }

  return redirectUrl;
};

/**
 * Get the notification from either the store or the api
 * @param store
 * @param api
 * @param to
 * @return {Promise<*>}
 */
const getNotification = async({ store, api, to }) => {
  let notification = store.getters.notifications.find(n => parseInt(n.id) === parseInt(to.query.notification));
  if (!notification) {
    notification = await api.notifications.single({
      id: to.query.notification
    }).then(response => response.data);
  }
  return notification;
};

/**
 * Handle notifications redirect
 * @param {Object} context
 * @param {Route} context.to
 * @param {Object} context.api
 * @param {Store} context.store
 * @param {Function} context.changeUserOrganization
 * @return {Promise<any | undefined | string>}
 */
export default async({ to, api, store, changeUserOrganization }) => {
  const notification = getNotification({ store, api, to });
  const orgs = _get(to.query, 'orgs', '').split(',').filter(o => parseInt(o) > 0);

  if (_get(to.query, 'source') === 'webpush') {
    await webPushHandler({ to, api, store });
  }

  // If the links has a specific orgId, we attempt to change to it
  if (orgs.length === 1 && orgs[0] !== store.getters.organization.id) {
    await changeUserOrganization(orgs[0], store);
  }

  return getRedirectUrl(await notification, store.getters.organization, orgs);
};
