/**
 * Checks that the shop that we are trying to work on, actually exists
 * @param {Store} store
 * @return {boolean}
 */
const checkShopExistence = async function(store) {
  store.dispatch('addPlatformLoader', { key: 'shop-check', text: 'Loading...' });

  const response = await store
    .dispatch('fetchShops', store.getters.organization.id)
    .then(masterShopsList => {
      return Promise.resolve(
        masterShopsList.find(shop => {
          return shop.domain === store.getters.shopDetails.domain;
        }) !== undefined
      );
    })
    .catch(e => {
      Promise.reject(false);
    });

  store.dispatch('removePlatformLoader', 'shop-check');
  return response;
};

/**
 * Run shop middleware
 * @param {Object} context
 * @param {Route} context.to
 * @param {Route} context.from
 * @param {VueRouter} context.router
 * @param {Store} context.store
 * @param {Function} context.next
 * @param {Object} context.middlewareHelper
 */
export default async({ store, next, router, middlewareHelper }) => {
  if (store.getters.shopDetails !== null) {
    const shopExists = await checkShopExistence(store);
    if (!shopExists) {
      return router.replace({
        path: '/signin/integration-confirmation',
        query: {
          from: middlewareHelper.addFromUrlToPath(location)
        }
      });
    }
  }
  return next();
};
