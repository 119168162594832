import { AFFILIATE } from '@/constants/orgTypes';

const routePermissionMap = {
  'payment-setup': { perms: ['PAYMENT_SETUP_VIEW'] },
  'payment-history': { perms: ['PAYMENT_HISTORY_VIEW'] },
  'organization-settings-partnerships': { perms: ['VIEW_COMPANY_PARTNERSHIP_LIST'], redirect: { name: 'organization-settings' } },
  'organization-settings-permissions': { perms: ['VIEW_COMPANY_PARTNERSHIP_LIST'], redirect: { name: 'organization-settings' } },
  'organization-settings-domains': { types: [AFFILIATE], redirect: { name: 'organization-settings' } },
  'organization-settings-conversions': { types: [AFFILIATE], redirect: { name: 'organization-settings' } }
};

/**
 * Run permissions middleware
 * @param {Function} context.next
 * @param {Route} context.to
 * @param {Store} context.store
 * @param {Object} vm
 */
export default async({ next, to, store, vm }) => {
  const key = Object.keys(routePermissionMap).find((key) => key === to.name);
  if (!key) return next();

  // check if user has permissions
  const { perms, types, redirect } = routePermissionMap[key];
  const userType = store.getters.userType;
  const hasAllPermissions = perms && perms.length ? perms.map((perm) => vm.$ability.can(vm.$permissions[perm])).every((hasPerm) => hasPerm) : true;
  const isCorrectType = types && types.length ? types.includes(userType) : true;
  if (!hasAllPermissions || !isCorrectType) return next(redirect || '/');

  return next();
};
