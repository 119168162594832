export default function(VueInstance) {
  const truncate = (value, limit) => {
    if (value.length > limit) {
      value = value.substring(0, (limit - 3)) + '...';
    }
    return value;
  };

  VueInstance.filter('truncate', truncate);
}
