
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

const _getEndpoint = function() {
  const url = process.env.VUE_APP_PLATFORM_API_URL;
  if (urlParams.get('apiPath') && process.env.NODE_ENV !== 'production') {
    return urlParams.get('apiPath');
  }
  return url;
};

const _getHeader = function() {
  return process.env.NODE_ENV !== 'production'
    ? urlParams.get('dbHeader') ? urlParams.get('dbHeader') : (window.VUE_APP_JB_DB_HEADER || process.env.VUE_APP_JB_DB_HEADER)
    : undefined;
  ;
};

const config = (() => {
  return {
    VUE_APP_PLATFORM_API_URL: _getEndpoint(),
    VUE_APP_PLATFORM_API_DB_HEADER: _getHeader()
  };
})();

export default config;
