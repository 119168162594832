/**
 * Run organization middleware
 * @param {Object} context
 * @param {VueRouter} context.router
 * @param {Store} context.store
 * @param {Function} context.next
 * @param {Object} context.middlewareHelper
 */
export default async({ next, store, api, ability, router, middlewareHelper }) => {
  if (!store.getters.organization) {
    return router.replace({
      path: '/organizations',
      query: {
        from: middlewareHelper.addFromUrlToPath(location)
      }
    });
  }

  // fetch and update user permissions
  await store.dispatch('fetchPermissions');

  return next();
};
