export const ACTIVE_PAGE_PREFIX = 'p-active-page-';

/**
 * Sets the correct theme for the application
 * @param {Object} context
 * @param {Route} context.to
 * @param {Store} context.store
 * @param {Function} context.next
 * @param {Document} context.document
 */
export default async({ to, store, next, document }) => {
  const pageClass = ACTIVE_PAGE_PREFIX + (to.name || 'unnamed');
  store.dispatch('setActivePage', { document, pageClass });
  return next();
};
