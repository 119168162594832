<template>
  <el-dialog
    ref="dialog"
    class="app-modal"
    v-bind="modalProps"
    v-on="$listeners">
    <div slot="title">
      <div class="tw-flex tw-justify-between">
        <slot name="title">
          <app-text weight="semi-bold" class="tw-text-jb-ink">
            {{ modalProps.title }}
          </app-text>
        </slot>
        <cancel-icon v-if="showCancelIcon" class="tw-w-space-4 tw-h-space-4 tw-cursor-pointer" @click="handleClose" />
      </div>
    </div>
    <slot name="message">
      <div v-if="modalProps['is-message-html']" v-html="message" />
      <app-text v-else weight="medium" size="sm" class="tw-text-jb-grey-700">
        {{ modalProps.message }}
      </app-text>
    </slot>

    <span slot="footer" class="tw-flex tw-justify-end">
      <slot name="footer">
        <app-button
          v-if="type !== 'alert'"
          state="secondary"
          size="mini"
          class="tw-mr-space-2"
          @click="handleCancel">{{ cancelBtnText }}</app-button>
        <app-button
          state="primary"
          size="mini"
          :loading="modalProps.loading"
          @click="handleConfirm">{{ confirmBtnText }}</app-button>
      </slot>
    </span>
  </el-dialog>
</template>

<script>
import AppButton from '@/components/AppButton';
import AppText from '@/components/AppText';
import _pick from 'lodash/pick';
import _mapKeys from 'lodash/mapKeys';
import _kebabCase from 'lodash/kebabCase';
import CancelIcon from '@/assets/svg/cancel-icon.svg';

const validTypes = ['confirm', 'alert'];

export default {
  name: 'AppModal',
  components: { AppButton, AppText, CancelIcon },
  props: {
    type: {
      type: String,
      default: 'confirm',
      validator: function(value) {
        // The weight must match one of these values
        return validTypes.indexOf(value) !== -1;
      }
    }
  },
  data() {
    return {
      title: '',
      message: '',
      confirmButtonText: '',
      cancelButtonText: '',
      top: '15vh',
      modal: '',
      customClass: '',
      destroyOnClose: false,
      isMessageHtml: false,
      closeOnPressEscape: true,
      closeOnClickModal: true,
      lockScroll: true,
      width: '25%',
      center: false,
      visible: false,
      showClose: true,
      beforeClose: null,
      fullscreen: false
    };
  },
  computed: {
    confirmBtnText() {
      if (this.modalProps['confirm-button-text']) return this.modalProps['confirm-button-text'];
      return this.type === 'alert' ? 'OK' : 'Confirm';
    },
    cancelBtnText() {
      if (this.modalProps['cancel-button-text']) return this.modalProps['cancel-button-text'];
      return 'Cancel';
    },
    showCancelIcon() {
      return this.modalProps['show-close'] !== false;
    },
    modalProps() {
      let data = _pick(this,
        ['confirmButtonYext', 'cancelButtonText', 'top', 'modal', 'destroyOnClose', 'isMessageHtml', 'loading',
          'customClass', 'center', 'closeOnClickModal', 'showClose', 'closeOnPressEscape',
          'lockScroll', 'width', 'title', 'message', 'type', 'visible', 'beforeClose', 'fullscreen']);
      data = _mapKeys(data, (v, k) => _kebabCase(k));
      const attrs = this.$attrs || {};
      const all = { ...data, ...attrs };
      return this.filterObjectValues(all);
    }
  },
  methods: {
    handleConfirm() {
      if (this.callback) this.callback('confirm');
      this.$emit('confirm');
    },
    handleCancel() {
      if (this.callback) this.callback('cancel');
      this.$emit('cancel');
    },
    handleClose() {
      if (this.callback) this.callback('close');
      this.$refs.dialog.$emit('update:visible', false);
      this.$refs.dialog.$emit('close');
      this.$emit('close');
    },
    filterObjectValues(obj = {}) {
      return Object.fromEntries(Object.entries(obj).filter(([key, val]) => typeof val !== 'undefined'));
    }
  }
};
</script>
<style lang="scss">
.app-modal {
  .el-dialog {
    @apply tw-rounded tw-min-w-[300px];

    &__header {
      @apply tw-p-space-4 tw-pb-0;

      .el-dialog__close {
        @apply tw-hidden;
      }

      &btn {
        @apply tw-top-space-4 tw-right-space-4;
      }
    }

    &__body {
      @apply tw-p-space-4 tw-pb-0 tw-break-normal;
    }

    &__footer {
      @apply tw-p-space-4;
    }
  }
}
</style>
