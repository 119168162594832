export default {
  /**
   * Prepare the question answer to be used on the front end
   * @param {Object} question
   * @return {*}
   */
  getAnswer(question) {
    return question.answer === null ? [] : question.answer;
  },
  /**
   * Get the question answer summary
   * @param {Object} question
   * @param {Object} options
   * @return {*}
   */
  getAnswerSummary(question, options) {
    options = options || question.options;

    let selectedOptions = [];
    if (options !== undefined && options !== null) {
      selectedOptions = options.filter(o => question.answer.indexOf(o.id) >= 0);
    }

    return `<ul>
      ${selectedOptions.map(o => `<li>${o.name}</li>`).join('')}
    </ul>`;
  }
};
