import MD5 from 'md5.js';
import cache from '@/services/cache';
import jbxApi from '@/services/jbx-api';

const CACHE_KEY_PREFIX = 'request-';
const CACHE_ENABLED = process.env.VUE_APP_CACHE_ENABLE === '1';
const CACHE_TTL = parseInt(process.env.VUE_APP_CACHE_TTL) * 60000;

/**
 * Create a cache key for the request
 * @param url
 * @param params
 * @return {string}
 */
function getCacheKey(url, params = {}) {
  return CACHE_KEY_PREFIX + new MD5().update(url + JSON.stringify(params) + jbxApi.getApiToken()).digest('hex');
}

const RequestCaching = {
  /**
   * Makes a request to the jbxApi and commits to the given vuex mutation
   * @param url
   * @param params
   * @param commit
   * @param cacheKey
   * @param cancellationKey
   * @returns {Promise}
   */
  async makeRequest(url, params, commit, cacheKey, cancellationKey) {
    return jbxApi.get(url, params, cancellationKey).then(response => {
      cache.set(cacheKey, response, CACHE_TTL);
      if (typeof commit === 'function') {
        commit(response);
      }
      return response;
    });
  },
  /**
   * Prepares a `get` request to an jbx endpoint with cache
   * @param { url, params, commit }
   */
  async get({ url, params, commit, onRequestStart, onRequestEnd, cancellationKey }) {
    const cacheKey = getCacheKey(url, params);
    let response = null;
    // Check if the request is already cached
    if (CACHE_ENABLED && (response = cache.get(cacheKey)) !== null) {
      // Commit the cached response immediately
      if (typeof commit === 'function') {
        commit(response);
      }
      // Make the actual request on the background
      this.makeRequest(url, params, null, cacheKey, cancellationKey);
    } else {
      if (typeof onRequestStart === 'function') {
        onRequestStart({ url, params });
      }
      // If not cached, wait for the request to finish
      response = await this.makeRequest(url, params, commit, cacheKey, cancellationKey);
      if (typeof onRequestEnd === 'function') {
        onRequestEnd({ response });
      }
    }
    return Promise.resolve(response);
  },
  /**
   * Removes the cached version of a request
   * @param url
   * @param params
   */
  removeCachedUrl(url, params = {}) {
    const cacheKey = getCacheKey(url, params);
    cache.remove(cacheKey);
  }
};

export default RequestCaching;
