<template>
  <div class="app-user-dropdown-menu">
    <header class="app-user-dropdown-menu__user">
      <div class="app-user-dropdown-menu__user__details">
        <app-profile-picture :full-name="userFullName" />
        <app-text weight="semi-bold" class="user-name">
          {{ userFullName }}
        </app-text>
        <app-text weight="medium" class="user-email">
          {{ user.email }}
        </app-text>
      </div>
    </header>
    <footer class="app-user-dropdown-menu__footer">
      <router-link
        :to="{ name: 'account-settings' }"
        class="app-user-dropdown-menu__footer-link"
        title="Account Settings"
        @click.native="closeDropdown">
        <div class="app-user-dropdown-menu__footer-link-item">
          <GearIcon height="16px" width="16px" class="footer-icon" />
          <app-text weight="medium" class="footer-text">
            Account Settings
          </app-text>
        </div>
      </router-link>
      <router-link v-if="canSeeReferrals" :to="{ name: 'referralMarketingLayout' }" class="app-user-dropdown-menu__footer-link" title="My Referrals" @click.native="closeDropdown">
        <div class="app-user-dropdown-menu__footer-link-item">
          <ReferralIcon height="18px" width="18px" class="footer-icon" />
          <app-text weight="medium" class="footer-text">
            Referrals <span class="tag-new">New</span>
          </app-text>
        </div>
      </router-link>
      <a v-if="isLegacyAffiliate" class="app-user-dropdown-menu__footer-link" @click="ssoAction">
        <div class="app-user-dropdown-menu__footer-link-item">
          <Hitpath height="18px" width="18px" class="footer-icon" />
          <app-text weight="medium" class="footer-text">
            HitPath
          </app-text>
        </div>
      </a>
      <router-link :to="{ name: 'signout' }" class="app-user-dropdown-menu__footer-link">
        <div class="app-user-dropdown-menu__footer-link-item sign-out">
          <app-text weight="medium" class="footer-text">
            Sign Out
          </app-text>
        </div>
      </router-link>
    </footer>
  </div>
</template>

<script>
import AppProfilePicture from '@/components/AppProfilePicture';
import Hitpath from '@/assets/svg/brands/hitpath-icon.svg';
import ReferralIcon from '@/assets/svg/referral-icon.svg';
import GearIcon from '@/assets/svg/gear-icon.svg';
import AppText from '@/components/AppText';
import SingleSignOn from '@/helpers/SingleSignOn';

export default {
  name: 'AppUserDropdownMenu',
  components: { AppProfilePicture, Hitpath, ReferralIcon, GearIcon, AppText },
  props: {
    user: {
      type: Object,
      required: true
    },
    organization: {
      type: Object,
      default: undefined
    }
  },
  computed: {
    userFullName() {
      return `${this.user.first_name} ${this.user.last_name}`;
    },
    isAffiliate() {
      return this.$store.getters.userIsAffiliate;
    },
    isLegacyAffiliate() {
      return this.isAffiliate && parseInt(this.organization.hitpath_account_id) < 383110;
    },
    canSeeReferrals() {
      return !this.$store.getters.userIsAdmin || this.$store.getters.userIsSuperAdmin;
    }
  },
  methods: {
    ssoAction() {
      const hpURL = process.env.VUE_APP_HITPATH_AFFILIATE_URL;
      const dest = this.$store.getters.userIsAdvertiser ? '/loggedadv.php' : '/logged.php';
      window.open(SingleSignOn(`${hpURL}${dest}`), '_blank');
    },
    closeDropdown() {
      this.$emit('close-dropdown');
    }
  }
};
</script>

<style lang="scss">
.app-user-dropdown-menu {
  &__user {
    @apply tw-bg-grey-selected tw-py-space-4;

    &__details {
      @apply tw-flex tw-flex-col tw-items-center tw-justify-center tw-whitespace-nowrap tw-overflow-hidden;

      .app-profile-picture {
        background-color: $--clb-color-secondary__dark;
        width: 60px;
        height: 60px;

        .el-avatar {
          @apply tw-text-h3 tw-font-medium;

          line-height: 44px;
        }
      }

      .user-name {
        @apply tw-text-jb-ink tw-capitalize tw-mt-space-2 tw-text-base tw-leading-none;
      }

      .user-email {
        @apply tw-text-jb-grey-700 tw-mt-space-1 tw-text-sm tw-truncate tw-leading-none;
      }
    }
  }

  &__footer {
    :first-child {
      @apply tw-border-t-0;
    }

    a {
      @apply tw-border-solid tw-border-t tw-border-jb-grey-50 hover:tw-bg-hover-background tw-text-sm tw-no-underline hover:tw-no-underline;
    }

    &-link {
      @apply tw-flex tw-items-center tw-h-9 tw-font-semibold tw-p-space-2;

      &-item {
        @apply tw-flex tw-w-full tw-items-center;

        .tag-new {
          @apply tw-bg-jb-teal tw-px-space-2 tw-pt-space-1 tw-pb-0.5 tw-mx-space-1 tw-align-middle tw-rounded-full
            tw-text-3xs tw-uppercase tw-font-bold tw-text-white;
        }

        .tag-soon {
          @apply tw-bg-jb-orange tw-px-space-2 tw-pt-space-1 tw-pb-0.5 tw-mx-space-1 tw-align-middle tw-rounded-full
            tw-text-3xs tw-uppercase tw-font-bold tw-text-white;
        }

        &.sign-out {
          @apply tw-justify-end;
        }

        .footer-icon {
          @apply tw-mr-space-3 tw-text-jb-grey-400;
        }

        .footer-text {
          @apply tw-text-jb-ink;
        }
      }
    }
  }
}
</style>
