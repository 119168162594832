
/**
 * Run promptGuard Guard
 * @param {Object} context
 * @param {Store} context.store
 * @param {Function} context.next
 */
export default async function({ next, store, $confirm }) {
  const shouldPreventNavigation = store.getters.shouldPreventNavigation;
  if (shouldPreventNavigation) {
    try {
      await $confirm(
        {
          title: 'Confirmation Required',
          isMessageHtml: true,
          message:
          `<div class="tw-text-jb-grey-700 tw-font-medium">Are you sure you want to exit without saving?</div>
            <br /> 
          <div class="tw-text-jb-red-dark tw-font-medium"> This action cannot be undone </div>`
        }
      );
    } catch (error) {
      return next(false);
    }

    store.dispatch('setShouldPreventNavigation', false);
  }

  return next();
}
