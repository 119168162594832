export default function(VueInstance) {
  VueInstance.directive('select-close-on-blur', {
    bind(el, binding, { componentInstance }) {
      let isChanging = false;
      let isChangingTimeout = null;
      const setChanging = () => {
        clearTimeout(isChangingTimeout);
        isChanging = true;
        isChangingTimeout = setTimeout(() => (isChanging = false), 150);
      };
      const handleBlur = () => {
        if (componentInstance.visible) {
          setTimeout(() => {
            if (!isChanging && componentInstance.visible) {
              componentInstance.blur();
            }
          }, 150);
        }
      };

      // Capture when keyboard closes
      el.addEventListener('focusout', handleBlur);

      // Find events related to the select component and silence blur events
      componentInstance.$on('visible-change', setChanging);
      componentInstance.$on('change', setChanging);

      el.addEventListener('click', setChanging);
      el.addEventListener('touchstart', setChanging);
      componentInstance.$refs.popper.$el.addEventListener('click', setChanging);
      componentInstance.$refs.popper.$el.addEventListener('touchstart', setChanging);
    }
  });
}
