import Vue from 'vue';
import ErrorHelper from '@/helpers/ErrorHelper';
import trackingEvents from '@/assets/data/trackingEvents';

/**
 *
 * @param {JBXApi} jbxApi
 * @param {RequestCaching} requestCaching
 * @return {*}
 */
export default ({ jbxApi, requestCaching }) => {
  const state = {
    campaignIntegrations: [],
    updatingCampaignIntegration: false,
    savingCampaignIntegration: false,
    integrationShopsList: [],
    selectedShopsList: []
  };

  const getters = {
    trackingEvents: () => shopType => {
      return trackingEvents.map(e => {
        e.eventName = e.name.toLowerCase();
        e.required = shopType ? e.requiredFor.includes(shopType) : false;
        return e;
      });
    },
    campaignIntegrations(state) {
      return state.campaignIntegrations;
    },
    selectedShopsList() {
      return state.selectedShopsList;
    },
    integrationShopsList() {
      return state.integrationShopsList;
    }
  };

  const actions = {
    async fetchCampaignIntegration({ commit, dispatch }, { integrationId, shopType, masterShopsList }) {
      return requestCaching
        .get({
          url: `/v2/integrations/${integrationId}/${shopType}`,
          commit: response => {
            commit('SET_CAMPAIGN_INTEGRATION', { integrationId, integration: response.data.data });
            commit('SET_INTEGRATION_SHOPS_LIST', { intShopsList: response.data.data.shops });
            commit('SET_SELECTED_SHOPS_LIST', {
              masterShopsList: masterShopsList,
              integrationShopsList: response.data.data
            });
          },
          onRequestStart: () => {
            dispatch('addPlatformLoader', { key: 'integration-details', text: 'Loading integration details...' });
          },
          onRequestEnd: () => {
            dispatch('removePlatformLoader', 'integration-details');
          }
        })
        .then(response => {
          return Promise.resolve(response.data.data);
        })
        .catch(e => {
          return Promise.reject(ErrorHelper(e));
        });
    },
    async createCampaignIntegration({ commit }, { orgId, campaignId }) {
      commit('CREATE_CAMPAIGN_INTEGRATION');
      return await jbxApi
        .post('/v2/integrations', {
          org_id: orgId,
          campaign_id: campaignId
        })
        .then(response => {
          commit('CREATE_CAMPAIGN_INTEGRATION_SUCCESS');
          return response;
        })
        .catch(e => {
          commit('CREATE_CAMPAIGN_INTEGRATION_FAIL', e);
          return Promise.reject(ErrorHelper(e));
        });
    },
    async updateCampaignIntegrationEvents(
      { commit },
      { integrationId, shopType, events, masterShopsList, selectedShops }
    ) {
      const shopsPayload = selectedShops
        .map(ss => {
          return masterShopsList.filter(msl => {
            return ss === msl.domain;
          });
        })
        .reduce((accum, currentVal) => {
          return accum.concat(currentVal);
        }, []);

      commit('UPDATE_CAMPAIGN_INTEGRATION');
      return await jbxApi
        .put(`/v2/integrations/${integrationId}/${shopType}`, { events: events, shops: shopsPayload })
        .then(response => {
          commit('UPDATE_CAMPAIGN_INTEGRATION_SUCCESS');
          requestCaching.removeCachedUrl(`/v2/integrations/${integrationId}/${shopType}`);
          return Promise.resolve({
            integrationId,
            events,
            response
          });
        })
        .catch(e => {
          commit('UPDATE_CAMPAIGN_INTEGRATION_FAIL', e);
          return Promise.reject(ErrorHelper(e));
        });
    }
  };

  const mutations = {
    SET_CAMPAIGN_INTEGRATION(state, { integrationId, integration }) {
      let campaignIndex = state.campaignIntegrations.findIndex(c => c.integration_id === integrationId);
      if (campaignIndex < 0) {
        campaignIndex = state.campaignIntegrations.length;
      }
      Vue.set(state.campaignIntegrations, campaignIndex, {
        integration_id: integrationId,
        events: integration.integration,
        shops: integration.shops
      });
    },
    CREATE_CAMPAIGN_INTEGRATION(state) {
      state.savingCampaignIntegration = true;
    },
    CREATE_CAMPAIGN_INTEGRATION_SUCCESS(state) {
      state.savingCampaignIntegration = false;
    },
    CREATE_CAMPAIGN_INTEGRATION_FAIL(state, error) {
      state.savingCampaignIntegration = false;
    },
    UPDATE_CAMPAIGN_INTEGRATION(state) {
      state.updatingCampaignIntegration = true;
    },
    UPDATE_CAMPAIGN_INTEGRATION_SUCCESS(state) {
      state.updatingCampaignIntegration = false;
    },
    UPDATE_CAMPAIGN_INTEGRATION_FAIL(state, error) {
      state.updatingCampaignIntegration = false;
    },
    SET_INTEGRATION_SHOPS_LIST(state, { intShopsList }) {
      state.integrationShopsList = intShopsList;
      // Vue.set(state.integrationShopsList, intShopsList);
    },
    SET_SELECTED_SHOPS_LIST(state, { masterShopsList, integrationShopsList }) {
      state.selectedShopsList = masterShopsList
        .map(msl => {
          return integrationShopsList.shops
            .filter(isl => {
              return isl.id === msl.id && isl.domain === msl.domain;
            })
            .map(s => {
              return s.domain;
            });
        })
        .reduce((accum, currentVal) => {
          return accum.concat(currentVal);
        }, []);
    }
  };

  return {
    state,
    getters,
    actions,
    mutations
  };
};
