<template>
  <div class="docs-docs">
    <AppIframe
      ref="frame"
      :key="src"
      loading-text="Loading Knowledge Base"
      :src="iframeSrc"
      :iframe-height="iframeHeight"
      @load="handleLoad" />
  </div>
</template>

<script>
import AppIframe from '@/components/AppIframe.vue';

export default {
  name: 'Docs',
  components: {
    AppIframe
  },
  props: {
    endpoint: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      src: undefined,
      hash: undefined,
      iframeHeight: '90vh',
      scrollTop: 0,
      hasScrolled: false
    };
  },
  computed: {
    orgId() {
      return this.$store.getters.organization.id;
    },
    sessionId() {
      return this.$store.getters.user.session_id;
    },
    iframeSrc() {
      return `${this.src}?only-page=true${this.hash}`;
    }
  },
  created() {
    const path = this.$route.params.pathMatch || '/welcome/';
    this.src = `${process.env.VUE_APP_DOCS_URL}${this.endpoint}${path}`;

    const hash = this.$route.query.title;
    this.hash = hash ? '#' + hash : '';

    window.addEventListener('message', this.handleMessage, false);
    window.addEventListener('scroll', this.handleScroll, false);
  },
  destroyed() {
    window.removeEventListener('message', this.handleMessage, false);
    window.removeEventListener('scroll', this.handleScroll, false);
  },
  methods: {
    handleMessage({ source, data = {} }) {
      const self = this;
      // Abstract iframe check into separate function to keep complexity low
      const isIframe = function(self) {
        return self.$refs.frame.$refs.iframe && source === self.$refs.frame.$refs.iframe.contentWindow;
      };
      // Handle scroll and iframeHeight
      if (isIframe(self) && data.height) {
        this.iframeHeight = parseInt(data.height, 10) + 'px';
      } else if (this.$refs.sidebar && source === this.$refs.sidebar.contentWindow && data.route) {
        this.hasScrolled = false;
        this.$router.replace('/docs' + data.route);
        this.$refs.frame.$refs.iframe.contentWindow.postMessage(data, '*');
      } else if (isIframe(self) && data.hasOwnProperty('scrollTop')) {
        this.scrollTop = data.scrollTop;
        this.scrollToContent();
      }
    },
    handleScroll(event) {
      this.hasScrolled = true;
    },
    handleLoad() {
      this.scrollToContent();
    },
    scrollToContent() {
      if (!this.hasScrolled) {
        if (scrollTo && typeof scrollTo === 'function') {
          scrollTo({
            top: parseInt(this.scrollTop),
            behavior: 'smooth'
          });
        } else {
          document.documentElement.scrollTop = parseInt(this.scrollTop);
        }
      }
    }
  }
};
</script>

<style lang="scss">
.docs-docs {
  transition: padding $--clb-transition-time ease;
  padding: $--clb-layout-1 $--clb-mobile-padding 0 $--clb-mobile-padding;
  border-radius: $--clb-border-radius;
  min-height: 100%;
  display: flex;

  @include md-up {
    transition: margin $--clb-transition-time ease, padding $--clb-transition-time ease;
    max-width: calc(100% - #{$--clb-mobile-padding * 2});
    margin: $--clb-layout-3 $--clb-mobile-padding 0 $--clb-mobile-padding;
    padding: $--clb-layout-1 $--clb-layout-2 0 $--clb-layout-2;
  }

  @include lg-up {
    margin-top: $--clb-layout-5;
    padding: $--clb-layout-1 $--clb-layout-4 0 $--clb-layout-4;
  }
}
</style>
