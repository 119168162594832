import dayjs from 'dayjs';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import _omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import storage from '@/services/storage';
import MetricsAggregator from '@/services/metrics-aggregator';
import { MINIMUM_COLUMN_COUNT_ON_PERFORMANCE_REPORT_TABLE } from '@/constants/performance';

/**
 * @typedef Audience
 * @type {Object}
 * @property {Object} attributes - the attributes representing an audience
 * @property {String} created - the creation date of an audience in ISO format
 * @property {String} crowd_id - the id of the crowd an audience belongs to
 * @property {Boolean} deleted - whether the audience is deleted or not
 * @property {String} id - the id representing the audience
 * @property {String} name - the name of the audience
 * @property {Number} rank - the rank of the audience in comparison to other audiences in a crowd
 * @property {String} updated - the updated date of an audience in ISO format
 * @property {Boolean} visible - whether the audience is visible or not
 */

/**
 * @typedef Crowd
 * @type {Object}
 * @property {Object} attributes - the attributes representing a crowd
 * @property {String} constraint_id - the constraint id of a crowd
 * @property {String} constraint_type - the type of constraint of a crowd
 * @property {String} created - the creation date of a crowd in ISO format
 * @property {Boolean} deleted - whether the crowd is deleted or not
 * @property {Boolean} favourite - whether the crowd is favourited or not
 * @property {String} id - the id representing the crowd
 * @property {String} name - the name of the crowd
 * @property {String} org_id - the id of the organization a crowd belongs to
 * @property {String} updated - the updated date of a crowd in ISO format
 * @property {Boolean} visible - whether the crowd is visible or not
 */

/**
 * Format audience data from API
 * @param {Audience} audience
 * @return {Audience}
 */
function formatAudienceFromApi(audience) {
  const formatted = _cloneDeep(audience);
  if (typeof formatted.deleted !== 'boolean') formatted.deleted = !!formatted.deleted;
  if (typeof formatted.visible !== 'boolean') formatted.visible = formatted.visible === '1';
  formatted.attributes = formatAttributesFromApi(formatted.attributes);
  return formatted;
}

/**
 * Format crowd data from API
 * @param {Crowd} crowd
 * @return {Crowd}
 */
function formatCrowdFromApi(crowd) {
  const formatted = _cloneDeep(crowd);
  formatted.audiences = formatted.audiences.map(formatAudienceFromApi);
  if (typeof formatted.favourite !== 'boolean') formatted.favourite = formatted.favourite === '1';
  if (typeof formatted.deleted !== 'boolean') formatted.deleted = !!formatted.deleted;
  if (typeof formatted.visible !== 'boolean') formatted.visible = formatted.visible === '1';
  return formatted;
}

/**
 * Format attributes from API
 * @param {Object} attributes
 * @return {Object}
 */
function formatAttributesFromApi(attributes) {
  const formatted = {};
  Object.keys(attributes || {}).forEach((key) => {
    if (attributes[key].length > 1 || (attributes[key].length === 1 && attributes[key][0].id !== null)) {
      formatted[key] = attributes[key].map((attribute) => ({ ...attribute, type: key }));
    }
  });
  return Object.freeze(formatted);
}

/**
 * Get default state of a single org for performance analytics
 * @returns {Object}
 */
function baseOrganizationStateFactory() {
  return {
    crowds: [],
    createdCrowd: {},
    crowdConstraints: {},
    crowdAttributes: {},
    audienceAttributes: {},
    selectedMetric: 'performance',
    dateFilter: {
      dateRange: 'CUSTOM',
      startDate: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD')
    },
    isLoadingCrowds: false,
    isLoadingAudienceMetrics: false,
    crowdMetrics: {}
  };
}

/**
 * Create a clear org based state state
 * @return {{orgs: {}, activeOrganizationState: {}}}
 */
const baseStateFactory = function() {
  return {
    orgs: {},
    activeOrganizationState: {},
    popovers: storage.getObject(process.env.VUE_APP_JB_CROWD_STORAGE_KEY, {})
  };
};

export default {
  state: baseStateFactory(),

  getters: {
    crowds: (state) => state.activeOrganizationState.crowds,
    activeCrowds: (state) => state.activeOrganizationState.crowds.filter((crowd) => !crowd.deleted),
    createdCrowd: (state) => state.activeOrganizationState.createdCrowd,
    crowdConstraints: (state) => state.activeOrganizationState.crowdConstraints,
    crowdAttributes: (state) => state.activeOrganizationState.crowdAttributes,
    audienceAttributes: (state) => state.activeOrganizationState.audienceAttributes,
    selectedMetric: (state) => state.activeOrganizationState.selectedMetric,
    crowdDateFilter: (state) => state.activeOrganizationState.dateFilter,
    isLoadingCrowds: (state) => state.activeOrganizationState.isLoadingCrowds,
    isLoadingAudienceMetrics: (state) => state.activeOrganizationState.isLoadingAudienceMetrics,
    crowdMetrics: (state) => state.activeOrganizationState.crowdMetrics,
    crowdPopovers: (state) => state.popovers,

    // get specific crowd from id
    getCrowdById: (state) => (crowdId) => state.activeOrganizationState.crowds.find((crowd) => parseInt(crowd.id) === parseInt(crowdId))
  },

  mutations: {
    SET_ACTIVE_ORGANIZATION(state, org) {
      if (!state.orgs.hasOwnProperty(org.id)) {
        state.orgs[org.id] = baseOrganizationStateFactory();
      }

      state.activeOrganizationState = state.orgs[org.id];
    },
    SET_CREATED_CROWD(state, data = {}) {
      state.activeOrganizationState.createdCrowd = data;
    },
    SET_CROWD_CONSTRAINTS(state, constraints) {
      state.activeOrganizationState.crowdConstraints = constraints;
    },
    SET_CROWD_ATTRIBUTES(state, attributes) {
      state.activeOrganizationState.crowdAttributes = attributes;
    },
    SET_AUDIENCE_ATTRIBUTES(state, attributes) {
      state.activeOrganizationState.audienceAttributes = attributes;
    },
    SET_CROWDS(state, crowds) {
      state.activeOrganizationState.crowds = crowds;
    },
    SET_IS_LOADING_CROWDS(state, isLoading) {
      state.activeOrganizationState.isLoadingCrowds = isLoading;
    },
    SET_IS_LOADING_AUDIENCE_METRICS(state, isLoading) {
      state.activeOrganizationState.isLoadingAudienceMetrics = isLoading;
    },
    SET_SELECTED_METRIC(state, selectedMetric) {
      selectedMetric = ['performance', 'demographics'].includes(selectedMetric) ? selectedMetric : 'performance';
      if (state.activeOrganizationState.selectedMetric !== selectedMetric) {
        state.activeOrganizationState.selectedMetric = selectedMetric;
      }
    },
    SET_DATE_FILTER_VALUE(state, dateFilter) {
      dateFilter = _omitBy(_pick(dateFilter, ['dateRange', 'startDate', 'endDate']), isNil);
      if (Object.keys(dateFilter).length && JSON.stringify(state.activeOrganizationState.dateFilter) !== JSON.stringify(dateFilter)) {
        state.activeOrganizationState.dateFilter = Object.assign({}, dateFilter);
      }
    },
    SET_CROWD(state, crowd) {
      const crowdIndex = state.activeOrganizationState.crowds.findIndex((x) => x.id === crowd.id);
      crowdIndex !== -1
        ? state.activeOrganizationState.crowds[crowdIndex] = crowd
        : state.activeOrganizationState.crowds.push(crowd);
    },
    SET_CROWD_VIEW(state, { crowdId, view }) {
      const crowd = state.activeOrganizationState.crowds.find((crowd) => crowd.id === crowdId);
      if (crowd) {
        crowd.audiences.forEach((a) => {
          a.updated = a.visible === view.includes(a.id) ? a.updated : dayjs().format('YYYY-MM-DD HH:mm:ss');
          a.visible = view.includes(a.id);
        });
      }
    },
    ADD_NEW_CROWD(state, data) {
      state.activeOrganizationState.crowds.push(data);
    },
    TOGGLE_CROWD_FAVOURITE(state, { crowdId, favourite = null }) {
      const foundCrowd = state.activeOrganizationState.crowds.find((crowd) => crowd.id === crowdId);
      if (foundCrowd !== undefined) foundCrowd.favourite = favourite !== null ? favourite : !foundCrowd.favourite;
    },
    DELETE_CROWD(state, { crowdId }) {
      const crowdIndex = state.activeOrganizationState.crowds.findIndex((crowd) => crowd.id === crowdId);
      if (crowdIndex !== -1) state.activeOrganizationState.crowds.splice(crowdIndex, 1);
    },
    ADD_NEW_AUDIENCE(state, audience) {
      const foundCrowd = state.activeOrganizationState.crowds.find((crowd) => crowd.id === audience.crowd_id);
      if (foundCrowd !== undefined) foundCrowd.audiences.push(audience);
    },
    TOGGLE_AUDIENCE_VISIBILITY(state, { crowdId, audienceId, visible = null }) {
      const foundCrowd = state.activeOrganizationState.crowds.find((crowd) => crowd.id === crowdId);
      const foundAudience = foundCrowd !== undefined ? foundCrowd.audiences.find((audience) => audience.id === audienceId) : undefined;
      if (foundAudience !== undefined) {
        foundAudience.visible = visible !== null ? visible : !foundAudience.visible;
        foundAudience.updated = dayjs().format('YYYY-MM-DD HH:mm:ss');
      }
    },
    DELETE_AUDIENCE(state, { crowdId, audienceId }) {
      const foundCrowd = state.activeOrganizationState.crowds.find((crowd) => crowd.id === crowdId);
      const audienceIndex = foundCrowd !== undefined ? foundCrowd.audiences.findIndex((audience) => audience.id === audienceId) : -1;
      if (audienceIndex !== -1) foundCrowd.audiences.splice(audienceIndex, 1);
    },
    SET_CROWD_METRICS(state, metrics) {
      state.activeOrganizationState.crowdMetrics = _cloneDeep(metrics);
    },
    SET_POPOVER_STATE(state, { key, value }) {
      this._vm.$set(state.popovers, key, value);
      storage.setObject(process.env.VUE_APP_JB_CROWD_STORAGE_KEY, state.popovers);
    },
    CLEAR_STORE(state) {
      Object.assign(state, baseStateFactory());
    }
  },

  actions: {
    setCreatedCrowd({ commit }, data) {
      commit('SET_CREATED_CROWD', data);
    },
    fetchCrowdsInit({ commit }) {
      commit('SET_IS_LOADING_CROWDS', true);
    },
    fetchCrowdsSuccess({ commit }, { response }) {
      const crowds = response.data.map(formatCrowdFromApi);
      commit('SET_CROWDS', crowds);
      commit('SET_IS_LOADING_CROWDS', false);
      return crowds;
    },
    fetchCrowdsFail({ commit }, { error }) {
      if (this._vm.$api.isCancel(error)) return;
      commit('SET_IS_LOADING_CROWDS', false);
    },
    fetchCrowdConstraintsInit({ commit }) {
      commit('SET_CROWD_CONSTRAINTS', {});
    },
    fetchCrowdConstraintsSuccess({ commit }, { response }) {
      const attributes = formatAttributesFromApi(response.data);
      commit('SET_CROWD_CONSTRAINTS', attributes);
      return attributes;
    },
    fetchCrowdAttributesSuccess({ commit }, { response }) {
      const formatted = formatAttributesFromApi(response.data);
      commit('SET_CROWD_ATTRIBUTES', formatted);
      return formatted;
    },
    clearCrowdAttributes({ commit }) {
      commit('SET_CROWD_ATTRIBUTES', {});
    },
    fetchAudienceAttributesSuccess({ commit }, { response }) {
      const formatted = formatAttributesFromApi(response.data);
      commit('SET_AUDIENCE_ATTRIBUTES', formatted);
      return formatted;
    },
    clearAudienceAttributes({ commit }) {
      commit('SET_AUDIENCE_ATTRIBUTES', {});
    },
    addNewCrowdSuccess({ commit }, { response }) {
      const crowd = formatCrowdFromApi(response.data);
      commit('SET_CROWD', crowd);
      commit('SET_CREATED_CROWD', {});
      return crowd;
    },
    toggleCrowdFavouriteInit({ getters, commit }, { payload }) {
      const crowd = _cloneDeep(getters.getCrowdById(payload.crowdId));
      const favourite = payload.params.favourite;
      commit('TOGGLE_CROWD_FAVOURITE', { crowdId: payload.crowdId, favourite });
      return {
        reject: () => commit('SET_CROWD', crowd) // reconcile crowd upon failure
      };
    },
    setCrowdViewInit({ commit }, { payload }) {
      const { crowdId, params } = payload;
      commit('SET_CROWD_VIEW', { crowdId: crowdId, view: params.view || [] });
    },
    deleteCrowdInit({ getters, commit }, { payload }) {
      const crowd = _cloneDeep(getters.getCrowdById(payload.crowdId));
      commit('DELETE_CROWD', { crowdId: payload.crowdId });
      return {
        reject: () => commit('SET_CROWD', crowd) // reconcile crowd upon failure
      };
    },
    addNewAudienceSuccess({ commit }, { response }) {
      const audience = formatAudienceFromApi(response.data);
      commit('ADD_NEW_AUDIENCE', audience);
      return audience;
    },
    toggleAudienceVisibilityInit({ getters, commit }, { payload }) {
      const audience = getters.getCrowdById(payload.crowdId).audiences.find(audience => audience.id === payload.audienceId);
      const visible = payload.params.visible;
      commit('TOGGLE_AUDIENCE_VISIBILITY', { crowdId: payload.crowdId, audienceId: payload.audienceId, visible });
      return {
        reject: () => commit('ADD_NEW_AUDIENCE', { ...audience, visible }) // reconcile crowd upon failure
      };
    },
    deleteAudienceInit({ getters, commit }, { payload }) {
      const audience = getters.getCrowdById(payload.crowdId).audiences.find(audience => audience.id === payload.audienceId);
      commit('DELETE_AUDIENCE', { crowdId: payload.crowdId, audienceId: payload.audienceId });
      return {
        reject: () => commit('ADD_NEW_AUDIENCE', { ...audience, visible: false })
      };
    },
    fetchAudienceMetricsInit({ commit }) {
      commit('SET_IS_LOADING_AUDIENCE_METRICS', true);
    },
    fetchAudienceMetricsSuccess({ commit }, { response }) {
      const metrics = new MetricsAggregator(response.data, MINIMUM_COLUMN_COUNT_ON_PERFORMANCE_REPORT_TABLE);
      commit('SET_CROWD_METRICS', metrics);
      commit('SET_IS_LOADING_AUDIENCE_METRICS', false);
      return metrics;
    },
    fetchAudienceMetricsFail({ commit }, { error }) {
      if (this._vm.$api.isCancel(error)) return;
      commit('SET_CROWD_METRICS', {});
      commit('SET_IS_LOADING_AUDIENCE_METRICS', false);
    },
    updateStoreFromRoute({ commit }, { to } = {}) {
      commit('SET_SELECTED_METRIC', _get(to, 'query.view', 'performance'));
      commit('SET_DATE_FILTER_VALUE', _pick(to.query, ['dateRange', 'startDate', 'endDate']));
    },
    setCrowdDateFilter({ commit }, dateFilter) {
      commit('SET_DATE_FILTER_VALUE', dateFilter);
    },
    setCrowdSelectedMetric({ commit }, metric = 'performance') {
      commit('SET_SELECTED_METRIC', metric);
    },
    setCrowdPopoverState({ commit }, action) {
      commit('SET_POPOVER_STATE', action);
    }
  }
};
