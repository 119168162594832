
import AppModal from '@/components/AppModal.vue';

export default function(VueInstance) {
  const AppModalConstructor = VueInstance.extend(AppModal);

  let currentMsg, instance;
  const msgQueue = [];

  const callback = action => {
    if (currentMsg.resolve && action === 'confirm') {
      currentMsg.resolve(action);
    } else if (currentMsg.reject && (action === 'cancel' || action === 'close')) {
      currentMsg.reject(action);
    }
    instance.visible = false;
  };

  const initInstance = () => {
    instance = new AppModalConstructor({
      el: document.createElement('div')
    });
  };

  const renderDialog = () => {
    if (!msgQueue.length) return;
    currentMsg = msgQueue.shift();
    const options = currentMsg.options;
    for (const prop in options) {
      if (options.hasOwnProperty(prop)) {
        instance[prop] = options[prop];
      }
    }
    if (options.callback === undefined) {
      instance.callback = callback;
    }

    const oldCb = instance.callback;
    instance.callback = (action, instance) => {
      oldCb(action, instance);
      renderDialog();
    };

    document.body.appendChild(instance.$el);
  };

  const jbModal = function(options = {}) {
    if (!instance) {
      initInstance();
    };

    return new Promise((resolve, reject) => {
      msgQueue.push({
        options: options,
        callback: callback,
        resolve: resolve,
        reject: reject
      });

      renderDialog();

      VueInstance.nextTick(() => {
        instance.visible = true;
      });
    });
  };

  jbModal.confirm = (options) => {
    return jbModal({
      type: 'confirm',
      ...options
    });
  };

  jbModal.alert = (options) => {
    return jbModal({
      type: 'alert',
      closeOnPressEscape: false,
      closeOnClickModal: false,
      showClose: true,
      ...options
    });
  };

  VueInstance.prototype.$jbModal = jbModal;
  VueInstance.prototype.$jbConfirm = jbModal.confirm;
  VueInstance.prototype.$jbAlert = jbModal.alert;
};
