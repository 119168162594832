import '@/polyfills';
/* globals rg4js */
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from '@/store';
import jbxApi from '@/services/jbx-api';
import eventTrack from '@/services/event-track';

// Api
import Api from '@/api/index';

// Styles
import '@/theme/jumbleberry/display.scss';
import 'vue-slider-component/theme/default.css';
import '@/theme/tailwind.css';

// Plugins
import ElementPlugin from '@/plugins/element.js';
import SliderPlugin from '@/plugins/vue-slider.js';
import PortalPlugin from '@/plugins/portal.js';
import VhCheck from '@/plugins/vh-check.js';
import Supports from '@/plugins/supports.js';
import WithRoot from '@/plugins/with-root.js';
import EventTrackPlugin from '@/plugins/event-track.js';
import AbilitiesPlugin from '@/plugins/abilities.js';

// Filters
import CapitalizeFilter from '@/filters/capitalize';
import BreakWordsFilter from '@/filters/break-words';
import MoneyFilter from '@/filters/money';
import TruncateFilter from '@/filters/truncate';
import StartCaseFilter from '@/filters/start-case';

// Directives
import SelectCloseOnBlurDirective from '@/directives/SelectCloseOnBlur.js';
import Scrollable from '@/directives/Scrollable.js';
import KeyboardAction from '@/directives/KeyboardAction.js';
import Tippy from '@/directives/tippy';
import EventTrackDirective from '@/directives/EventTrack.js';
import JbModal from '@/plugins/jbModal.js';

Api(Vue, store);
ElementPlugin(Vue);
Supports(Vue);
SliderPlugin(Vue);
PortalPlugin(Vue);
VhCheck();
WithRoot(Vue);
EventTrackPlugin(Vue);
AbilitiesPlugin(Vue);
CapitalizeFilter(Vue);
BreakWordsFilter(Vue);
MoneyFilter(Vue);
TruncateFilter(Vue);
StartCaseFilter(Vue);
Scrollable(Vue);
KeyboardAction(Vue);
EventTrackDirective(Vue);
Tippy(Vue);
SelectCloseOnBlurDirective(Vue);
JbModal(Vue);

jbxApi.setRouterInstance(router);

Vue.config.productionTip = process.env.NODE_ENV === 'production';
Vue.config.devtools = process.env.NODE_ENV !== 'production';

if (process.env.NODE_ENV !== 'development') {
  let errorsReported = 0;
  Vue.config.errorHandler = function(err, vm, info) {
    if (errorsReported++ <= 10) {
      eventTrack({ action: 'JavaScript Error', props: { error: err, errorInfo: info } });
      window.rg4js && rg4js('send', {
        error: err,
        customData: [{ info: info }]
      });
    }
  };
};

if (process.env.NODE_ENV === 'test' || process.env.VUE_APP_DISABLE_TRANSITIONS) {
  Vue.component('transition', {
    functional: true,
    render: function(h, { slots }) {
      return slots().default;
    }
  });
  Vue.component('transition-group', {
    props: {
      tag: {
        type: String,
        default: 'span'
      }
    },
    render: function(h) {
      return h(this.$props.tag, this, this.$slots.default);
    }
  });
}

// Sets device flag
Vue.prototype.$deviceType = 'desktop';

const VueApp = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

if (window.Cypress) {
  window.__app__ = VueApp;
}
