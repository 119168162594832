import _get from 'lodash/get';
import _uniqueId from 'lodash/uniqueId';
import { AbilityBuilder, Ability } from '@casl/ability';
import { ALL_AVAILABLE_PERMISSIONS, AVAILABLE_RESOURCE_PERMISSIONS } from '@/constants/permissions';

/**
 * @typedef {Object} AbilityRule
 * @property {String} action
 * @property {String} subject
 */

/**
 * @typedef {Object} ApiPermission
 * @property {String} id
 * @property {String} name
 * @property {String} description
 * @property {String} category
 */

/**
 * @typedef {Object} Resource
 * @property {String} __type
 * @property {String} id
 */

export const AppAbility = Ability;

// TODO: comes from permissions endpoint
const USER_PERMISSIONS = [
  { id: _uniqueId(), name: 'campaign_discover_view', description: 'placeholder', category: ' "test"' },
  { id: _uniqueId(), name: 'campaign_application_view', description: 'placeholder', category: ' "test"' },
  { id: _uniqueId(), name: 'campaign_details_view', description: 'placeholder', category: ' "test"' },
  { id: _uniqueId(), name: 'campaign_funnels_view', description: 'placeholder', category: ' "test"' },
  { id: _uniqueId(), name: 'campaign_pixels_view', description: 'placeholder', category: ' "test"' },
  { id: _uniqueId(), name: 'financial_payment_history_view', description: 'placeholder', category: ' "test"' },
  // { id: _uniqueId(), name: 'financial_payment_setup_view', description: 'placeholder', category: ' "test"' },
  { id: _uniqueId(), name: 'financial_reports_sub_id_view_own', description: 'placeholder', category: ' "test"' },
  { id: _uniqueId(), name: 'financial_reports_campaign_report_view_own', description: 'placeholder', category: ' "test"' },

  { id: _uniqueId(), name: 'organization_management_transfer_org_ownership', description: 'placeholder', category: ' "test"' },
  { id: _uniqueId(), name: 'organization_management_view_orgs_user_list', description: 'placeholder', category: ' "test"' },
  { id: _uniqueId(), name: 'organization_management_invite_user_to_org', description: 'placeholder', category: ' "test"' },
  { id: _uniqueId(), name: 'organization_management_delete_user_from_organization', description: 'placeholder', category: ' "test"' },
  { id: _uniqueId(), name: 'organization_management_view_user_role_in_orgs_user_list', description: 'placeholder', category: ' "test"' },
  { id: _uniqueId(), name: 'organization_management_modify_user_s_role', description: 'placeholder', category: ' "test"' },
  { id: _uniqueId(), name: 'organization_management_view_company_partnership_list', description: 'placeholder', category: ' "test"' },
  { id: _uniqueId(), name: 'organization_management_view_own_partnership_list', description: 'placeholder', category: ' "test"' },
  { id: _uniqueId(), name: 'organization_management_modify_users_on_partnerships', description: 'placeholder', category: ' "test"' },
  { id: _uniqueId(), name: 'organization_management_create_new_partnership_between_orgs', description: 'placeholder', category: ' "test"' },
  { id: _uniqueId(), name: 'organization_management_modify_partnership_permissions_resource_', description: 'placeholder', category: ' "test"' }

];

// TODO: comes from campaign details endpoint
const RESOURCE_PERMISSIONS = [
  { id: '1', name: 'campaign_application_view', description: 'placeholder', category: ' "test"' },
  { id: '2', name: 'campaign_details_view', description: 'placeholder', category: ' "test"' },
  { id: '3', name: 'campaign_funnels_view', description: 'placeholder', category: ' "test"' },
  // { id: '4', name: 'campaign_pixels_view', description: 'placeholder', category: ' "test"' }
  { id: '5', name: 'financial_payment_history_view', description: 'placeholder', category: ' "test"' }
  // { id: '6', name: 'financial_payment_setup_view', description: 'placeholder', category: ' "test"' },
];

/**
 * Define the permission rules from user permissions and optionally resource permissions
 * @param {ApiPermission[]} permissions - List of user permissions
 * @param {ApiPermission[]} resourcePermissions - List of resource permissions
 * @param {Resource} resource - Resource
 * @returns {AbilityRule[]} Ability rules
 */
export function defineRules(permissions = USER_PERMISSIONS, resourcePermissions = RESOURCE_PERMISSIONS, resource = { __type: 'campaign', id: '1234' }) {
  const { can, rules } = new AbilityBuilder(AppAbility);

  Object.values(ALL_AVAILABLE_PERMISSIONS).forEach((perm) => {
    if (Object.values(AVAILABLE_RESOURCE_PERMISSIONS).includes(perm)) {
      const resourceId = _get(resource, 'id');
      if (resourceId && resourcePermissions.find((x) => x.name === perm)) can(perm, _get(resource, '__type', 'campaign'), { id: resourceId });
    } else if (permissions.find((x) => x.name === perm)) {
      can(perm, 'all');
    }
  });

  return rules;
}

/**
 * Define the ability instance for a user by their roleId
 * @param {ApiPermission[]} permissions - List of user permissions
 * @param {ApiPermission[]} resourcePermissions - List of resource permissions
 * @param {Resource} resource - Resource
 * @returns {Ability}
 */
export function buildAbility(permissions, resourcePermissions, resource) {
  return new AppAbility(defineRules(permissions, resourcePermissions, resource), { detectSubjectType: (object) => object && object.__type ? object.__type : 'all' });
}
