<template>
  <header class="app-banner">
    <portal-target name="app-banner__header" class="app-banner__header" />
    <portal-target name="app-banner__content" class="app-banner__content" />
  </header>
</template>
<script>

export default {
  name: 'AppBanner'
};
</script>
<style lang="scss" scoped>
.app-banner {
  width: 100%;
  position: relative;
  overflow: hidden;

  &__content {
    max-width: 100vw;
    width: 100%;
    position: relative;
    margin: 0 auto;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
</style>
