import questionsMappers from './mappers';
import defaultMapper from './default-mapper';
import groupMapper from './question-group';

questionsMappers.groupMapper = groupMapper;

export default function(questionType) {
  const mapperKey = `${questionType}Mapper`;
  if (questionsMappers.hasOwnProperty(mapperKey)) {
    return Object.assign({}, defaultMapper, questionsMappers[mapperKey]);
  }
  return defaultMapper;
}
