import axios from 'axios';
import qs from 'qs';
import jbxAxios from '@/services/jbx-axios';
import _get from 'lodash/get';
import config from '@/helpers/config';

let apiToken = null;
let router = null;
const baseURL = config.VUE_APP_PLATFORM_API_URL;

/**
 * Get a query string from either an object or a string
 * @param params
 * @return {string}
 */
function getQueryString(params) {
  return params !== null
    ? `?${typeof params === 'object' ? qs.stringify(params) : params}`
    : '';
}

/**
 * Redirect to signout on unauthorized response
 */
jbxAxios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error.hasOwnProperty('response') && error.response.status === 401) {
      router.replace('/signout');
    }
    return Promise.reject(error);
  }
);

const JBXApi = {
  cancelToken: axios.CancelToken,
  isCancel: (e) => axios.isCancel(e && e.error ? e.error : e),
  hasErrors: (e) => _get(e, 'response.data._meta.errors') && e.response.data._meta.errors.length,

  /**
   * Set an instance of vue-router on the api service
   * @param {VueRouter} routerInstance
   */
  setRouterInstance(routerInstance) {
    router = routerInstance;
  },

  /**
   * @return {Axios}
   */
  getAxiosInstance() {
    return jbxAxios;
  },

  /**
   * @return {String}
   */
  getApiToken() {
    return apiToken;
  },

  /**
   * @param {String} token
   */
  setAuthToken(token) {
    apiToken = token;
  },

  /**
   * Clears current api token
   */
  clearAuthToken() {
    apiToken = null;
  },

  /**
   * @param {Object} options
   * @returns {Promise}
   */
  request(options) {
    const headers = {};
    if (apiToken !== null) {
      headers['api-token'] = apiToken;
    }
    return jbxAxios.request({ headers, ...options });
  },

  /**
   * @param {String} url
   * @param {Object} params
   * @param {String} cancellationKey
   * @returns {Promise}
   */
  get(url, params = null, cancellationKey = null) {
    const options = {
      method: 'get',
      url: url + getQueryString(params)
    };

    if (cancellationKey !== null) {
      options.cancellationKey = cancellationKey;
    }

    return this.request(options);
  },

  /**
   * @param {String} url
   * @param {Object} data
   * @returns {Promise}
   */
  post(url, data = {}) {
    return this.request({ url, data, method: 'post' });
  },

  /**
   * @param {String} url
   * @param {Object} data
   * @returns {Promise}
   */
  put(url, data) {
    return this.request({ url, data, method: 'put' });
  },

  /**
   * @param {String} url
   * @param {Object} data
   * @returns {Promise}
   */
  delete(url, data) {
    return this.request({ url, data, method: 'delete' });
  },

  /**
   * @param {String} url
   * @param {Object} params
   * @returns {Promise}
   */
  download(url, params, filename) {
    if (window.plugins && window.plugins.socialsharing) {
      return this.shareCsvMobile(url, params, filename);
    }
    return this.get(url, params)
      .then(response => {
        const blob = new Blob([response.data], { type: 'application/csv' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);

        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return response;
      }).catch(error => {
        // Todo: Better error handling
        console.log(error);
      });
  },

  /**
   * @param {String} url
   * @param {Object} params
   * @returns {Promise}
   */
  shareCsvMobile(url, params, filename) {
    params['api-token'] = this.getApiToken();
    params.format = 'csv';
    const urlWithParam = baseURL + `${url}/${filename}` + getQueryString(params);
    const options = {
      files: [urlWithParam] // an array of filenames either locally or remotely
    };

    return new Promise((resolve, reject) => {
      const onSuccess = function(result) {
        resolve(result);
      };
      const onError = function(msg) {
        reject(msg);
      };
      window.plugins.socialsharing.shareWithOptions(options, onSuccess, onError);
    });
  }
};

/**
 * JBX-API Service
 */
export default JBXApi;
