import qs from 'qs';

const INSTALLATION_REDIRECT_URLS = {
  shopify: process.env.VUE_APP_EMBEDDED_INSTALLATION_REDIRECT_SHOPIFY
};

const FRAME_REDIRECT_PATH = {
  shopify: '/admin/apps/' + process.env.VUE_APP_SHOPIFY_APP_NAME
};

/**
 * Check if the platform is embedded in a shopify store as an app
 * @param queryParams
 */
const isShopify = function(queryParams) {
  return queryParams.hasOwnProperty('shop');
};

/**
 * Resolves the type of shop provider from the query params
 * @param queryParams
 * @return {*}
 */
const resolveEmbeddedSource = function(queryParams) {
  if (isShopify(queryParams)) {
    return 'shopify';
  }
  return null;
};

/**
 * Resolves the embedded app url from the query params
 * @param queryParams
 * @return {*}
 */
const resolveEmbeddedUrl = function(queryParams) {
  if (isShopify(queryParams)) {
    return `https://${queryParams.shop}${FRAME_REDIRECT_PATH.shopify}`;
  }
  return null;
};

export default {
  resolveInstallationRedirectUrl(queryString) {
    const queryParams = qs.parse(queryString, { ignoreQueryPrefix: true });
    const embeddedSource = resolveEmbeddedSource(queryParams);
    return INSTALLATION_REDIRECT_URLS.hasOwnProperty(embeddedSource)
      ? INSTALLATION_REDIRECT_URLS[embeddedSource] + queryString
      : null;
  },
  resolveAppFrameRedirectUrl(queryString) {
    const queryParams = qs.parse(queryString, { ignoreQueryPrefix: true });
    return resolveEmbeddedUrl(queryParams);
  },
  getShopDetails(queryString) {
    const queryParams = qs.parse(queryString, { ignoreQueryPrefix: true });
    if (isShopify(queryParams)) {
      return {
        hmac: queryParams.hmac,
        domain: queryParams.shop,
        queryString: queryString
      };
    }
    return null;
  }
};
