import MiddlewareError from '@/routing/middleware-error';

/**
 * Get the sessionId of the user from either a query param or the auth store
 * @param to
 * @return {*}
 */
const getApiToken = function(to) {
  return to.query.hasOwnProperty('api-token') ? to.query['api-token'] : null;
};

/**
 * Check if there is an "api-token" query param on the url
 * and attempt to sign-in with it
 * @param {Object} context
 * @param {Route} context.to
 * @param {VueRouter} context.router
 * @param {Store} context.store
 * @param {Function} context.next
 */
export default async({ to, store, next, router }) => {
  const apiToken = getApiToken(to);

  if (!apiToken) return next();

  try {
    await store.dispatch('signout');
    await store.dispatch('signinWithApiToken', apiToken);
    return router.replace(to.path);
  } catch (e) {
    if (e.hasOwnProperty('response')) {
      throw new MiddlewareError('Invalid Sign In Token', '/signout');
    }
    return Promise.reject(e);
  }
};
