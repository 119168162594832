/**
 * Sanitize template string
 * @param template
 * @return {*}
 */
function prepareSafeEnvTemplates(template) {
  return template.replace(/\${{(.*?)}}/g, (match, group) => {
    return '${' + group + '}';
  });
}

/**
 * Prepare template scope
 * @param template
 * @return {*}
 */
function prepareTemplateScope(template) {
  return template.replace(/\${/g, '${this.');
}

/**
 * Transpile template for IE11 compatibility
 * @param {String} template
 */
function transpile(template) {
  return template.replace(/\${(.*?)}/g, (match, group) => {
    return '" + ' + group + ' + "';
  });
}

export default {
  compile(template, variables) {
    try {
      return new Function('return "' + transpile(prepareTemplateScope(prepareSafeEnvTemplates(template))) + '";').call(
        variables
      );
    } catch (e) {
      return template;
    }
  }
};
