/*
 * @param {ApiContext} context
 * @return {object}
 */
export default ({ fetch, storeHandler }) => {
  return {
    async fetchUsers(options = {}) {
      const organization = storeHandler.get('organization');
      return fetch({ url: `/v2/org/${organization.id}/users`, options });
    },
    async fetchCountries(options = {}) {
      const organization = storeHandler.get('organization');
      return fetch({ url: `/v2/org/${organization.id}/countries/active`, options });
    },
    async fetchVerticals(options = {}) {
      const organization = storeHandler.get('organization');
      return fetch({ url: `/v2/org/${organization.id}/verticals/active`, options });
    },
    async domains(options = {}) {
      const organization = storeHandler.get('organization');
      return fetch({ url: `/v2/org/${organization.id}/redirectors`, options });
    },
    async addNewDomain({ domain, ...options } = {}) {
      options.params = domain;
      const organization = storeHandler.get('organization');
      return fetch({ url: `/v2/org/${organization.id}/redirectors`, method: 'POST', options });
    },
    async deleteDomain(options) {
      const organization = storeHandler.get('organization');
      return fetch({ url: `/v2/org/${organization.id}/redirector/${options.redirector_id}`, method: 'DELETE', options });
    },
    async validateDomain({ domain, ...options } = {}) {
      options.params = { domain };
      const organization = storeHandler.get('organization');
      return fetch({ url: `/v2/org/${organization.id}/redirectors/validate`, method: 'POST', options });
    },
    async addNewUser(options = {}) {
      const organization = storeHandler.get('organization');
      return fetch({ url: `/v2/org/${organization.id}/user`, method: 'POST', options });
    },
    async fetchUserRoles(options = {}) {
      const organization = storeHandler.get('organization');
      return fetch({ url: `/v2/org/${organization.id}/roles`, options });
    },
    async deleteUser({ userId, ...options } = {}) {
      const organization = storeHandler.get('organization');
      return fetch({ url: `/v2/org/${organization.id}/user/${userId}`, method: 'DELETE', options });
    }
  };
};
