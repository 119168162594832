var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"drawer-layout"},[_c('div',{staticClass:"drawer-overlay",class:_vm.animateClasses,style:(_vm.contentStyle.overlay),on:{"click":_vm.handleOverlayClick}}),_vm._l((['left']),function(name){
var _obj;
return [(_vm.$slots[name])?_c('div',{key:name,ref:name,refInFor:true,staticClass:"drawer-wrap",class:Object.assign({}, _vm.animateClasses, ( _obj = {}, _obj[("drawer-layout__" + name)] = true, _obj['active'] =  name === _vm.activeSidebar || _vm.visibleSidebars.includes(name), _obj )),style:(_vm.drawerStyle[name])},[_vm._t(name)],2):_vm._e()]}),_c('div',{ref:"header",staticClass:"header-wrap",class:_vm.animateClasses,style:(_vm.contentStyle.header)},[_vm._t("header")],2),(_vm.$slots.content)?_c('div',{ref:"content",staticClass:"content-wrap",class:_vm.animateClasses,style:(_vm.contentStyle.content)},[_vm._t("content")],2):_vm._e(),_vm._l((['right', 'drawer']),function(name){
var _obj;
return [(_vm.$slots[name] && _vm.rightSidebarHasContent)?_c('div',{key:name,ref:name,refInFor:true,staticClass:"drawer-wrap",class:Object.assign({}, _vm.animateClasses, ( _obj = {}, _obj[("drawer-layout__" + name)] = true, _obj['active'] =  name === _vm.activeSidebar || _vm.visibleSidebars.includes(name), _obj )),style:(Object.assign({}, _vm.drawerStyle[name],
        {visibility: _vm.rightSidebarHasContent ? 'visible' : null,
        pointerEvents: !_vm.rightSidebarHasContent ? 'none' : null,
        transitionDuration: !_vm.rightSidebarHasContent ? '0ms' : null}))},[_vm._t(name),(_vm.rightSidebarHasContent)?_c('portal-target',{key:("app-" + name + "-sidebar-extension-handler"),ref:("app-" + name + "-sidebar-extension-handler"),refInFor:true,attrs:{"name":("app-" + name + "-sidebar-extension-handler"),"slot-props":{ name: name, open: _vm.open, toggle: _vm.toggle, click: function () { return _vm.toggle(name, _vm.open !== name); } },"slim":""}},[_c('app-sidebar-handle',{key:("app-" + name + "-sidebar-extension-handler-button"),on:{"click":function($event){return _vm.toggle(name, _vm.open !== name)}}})],1):_vm._e()],2):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }