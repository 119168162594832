import Vue from 'vue';
/**
 *
 * @param { JBXApi } jbxApi
 * @param { RequestCaching } requestCaching
 */
export default ({ jbxApi, requestCaching }) => {
  const baseOrganizationStateFactory = function() {
    return {
      links: {}
    };
  };

  const baseStateFactory = function() {
    return {
      orgs: {},
      activeOrganizationState: {},
      isFetchingLinks: false
    };
  };

  const state = baseStateFactory();
  const getters = {
    linksById: state => id => {
      return state.activeOrganizationState.links[id];
    },
    isFetchingLinks(state) {
      return state.isFetchingLinks;
    }
  };
  const actions = {

    /**
      * @param {Object} context
      * @param {Function} context.commit
      * @param {Object} params
      * @param {Number} params.orgId
      * @param {Number} params.campaignId
      * @return {Promise<* | never>}
      */

    async fetchLinks({ commit }, { orgId, campaignId }) {
      commit('FETCH_LINKS');
      return requestCaching.get({
        url: `v2/org/${orgId}/campaign/${campaignId}/funnels`
      }).then(response => {
        commit('FETCH_LINKS_SUCCESS', { data: response.data.data, campaignId });
      }).catch(e => {
        commit('FETCH_LINKS_FAIL');
      });
    }
  };
  const mutations = {
    SET_ACTIVE_ORGANIZATION(state, org) {
      if (!state.orgs.hasOwnProperty(org.id)) {
        state.orgs[org.id] = baseOrganizationStateFactory();
      }
      state.activeOrganizationState = state.orgs[org.id];
    },
    FETCH_LINKS(state) {
      state.isFetchingLinks = true;
    },
    FETCH_LINKS_SUCCESS(state, { data, campaignId }) {
      const campaignLinksData = {};
      campaignLinksData[campaignId] = data;
      state.activeOrganizationState.links = { ...state.activeOrganizationState.links, ...campaignLinksData };
      state.isFetchingLinks = false;
    },
    FETCH_LINKS_FAIL(state) {
      state.isFetchingLinks = false;
    },
    CLEAR_STORE(state) {
      state = Object.assign(state, baseStateFactory());
    }
  };

  return {
    state,
    getters,
    actions,
    mutations
  };
};
