import Vue from 'vue';
import _get from 'lodash/get';
import queryStringOptions from '@/services/query-string-options';

const VALID_REPORT_TYPES = ['mtd', 'subid', 'clicks', 'sales'];

/**
 *
 * @param { JBXApi } jbxApi
 * @param { RequestCaching } requestCaching
 */
export default ({ jbxApi, requestCaching }) => {
  const baseOrganizationStateFactory = function() {
    const subReportsOptions = function() {
      return {
        base: {},
        daily: {},
        sales: {},
        clicks: {}
      };
    };
    const subReportsData = function() {
      return {
        base: [],
        daily: [],
        sales: [],
        clicks: []
      };
    };

    return {
      reports: {
        mtd: subReportsData(),
        subid: subReportsData()
      },
      options: {
        mtd: subReportsOptions(),
        subid: subReportsOptions()
      },
      fetchingReport: {},
      reportNavigationStack: []
    };
  };

  const baseStateFactory = function() {
    return {
      orgs: {},
      activeOrganizationState: {}
    };
  };

  const state = baseStateFactory();
  const getters = {
    affiliateReportOptions: (state) => (reportType, reportSubType = 'base') => {
      return state.activeOrganizationState.options[reportType][reportSubType];
    },
    reportData: (state) => (reportType, reportSubType = 'base') => {
      return state.activeOrganizationState.reports[reportType][reportSubType];
    },
    fetchingReport: (state) => (reportType, reportSubType = 'base') => {
      return state.activeOrganizationState.fetchingReport.hasOwnProperty(`${reportType}-${reportSubType}`);
    },
    reportNavigationStack: state => {
      return state.activeOrganizationState.reportNavigationStack;
    }
  };
  const actions = {
    async setAffiliateReportOptions({ commit }, { reportType, reportSubType, options }) {
      commit('SET_AFFILIATE_REPORT_OPTIONS', { reportType, reportSubType, options });
    },
    async fetchReportSummary({ getters, commit }, { orgId, reportType, reportSubType, options }) {
      commit('FETCH_REPORT', { reportType, reportSubType });
      options.type = VALID_REPORT_TYPES.includes(reportSubType) ? reportSubType : reportType;

      try {
        const cancelToken = `report-${reportType}-${reportSubType || 'base'}`;
        const response = await jbxApi.get(`/v2/org/${orgId}/reporting_summary`, queryStringOptions.apify(options, ['filter']), cancelToken);
        const reportData = _get(response, 'data', {});
        commit('FETCH_REPORT_SUCCESS', { reportType, reportSubType, reportData: _get(reportData, 'data', []) });
        return Promise.resolve(reportData);
      } catch (err) {
        if (err.__CANCEL__) return Promise.resolve([]);
        commit('FETCH_REPORT_FAIL', { reportType, reportSubType });
        return Promise.reject(err);
      }
    },
    async downloadReportingCsv({ getters }, { filename, orgId, reportType, reportSubType, options }) {
      options.format = 'csv';
      options.type = VALID_REPORT_TYPES.includes(reportSubType) ? reportSubType : reportType;

      try {
        const result = await jbxApi.download(`/v2/org/${orgId}/reporting_summary`, queryStringOptions.apify(options, ['filter']), filename);
        return Promise.resolve(result.data);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    pushReportNavigationStack({ getters, commit }, item) {
      commit('PUSH_REPORT_NAVIGATION_STACK', item);
    },
    popReportNavigationStack({ getters, commit, state }) {
      const reportNavigationStack = JSON.parse(JSON.stringify(state.activeOrganizationState.reportNavigationStack));

      if (reportNavigationStack.length) {
        const previousRoute = reportNavigationStack.pop();
        commit('SET_REPORT_NAVIGATION_STACK', reportNavigationStack);
        return Promise.resolve(previousRoute);
      }
    },
    clearReportNavigationStack({ commit, state }) {
      const reportNavigationStack = JSON.parse(JSON.stringify(state.activeOrganizationState.reportNavigationStack));
      commit('CLEAR_REPORT_NAVIGATION_STACK', reportNavigationStack);
    }
  };

  const mutations = {
    FETCH_REPORT(state, { reportType, reportSubType = 'base' }) {
      Vue.set(state.activeOrganizationState.fetchingReport, `${reportType}-${reportSubType}`, true);
    },
    FETCH_REPORT_SUCCESS(state, { reportType, reportSubType = 'base', reportData }) {
      Vue.set(state.activeOrganizationState.reports[reportType], reportSubType, reportData);
      Vue.delete(state.activeOrganizationState.fetchingReport, `${reportType}-${reportSubType}`);
    },
    FETCH_REPORT_FAIL(state, { reportType, reportSubType = 'base' }) {
      Vue.delete(state.activeOrganizationState.fetchingReport, `${reportType}-${reportSubType}`);
      Vue.set(state.activeOrganizationState.reports[reportType], reportSubType, []);
    },
    SET_ACTIVE_ORGANIZATION(state, org) {
      if (!state.orgs.hasOwnProperty(org.id)) {
        state.orgs[org.id] = baseOrganizationStateFactory();
      }
      state.activeOrganizationState = state.orgs[org.id];
    },
    CLEAR_STORE(state) {
      state = Object.assign(state, baseStateFactory());
    },
    SET_AFFILIATE_REPORT_OPTIONS(state, { reportType, reportSubType = 'base', options }) {
      if (reportSubType !== 'base') {
        Vue.set(state.activeOrganizationState.options[reportType], reportSubType, options);
      }
      Vue.set(state.activeOrganizationState.options[reportType], 'base', { report: options.report });
    },
    PUSH_REPORT_NAVIGATION_STACK(state, item) {
      state.activeOrganizationState.reportNavigationStack.push(item);
    },
    SET_REPORT_NAVIGATION_STACK(state, newStack) {
      state.activeOrganizationState.reportNavigationStack = newStack;
    },
    CLEAR_REPORT_NAVIGATION_STACK(state) {
      state.activeOrganizationState.reportNavigationStack = [];
    }
  };

  return {
    state,
    getters,
    actions,
    mutations
  };
};
