export const merchant = {
  splashSubheader: 'These questions are crucial in order to verify your identity and to help us craft profitable campaigns for your business. All of your answers will be collected securely and held in strict confidence. We will be collecting the following:',
  splashItems: [
    {
      name: 'Your Personal Information',
      status: 'completed',
      description: 'Like your phone number and full name, so that we can get in touch'
    },
    {
      name: 'Your Company Information',
      status: 'started',
      description: 'Like where your company is located and the size & capabilities of your team'
    },
    {
      name: 'Your Marketing / Platform Details',
      status: 'pending',
      description: 'Like how you are currently marketing your products, and what data you collect'
    }
  ],
  surveyFinish: {
    approved: {
      title: 'Congratulations, your application has been submitted!',
      details:
        'Your application is currently pending review. Someone from Jumbleberry will reach out if additional information is required.'
    },
    pending: {
      title: 'Congratulations, your application has been submitted!',
      details:
        'Your application is currently pending review. Someone from Jumbleberry will reach out if additional information is required.'
    }
  }
};

export const affiliate = {
  splashSubheader:
    'These questions are crucial in order to verify your identity and to recommend uniquely tailored campaigns for you to run. We will be collecting the following:',
  splashItems: [
    {
      name: 'Your Personal Information',
      status: 'completed',
      description: 'Like your phone number and full name, so that we can get in touch'
    },
    {
      name: 'Your Company Information',
      status: 'started',
      description: 'Like where your company is located and the size of your team'
    },
    {
      name: 'Your Preferences',
      status: 'pending',
      description: 'Like what kind of campaigns you like to promote, and how you measure success'
    }
  ],
  surveyFinish: {
    approved: {
      title: 'Congratulations, your application has been approved!',
      redirectButtonUrl:
        '/discover',
      redirectButtonText: 'Discover Campaigns'
    },
    pending: {
      title: 'Congratulations, your application has been submitted!',
      details:
        'Your account is currently pending approval. Someone from Jumbleberry will reach out if additional information is required.'
    }
  }
};
export const general = {
  splashSubheader:
  'These questions are crucial in order to verify your identity and to recommend uniquely tailored campaigns for you to run. We will be collecting the following:',
  splashItems: [

  ],
  surveyFinish: {
    approved: {
      title: 'Congratulations, your signup is complete!',
      redirectButtonUrl:
        '/referral/marketing',
      redirectButtonText: ' Go to my account'
    }
  }
};

export default {
  merchant,
  affiliate,
  general
};
