<template>
  <div class="signup-layout">
    <transition name="fade" mode="out-in">
      <signup-header :key="isAuthenticated" :show-signout-button="isAuthenticated" :is-not-authenticated="!isAuthenticated" />
    </transition>
    <el-container :class="{ auth: onAuthPage }" direction="vertical">
      <svg :class="{ hidden: onAuthPage }" class="morph" viewBox="0 0 1400 770">
        <path
          ref="path"
          d="M 262.9,252.2 C 210.1,338.2 212.6,487.6 288.8,553.9 372.2,626.5 511.2,517.8 620.3,536.3 750.6,558.4 860.3,723 987.3,686.5 1089,657.3 1168,534.7 1173,429.2 1178,313.7 1096,189.1 995.1,130.7 852.1,47.07 658.8,78.95 498.1,119.2 410.7,141.1 322.6,154.8 262.9,252.2 Z" />
      </svg>
      <el-main>
        <transition name="el-fade-in" mode="out-in">
          <router-view v-if="platformLoaded" class="el-content" />
        </transition>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import SignupHeader from '@/components/signup/SignupHeader';
import backgroundShapes from '@/assets/data/backgroundShapes';
import anime from 'animejs';

export default {
  name: 'SignupLayout',
  components: {
    SignupHeader
  },
  data() {
    return {
      shape: 0,
      instances: [],
      frames: 0
    };
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    onAuthPage() {
      return !this.$store.getters.isAuthenticated;
    },
    platformLoaded() {
      return !this.$store.state.platform.loading;
    }
  },
  watch: {
    onAuthPage(newVal) {
      newVal ? this.stopShapes() : this.nextShape();
    }
  },
  mounted() {
    if (!this.onAuthPage) {
      this.nextShape();
      if (this.$route.name !== 'signup-survey-finish') {
        window.onbeforeunload = () => true;
      }
    }
  },
  beforeDestroy() {
    this.stopShapes();
    if (this.onAuthPage) window.onbeforeunload = null;
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === 'signout' || to.name === 'signup-survey-finish') {
      window.onbeforeunload = null;
    }
    next();
  },
  methods: {
    nextShape() {
      this.shape = (this.shape + 1) % backgroundShapes.length;
      this.stopShapes();
      anime({
        targets: this.$refs.path,
        duration: backgroundShapes[this.shape].animation.path.duration,
        easing: backgroundShapes[this.shape].animation.path.easing,
        d: backgroundShapes[this.shape].path,
        complete: () => {
          !this.onAuthPage && this.nextShape();
        },
        update: () => {
          if (++this.frames >= 2) {
            this.frames = 0;
            this.instances.map(i => i.pause());
            setTimeout(() => this.instances.map(i => i.play()), 55);
          }
        }
      });
      this.instances = anime.running.slice();
    },
    stopShapes() {
      anime.remove(this.$refs.path);
      this.instances.map(i => i.pause());
      this.instances = [];
    }
  }
};
</script>
<style lang="scss">
.el-container {
  @media (max-width: $--sm) {
    margin: 0 auto;
    width: 100%;
  }
}

.signup-layout {
  .morph {
    fill: #f3f5fd;
    height: 100vh;
    height: calc(100 * var(--vh, 1vh));
    left: -25%;
    margin: 0 auto;
    position: fixed;
    top: 0;
    width: 150vw;
    z-index: -1;

    @media (max-width: $--sm) {
      height: 1000px;
      left: calc(50vw - 500px);
      margin-top: 40px;
      top: calc(50vh - 500px);
      top: calc(50 * var(--vh, 1vh) - 500px);
      width: 1000px;
    }

    &.hidden {
      display: none;
    }
  }

  .el-main {
    align-items: center;
    display: flex;
    padding: 0;
    height: 0;
    min-height: calc(90vh - 120px);
    min-height: calc(90 * var(--vh, 1vh) - 120px);
    overflow: visible;
    position: relative;
    text-align: center;

    .el-content {
      flex: 1 1 auto;
      max-height: 100%;
    }

    @media (max-height: $--sm) {
      min-height: calc(100vh - 160px);
      min-height: calc(100 * var(--vh, 1vh) - 160px);
    }
  }

  .auth .el-main {
    align-items: stretch;
    height: 0;
    min-height: 100vh;
    min-height: calc(100 * var(--vh, 1vh));
  }

  .auth.fade-leave-active .el-main {
    min-height: 0;
  }

  .survey-finish-container,
  .survey-splash-component,
  .survey-question-component,
  .survey-summary-component {
    max-width: 550px;
    position: relative;
    margin: 60px auto 32px auto;
  }

  .survey-finish-container,
  .survey-splash-component,
  .survey-summary-component {
    margin-top: 0;
  }

  @media (max-width: 590px) {
    .survey-finish-container,
    .survey-splash-component,
    .survey-question-component,
    .survey-summary-component {
      margin: 60px $--clb-mobile-padding 32px $--clb-mobile-padding;
      max-width: calc(100vw - 2.4em);
    }

    .survey-finish-container,
    .survey-splash-component,
    .survey-summary-component {
      margin-top: 0;
    }
  }

  .back-link {
    display: block;
    align-items: center;
    background: $--jb-bg-accent-color;
    border: none;
    border-radius: 50%;
    color: $--color-text-primary;
    font-size: 15px;
    height: 40px;
    width: 40px;
    justify-content: space-around;
    left: 40px;
    padding: $--jb-backlink-padding;
    position: absolute;
    text-decoration: none;
    top: 20px;
    transition: 0.3s ease;
    z-index: 99;

    .el-icon-back {
      position: absolute;
      height: 15px;
      width: 13px;
      display: inline-block;
      left: 13px;
      top: 12px;
    }

    &:hover {
      background: $--jb-light-primary-color;
      box-shadow: $--jb-box-shadow-base;
      color: $--jb-bg-primary-color;
      transform: scale(1.1);
    }

    @media (max-width: $--sm) {
      left: 16px;
      top: 78px;
    }

    @media (min-width: $--sm) {
      top: 95px;
    }
  }

  .survey-header {
    margin-bottom: 0;
  }

  .survey-sub-header {
    color: $--jb-survey-sub-header;
    margin-bottom: 32px;
  }

  .el-button {
    font-size: $--font-size-base;
    font-weight: 600;
    white-space: normal;
  }

  .el-input__inner {
    font-weight: 600;

    &::placeholder {
      font-weight: 400;
    }
  }

  .el-input__inner,
  .el-input__inner:valid {
    border-color: $--color-primary;
  }

  .el-input__inner:invalid,
  .el-form-item.is-error .el-input__inner {
    border-color: $--color-danger;
  }

  //  ---------  Global Slider Styles
  .slider-label-cont {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    padding: 0 10px;
  }

  //  ---------/Global Slider Styles

  /* ----------
    Tablet >
  ------------ */
  @media (min-width: $--sm) {
    // survey-page
    .survey-header {
      margin-bottom: 0;
    }

    .survey-sub-header {
      margin-bottom: 32px;
    }
    //  ERROR MESSAGES
    .el-message--error {
      width: 550px;
    }
  }

  @media (min-width: $--md) {
    .back-link {
      left: calc(50% - 440px);
      top: 45%;
    }
  }
}
</style>
