export class MetricsAggregator {
  columns = [];
  metrics = {};
  localMax = {};
  globalMax = {};

  constructor(audiences = [], minColumns = 1) {
    minColumns = audiences.length + 1;
    // Fill columns if minColumns count is set
    if (minColumns > 0) {
      this.columns.push(...Array(minColumns).fill().map((v, index) => ({ id: `seeded-${index + 1}` })));
    }

    audiences.forEach(({ audience_id, audience_name, metrics }, index) => {
      // Keep list of columns positionally indexed
      this.columns[index] = { id: audience_id, name: audience_name };

      // Create a map for indexing values
      const valueMap = {};

      // Build metrics matrix
      metrics.forEach(({ name, values }) => {
        valueMap[name] = {};

        // Create storage objects
        this.metrics[name] = (this.metrics[name] || {});
        this.localMax[name] = (this.localMax[name] || {});

        // Seed available metrics keys for all new keys found
        Object.keys(values).forEach((key) => {
          valueMap[name][key] = values[key];
          if (!(key in this.metrics[name])) {
            this.metrics[name][key] = new Array(this.columns.length).fill('');

            // Seed totals
            this.globalMax[key] = this.globalMax[key] || 0;
            this.localMax[name][key] = this.localMax[name][key] || 0;
          }
        });
      });

      Object.keys(this.metrics).forEach((name) => {
        valueMap[name] = valueMap[name] || {};

        // Fill all metrics keys with audience values
        Object.keys(this.metrics[name]).forEach((key) => {
          // Set metric value onto the index of the audience
          this.metrics[name][key][index] = valueMap[name][key] || '-';

          // Keep track of local and global maximums
          const floatVal = valueMap[name][key] ? parseFloat(valueMap[name][key].toString().replace(/,/g, '')) : 0;
          if (floatVal > 0) {
            this.globalMax[key] = Math.max(this.globalMax[key], floatVal);
            this.localMax[name][key] = Math.max(this.localMax[name][key], floatVal);
          }
        });
      });
    });
  }

  getComputed() {
    return {
      columns: this.columns,
      metrics: this.metrics,
      localMax: this.localMax,
      globalMax: this.globalMax
    };
  }
}

export default MetricsAggregator;
