<template>
  <div class="app-image">
    <img class="tw-w-full tw-h-full" :src="computedSrc">
  </div>
</template>

<script>
import imageService from '@/services/image-service';

export default {
  name: 'AppImage',
  props: {
    width: {
      type: String,
      default: '150'
    },
    height: {
      type: String,
      default: '150'
    },
    src: {
      type: String,
      required: true
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    computedSrc() {
      return this.resizedImage();
    }
  },
  methods: {
    resizedImage() {
      return imageService.resizeImage(this.src, {
        w: this.width,
        h: this.height,
        auto: 'enhance,format',
        fit: 'crop',
        crop: 'top',
        bg: 'efefef',
        fm: 'jpeg',
        q: 85,
        ...this.options
      });
    }
  }
};
</script>
