<template>
  <div id="app-campaign-search-list-item" class="app-campaign-search-list-item " @click="$emit('click', campaign)">
    <div class="top tw-mb-space-2 tw-flex tw-justify-between">
      <div class="header">
        <slot name="id" :campaignid="campaign.id" />
        <slot />
        <slot name="cap" :campaigncap="campaign.weekly_cap" />
      </div>
      <div class="countries">
        <slot name="countries" :countries="campaign.countries">
          <app-country-icon
            :countries="campaign.countries"
            :limit="4"
            size="mini" />
        </slot>
      </div>
    </div>
    <div class="bottom">
      <div class="description">
        <slot name="title" :campaignname="campaign.name" />
        <slot name="status" :campaignstatus="campaign.status">
          <app-status :status="campaign.status" size="mini" />
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import AppCountryIcon from '@/components/AppCountryIcon';
import AppStatus from '@/components/AppStatus';

export default {
  name: 'AppCampaignSearchListItem',
  components: { AppCountryIcon, AppStatus },
  props: {
    campaign: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    isActive: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss">
.app-campaign-search-list-item {
  &:hover,
  &:active {
    @apply tw-bg-grey-white-ter tw-border-grey-white-ter tw-border tw-border-solid tw-cursor-pointer;
  }
}
</style>
