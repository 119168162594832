import storage from '@/services/storage';
import embeddedAppService from '@/services/embedded-app';
import _get from 'lodash/get';
import axios from 'axios';

const getLoaderIndex = function(loaders, key) {
  return loaders.findIndex(l => l.key === key);
};
const getLoader = function(loaders, key) {
  const index = getLoaderIndex(loaders, key);
  return index !== -1 ? loaders[index] : undefined;
};
const classAdd = function(elem, add) {
  if (!(new RegExp(`((?:^| )${add})($| )`)).test(elem.className)) { elem.className = `${elem.className} ${add}`.trim(); }
};
const classReplace = function(elem, remove, add) {
  const classRemoved = elem.className.replace(new RegExp(`((?:^| )${remove})($| )`), '$2');
  if (elem.className !== classRemoved) { elem.className = `${classRemoved} ${add}`.trim(); }
};

const state = {
  embedded: window.self !== window.top,
  leftSidebarVisible: false,
  leftSidebarWidth: undefined,
  rightDrawerVisible: false,
  rightDrawerWidth: undefined,
  rightSidebarVisible: false,
  npsSurveyVisible: false,
  loaders: [],
  windowSize: { width: window.innerWidth, height: window.innerHeight },
  activeTheme: null,
  activePage: null,
  version: process.env.VUE_APP_VERSION,
  build_time: process.env.VUE_APP_BUILD_DATE,
  requires_update: false,
  npsSurveySeen: false,
  isMenuCollapsed: true,
  shouldPreventNavigation: false
};

const getters = {
  requiresUpdate: (state) => state.requires_update,
  embedded(state) {
    return state.embedded;
  },
  loaders(state) {
    return state.loaders;
  },
  loading(state) {
    return state.loaders.length > 0;
  },
  windowWidth(state) {
    return state.windowSize.width;
  },
  windowHeight(state) {
    return state.windowSize.height;
  },
  leftSidebarVisible(state) {
    return state.leftSidebarVisible;
  },
  leftSidebarWidth(state) {
    return state.leftSidebarWidth;
  },
  rightDrawerVisible(state) {
    return state.rightDrawerVisible;
  },
  rightDrawerWidth(state) {
    return state.rightDrawerWidth;
  },
  rightSidebarVisible(state) {
    return state.rightSidebarVisible;
  },
  npsSurveyVisible(state) {
    return state.npsSurveyVisible;
  },
  npsSurveySeen() {
    return state.npsSurveySeen;
  },
  activeTheme(state) {
    return state.activeTheme;
  },
  activePage(state) {
    return state.activePage;
  },
  isMenuCollapsed() {
    return state.isMenuCollapsed;
  },
  shouldPreventNavigation() {
    return state.shouldPreventNavigation;
  }
};

const actions = {
  /**
   * Checks if the application needs to be updated
   * @param commit
   * @param state
   */
  checkForUpdates({ commit, state }) {
    axios.get(window.location.origin + '/latest.json?t=' + (new Date()).getTime())
      .then((response) => {
        commit('SET_REQUIRES_UPDATE', _get(response, 'data.version') !== state.version);
      })
      .catch(() => {
      });
  },
  /**
   * Adds a loader to the store
   * @param {Object} context
   * @param {Function} context.commit
   * @param {Object} payload
   * @param {String} payload.key
   * @param {String} payload.text
   */
  addPlatformLoader({ commit }, { key, text }) {
    commit('REMOVE_PLATFORM_LOADER', key);
    commit('ADD_PLATFORM_LOADER', { key, text });
  },
  /**
   * Adds a loader to the store
   * @param {Object} context
   * @param {Function} context.commit
   * @param {String} key
   */
  removePlatformLoader({ commit }, key) {
    commit('REMOVE_PLATFORM_LOADER', key);
  },
  /**
   * Shows error message on the app
   * @param {Object} context
   * @param {String|Array} content
   */
  showErrorMessage(context, content) {
    context.dispatch('showMessage', { content, type: 'error' });
  },
  /**
   * Shows message on the app
   * @param {Object} context
   * @param {Object} payload
   * @param {String|Array} payload.content
   * @param {String} payload.type
   */
  showMessage(context, { content, type, duration }) {
    if (typeof content === 'string') {
      content = [content];
    }

    const h = this._vm.$createElement;
    this._vm.$message({
      showClose: true,
      message: h('div', null, content.map(text => h('p', null, text))),
      type,
      duration
    });
  },
  /**
   * Run installation process when the app is embedded on a different platform
   * @param context
   * @param {Window} window
   * @param {Function} next
   */
  installEmbeddedApp(context, { window, next }) {
    const redirectUrl = embeddedAppService.resolveInstallationRedirectUrl(window.location.search);
    if (redirectUrl) {
      storage.setItem('embedded-app-params', window.location.search);
      window.location = redirectUrl;
    } else {
      next('/');
    }
  },
  /**
   * @param context
   * @param {Window} window
   * @param {Function} next
   */
  initializeEmbeddedApp({ dispatch }, { window, next }) {
    const embeddedAppParams = storage.getItem('embedded-app-params');
    if (window.self === window.top) {
      window.location = embeddedAppService.resolveAppFrameRedirectUrl(embeddedAppParams);
    } else {
      dispatch('setShopDetails', embeddedAppService.getShopDetails(embeddedAppParams));
      storage.removeItem('embedded-app-params');
      next('/integration' + embeddedAppParams);
    }
  },
  /**
   * Handles the size of the app window
   */
  handleWindowResize({ commit }, size) {
    commit('SET_WINDOW_SIZE', size);
  },
  /**
   * Toggle if the sidebar has extended content
   * @param state
   * @param visible
   */
  setLeftSidebarVisibility({ commit }, visible) {
    commit('SET_LEFT_SIDEBAR_VISIBILITY', visible);
  },
  /**
   * Increase the width of the left sidebar
   * @param state
   * @param width
   */
  setLeftSidebarWidth({ commit }, width) {
    commit('SET_LEFT_SIDEBAR_WIDTH', width);
  },
  /**
   * Toggle if the sidebar has extended content
   * @param state
   * @param visible
   */
  setRightDrawerVisibility({ commit }, visible) {
    commit('SET_RIGHT_DRAWER_VISIBILITY', visible);
  },
  /**
   * Increase the width of the right sidebar
   * @param state
   * @param width
   */
  setRightDrawerWidth({ commit }, width) {
    commit('SET_RIGHT_DRAWER_WIDTH', width);
  },
  /**
   * Toggle if the right sidebar is visible
   * @param commit
   */
  setRightSidebarVisibility({ commit }, visible) {
    commit('SET_RIGHT_SIDEBAR_VISIBILITY', visible);
  },
  /**
   * Toggle visibility of the Nps Survey
   * @param commit
   * @param visible
   */
  setNpsSurveyVisibility({ commit }, visible) {
    commit('SET_NPS_SURVEY_VISIBILITY', visible);
  },
  /**
   * Sets the active theme of the app
   * @param commit
   * @param document
   * @param themeClass
   */
  setActiveTheme({ commit }, { document, themeClass }) {
    commit('SET_ACTIVE_THEME', { document, themeClass });
  },
  /**
   * Sets the active page of the app
   * @param commit
   * @param document
   * @param pageClass
   */
  setActivePage({ commit }, { document, pageClass }) {
    commit('SET_ACTIVE_PAGE', { document, pageClass });
  },
  setIsMenuCollapsed({ commit }, isMenuCollapsed) {
    commit('SET_IS_MENU_COLLAPSED', isMenuCollapsed);
  },
  setShouldPreventNavigation({ commit }, shouldPreventNavigation) {
    commit('SET_SHOULD_PREVENT_NAVIGATION', shouldPreventNavigation);
  }
};
const mutations = {
  SET_REQUIRES_UPDATE(state, requiresUpdate) {
    state.requires_update = requiresUpdate;
  },
  ADD_PLATFORM_LOADER(state, loader) {
    state.loaders.push(loader);
  },
  REMOVE_PLATFORM_LOADER(state, key) {
    const loader = getLoader(state.loaders, key);
    loader &&
      setTimeout(() => {
        state.loaders = state.loaders.filter(item => item !== loader);
      }, 100);
  },
  SET_WINDOW_SIZE(state, size) {
    state.windowSize = size;
  },
  SET_LEFT_SIDEBAR_VISIBILITY(state, visible) {
    state.leftSidebarVisible = visible !== undefined ? !!visible : !state.leftSidebarVisible;
  },
  SET_LEFT_SIDEBAR_WIDTH(state, width) {
    state.leftSidebarWidth = width;
  },
  SET_RIGHT_DRAWER_VISIBILITY(state, visible) {
    state.rightDrawerVisible = visible !== undefined ? !!visible : !state.rightDrawerVisible;
  },
  SET_RIGHT_DRAWER_WIDTH(state, width) {
    state.rightDrawerWidth = width;
  },
  SET_RIGHT_SIDEBAR_VISIBILITY(state, visible) {
    state.rightSidebarVisible = visible !== undefined ? !!visible : !state.rightSidebarVisible;
  },
  SET_NPS_SURVEY_VISIBILITY(state, visible) {
    state.npsSurveyVisible = visible !== undefined ? !!visible : !state.rightSidebarVisible;
    // Toggle sidebar accordingly to the survey
    state.rightSidebarVisible = state.npsSurveyVisible;
    // When the survey is closed we mark it as seen
    if (!state.npsSurveyVisible) {
      state.npsSurveySeen = true;
    }
  },
  SET_ACTIVE_THEME(state, { document, themeClass }) {
    if (state.activeTheme !== null) {
      classReplace(document.documentElement, state.activeTheme, themeClass);
    } else {
      classAdd(document.documentElement, themeClass);
    }
    state.activeTheme = themeClass;
  },
  SET_ACTIVE_PAGE(state, { document, pageClass }) {
    if (state.activePage === null) {
      classAdd(document.getElementById('app'), pageClass);
    } else {
      classReplace(document.getElementById('app'), this.activePage, pageClass);
    }
    state.activePage = pageClass;
  },
  SET_IS_MENU_COLLAPSED(state, isMenuCollapsed) {
    state.isMenuCollapsed = isMenuCollapsed;
  },
  SET_SHOULD_PREVENT_NAVIGATION(state, shouldPreventNavigation) {
    state.shouldPreventNavigation = shouldPreventNavigation;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
