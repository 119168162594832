<template>
  <div class="campaign-layout tw-my-layout-4 md:tw-my-10 tw-w-full">
    <portal to="app-banner__header" class="app-banner">
      <campaign-navigation-header
        :campaign="campaign"
        @on-tab-change="tabChange" />
    </portal>
    <section>
      <div class="campaign-layout__header tw-py-layout-3 tw-px-layout-1 sm:tw-px-layout-2 md:tw-px-layout-3">
        <transition name="fade" mode="out-in">
          <div v-if="showHeaderDetails" key="overview" class="xs:tw-flex-row tw-flex tw-flex-col tw-justify-between">
            <div class="xs:tw-items-end xs:tw-flex-row tw-flex tw-flex-col tw-flex-wrap tw-items-start">
              <template v-if="campaign.name">
                <app-image
                  v-if="campaign.image_desktop"
                  :src="campaign.image_desktop"
                  :options="imageOptions"
                  class="sm:tw-block tw-border-jb-grey-50 tw-border-rounded tw-w-layout-6 xs:tw-min-w-44 xs:tw-w-44 xs:tw-h-44 tw-h-layout-6 tw-border-space-4px tw-hidden tw-object-cover tw-border-solid tw-rounded" />
              </template>
              <skeleton-box v-else class="sm:tw-block tw-border-jb-grey-50 tw-border-rounded tw-w-layout-6 xs:tw-min-w-44 xs:tw-w-44 xs:tw-h-44 tw-h-layout-6 tw-border-space-4px tw-hidden tw-object-cover tw-border-solid tw-rounded" />
              <div class="xs:tw-justify-end xs:tw-w-auto sm:tw-ml-space-4 tw-flex tw-flex-col tw-justify-start tw-w-full">
                <div class="tw-mb-space-4 tw-flex">
                  <template v-if="campaign.name">
                    <app-image
                      v-if="campaign.image_desktop"
                      :src="campaign.image_desktop"
                      :options="imageOptions"
                      class=" sm:tw-hidden tw-mr-space-4 tw-border-jb-grey-50 tw-border-rounded tw-w-layout-6 tw-h-layout-6 tw-border-space-4px tw-block tw-object-cover tw-border-solid tw-rounded" />
                  </template>
                  <skeleton-box v-else class="tw-mx-space-1 sm:tw-hidden tw-mr-space-4 tw-border-jb-grey-50 tw-border-rounded tw-w-layout-6 tw-h-layout-6 tw-border-space-4px tw-block tw-object-cover tw-border-solid tw-rounded" />
                  <div class="tw-flex tw-flex-col tw-justify-end">
                    <app-heading class="tw-text-jb-ink tw-mb-space-2 sm:tw-text-3xl tw-m-0 tw-text-2xl" level="h1">
                      <template v-if="campaign.name">
                        {{ campaign.name }}
                      </template>
                      <skeleton-box v-else class="tw-mb-layout-1 tw-m-0" width="200" height="20" />
                    </app-heading>
                    <div class=" tw-text-jb-grey-700 sm:tw-text-base tw-flex tw-m-0 tw-mb-0 tw-text-sm tw-capitalize">
                      <app-text size="h6" class="tw-text-jb-grey-700 sm:tw-text-base tw-pr-space-2 tw-border-jb-grey-100 tw-m-0 tw-mb-0 tw-text-sm tw-capitalize tw-border-r tw-border-solid">
                        <template v-if="campaign.id">
                          # {{ campaign.id }}
                        </template>
                        <skeleton-box v-else class="tw-m-0" width="70" height="10" />
                      </app-text>
                      <app-text size="h6" class="tw-text-jb-grey-700 sm:tw-text-base tw-pl-space-2 tw-m-0 tw-mb-0 tw-text-sm tw-capitalize">
                        <template v-if="campaign.vertical">
                          # {{ campaign.vertical }}
                        </template>
                        <skeleton-box v-else class="tw-m-0" width="70" height="10" />
                      </app-text>
                    </div>
                  </div>
                </div>
                <div
                  class="tw-p-space-4 xs:tw-flex-row tw-flex tw-flex-col tw-justify-between tw-bg-white tw-rounded">
                  <div
                    class="tw-pr-space-4 tw-pr-space-2 xs:tw-pr-space-4 xs:tw-border-r xs:tw-border-jb-grey-100 xs:tw-border-solid">
                    <app-text weight="medium" size="sm" class="tw-text-jb-grey-600 tw-text-xs">
                      MARKET
                    </app-text>
                    <app-country-icon
                      v-if="campaign.name"
                      :countries="campaign.countries"
                      :limit="4"
                      size="mini"
                      class="app-country-icon" />
                    <skeleton-box v-else class="tw-mt-space-2 tw-m-0" width="20" height="20" />
                  </div>
                  <div
                    class="xs:tw-mt-0 tw-divide-jb-grey-100 tw-divide-solid tw-mt-space-2 xs:tw-divide-x-0 tw-flex tw-justify-between tw-divide-x">
                    <div
                      class="xs:tw-px-space-4 tw-flex tw-flex-col tw-items-center tw-w-full tw-px-0">
                      <div>
                        <app-text weight="medium" size="sm" class="tw-text-jb-grey-600 tw-text-xs">
                          DEMAND
                        </app-text>
                        <app-text type="div" class="tw-pt-space-1 xs:tw-pt-space-2 tw-text-base tw-text-black tw-capitalize">
                          {{ campaign.metrics && campaign.metrics.demand || 'N/A' }}
                        </app-text>
                      </div>
                    </div>
                    <div
                      class="xs:tw-px-space-4 tw-flex tw-flex-col tw-items-center tw-w-full tw-px-0">
                      <div>
                        <app-text weight="medium" size="sm" class="tw-text-jb-grey-600 tw-text-xs">
                          BEPC
                        </app-text>
                        <app-text type="div" class="tw-pt-space-1 xs:tw-pt-space-2 tw-text-base tw-text-black">
                          {{ campaign.metrics && campaign.metrics.bepc || 'N/A' }}
                        </app-text>
                      </div>
                    </div>
                    <div
                      class="xs:tw-px-space-4 tw-flex tw-flex-col tw-items-center tw-w-full tw-px-0">
                      <div>
                        <app-text weight="medium" size="sm" class="tw-text-jb-grey-600 tw-text-xs">
                          CAP
                        </app-text>
                        <app-text type="div" class="tw-pt-space-1 xs:tw-pt-space-2 tw-text-base tw-text-black">
                          {{ campaign.metrics && campaign.metrics.weekly_cap || 'N/A' }}
                        </app-text>
                      </div>
                    </div>
                    <div
                      class="xs:tw-px-space-4 tw-flex tw-flex-col tw-items-center tw-w-full tw-px-0">
                      <div>
                        <app-text weight="medium" size="sm" class="tw-text-jb-grey-600 tw-text-xs">
                          CPA
                        </app-text>
                        <app-text type="div" class="tw-pt-space-1 xs:tw-pt-space-2 tw-text-base tw-text-black">
                          {{ campaign && campaign.cpa || 'N/A' }}
                        </app-text>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <app-button
              v-if="showActionButton"
              :key="campaign.id"
              :disabled="applicationButtonDisabled"
              :state="state"
              class="tw-mt-space-4 xs:tw-w-auto tw-w-full"
              :class="state === undefined && 'tw-bg-jb-indigo'"
              @click="applyingToCampaign = true">
              {{ applicationButtonText }} <i v-if="!applicationButtonDisabled" class="el-icon-arrow-right" />
            </app-button>
          </div>
          <div v-else key="tab-title">
            <portal-target name="campaign-page-navigation" />
            <div class="tw-flex tw-justify-between">
              <transition :name="transition" mode="out-in">
                <app-heading :key="tabName" class="tw-text-jb-ink tw-m-0 tw-capitalize" level="h2">
                  {{ tabName }}
                </app-heading>
              </transition>
              <portal-target v-if="route === 'dashboard-campaign-setup-approvals'" name="ad-approval-new-request" />
            </div>
          </div>
        </transition>
        <portal-target name="campaign-page-header-slot" />
      </div>
    </section>
    <el-dialog
      title="Apply to Offer"
      :visible.sync="applyingToCampaign"
      :width="responsiveModalWidth"
      :z-index="3003"
      :lock-scroll="false"
      class="campaign-application-modal">
      <campaign-application />
    </el-dialog>
    <transition :name="transition" mode="out-in">
      <router-view :key="orgId" />
    </transition>
  </div>
</template>

<script>
import CampaignNavigationHeader from '@/components/campaigns/CampaignNavigationHeader';
import AppText from '@/components/AppText';
import AppHeading from '@/components/AppHeading';
import AppCountryIcon from '@/components/AppCountryIcon';
import AppImage from '@/components/AppImage';
import AppButton from '@/components/AppButton';
import { Dialog } from 'element-ui';
import CampaignApplication from '@/views/campaign/CampaignApplication.vue';
import Breakpoints from '@/mixins/Breakpoints';
import SkeletonBox from '@/components/skeletons/SkeletonBox';

export default {
  name: 'CampaignLayout',
  components: {
    AppHeading,
    AppCountryIcon,
    AppImage,
    AppText,
    CampaignNavigationHeader,
    AppButton,
    'el-dialog': Dialog,
    CampaignApplication,
    SkeletonBox
  },
  mixins: [Breakpoints([992])],
  data() {
    return {
      activeTabIndex: 0,
      transition: '',
      imageOptions: {
        auto: 'enhance,format',
        fit: 'crop',
        crop: 'focalpoint',
        h: 350,
        w: 350,
        fm: 'jpeg',
        q: 50
      },
      applyingToCampaign: false
    };
  },
  computed: {
    orgId() {
      return this.$store.getters.organization.id;
    },
    route() {
      return this.$route.name;
    },
    showHeaderDetails() {
      return this.$route.name.includes('campaign-setup-marketing');
    },
    activeCampaignId() {
      return this.$route.params.id;
    },
    tab() {
      return this.$route.meta.tab;
    },
    tabName() {
      if (this.isApprovalActiveTabSettings) return 'Guidelines';
      return this.tab;
    },
    campaign() {
      return this.$store.getters.campaign(this.activeCampaignId) || {};
    },
    fetchingCampaign() {
      return this.$store.getters.fetchingCampaign && !this.campaign;
    },
    isApprovalActiveTabSettings() {
      return this.$route.params.tab === 'settings';
    },
    hasMultipleCountries() {
      return this.campaign.countries && this.campaign.countries.length > 1;
    },
    isAffiliate() {
      return this.$store.getters.userIsAffiliate;
    },
    isPrelaunch() {
      return this.campaign.status === 'prelaunch';
    },
    state() {
      const states = { approved: 'success', prelaunch: 'positive' };
      return states[this.campaign.approval_status] || states[this.campaign.status] || undefined;
    },
    showActionButton() {
      return this.isAffiliate && this.campaign.id && !this.isApplicationApproved;
    },
    isApplicationApproved() {
      return this.campaign.approval_status === 'approved';
    },
    applicationButtonDisabled() {
      return this.campaign.approval_status !== 'unapproved' && !this.isApplicationApproved;
    },
    applicationButtonText() {
      if (this.campaign.approval_status === 'unapproved') {
        return this.isPrelaunch ? 'Apply for Early Access' : 'Apply to Campaign';
      }
      return 'Application ' + this.campaign.approval_status.replace(/^(.)/, c => c.toUpperCase());
    },
    responsiveModalWidth() {
      return this.windowBreakpoint < 992 ? '95%' : '60%';
    }
  },
  methods: {
    tabChange({ name, index, activeTab, params }) {
      this.transition = index > this.activeTabIndex ? 'slide-left' : 'slide-right';
      this.activeTabIndex = index;
      this.$router.push({ name: `dashboard-campaign-setup-${activeTab}`, params: params });
    }
  }
};
</script>

<style lang="scss">
  .campaign-layout {
    width: 100%;

    &__header {
      @media (min-width: 2200px) {
        @apply tw-max-w-screen-2xl tw-mx-auto;
        @apply tw-px-0 #{!important};
      }
    }

    .campaign-application-modal {
      .el-dialog__header {
        @apply tw-px-layout-1 tw-py-layout-2 md:tw-px-layout-3;
      }

      .el-dialog__title {
        @apply tw-text-jb-ink tw-font-semibold tw-text-xl;
      }

      .el-dialog__body {
        @apply tw-p-0;
      }
    }
  }
</style>
