<template>
  <el-form ref="form" class="app-form" v-bind="$attrs" v-on="$listeners">
    <slot />
  </el-form>
</template>

<script>
export default {
  name: 'AppForm'
};
</script>
