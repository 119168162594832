import store from '@/store';
import SignupLayout from '@/layouts/SignupLayout';
import MainLayout from '@/layouts/MainLayout';
import InterstitialLayout from '@/layouts/InterstitialLayout';
import OrgSelectionView from '@/views/auth/OrgSelectionView';
import PaymentView from '@/views/payment/Payments';
import DocsView from '@/views/docs/Docs';
import AppRedirect from '@/components/AppRedirect';
import AccountSettings from '@/views/accountSettings/AccountSettingsView';

// ROUTE SECTIONS
import CampaignRoutes from '@/router/sections/campaign';
import ReportRoutes from '@/router/sections/reports';

// ROUTE GROUPS
import OnboardingGroup from './groups/onboarding';
import CampaignDetailsGroup from './groups/campaign-details';
import AbTestsGroup from './groups/ab';
import ReferralGroup from './groups/referral-group';
import OrganizationSettingsGroup, { ORGANIZATION_SETTINGS_TABS } from './groups/organization-settings';

import { EMPLOYEE, NETWORK, GENERAL } from '@/constants/orgTypes';
import { TERMS_AND_CONDITIONS, PRIVACY_POLICY } from '@/constants/externalLinks';

// Components
const PerformanceAnalytics = () => import('@/views/performanceAnalytics/PerformanceAnalyticsReport.vue');

export default [
  {
    path: '*',
    redirect: '/signin'
  },
  {
    path: '/',
    beforeEnter(to, from, next) {
      if ([EMPLOYEE, NETWORK].includes(store.getters.organization.type)) return next({ name: 'insights' });
      if (store.getters.organization.type === GENERAL) return next({ name: 'referralMarketingLayout' });
      next();
    },
    component: MainLayout,
    meta: {
      middleware: ['auth', 'organization', 'approvedAccount'],
      theme: 'clb'
    },
    children: [
      {
        path: '/',
        name: 'performance-dashboard',
        component: CampaignDetailsGroup('dashboard')
      }
    ]
  },
  {
    path: '/redirect',
    component: MainLayout,
    meta: {
      middleware: ['auth', 'organization', 'approvedAccount'],
      theme: 'clb'
    },
    children: [
      {
        name: 'redirect',
        path: '/',
        component: AppRedirect,
        props: (route) => ({
          ...route.params
        })
      }
    ]
  },
  {
    /**
     * Route used to install the app in different platforms
     * Usually, this will redirect users to an api endpoint to complete an oAuth flow
     */
    path: '/install',
    beforeEnter(to, from, next) {
      store.dispatch('installEmbeddedApp', {
        window,
        next
      });
    }
  },
  {
    /**
     * After the installation process, the user should be redirected to this route
     */
    path: '/embedded-app',
    beforeEnter(to, from, next) {
      store.dispatch('initializeEmbeddedApp', {
        window,
        next
      });
    }
  },
  {
    path: '/signout',
    name: 'signout',
    beforeEnter(to, from, next) {
      store.dispatch('signout');
      next('/signin');
    }
  },
  {
    path: '/signin',
    component: SignupLayout,
    meta: {
      theme: 'onboarding'
    },
    children: [
      {
        path: '/',
        name: 'signin',
        component: OnboardingGroup('signIn'),
        meta: {
          middleware: ['guest'],
          mainHeader: 'Sign In',
          formComponent: 'auth',
          formHeader: 'Access an already created account'
        }
      },
      {
        path: 'forgot-password',
        name: 'forgot-password',
        component: OnboardingGroup('signIn'),
        meta: {
          middleware: ['guest'],
          mainHeader: 'Reset Password',
          formComponent: 'forgot-password',
          formHeader: 'Recover access to your account via email'
        }
      },
      {
        path: 'forgot-password/:token',
        name: 'password-reset',
        component: OnboardingGroup('signIn'),
        meta: {
          middleware: ['passwordReset'],
          mainHeader: 'Reset Password',
          formComponent: 'reset-password',
          formHeader: 'Choose a new password for your account'
        }
      },
      {
        path: 'general/:token',
        name: 'general-signin',
        component: OnboardingGroup('signIn'),
        meta: {
          middleware: ['passwordReset'],
          mainHeader: 'Sign Up',
          formComponent: 'reset-password',
          onePasswordInput: true,
          formHeader: (
            'By signing up, you agree to our ' +
            `<a href="${TERMS_AND_CONDITIONS}" target="_blank">Terms</a> and to have read our ` +
            `<a href="${PRIVACY_POLICY}" target="_blank">Privacy Policy</a>.`
          )
        }
      },
      {
        path: 'verify-email/:token',
        name: 'verify-email',
        component: OnboardingGroup('signIn'),
        meta: {
          middleware: ['verifyEmail'],
          mainHeader: 'Sign In',
          formComponent: 'auth',
          formHeader: 'Please sign in to confirm your new email'
        }
      },
      {
        path: 'orginvite/:token',
        name: 'org-invite',
        component: OnboardingGroup('signIn'),
        meta: {
          middleware: ['passwordReset'],
          mainHeader: 'Sign Up',
          formComponent: 'org-invite',
          formHeader: (
            'By signing up, you agree to our ' +
            `<a href="${TERMS_AND_CONDITIONS}" target="_blank">Terms</a> and to have read our ` +
            `<a href="${PRIVACY_POLICY}" target="_blank">Privacy Policy</a>.`
          )
        }
      }
    ]
  },
  {
    path: '/signup',
    component: SignupLayout,
    meta: {
      theme: 'onboarding'
    },
    children: [
      {
        path: '/',
        name: 'signup',
        component: OnboardingGroup('signUp'),
        meta: {
          middleware: ['guest'],
          guestPath: '/signup/splash'
        }
      },
      {
        path: 'splash',
        name: 'signup-splash',
        component: () => import(/* webpackChunkName: "SplashView" */ '@/views/survey/SplashView'),
        meta: {
          middleware: ['auth', 'survey'],
          survey: {
            allowedStatus: ['incomplete']
          }
        }
      },
      {
        path: 'survey',
        redirect: 'survey/start'
      },
      {
        path: 'survey/start',
        meta: {
          middleware: ['auth', 'survey']
        }
      },
      {
        path: 'survey/confirm',
        name: 'signup-survey-confirmation',
        component: OnboardingGroup('surveyConfirmation'),
        meta: {
          middleware: ['auth', 'survey', 'surveySummary'],
          showBackButton: false,
          survey: {
            allowedStatus: ['complete']
          }
        }
      },
      {
        path: 'survey/finish',
        name: 'signup-survey-finish',
        component: OnboardingGroup('surveyFinish'),
        meta: {
          middleware: ['auth', 'organization', 'survey'],
          showBackButton: false,
          survey: {
            allowedStatus: ['confirmed']
          }
        }
      },
      {
        path: 'survey/:id',
        name: 'signup-survey',
        component: OnboardingGroup('surveyView'),
        meta: {
          middleware: ['auth', 'survey', 'surveyQuestion'],
          showBackButton: true,
          survey: {
            allowedStatus: ['incomplete', 'complete']
          }
        }
      },
      {
        path: ':param1',
        name: 'signup-with-org',
        component: OnboardingGroup('signUp'),
        meta: {
          middleware: ['guest', 'leadSource'],
          guestPath: '/signup/splash'
        }
      },
      {
        path: 'invite/:token',
        name: 'invite',
        meta: {
          middleware: ['invitation']
        }
      },
      {
        path: 'referral/:referralCode',
        name: 'referral',
        meta: {
          middleware: ['referralCode']
        }
      },
      {
        path: ':param1/invite/:token',
        name: 'signup-invitation',
        component: OnboardingGroup('signUp'),
        meta: {
          middleware: ['invitation']
        }
      },
      {
        /* Generic two-part route for handing multiple deeplinking cases */
        path: ':param1/:param2',
        name: 'signup-with-org-with-manager',
        component: OnboardingGroup('signUp'),
        meta: {
          middleware: ['guest', 'leadSource'],
          guestPath: '/signup/splash'
        }
      },
      {
        path: ':param1/:param2/invite/:token',
        name: 'signup-invitation-with-program',
        component: OnboardingGroup('signUp'),
        meta: {
          middleware: ['guest', 'leadSource', 'invitation'],
          guestPath: '/signup/splash'
        }
      }
    ]
  },
  {
    path: '/organizations',
    component: InterstitialLayout,
    meta: {
      theme: 'clb'
    },
    children: [
      {
        path: '/',
        name: 'organizations',
        component: OrgSelectionView,
        meta: {
          middleware: ['auth', 'approvedAccount']
        }
      }
    ]
  },
  {
    path: '/user',
    children: [
      {
        path: 'email-verification/:token',
        meta: {
          middleware: ['emailVerification']
        }
      }
    ]
  },
  {
    path: '/campaign',
    component: MainLayout,
    redirect: '/',
    meta: {
      theme: 'clb',
      middleware: ['auth', 'organization', 'approvedAccount'],
      fullContentWidth: true
    },
    children: CampaignRoutes
  },
  {
    path: '/payment',
    component: MainLayout,
    meta: {
      middleware: ['auth', 'organization', 'approvedAccount', 'affiliate', 'permissions'],
      theme: 'clb'
    },
    children: [
      {
        path: 'setup',
        component: PaymentView,
        name: 'payment-setup',
        props: { endpoint: '/payments/setup' },
        meta: {
          underlapFooter: false
        }
      },
      {
        path: 'history',
        name: 'payment-history',
        component: PaymentView,
        props: { endpoint: '/payments/history' },
        meta: {
          underlapFooter: false
        }
      }
    ]
  },
  {
    path: '/reporting',
    component: MainLayout,
    redirect: '/reporting/subid',
    meta: {
      middleware: ['auth', 'organization', 'approvedAccount', 'affiliate'],
      theme: 'clb'
    },
    children: [...ReportRoutes('main')]
  },
  {
    path: '/discover',
    component: MainLayout,
    meta: {
      middleware: ['auth', 'organization', 'approvedAccount', 'affiliate'],
      theme: 'clb',
      breadcrumb: 'Discover'
    },
    children: [
      {
        path: '/',
        name: 'campaign-discover',
        component: CampaignDetailsGroup('discover')
      }
    ]
  },
  {
    path: '/ab',
    component: MainLayout,
    props: (route) => {
      return Object.values(route.params).length
        ? {
          showFooter: false,
          fullWidth: true
        }
        : {};
    },
    meta: {
      middleware: ['auth', 'organization', 'internalUser'],
      theme: 'clb',
      breadcrumb: 'AB'
    },
    children: [
      {
        path: '/',
        name: 'ab',
        component: AbTestsGroup('tests')
      },
      {
        path: ':id',
        name: 'ab-integration',
        component: AbTestsGroup('integration')
      }
    ]
  },
  {
    path: '/insights',
    component: MainLayout,
    meta: {
      middleware: ['auth', 'organization', 'approvedAccount', 'internalUser'],
      theme: 'clb'
    },
    children: [
      {
        path: '/',
        name: 'insights',
        component: PerformanceAnalytics
      },
      {
        path: 'create',
        name: 'insights-crowd-create',
        component: PerformanceAnalytics
      },
      {
        path: 'create/attributes',
        name: 'insights-crowd-create-attributes',
        component: PerformanceAnalytics
      },
      {
        path: ':crowdId',
        name: 'insights-crowd',
        component: PerformanceAnalytics
      },
      {
        path: ':crowdId/audience/create',
        name: 'insights-audience-create',
        component: PerformanceAnalytics
      }
    ]
  },
  {
    path: '/docs',
    component: MainLayout,
    meta: {
      middleware: ['auth', 'organization', 'approvedAccount'],
      theme: 'clb'
    },
    children: [
      {
        path: 'affiliate*',
        component: DocsView,
        name: 'affiliate-docs',
        props: { endpoint: '/affiliate' },
        meta: {
          middleware: ['affiliate'],
          underlapFooter: false,
          noScroll: true
        }
      },
      {
        path: 'merchant*',
        component: DocsView,
        name: 'merchant-docs',
        props: { endpoint: '/merchant' },
        meta: {
          middleware: ['advertiser'],
          underlapFooter: false,
          noScroll: true
        }
      }
    ]
  },
  {
    path: '/referral',
    component: MainLayout,
    meta: {
      theme: 'clb',
      middleware: ['auth', 'organization', 'approvedAccount', 'referral']
    },
    children: [
      {
        path: '/',
        name: 'referralLayout',
        component: ReferralGroup('referralLayout'),
        children: [
          {
            path: 'marketing',
            beforeEnter(to, from, next) {
              if (from.name === 'referral-setup' && !store.getters.isSetupComplete) {
                return next({ name: 'referral-setup' });
              }
              next();
            },
            name: 'referralMarketingLayout',
            component: ReferralGroup('referralMarketingLayout')
          },
          {
            path: 'management',
            name: 'referralManagement',
            component: ReferralGroup('referralManagement')
          },
          {
            path: 'history',
            beforeEnter(to, from, next) {
              if (from.name !== 'referralManagement') return next({ name: 'referralManagement' });
              next();
            },
            name: 'referral-history',
            component: ReferralGroup('referralPayments'),
            props: { endpoint: '/payments/history' },
            meta: { underlapFooter: false }
          },
          {
            path: 'setup',
            name: 'referral-setup',
            component: ReferralGroup('referralPayments'),
            props: { endpoint: '/payments/setup' },
            meta: { underlapFooter: false }
          }
        ]
      }
    ]
  },
  {
    path: '/organization',
    component: MainLayout,
    meta: {
      theme: 'clb',
      middleware: ['auth', 'organization', 'approvedAccount', 'permissions']
    },
    children: [
      {
        path: '/',
        component: OrganizationSettingsGroup('layout'),
        children: [
          {
            path: '/',
            name: 'organization-settings',
            redirect: ORGANIZATION_SETTINGS_TABS[0].path
          },
          ...ORGANIZATION_SETTINGS_TABS.map(({ id, path, name }) => ({ path, name, component: OrganizationSettingsGroup(id) }))
        ]
      }
    ]
  },
  {
    path: '/account/settings',
    component: MainLayout,
    meta: {
      theme: 'clb',
      middleware: ['auth', 'organization', 'approvedAccount']
    },
    children: [
      {
        path: '/',
        component: AccountSettings,
        name: 'account-settings'
      }
    ]
  }
];
