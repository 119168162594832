import questionsMappers from './mappers';
import defaultMapper from './default-mapper';

const getMapper = function(questionType) {
  const mapperKey = `${questionType}Mapper`;
  if (questionsMappers.hasOwnProperty(mapperKey)) {
    return Object.assign({}, defaultMapper, questionsMappers[mapperKey]);
  }
  return defaultMapper;
};

export default {
  /**
   * Get the question answer summary
   * @param {Object} question
   * @param {Object} options
   * @return {*}
   */
  getAnswerSummary(question) {
    const answers = Object.keys(question.questions).map(key => {
      return {
        title: question.questions[key].question,
        value: question.questions[key].answer
      };
    });
    return `<ul>
      ${answers.map(a => `<li>${a.title}: <strong>${a.value}</strong></li>`).join('')}
    </ul>`;
  },
  /**
   * Prepare the question answer to be used on the front end
   * @param {Object} question
   * @return {*}
   */
  getAnswer(question) {
    const answerObj = {};
    Object.keys(question.questions).forEach(key => {
      const q = question.questions[key];
      answerObj[q.key] = getMapper(q.type).getAnswer(q);
    });
    return answerObj;
  },
  /**
   * Prepare the question answer to be sent to the api
   * @param {Object} question
   * @param {*} answer
   * @return {Object}
   */
  prepareApiPayload(question, answer) {
    return Object.keys(question.questions).map(key => {
      const q = question.questions[key];
      const nestedQuestionAnswer = getMapper(q.type).prepareApiPayload(q, answer[q.key]);
      return {
        ...nestedQuestionAnswer,
        question: q.key
      };
    });
  }
};
