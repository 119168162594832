export default ({ fetch, storeHandler }) => {
  return {
    all(options = {}) {
      const orgId = storeHandler.get('organizationId');
      return fetch({ url: `/v2/org/${orgId}/crowds`, options });
    },

    single({ crowdId, ...options } = {}) {
      const orgId = storeHandler.get('organizationId');
      return fetch({ url: `/v2/org/${orgId}/crowd/${crowdId}`, options });
    },

    addNewCrowd(options = {}) {
      const orgId = storeHandler.get('organizationId');
      return fetch({ url: `/v2/org/${orgId}/crowds`, method: 'POST', options });
    },

    setCrowdView(options = {}) {
      const orgId = storeHandler.get('organizationId');
      return fetch({ url: `/v2/org/${orgId}/crowd/${options.crowdId}`, method: 'PUT', options });
    },

    toggleCrowdFavourite(options = {}) {
      const orgId = storeHandler.get('organizationId');
      return fetch({ url: `/v2/org/${orgId}/crowd/${options.crowdId}`, method: 'PUT', options });
    },

    deleteCrowd(options = {}) {
      const orgId = storeHandler.get('organizationId');
      return fetch({ url: `/v2/org/${orgId}/crowd/${options.crowdId}`, method: 'DELETE', options });
    },

    addNewAudience({ crowdId, ...options } = {}) {
      const orgId = storeHandler.get('organizationId');
      return fetch({ url: `/v2/org/${orgId}/crowd/${crowdId}/audience`, method: 'POST', options });
    },

    deleteAudience(options = {}) {
      const orgId = storeHandler.get('organizationId');
      return fetch({ url: `/v2/org/${orgId}/crowd/${options.crowdId}/audience/${options.audienceId}`, method: 'DELETE', options });
    },

    crowdMetrics({ view, crowdId, audienceIds, ...options } = {}) {
      const orgId = storeHandler.get('organizationId');
      options.cancellationKey = 'crowd-metrics';
      return fetch({ url: `/v2/org/${orgId}/crowd/${crowdId}/${view}`, options });
    },

    crowdConstraints(options = {}) {
      const orgId = storeHandler.get('organizationId');
      options.params = { fields: 'advertiser_id,affiliate_id,campaign_id', ...options.params };
      return fetch({ url: `/v2/org/${orgId}/attributes`, options });
    },

    crowdAttributes(options = {}) {
      const orgId = storeHandler.get('organizationId');
      return fetch({ url: `/v2/org/${orgId}/attributes`, options });
    },

    audienceAttributes({ crowdId, ...options } = {}) {
      const orgId = storeHandler.get('organizationId');
      return fetch({ url: `/v2/org/${orgId}/crowd/${crowdId}/attributes`, options });
    }
  };
};
