<template>
  <div class="notification-list-item" :class="{ unseen: !notification.seen }" @click="handleNotificationClick(notification)">
    <div class="notification-list-item__content">
      <small>
        <span :title="notification.created">{{ notificationTimeAgo | capitalizeAll }}</span>
        <template v-if="!notification.seen"> · <a @click.stop="handleMarkReadClick(notification)">Mark As Read</a>
        </template>
      </small>
      <div class="notification-list-item__content-notification" :class="notification.priority">
        <h4>{{ notification.title }}</h4>
        <p>{{ notification.body }}</p>
      </div>
    </div>
    <ul class="notification-list-item__organizations">
      <li
        v-for="organization in visibleOrgs"
        :key="`org-${organization.id}`"
        v-tippy="{ enabled: true, showOnInit: false, content: organization.name, placement: 'left', arrow: true, followCursor: false }"
        @click.stop="handleNotificationClick(notification, [organization])">
        <app-profile-picture :full-name="organization.name" />
      </li>
      <li
        v-if="overflowOrgs.length"
        key="org-all"
        v-tippy="{ enabled: true, showOnInit: false, content: `+${overflowOrgs.length} Other Organizations`, placement: 'left', arrow: true, followCursor: false }"
        @click.stop="handleNotificationClick(notification, overflowOrgs)">
        <app-profile-picture full-name=". . ." />
      </li>
    </ul>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import AppProfilePicture from '@/components/AppProfilePicture';

dayjs.extend(relativeTime);

export default {
  name: 'NotificationListItem',
  components: {
    AppProfilePicture
  },
  props: {
    notification: {
      type: Object,
      required: true
    },
    orgLimit: {
      type: Number,
      default: 4
    }
  },
  computed: {
    notificationTimeAgo() {
      return dayjs(this.notification.created).fromNow();
    },
    organizations() {
      return this.notification.orgs || [];
    },
    visibleOrgLimit() {
      return this.organizations.length > this.orgLimit ? this.orgLimit - 1 : this.orgLimit;
    },
    visibleOrgs() {
      return this.organizations.slice(0, this.visibleOrgLimit);
    },
    overflowOrgs() {
      return this.organizations.slice(this.visibleOrgLimit);
    }
  },
  methods: {
    handleMarkReadClick(notification) {
      this.$emit('mark-read', {
        notification
      });
    },
    handleNotificationClick(notification, organizations) {
      this.handleMarkReadClick(notification);

      this.$emit('notification-click', {
        notification,
        organizations
      });
    }
  }
};
</script>

<style lang="scss">
.notification-list-item {
  display: flex;
  flex-direction: row;
  padding: $--clb-space-2 $--clb-space-3 $--clb-space-3 $--clb-space-3;
  transition: background-color 0.2s ease-in;
  cursor: pointer;

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    small {
      line-height: 22px;
    }

    &-notification {
      flex: 1;
      padding: 0 $--clb-space-3;
      border-left: 3px solid transparent;

      h4 {
        transition: color 0.2s ease-in;
        font-size: $--clb-font-size-base;
        margin: 0;
      }

      p {
        transition: color 0.2s ease-in;
        font-size: $--clb-font-size-sm;
      }

      &.urgent {
        border-left-color: $--clb-color-danger;
      }

      &.normal {
        border-left-color: $--clb-color-warning;
      }

      &.low {
        border-left-color: $--clb-color-primary;
      }
    }
  }

  &:hover,
  &.unseen {
    background: $--clb-color-secondary__light;
  }

  &:hover {
    .notification-list-item__content-notification {
      h4,
      p {
        color: $--clb-color-primary;
      }
    }
  }

  &__organizations {
    flex: 0 0 22px;

    li {
      margin-bottom: $--clb-space-1;
      outline: none;
    }

    .app-profile-picture {
      width: 22px;
      min-width: 22px;
      height: 22px;
      min-height: 22px;
      cursor: pointer;
    }

    .el-avatar {
      font-size: $--clb-font-size-xs - 2px;
      line-height: 40px !important;
    }
  }
}
</style>
