import tippy from 'tippy.js';

const zIndex = process.env.BUILD_TARGET === 'mobile' ? 5000 : 2100;

const tippyOptions = {
  allowHTML: true,
  animateFill: false,
  content: '',
  followCursor: !('ontouchstart' in document.documentElement),
  touchHold: true,
  hideOnClick: false,
  showOnInit: true,
  zIndex: zIndex
};

function updateTippy(el, binding) {
  if (!binding.value.enabled || binding.value.content === null) { return destroyTippy(el); }

  if (el.hasOwnProperty('_tippy')) {
    el._tippy.setContent(binding.value.content);
  } else {
    const clone = binding.value;
    delete clone.enabled;
    tippy(el, Object.assign({}, tippyOptions, clone));
  }
}

function destroyTippy(el) {
  el._tippy && el._tippy.destroy();
}

export default function(VueInstance) {
  VueInstance.directive('tippy', {
    bind(el, binding) {
      updateTippy(el, binding);
    },
    update(el, binding) {
      updateTippy(el, binding);
    },
    unbind(el) {
      destroyTippy(el);
    }
  });
};
