export const EVENT_PLATFORMS = [
  'HTML/JS'
];

export const PLATFORMS = [
  'HTML/JS',
  'Postback'
  // 'Facebook',
  // 'Google Ads',
  // 'Voluum',
  // 'HasOffers',
  // 'Cake',
  // 'HitPath',
  // 'GTM',
  // 'Bing',
  // 'Snapchat',
  // 'Taboola',
  // 'Outbrain'
];

export const MACROS = {
  xxhitidxx: 'User ID',
  xxcampaignxx: 'Campaign',
  xxeventxx: 'Event',
  xxc1xx: 'C1',
  xxc2xx: 'C2',
  xxc3xx: 'C3',
  xxpayoutxx: 'Payout',
  xxrandxx: 'Random'
};

export const SUBVARS = {
  c1: 'C1',
  c2: 'C2',
  c3: 'C3'
};

export const PIXEL = {
  pixel_id: null,
  name: null,
  pixel: null,
  subvar: null,
  subid: null,
  event: 'Purchase',
  platform: 'HTML/JS',
  global: false,
  redirector_id: null
};
