<template>
  <div class="morph">
    <svg viewBox="0 0 1400 700" preserveAspectRatio="none">
      <path
        ref="path"
        d="M 262.9,252.2 C 210.1,338.2 212.6,487.6 288.8,553.9 372.2,626.5 511.2,517.8 620.3,536.3 750.6,558.4 860.3,723 987.3,686.5 1089,657.3 1168,534.7 1173,429.2 1178,313.7 1096,189.1 995.1,130.7 852.1,47.07 658.8,78.95 498.1,119.2 410.7,141.1 322.6,154.8 262.9,252.2 Z" />
    </svg>
  </div>
</template>

<script>
import backgroundShapes from '@/assets/data/backgroundShapes';
import anime from 'animejs';

export default {
  name: 'Morph',
  data() {
    return {
      shape: 0,
      instances: [],
      frames: 0
    };
  },
  mounted() {
    this.nextShape();
  },
  beforeDestroy() {
    this.stopShapes();
  },
  methods: {
    nextShape() {
      this.shape = (this.shape + 1) % backgroundShapes.length;
      this.stopShapes();
      anime({
        targets: this.$refs.path,
        duration: backgroundShapes[this.shape].animation.path.duration,
        easing: backgroundShapes[this.shape].animation.path.easing,
        d: backgroundShapes[this.shape].path,
        complete: () => {
          !this.onAuthPage && this.nextShape();
        },
        update: () => {
          if (++this.frames >= 2) {
            this.frames = 0;
            this.instances.map(i => i.pause());
            setTimeout(() => this.instances.map(i => i.play()), 55);
          }
        }
      });
      this.instances = anime.running.slice();
    },
    stopShapes() {
      anime.remove(this.$refs.path);
      this.instances.map(i => i.pause());
      this.instances = [];
    }
  }
};
</script>

<style lang="scss" scoped>
.morph {
  position: fixed;
  overflow: visible;
  fill: #f3f5fd;
  height: 100vh;
  height: calc(100 * var(--vh, 1vh));
  min-height: 75vw;
  top: 0;
  left: -50vw;
  width: 200vw;
  z-index: -1;

  svg {
    width: 100%;
    max-width: 3456px;
    height: calc(100vh - 110px);
    min-height: 800px;
    margin: auto;
    padding: 55px 0 0 0;
    display: block;
    overflow: visible;
  }

  @media (min-width: $--lg) {
    width: 150vw;
    left: -25vw;
  }

  @media (min-width: 1600px) {
    width: 100vw;
    left: 0;
  }
}
</style>
