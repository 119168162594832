/**
 * AB Tests Routing Group
 * @param page
 * @return {function(): *}
 */
export default page => {
  return () => {
    const pages = {
      tests: import(/* webpackChunkName: "AbTests" */'@/views/ab/Tests'),
      integration: import(/* webpackChunkName: "AbIntegration" */'@/views/ab/Integration')
    };
    return pages[page];
  };
};
