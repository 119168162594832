<template>
  <div class="org-selection-list">
    <app-search-list-v2
      :entries="entries"
      :loading="loading"
      :show-content="true"
      search-placeholder="Search"
      @search="handleSearch"
      @load-more="$emit('handle-search-more')">
      <div
        :key="row.entry.id"
        slot-scope="row"
        class="app-search-list-v2__entry"
        @click="$emit('handle-selection', row.index)">
        <app-profile-picture :full-name="row.entry.name" class="tw-mr-space-2" />
        <div class="tw-flex tw-flex-col">
          <app-text weight="medium" size="sm" class="tw-text-jb-ink">
            {{ row.entry.name || ' ' }}
          </app-text>
          <app-text weight="medium" class="tw-text-jb-grey-400 tw-text-xs">
            {{ row.entry.type | capitalize }} (#{{ row.entry.hitpath_account_id || row.entry.id }})
          </app-text>
        </div>
      </div>
      <template v-slot:loading>
        <div :key="`loading-${entries.length}`" class="disabled app-search-list-v2__entry">
          Loading ...
        </div>
      </template>
      <template v-slot:no-content>
        <div key="no-content" class="disabled app-search-list-v2__entry">
          <app-text weight="medium" class="tw-text-jb-grey-700 tw-text-sm">
            No Organizations found
          </app-text>
        </div>
      </template>
    </app-search-list-v2>
  </div>
</template>

<script>
import AppSearchListV2 from '@/components/AppSearchListV2.vue';
import AppProfilePicture from '@/components/AppProfilePicture';
import AppText from '@/components/AppText';

export default {
  name: 'OrgSelectionList',
  components: { AppSearchListV2, AppProfilePicture, AppText },
  props: {
    entries: {
      type: Array,
      required: true,
      default: () => []
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    handleSearch(search) {
      this.$emit('handle-search', search);
    }
  }
};
</script>

<style lang="scss">
.org-selection-list {
  .app-search-list-v2 {
    &__entry {
      @apply tw-flex tw-items-center tw-py-space-2 tw-pl-space-3 hover:tw-bg-jb-grey-hover tw-cursor-pointer;

      .app-profile-picture {
        &__text {
          @apply tw-text-white tw-text-xs;
        }

        .el-avatar {
          @apply tw-bg-jb-grey-700;
        }
      }

      .names {
        text-align: left;
        margin-left: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
      }

      &.disabled {
        padding: 0 24px;
        height: 87px;
        line-height: 87px;
      }

      &:hover {
        h4,
        h5 {
          color: $--clb-color-primary__black;
        }
      }
    }
  }

  /* IE SPECIFIC RULE */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    overflow: auto;
    height: 280px;
  }
}
</style>
