import jbxApi from '@/services/jbx-api';

/**
 * @param {ApiContext} context
 * @return {object}
 */
export default ({ fetch, store }) => {
  return {
    async all(options = {}) {
      return fetch(`/v2/org/${options.orgId}/notifications`, { ...options, cancellationKey: 'GET_NOTIFICATIONS' });
    },
    async single(options = {}) {
      return fetch(`/v2/notification/${options.id}`, options);
    },
    async setNotificationsSeen(options = {}) {
      return jbxApi.post(`v2/org/${options.orgId}/notifications/seen`);
    },
    async setNotificationSeen(options = {}) {
      return jbxApi.post(`v2/notification/${options.id}/seen`);
    },
    async setWebPushNotificationAsSeen({ notificationId, messageId }) {
      return jbxApi.post(`/v2/notification/${notificationId}/message/${messageId}/seen`);
    }
  };
};
