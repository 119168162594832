import { AFFILIATE, ADVERTISER, EMPLOYEE, NETWORK, GENERAL } from './orgTypes';

export const SIDEBAR_TOP_MENU_LINKS = [
  {
    label: 'Home',
    iconComponent: 'home-icon',
    route: { name: 'performance-dashboard' },
    types: [AFFILIATE, ADVERTISER]
  },
  {
    label: 'Discover',
    iconComponent: 'lightbulb-icon',
    route: { name: 'campaign-discover' },
    types: [AFFILIATE]
  },
  {
    label: 'Reports',
    iconComponent: 'barchart-icon',
    types: [AFFILIATE, EMPLOYEE, NETWORK],
    children: [
      {
        label: 'Sub ID',
        route: { name: 'main-reports', params: { reportType: 'subid' } },
        types: [AFFILIATE]
      },
      {
        label: 'Crowd Insights',
        route: { name: 'insights' },
        types: [EMPLOYEE, NETWORK]
      }
    ]
  },
  {
    label: 'AB Testing',
    iconComponent: 'exchange-icon',
    route: { name: 'ab', match: /^\/ab(?:\/(?:[a-z0-9].*)?)?$/gi },
    types: [EMPLOYEE, NETWORK]
  },
  {
    label: 'Referrals',
    iconComponent: 'referral-icon',
    route: { name: 'referralMarketingLayout' },
    types: [GENERAL]
  }
];

export const SIDEBAR_BOTTOM_MENU_LINKS = [
  {
    label: 'Help Center',
    iconClass: 'el-icon-question',
    route: { path: '/docs/affiliate' },
    types: [AFFILIATE]
  },
  {
    label: 'Help Center',
    iconClass: 'el-icon-question',
    route: { path: '/docs/merchant' },
    types: [ADVERTISER]
  }
];
