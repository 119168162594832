import get from 'lodash/get';

export default (breakpoints = [], watch = '$store.getters.windowWidth', variable = 'windowBreakpoint') => {
  return {
    data() {
      return {
        [variable]: this[`${variable}FromValue`](get(this, watch))
      };
    },
    watch: {
      [watch](value) {
        const breakpoint = this[`${variable}FromValue`](value);
        if (breakpoint !== this[variable]) {
          this[variable] = breakpoint;
        }
      }
    },
    methods: {
      [`${variable}FromValue`](value) {
        let currentBreakpoint = 0;
        breakpoints.some((breakpoint) => {
          if (value >= breakpoint) {
            currentBreakpoint = breakpoint;
          } else {
            return true;
          }
        });

        return currentBreakpoint;
      }
    }
  };
};
