import ErrorHelper from '@/helpers/ErrorHelper';
import { PIXEL } from '@/constants/pixels';
import _pick from 'lodash/pick';

/**
 * Map pixel response to simplify component creation
 * @param pixel
 * @return {*}
 */
const pixelMapper = {
  request(pixel, { redirector_id } = { redirector_id: null }) {
    const pixelData = _pick(JSON.parse(JSON.stringify(pixel)), Object.keys(PIXEL));
    pixelData.global = pixelData.global ? 'yes' : 'no';
    // Pixels with main domain as redirector need to be set back to null when sending to the api
    pixelData.redirector_id = pixelData.redirector_id === redirector_id ? null : pixelData.redirector_id;
    return pixelData;
  },
  response(pixel, { redirector_id } = { redirector_id: null }) {
    pixel.global = pixel.global === 'yes';
    if (pixel.global) {
      pixel.name = 'Global Pixel';
      pixel.event = 'Purchase';
    }
    // Main redirector is assigned to any pixel without a specific one
    if (!pixel.redirector_id) {
      pixel.redirector_id = redirector_id;
    }
    return pixel;
  }
};

/**
 *
 * @param { JBXApi } jbxApi
 * @param { RequestCaching } requestCaching
 */
export default ({ jbxApi, requestCaching }) => {
  const state = {};
  const getters = {};
  const actions = {
    /**
     * Fetch all the pixels of a given campaign
     * @param {Object}context
     * @param {Object} payload
     * @param {Number} payload.orgId
     * @param {Number} payload.campaignId
     * @return {Promise<never>}
     */
    async fetchCampaignPixels({ getters }, { orgId, campaignId }) {
      return requestCaching
        .get({
          url: `/v2/org/${orgId}/campaign/${campaignId}/pixels`
        })
        .then(response => {
          return response.data.data.map((pixel) => {
            return pixelMapper.response(pixel, getters.organizationPrimaryDomain);
          });
        })
        .catch(e => {
          return Promise.reject(ErrorHelper(e));
        });
    },
    /**
     * Save the details of a pixel.
     * It will correctly use post or put depending on the case
     * @param {Object} context
     * @param {Number} payload.orgId
     * @param {Number} payload.campaignId
     * @param {Object} payload.pixel
     * @return {Promise<void|*>}
     */
    async savePixel({ getters }, { orgId, campaignId, pixel }) {
      const options = pixel.pixel_id
        ? { method: 'put', url: `/v2/org/${orgId}/campaign/${campaignId}/pixel/${pixel.pixel_id}` }
        : { method: 'post', url: `/v2/org/${orgId}/campaign/${campaignId}/pixels` };

      return jbxApi.request({
        method: options.method,
        url: options.url,
        data: pixelMapper.request(pixel, getters.organizationPrimaryDomain)
      })
        .then(response => {
          return pixelMapper.response(response.data.data, getters.organizationPrimaryDomain);
        })
        .catch(e => {
          return Promise.reject(ErrorHelper(e));
        });
    },

    /**
     * Delete a given pixel
     * @param {Object} context
     * @param {Number} payload.orgId
     * @param {Number} payload.campaignId
     * @param {Object} payload.pixel
     */
    async deletePixel(context, { orgId, campaignId, pixel }) {
      return jbxApi.request({
        method: 'delete',
        url: `/v2/org/${orgId}/campaign/${campaignId}/pixel/${pixel.pixel_id}`
      })
        .then(response => {
          return response.data.data;
        })
        .catch(e => {
          return Promise.reject(ErrorHelper(e));
        });
    }
  };
  const mutations = {};

  return {
    state,
    getters,
    actions,
    mutations
  };
};
