import { ALLOWED_PROGRAMS } from '@/constants/programs';
import { ALLOWED_ORG_TYPES } from '@/constants/orgTypes';
import { MANAGERS } from '@/constants/managers';

const ALLOWED_TYPES = [...ALLOWED_ORG_TYPES, ...ALLOWED_PROGRAMS];

/**
 * Check if the current route is a valid lead route
 * It can be either of the following:
 *  /signup/
 *  /signup/{orgType}
 *  /signup/{program}
 *  /signup/{orgType}/{program}
 *  /signup/{orgType}/{manager}
 * @param {Route} to
 */
const isValidLeadSourceRoute = function(to) {
  return ALLOWED_TYPES.includes(to.params.param1) &&
    (
      !to.params.param2 || // Doesn't have a second parameters
      ALLOWED_TYPES.includes(to.params.param2) || // Second parameter is either an org-type or program
      Object.keys(MANAGERS).includes(to.params.param2) // Second parameter is a manager
    );
};

/**
 * Check if the route has a manager name on it, but isn't an organization or program type.
 * Managers can only be set on param2 after an organization
 * @param {Route} to
 */
const hasManagerName = function(to) {
  return to.params.hasOwnProperty('param2') &&
    !ALLOWED_TYPES.includes(to.params.param2) &&
    MANAGERS[to.params.param2];
};

/**
 * Check if the route has a valid program, it can be on either param
 * @param to
 * @return {*}
 */
const hasProgram = function(to) {
  if (ALLOWED_PROGRAMS.includes(to.params.param1)) {
    return to.params.param1;
  } else if (ALLOWED_PROGRAMS.includes(to.params.param2)) {
    return to.params.param2;
  } else {
    return false;
  }
};

/**
 * Middleware to validate lead source URL's
 * @param {Object} context
 * @param {Route} context.to
 * @param {VueRouter} context.router
 * @param {Store} context.store
 * @param {Function} context.next
 */
export default async({ to, store, next, router }) => {
  if (!isValidLeadSourceRoute(to)) {
    return router.replace('/signup');
  }

  if (hasProgram(to)) {
    store.dispatch('updateSubType', hasProgram(to).toLowerCase());
  } else if (hasManagerName(to)) {
    const managerAlias = to.params.param2;
    const manager = MANAGERS[managerAlias];
    if (manager.orgType === to.params.param1) {
      store.dispatch('updateManager', {
        alias: managerAlias,
        name: manager.name,
        type: manager.orgType,
        title: manager.title
      });
    } else {
      return router.replace(`/signup/${manager.orgType}/${managerAlias}`);
    }
  }
  next();
};
