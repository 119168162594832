<template>
  <div class="router-view-layout">
    <router-view key="routerView" />
  </div>
</template>

<script>
export default {
  name: 'RouterViewLayout'
};
</script>
