export default function(VueInstance) {
  VueInstance.component('with-root', {
    functional: true,
    props: ['show'],
    render(h, ctx) {
      const children = ctx.children.filter(vnode => vnode.tag); // remove unnecessary text nodes
      if (children.length !== 1) {
        console.warn('this component accepts only one root node in its slot');
      }

      if (ctx.props.show) {
        return children[0];
      } else if (children[0].children) {
        return children[0].children;
      } else if (children[0].componentOptions && children[0].componentOptions.children) {
        return children[0].componentOptions.children;
      }
    }
  });
}
