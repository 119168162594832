<template>
  <div :class="[status, size]" class="app-status">
    {{ status | capitalizeAll }}
  </div>
</template>

<script>
export default {
  name: 'AppStatus',
  props: {
    status: {
      type: String,
      required: false,
      default: () => ''
    },
    size: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss">
.app-status {
  display: inline-block;
  padding: $--clb-space-1 + 1px $--clb-space-4;
  border-radius: 24px;
  font-size: $--clb-font-size-xs - 4px;
  line-height: $--clb-font-size-xs;
  color: #455a64;
  text-transform: uppercase;
  text-align: center;

  &.mini {
    padding: $--clb-space-1 $--clb-space-2 - 2;
    border-radius: 20px;
    font-size: $--clb-font-size-xs - 2px;
    line-height: $--clb-font-size-xs - 2px;
  }

  &.prelaunch {
    background: $--clb-color-pink;
  }

  &.active {
    @apply tw-bg-jb-green-light;
  }

  &.paused {
    @apply tw-bg-jb-yellow-lighter;
  }

  &.cancelled,
  &.expired {
    @apply tw-bg-jb-grey-100;
  }

  &.setup,
  &.capped {
    background: $--clb-body-font;
  }
}
</style>
