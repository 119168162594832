export const ORGANIZATION_SETTINGS_TABS = [
  {
    id: 'members',
    path: 'members',
    name: 'organization-settings-members',
    label: 'Members',
    component: import('@/views/organization-settings/OrganizationMembers.vue')
  },
  {
    id: 'permissions',
    path: 'permissions',
    name: 'organization-settings-permissions',
    label: 'Partnership Permissions',
    component: import('@/views/organization-settings/OrganizationPermissions.vue')
  },
  {
    id: 'partnerships',
    path: 'partnerships',
    name: 'organization-settings-partnerships',
    label: 'Partnership Assignment',
    component: import('@/views/organization-settings/OrganizationPartnerships.vue')
  },
  {
    id: 'domains',
    path: 'domains',
    name: 'organization-settings-domains',
    label: 'Custom Domains',
    component: import('@/views/organization-settings/OrganizationDomains.vue')
  },
  {
    id: 'conversion-apis',
    path: 'conversion-apis',
    name: 'organization-settings-conversions',
    label: 'Conversion APIs',
    component: import('@/views/organization-settings/OrganizationConversionApis.vue')
  }
];

export default (page) => {
  return () => {
    const tabs = ORGANIZATION_SETTINGS_TABS.reduce((acc, curr) => {
      acc[curr.id] = curr.component;
      return acc;
    }, {});

    const pages = {
      layout: import('@/views/organization-settings/OrganizationSettingsLayout.vue'),
      ...tabs
    };
    return pages[page];
  };
};
