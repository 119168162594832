/*
 * @param fetch
 * @param storeHandler
 */
export default ({ fetch, storeHandler }) => {
  return {
    async fetchCampaignAffiliateList(options = {}) {
      const orgId = storeHandler.get('organizationId');
      return fetch({ url: `/v2/org/${orgId}/content-request/filters`, options });
    },
    async fetchContents({ campaignId, ...options } = {}) {
      const orgId = storeHandler.get('organizationId');
      return fetch({ url: `/v2/org/${orgId}/campaign/${campaignId}/content-request`, options });
    },
    async putContent({ campaignId, requestId, ...options } = {}) {
      const orgId = storeHandler.get('organizationId');
      return fetch({ url: `/v2/org/${orgId}/campaign/${campaignId}/content-request/${requestId}`, method: 'PUT', options });
    },
    async deleteContent({ campaignId, requestId, ...options } = {}) {
      const orgId = storeHandler.get('organizationId');
      return fetch({ url: `/v2/org/${orgId}/campaign/${campaignId}/content-request/${requestId}`, method: 'DELETE', options });
    },
    async fetchContentHistory({ campaignId, requestId, ...options } = {}) {
      const orgId = storeHandler.get('organizationId');
      return fetch({ url: `/v2/org/${orgId}/campaign/${campaignId}/content-request/${requestId}/history`, options });
    },
    async setResponseTime({ campaignId, ...options } = {}) {
      const orgId = storeHandler.get('organizationId');
      return fetch({ url: `/v2/org/${orgId}/campaign/${campaignId}/creative-settings/response-time`, method: 'PUT', options });
    },
    async fetchCreativeSettings({ campaignId, ...options } = {}) {
      const orgId = storeHandler.get('organizationId');
      return fetch({ url: `/v2/org/${orgId}/campaign/${campaignId}/creative-settings`, options });
    },
    async setGuidelines({ campaignId, ...options } = {}) {
      const orgId = storeHandler.get('organizationId');
      return fetch({ url: `/v2/org/${orgId}/campaign/${campaignId}/creative-settings/guidelines`, method: 'PUT', options });
    },
    async setBannedWords({ campaignId, ...options } = {}) {
      const orgId = storeHandler.get('organizationId');
      return fetch({ url: `/v2/org/${orgId}/campaign/${campaignId}/creative-settings/banned-words`, method: 'PUT', options });
    },
    async setNewContentRequest({ campaignId, ...options } = {}) {
      const orgId = storeHandler.get('organizationId');
      return fetch({ url: `/v2/org/${orgId}/campaign/${campaignId}/content-request`, method: 'POST', options });
    }
  };
};
