<template>
  <el-collapse-item ref="collapseItem" class="app-global-sidebar-list-accordion-item tw-flex tw-flex-col tw-max-h-full" :name="name" :disabled="disabled">
    <template #title>
      <slot name="title">
        <app-global-sidebar-item :label="title" class="tw-ml-layout-1 !tw-text-opacity-75">
          <template #icon>
            <caret-down-icon
              ref="caret"
              class="tw-px-space-1 tw-mr-space-2 tw-w-layout-1 tw-text-jb-grey-400 tw-flex tw-items-center tw-justify-center tw-transition tw-duration-300 tw-origin-center tw-transform" />
          </template>
        </app-global-sidebar-item>
      </slot>
    </template>

    <template #default>
      <el-scrollbar ref="scrollbar" class="app-global-sidebar-list-accordion-item-scrollbar tw-flex tw-flex-col" :native="!showScrollbar || $supportsTouch">
        <slot name="default" />
        <transition name="fade" mode="out-in">
          <app-global-sidebar-item v-if="shouldLoadMore() || isLoading" ref="loading" label="Loading..." class="tw-ml-layout-1 !tw-text-opacity-25" />
        </transition>
      </el-scrollbar>
      <div v-if="showScrollbar" ref="scrollOverlay" class="app-global-sidebar-list-accordion-item-scroll-overlay" />
    </template>
  </el-collapse-item>
</template>

<script>
import { Scrollbar } from 'element-ui';
import AppGlobalSidebarItem from './AppGlobalSidebarItem.vue';
import CaretDownIcon from '@/assets/svg/caret-down-icon.svg';
import _debounce from 'lodash/debounce';

export default {
  name: 'AppGlobalSidebarListAccordionItem',
  components: { 'el-scrollbar': Scrollbar, AppGlobalSidebarItem, CaretDownIcon },
  props: {
    name: {
      type: [String, Number],
      required: false,
      default: undefined
    },
    title: {
      type: String,
      required: false,
      default: undefined
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    showScrollbar: {
      type: Boolean,
      default: false
    },
    hasMore: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      debouncedScrollListener: _debounce(this.scrollListener, 32, { trailing: true })
    };
  },
  watch: {
    showScrollbar(show) {
      if (this.$refs.scrollbar) {
        this.$refs.scrollbar.update();
        if (show) this.handleRegisterScrollListener();
        if (!show) this.handleUnregisterScrollListener();
      }
    }
  },
  mounted() {
    this.handleRegisterScrollListener();
    this.$refs.collapseItem && this.$refs.collapseItem.isActive && this.handleCaretToggle(true);

    // for some reason setting a data value here will skip the collapse transition, so we have to target the DOM directly
    this.$refs.collapseItem && this.$watch(() => this.$refs.collapseItem.isActive, this.handleCaretToggle);
  },
  beforeDestroy() {
    this.handleUnregisterScrollListener();
  },
  methods: {
    handleRegisterScrollListener() {
      if (this.$refs.scrollbar) {
        this.$refs.scrollbar.$on('hook:updated', this.debouncedScrollListener);
        this.$refs.scrollbar.wrap.addEventListener('scroll', this.debouncedScrollListener, this.$supportsPassive && { passive: true });
      }
    },
    handleUnregisterScrollListener() {
      if (this.$refs.scrollbar) {
        this.$refs.scrollbar.$off('hook:updated');
        this.$refs.scrollbar.wrap.removeEventListener('scroll', this.debouncedScrollListener, this.$supportsPassive && { passive: true });
      }
    },
    handleCaretToggle(active) {
      if (active) {
        this.$refs.caret && this.$refs.caret.classList.add('tw-rotate-180');

        this.$refs.scrollOverlay && this.$refs.scrollOverlay.classList.add('tw-opacity-0');
        setTimeout(() => {
          this.$refs.scrollbar && (this.$refs.scrollbar.wrap.scrollTop = 0);
          this.$refs.scrollbar && this.$refs.scrollbar.update();
          this.$refs.scrollOverlay && this.$refs.scrollOverlay.classList.remove('tw-opacity-0');
        }, 400);
      } else {
        this.$refs.caret && this.$refs.caret.classList.remove('tw-rotate-180');
        this.$refs.scrollOverlay && this.$refs.scrollOverlay.classList.add('tw-opacity-0');
      }
    },
    scrollListener() {
      this.$refs.scrollbar && this.$refs.scrollbar.update();
      this.handleScrollOverlayScale();
      this.handleInfiniteScroll();
    },
    handleScrollOverlayScale() {
      if (this.$refs.scrollbar && this.$refs.scrollOverlay) {
        const wrap = this.$refs.scrollbar.wrap;
        const height = 160;
        const pos = Math.min(wrap.scrollHeight - (wrap.scrollTop + wrap.clientHeight), height);
        const scale = height ? parseFloat((pos / height).toFixed(2)) : 0;
        this.$refs.scrollOverlay.style.transform = `scaleY(${scale})`;
      }
    },
    shouldLoadMore() {
      if (this.isLoading || !this.hasMore || !this.$refs.scrollbar || !this.$refs.scrollbar.wrap) return false;
      const wrap = this.$refs.scrollbar.wrap;
      const pos = wrap.scrollHeight - (wrap.scrollTop + wrap.clientHeight);
      return wrap.scrollTop > 300 && pos < 20;
    },
    handleInfiniteScroll() {
      if (this.$listeners['load-more'] && this.$refs.scrollbar && this.shouldLoadMore()) {
        this.$emit('load-more');
      }
    }
  }
};
</script>

<style lang="scss">
.app-global-sidebar-list-accordion-item {
  @apply tw-relative;

  .el-collapse-item {
    &__header {
      @apply tw-text-base tw-leading-normal tw-h-auto tw-border-0 tw-text-jb-ink tw-block;
    }

    &__wrap {
      @apply tw-flex tw-flex-col tw-max-h-full tw-border-0;
    }

    &__content {
      @apply tw-flex tw-flex-col tw-max-h-full tw-overflow-y-auto tw-pb-space-1;
    }

    &__arrow {
      @apply tw-hidden;
    }
  }

  .el-scrollbar {
    &__wrap {
      @apply tw-overflow-x-hidden tw-overscroll-contain;

      margin-bottom: 0 !important;
    }

    &__bar.is-horizontal {
      @apply tw-hidden;
    }
  }

  .app-global-sidebar-list-accordion-item-scroll-overlay {
    --tw-gradient-to: rgba(255, 255, 255, 0) !important;

    @apply tw-pt-layout-6 tw-bg-gradient-to-t tw-from-white tw-to-transparent tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-origin-bottom tw-pointer-events-none;
  }
}
</style>
