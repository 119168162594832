export default ({ fetch, storeHandler }) => {
  return {
    fetchSummary(options = {}) {
      const user = storeHandler.get('user');
      return fetch({ url: `/v2/user/${user.user_id}/referral/summary`, options });
    },
    fetchDetails(options = {}) {
      const user = storeHandler.get('user');
      return fetch({ url: `/v2/user/${user.user_id}/referral/details`, options });
    }
  };
};
