<template>
  <button
    v-tippy="{
      enabled: tippy,
      content: tippy,
      showOnInit: false,
      arrow: true,
      placement: 'bottom',
      boundary: 'window',
      followCursor: false
    }"
    :type="type"
    :disabled="disabled"
    :class="{
      'app-button--loading': loading,
      [state]: state,
      [size]: size,
      [type]: type,
    }"
    class="app-button"
    @click="handleClick">
    <template v-if="loading">
      <div v-if="loading" class="app-button__loader">
        <slot name="loader">
          <div v-if="loading" class="el-icon-loading" />
        </slot>
      </div>
    </template>
    <span v-if="state=='text'">
      <app-text :weight="textWeight" :color="textColor">
        <slot />
      </app-text>
    </span>
    <slot v-else />
  </button>
</template>

<script>
import AppText from '@/components/AppText';

export const allowedStates = ['primary', 'secondary', 'text', 'link', 'green', 'red', 'positive', 'success', 'warning', 'danger', 'indigo'];
export const allowedSizes = ['mini', 'micro', 'default'];

export default {
  name: 'AppButton',
  components: { AppText },
  props: {
    type: {
      type: String,
      default: 'button',
      validator: function(value) {
        // The type must match one of these options
        return ['button', 'submit', 'reset'].indexOf(value) !== -1;
      }
    },
    tippy: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    state: {
      type: String,
      default: 'primary',
      validator: function(value) {
        // The state must match one of these options
        return allowedStates.indexOf(value) !== -1;
      }
    },
    textColor: {
      type: String,
      default: 'grey-dark'
    },
    size: {
      type: String,
      default: 'default',
      validator: function(value) {
        // The size must match one of these options
        return allowedSizes.indexOf(value) !== -1;
      }
    },
    textWeight: {
      type: String,
      default: 'bold'
    }
  },
  methods: {
    handleClick($event) {
      this.$emit('click', $event);
    }
  }
};
</script>

<style lang="scss">
.app-button {
  @include clb-button;

  &.indigo {
    @apply tw-bg-jb-indigo hover:tw-bg-jb-indigo-dark;
  }
}
</style>
