/**
 * Calculate a Check Digit for a given string using a Mod11
 *
 * @param {string} baseString String to calculate the CheckDigit
 */
function mod11(baseString) {
  const weight = [2, 3, 4, 5, 6, 7];
  let sum = 0;

  baseString.split('').reverse().forEach((char, index) => {
    sum += char.charCodeAt(0) * weight[index % 6];
  });

  const reminder = sum % 11;
  return [0, 1].includes(reminder) ? 0 : 11 - reminder;
}

export default {
  /**
   * Check if a given referal code is valid
   *
   * @param {string} $code Code to validate
   */
  isValidReferralCode($code) {
    const [baseCode, checkDigit] = $code.split('-');
    const calculatedCheckDigit = mod11(baseCode);

    return calculatedCheckDigit === parseInt(checkDigit);
  },
  /**
   * Get the referral code from the given path
   *
   * @param {string} path
   * @return {string}
   */
  getCodeFromPath(path) {
    const segments = path.split('/');
    return Array.isArray(segments) ? segments.pop() : null;
  }
};
