export default {
  convertToObject(bannedWords) {
    return bannedWords.reduce((acc, val) => {
      acc[val] = true;
      return acc;
    }, {});
  },

  convertToArray(bannedWords) {
    return Object.keys(bannedWords);
  }
};
