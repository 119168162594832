import _debounce from 'lodash/debounce';

export default {
  oneSignal: null,
  store: null,
  initialized: false,
  enabled: false,
  /**
   * Initialize one signal integration
   * @param OneSignal
   * @param {Object} store
   * @param {Object} store.getters
   * @param {Function} store.dispatch
   */
  init(OneSignal, store) {
    this.oneSignal = OneSignal || [];

    if (!this.oneSignal.initialized) {
      this.store = store;
      this.oneSignal.push(async() => {
        await this.oneSignal.init({
          appId: process.env.VUE_APP_ONESIGNAL,
          allowLocalhostAsSecureOrigin: process.env.NODE_ENV !== 'production',
          welcomeNotification: { disable: true }
        });
        this.initialized = true;
      });
      this.bindEvents();
    }

    this.checkNotificationStatus(true);
  },
  setUserId(userId) {
    this.oneSignal.push(() => this.oneSignal.setExternalUserId(userId));
  },
  bindEvents() {
    this.oneSignal.push(() => {
      const debouncedCheckStatus = _debounce(() => this.checkNotificationStatus(), 64, { maxWait: 64, trailing: true });
      this.oneSignal.on('notificationPermissionChange', debouncedCheckStatus);
      this.oneSignal.on('subscriptionChange', debouncedCheckStatus);
    });
  },
  checkNotificationStatus(initialLoad = false) {
    this.oneSignal.push(async() => {
      let pushNotificationsSupported = this.oneSignal.isPushNotificationsSupported();
      let pushNotificationsEnabled = this.oneSignal.isPushNotificationsEnabled();
      let pushNotificationsPermission = this.oneSignal.getNotificationPermission();
      let browserId = this.oneSignal.getUserId();
      let externalUserId = this.oneSignal.getExternalUserId();

      try {
        [pushNotificationsEnabled, pushNotificationsPermission, browserId, externalUserId] = await Promise.all([pushNotificationsEnabled, pushNotificationsPermission, browserId, externalUserId]);
      } catch (err) {
        pushNotificationsSupported = pushNotificationsEnabled = false;
        pushNotificationsPermission = 'default';
      }

      this.enabled = pushNotificationsPermission === 'granted';

      this.store.dispatch('setNotificationSettings', {
        initialLoad,
        pushNotificationsEnabled,
        pushNotificationsSupported,
        pushNotificationsPermission,
        externalUserId
      });

      this.store.dispatch('updatePushNotificationUserSettings', {
        pushNotificationsPermission,
        browserId
      });
    });
  },
  askPermission() {
    this.oneSignal.push(() => {
      // Notifications are already enabled, we just need to change the user id
      if (this.enabled) {
        this.checkNotificationStatus();

      // Notifications are not enabled, prompt user
      } else {
        this.oneSignal.showNativePrompt();
      }
    });
  }
};
