<template>
  <svg
    :width="computedWidth"
    :height="computedHeight"
    class="skeleton-box"
    viewBox="0 0 100 100"
    preserveAspectRatio="none">
    <defs v-if="animate">
      <mask :id="`mask-element-${_uid}`">
        <path id="qube" fill="#777" d="M0,0 L100,0 L100,100 L0,100 L0,0" />
        <path id="mask" fill="hsla(200,0%,10%,.6)" d="M-50,100 l30,-100 l40,0 l-20,100" />
      </mask>
    </defs>
    <path
      id="qube"
      :mask="`url(#mask-element-${_uid})`"
      d="M0,0 L100,0 L100,100 L0,100 L0,0"
      class="tw-fill-jb-grey-100" />
  </svg>
</template>

<script>
export default {
  name: 'SkeletonBox',
  props: {
    height: {
      type: String,
      default: null
    },
    width: {
      type: String,
      default: null
    },
    animate: {
      type: Boolean,
      default: true
    },
    aspectRatio: {
      type: Number,
      default: 1
    },
    scale: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      defaultWidth: '100%'
    };
  },
  computed: {
    unit() {
      if (this.noInitialValues) {
        return '%';
      }
      const size = this.width || this.height;
      return size.replace(parseInt(size), '');
    },
    noInitialValues() {
      return this.width === null && this.height === null;
    },
    computedHeight() {
      if (this.noInitialValues) {
        return this.defaultHeight;
      }
      return this.height !== null ? this.height : parseFloat(this.width) * this.aspectRatio + this.unit;
    },
    computedWidth() {
      if (this.noInitialValues) {
        return this.defaultWidth;
      }
      return this.width !== null ? this.width : parseFloat(this.height) / this.aspectRatio + this.unit;
    }
  }
};
</script>

<style lang="scss">
svg.skeleton-box {
  margin: auto;
  border-radius: $--clb-border-radius;
  display: block;
  background: $--clb-skeleton-color;

  #mask {
    animation: mask 2s ease infinite;
  }

  @keyframes mask {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(280px);
    }
  }
}
</style>
