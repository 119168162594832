/**
 * Run guest middleware
 * @param {Object} context
 * @param {Route} context.to
 * @param {Route} context.from
 * @param {VueRouter} context.router
 * @param {Store} context.store
 * @param {Function} context.next
 * @param {MiddlewareHelper} context.middlewareHelper
 */
export default async function({ next, to, from, store, router, middlewareHelper }) {
  if (store.getters.isAuthenticated) {
    const path = middlewareHelper.getMetaOption(to, 'guestPath', '/signup/survey/finish');
    if (path === from.path) {
      return next(false);
    } else {
      return router.replace(path);
    }
  }
  return next();
}
