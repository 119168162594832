import dayjs from 'dayjs';
import money from '@/services/money';
import { STAT_LABELS, STATUS_PRIORITIES } from '@/constants/referral';

export const formatReferralStatsFromApi = (summary = {}) => {
  return Object.entries(STAT_LABELS).map(([key, label]) => {
    let value = summary[key] || 0;
    if (['total_credits', 'pending_credits'].includes(key)) {
      const preserveDecimals = parseFloat(value) > 0 && parseFloat(value) < 100;
      value = money(parseFloat(value), preserveDecimals, '$');
    };
    return { label, value };
  });
};

export const formatReferralTimelineFromApi = (payments, paymentEnabled, pendingCredits) => {
  const enabled = payments.length || pendingCredits > 0;

  let total = 0;
  const labels = [];
  const values = [];

  if (enabled) {
    labels.push(dayjs(paymentEnabled).format('YYYY-MM-DD'));
    labels.push(...payments.map((time) => dayjs(time.date).format('YYYY-MM-DD')));
    labels.push(dayjs(new Date()).format('YYYY-MM-DD 23:59:59'));

    values.push(0);
    values.push(...payments.map((time) => {
      total += parseFloat(time.amount);
      return total;
    }));
    values.push(total + parseFloat(pendingCredits || 0));
  }

  return { labels, values, paymentEnabled, pendingCredits };
};

export const formatReferralDetailsFromApi = (details) => {
  const formatted = details.map((row) => {
    if (row.formatted) return row;
    row.status_priority = STATUS_PRIORITIES[row.referral_status];
    row.signup_date = dayjs(row.signup_date).format('MMM D, YYYY');
    row.commission_expiration_date = row.commission_expiration_date ? dayjs(row.commission_expiration_date).format('MMM D, YYYY') : 'TBD';
    row.amount_earned = parseFloat(row.amount_earned) ? money(parseFloat(row.amount_earned), false, '') : '-';
    row.formatted = true;
    return row;
  });
  return formatted.sort((a, b) => { return a.status_priority > b.status_priority ? 1 : -1; });
};
