<template>
  <form class="campaign-application-form" @submit.prevent="$emit('submit', clonedData)">
    <div class="campaign-application-form__info tw-flex tw-flex-col">
      <div class="tw-flex tw-flex-col">
        <div class="campaign-application-form__left">
          <p v-if="isPrelaunch">
            This campaign will officially launch on <strong>{{ launchDate }}</strong>.<br><br>
          </p>
          <p>
            Your application will be submitted to your Affiliate Manager. Remember to include all relevant information in order to ensure a quick approval.
          </p>
        </div>
        <h4 class="tw-mt-space 2 md:tw-mt-space-4 tw-mb-space-2 tw-text-base tw-font-medium">
          Prohibited Traffic
        </h4>
        <ul class="campaign-application-form__prohibited">
          <li>Incentivized</li>
          <li>Unauthorized Endorsements</li>
          <li>Unauthorized 3rd Party Trademarks</li>
          <li>Survey</li>
          <li>Trademark Bidding</li>
          <li>Product Name Bidding</li>
          <li>SEO</li>
        </ul>

        <h4 class="tw-mt-space 2 md:tw-mt-space-4 tw-mb-space-2 tw-text-base tw-font-medium">
          Campaign Plan & Questions
        </h4>
        <div class="comment-cont">
          <textarea
            id="campaign-application-form-comments"
            v-model="form.data.comments"
            name="comments"
            class="input-comments"
            placeholder="Tell us a bit about how you're planning to run this campaign" />
        </div>
        <h4 class="tw-mt-space 2 md:tw-mt-space-4 tw-mb-space-2 tw-text-base tw-font-medium">
          Expected EPC
        </h4>
        <div class="slider-wrapper">
          <vue-slider
            v-model="form.data.expected_epc"
            :min="0"
            :max="100"
            :dot-size="32"
            :height="10"
            :duration="0.3"
            :tooltip-formatter="val => '$' + (Math.round(val * 10) / 100).toFixed(2)"
            class="slider-cont input-target-epc"
            tooltip="always"
            tooltip-placement="bottom" />
        </div>
      </div>

      <h4 class="tw-mt-space-2 md:tw-mt-space-4 tw-mb-space-2 tw-text-base tw-font-medium">
        Terms & Conditions
      </h4>
      <div class="campaign-application-form__restrictions">
        <template v-if="!loaded">
          <skeleton-box class="campaign-application-form__restrictions-description" width="100%" height="150px" />
        </template>
        <template v-else-if="campaign.terms">
          <p class="campaign-application-form__restrictions-description">
            {{ campaign.terms }}
          </p>
        </template>
        <el-checkbox
          v-model="form.data.terms"
          class="input-term-conditions"
          size="large">
          <p
            class="md:tw-text-base tw-text-sm tw-font-normal">
            I agree to abide by the Terms & Conditions and Prohibited Traffic Sources.
          </p>
        </el-checkbox>
        <div class="submit-btn-cont">
          <app-button
            :disabled="!formCompleted || !loaded"
            :loading="loading"
            class="app-button tw-mb-space-4 md:tw-mb-space-6"
            type="submit">
            Submit Application
            <i class="el-icon-arrow-right" />
          </app-button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import AppButton from '@/components/AppButton';
import Breakpoints from '@/mixins/Breakpoints';
import dateService from '@/services/date';
import SkeletonBox from '@/components/skeletons/SkeletonBox';

export default {
  name: 'CampaignApplicationForm',
  components: { AppButton, SkeletonBox },
  mixins: [Breakpoints([992])],
  props: {
    campaign: {
      type: Object | null,
      default: () => null
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        data: {
          comments: '',
          expected_epc: 25,
          terms: false
        }
      }
    };
  },
  computed: {
    gutter() {
      return this.windowBreakpoint >= 992 ? 48 : 24;
    },
    clonedData() {
      return JSON.parse(
        JSON.stringify(
          Object.assign({}, this.form.data, {
            expected_epc: this.form.data.expected_epc / 10
          })
        )
      );
    },
    loaded() {
      return this.campaign && this.campaign.hasOwnProperty('terms');
    },
    isPrelaunch() {
      return this.campaign && this.campaign.status === 'prelaunch';
    },
    launchDate() {
      const date = dateService.parseISOString(this.campaign.metrics.launch_date);
      return `${dateService.getDayName(date)} ${dateService.getMonthName(date)} ${dateService.getDateOrdinal(date)}`;
    },
    formCompleted() {
      return this.form.data.terms;
    }
  }
};
</script>

<style lang="scss">
.campaign-application-form {
  @apply tw-w-full;

  h4 {
    @apply tw-mt-layout-2 tw-mx-0 tw-mb-layout-1;
  }

  &__left {
    p {
      @apply tw-text-sm md:tw-text-base;
    }
  }

  &__info {
    &__prohibited {
      li {
        @apply tw-leading-5;
      }
    }

    .comment-cont {
      text-align: center;

      textarea {
        min-height: 80px + ($--clb-space-2 * 2);
        border: 1px solid $--clb-border-color-base;
        transition: $--all-transition;

        @apply tw-w-full tw-p-space-2 tw-leading-5 tw-text-body-font tw-outline-none tw-rounded;

        @variants focus,hover {
          @apply tw-border-primary tw-text-jb-ink;
        }
      }
    }

    .slider-wrapper {
      @apply tw-mt-0 tw-mr-0 tw-mb-layout-2;
    }
  }

  &__restrictions {
    .el-checkbox {
      @apply tw-flex tw-cursor-pointer tw-whitespace-normal tw-mb-layout-1 ;
    }

    .app-button {
      @include clb-button;

      @apply tw-w-full;
    }
  }
}
</style>
