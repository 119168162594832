import store from '@/store';
const baseUrl = process.env.VUE_APP_HITPATH_AFFILIATE_URL;

export default (success, error) => {
  const apiToken = store.getters.user.session_id;
  const orgId = store.getters.organizationId;
  const orgPath = orgId ? `/org/${orgId}` : orgId;

  success = success ? `&success=${encodeURIComponent(success)}` : '';
  error = error ? `&error=${encodeURIComponent(error)}` : '';

  return `${baseUrl}/v2${orgPath}/sso?api-token=${apiToken}${success}${error}`;
};
