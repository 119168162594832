<template>
  <app-text
    :type="tagComputed"
    :size="sizeComputed"
    :weight="weightComputed"
    :color="color"
    :icon="icon"
    :icon-style="iconStyle"
    class="app-heading">
    <slot />
  </app-text>
</template>

<script>
import AppText, { allowedTags, allowedWeights, allowedColors, allowedSizes, allowedIconStyles } from '@/components/AppText';
export const allowedLevels = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

export default {
  name: 'AppHeading',
  components: { AppText },
  props: {
    level: {
      type: String,
      default: 'h1',
      validator: function(value) {
        // The level must match one of these values
        return allowedLevels.indexOf(value) !== -1;
      }
    },
    tag: {
      type: String,
      default: null,
      validator: function(value) {
        // The tag must match one of these values
        return allowedTags.indexOf(value) !== -1;
      }
    },
    weight: {
      type: String,
      default: null,
      validator: function(value) {
        // The weight must match one of these values
        return allowedWeights.indexOf(value) !== -1;
      }
    },
    color: {
      type: String,
      default: null,
      validator: function(value) {
        // The color must match one of these values
        return allowedColors.indexOf(value) !== -1;
      }
    },
    size: {
      type: String,
      default: null,
      validator: function(value) {
        // The size must match one of these values
        return allowedSizes.indexOf(value) !== -1;
      }
    },
    icon: {
      type: String,
      default: null
    },
    iconStyle: {
      type: String,
      default: 'prepend',
      validator: function(value) {
        return allowedIconStyles.indexOf(value) !== -1;
      }
    }
  },
  computed: {
    tagComputed() {
      return this.tag || this.level;
    },
    sizeComputed() {
      return this.size || this.level;
    },
    weightComputed() {
      if (this.weight) return this.weight;
      return ['h3', 'h4', 'h5', 'h6'].includes(this.level) ? 'bold' : 'semi-bold';
    }
  }
};
</script>
