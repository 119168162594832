<template>
  <div id="app" :class="[activePage, loading ? 'loading' : '']">
    <app-loader key="global-loader" :loading="loading" :loaders="loaders" />
    <transition name="fade" mode="out-in">
      <router-view :key="isAuthenticated" />
    </transition>
    <el-alert
      v-if="requiresUpdate"
      :closable="true"
      class="app-requires-update"
      title="An Update is Available"
      type="error"
      close-text="Update Now"
      effect="dark"
      @close="reload" />
  </div>
</template>

<script>
import AppLoader from '@/components/AppLoader';
import _debounce from 'lodash/debounce';

export default {
  name: 'App',
  components: { AppLoader },
  data() {
    return {
      debouncedUpdate: _debounce(this.checkForUpdates, 60000, {
        maxWait: 60000,
        leading: true
      })
    };
  },
  computed: {
    requiresUpdate() {
      return this.$store.getters.requiresUpdate;
    },
    organizationId() {
      return this.$store.getters.organizationId;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    loading() {
      return this.$store.getters.loading;
    },
    loaders() {
      return this.$store.getters.loaders;
    },
    activePage() {
      return this.$store.getters.activePage;
    }
  },
  created() {
    document.documentElement.className += this.$supportsTouch ? ' touch' : ' no-touch';
    window.addEventListener('resize', this.windowResized, this.$supportsPassive && { passive: true });
    window.addEventListener('mousemove', this.debouncedUpdate, this.$supportsPassive && { passive: true });
    window.addEventListener('touchmove', this.debouncedUpdate, this.$supportsPassive && { passive: true });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.windowResized, this.$supportsPassive && { passive: true });
    window.removeEventListener('mousemove', this.debouncedUpdate, this.$supportsPassive && { passive: true });
    window.removeEventListener('touchmove', this.debouncedUpdate, this.$supportsPassive && { passive: true });
  },
  methods: {
    windowResized() {
      this.$store.dispatch('handleWindowResize', {
        width: window.innerWidth,
        height: window.innerHeight
      });
    },
    checkForUpdates() {
      this.$store.dispatch('checkForUpdates');
    },
    reload() {
      window.location.reload(true);
    }
  }
};
</script>
<style lang="scss">
@import './assets/globalStyles/appV2.scss';
@import './assets/globalStyles/app.scss';

// Page fades in
.fade-enter-active,
.fade-leave-active {
  transition: opacity 300ms;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

#app.loading {
  overflow: hidden;
  max-height: calc(100 * var(--vh, 1vh));
}

.jb-logo-cont {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 40px 40px 0 40px;
  transition: padding 300ms ease, width 300ms ease;

  @media (max-width: $--md) {
    padding: $--clb-mobile-padding $--clb-mobile-padding 0 $--clb-mobile-padding;
  }
}

#app > .app-loader {
  background-color: $--jb-bg-primary-color;
  position: fixed;
  height: 100vh;
  width: 100vw;
}

.logo-link {
  font-size: 0;

  .logo {
    color: $--jb-dark-primary-color;
    height: 40px;
    transition: color 500ms ease-out, transform 300ms ease-out;
    width: 40px;

    &:hover {
      transform: translate(-2px, -4px);
    }

    &.white {
      color: #fff;
    }

    &.blue {
      color: $--jb-dark-primary-color;

      &:hover {
        color: $--jb-light-primary-color;
      }
    }

    @media (max-width: $--md) {
      color: #fff;

      &:hover {
        color: #fff;
      }
    }
  }
}

.el-alert.app-requires-update {
  position: fixed;
  z-index: 10000;
  bottom: 0;
  margin: 0;
  border-radius: 0;
}
</style>
