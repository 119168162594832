<template>
  <transition name="slide-fade">
    <div v-show="loading" class="app-loader">
      <div class="logo-link">
        <Logo class="logo" />
        <div class="loader-copy-cont">
          <transition name="loading-text" mode="out-in">
            <h1 v-if="loaders.length" :key="loaders[loaders.length - 1].key" class="loading-text-item">
              {{ loaders[loaders.length - 1].text }}
            </h1>
          </transition>
        </div>
        <div v-if="!loaders.length" class="loader-copy-cont">
          <div class="bouncing-loader">
            <div />
            <div />
            <div />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Logo from '@/assets/svg/logo.svg';

export default {
  components: { Logo },
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    loaders: {
      type: Array,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
// Loader fades
.slide-fade-enter-active {
  transition: opacity 0.1s ease;
}

.slide-fade-leave-active {
  transition: opacity 0.3s ease 0.3s;
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
}

.app-loader {
  text-align: center;
  height: 100%;
  width: 100%;
  z-index: 999999;
  overflow: hidden;

  // Floating logo
  .logo-link {
    left: 0;
    position: relative;
    top: 25vh;

    .logo {
      color: $--color-primary;
      animation: float 4.8s ease-in-out infinite;
      box-sizing: border-box;
      overflow: hidden;
      height: 80px;
      width: 80px;
      margin-bottom: 12px;
    }
  }
}

// https://stackoverflow.com/questions/26513046/why-does-svg-get-blurred-when-scaled-under-webkit-browsers
@keyframes float {
  0% {
    transform: translateY(0) scale3d(0.9, 0.9, 1);
  }

  50% {
    transform: translateY(-16px) scale3d(1, 1, 1);
  }

  100% {
    transform: translateY(0) scale3d(0.9, 0.9, 1);
  }
}

.loader-copy-cont {
  .loading-text-item {
    color: $--jb-dark-primary-color;
    display: block;
    font-size: 18px;
    margin-right: 0;
    padding: 0;
    transition: all 0.4s ease-out;
  }

  .loading-text-enter,
  .loading-text-leave-to {
    opacity: 0;
    transform: translateY(20px);
  }

  .loading-text-leave-active {
    position: absolute;
    text-align: center;
    transform: translateY(-30px);
    width: 100%;
  }
}

//loading text bouncing dots
@keyframes bouncing-loader {
  to {
    opacity: 0.25;
    transform: translate3d(0, 12px, 0);
  }
}

.bouncing-loader {
  display: flex;
  justify-content: center;
}

.bouncing-loader > div {
  animation: bouncing-loader 0.6s ease infinite alternate;
  background: $--jb-dark-primary-color;
  border-radius: 50%;
  height: 10px;
  margin: 1rem 0.2rem;
  width: 10px;
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}
</style>
