/**
 * Run approved account middleware
 * @param {Object} context
 * @param {VueRouter} context.router
 * @param {Store} context.store
 * @param {Function} context.next
 */
export default async({ next, store, router }) => {
  if (store.getters.isAccountSigningUp) {
    return router.replace({
      path: '/signup/splash'
    });
  }
  return next();
};
