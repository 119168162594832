import { EMPLOYEE, NETWORK } from '@/constants/orgTypes';

/**
 * Run internal user middleware
 * @param {Object} context
 * @param {VueRouter} context.router
 * @param {Store} context.store
 * @param {Function} context.next
 */
export default async({ to, next, store, router }) => {
  if (![EMPLOYEE, NETWORK].includes(store.getters.organization.type)) {
    return router.replace({
      path: '/'
    });
  }

  return next();
};
