import MiddlewareError from '@/routing/middleware-error';

/**
 * Run shop middleware
 * @param {Object} context
 * @param {Route} context.to
 * @param {VueRouter} context.router
 * @param {Store} context.store
 * @param {Function} context.next
 */
export default async function({ to, next, router, store }) {
  try {
    await store.dispatch('signout');
    await store.dispatch('getPasswordTokenDetails', {
      token: to.params.token
    });
    return next();
  } catch (e) {
    if (e.hasOwnProperty('response')) {
      throw new MiddlewareError('Invalid token', '/signin');
    }
    return Promise.reject(e);
  }
}
