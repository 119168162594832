<template>
  <div class="campaign-navigation-header">
    <div class="tw-border-jb-grey-100 tw-px-layout-1 tw-flex tw-items-center tw-border-b tw-border-solid">
      <div class="tw-max-w-1/2 xs:tw-max-w-2/3 md:tw-max-w-1/5 md:tw-mr-layout-4 tw-flex">
        <app-text v-if="campaign.name" weight="semi-bold" class="tw-text-jb-ink tw-truncate" :title="campaign.name">
          {{ campaign.name }}
        </app-text>
        <skeleton-box v-else height="10" width="80" class="tw-mx-space-1 tw-my-auto" />
        <el-divider direction="vertical" class="tw-bg-jb-grey-100 tw-mt-space-1px xs:tw-hidden tw-h-5" />
      </div>
      <div v-if="!showDropDown" class="tw-flex tw-flex-col tw-h-10 tw-mx-auto tw-overflow-auto">
        <el-tabs
          ref="tabs"
          v-scrollable:x
          :value="activeTab"
          @tab-click="handleMenuItemClick">
          <el-tab-pane
            v-for="item in campaignMenu"
            :key="`option-${item.tab}`"
            :name="item.tab"
            class="campaign-details-tabs__list-item">
            <app-text slot="label" weight="semi-bold">
              {{ item.name }}
            </app-text>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div v-else class="tw-h-layout-4 tw-mr-space-1 tw-whitespace-nowrap tw-flex tw-justify-end tw-w-full tw-overflow-x-auto">
        <el-dropdown class="campaign-navigation-header__dropdown tw-self-center" trigger="click" @command="handleDropdownItemClick">
          <app-text weight="semi-bold" class="tw-text-jb-indigo tw-capitalize">
            {{ activeTabName }}<i class="el-icon-arrow-down el-icon--right tw-text-jb-grey-400" />
          </app-text>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item, index) in filteredCampaignMenu"
              :key="`item-${item.tab}`"
              class="hover:tw-bg-grey-hover"
              :command="{index: index, name: item.name, activeTab: item.tab}">
              <app-text>{{ item.name }}</app-text>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import AppText from '@/components/AppText';
import Breakpoints from '@/mixins/Breakpoints';
import { AFFILIATE, ADVERTISER } from '@/constants/orgTypes';
import SkeletonBox from '@/components/skeletons/SkeletonBox';

const MENU_ITEMS = [
  {
    name: 'Overview',
    tab: 'marketing'
  },
  {
    name: 'Creatives',
    tab: 'creatives'
  },
  {
    name: 'Approvals',
    tab: 'approvals'
  },
  {
    name: 'Funnels',
    tab: 'funnels',
    type: [AFFILIATE, ADVERTISER]
  },
  {
    name: 'Pixels',
    tab: 'pixels',
    type: [AFFILIATE]
  },
  {
    name: 'Reports',
    tab: 'reports',
    type: [AFFILIATE]
  },
  {
    name: 'Suppression',
    tab: 'suppression',
    type: [AFFILIATE]
  }
];

export default {
  name: 'CampaignNavigationHeader',
  components: { AppText, SkeletonBox },
  mixins: [Breakpoints([992])],
  data() {
    return {
      transition: '',
      activeTabIndex: 0
    };
  },
  computed: {
    activeTab() {
      return this.$route.meta.tab;
    },
    orgType() {
      return this.$store.getters.organization.type;
    },
    campaignId() {
      return this.$route.params.id;
    },
    campaign() {
      return this.$store.getters.campaign(this.campaignId) || {};
    },
    showDropDown() {
      return this.windowBreakpoint < 992;
    },
    activeTabName() {
      return this.campaignMenu.find((menuItem) => menuItem.tab === this.activeTab).name || '';
    },
    campaignMenu() {
      const approvalStatus = this.campaign.approval_status || 'approved';
      if (this.orgType === 'affiliate' && approvalStatus !== 'approved') return [];
      return MENU_ITEMS.slice(0).filter((menuItem) => !menuItem.type || menuItem.type.includes(this.orgType));

      // TODO: filter out tabs when BE sends resource-level permissions
      // const tabPermissionMap = { marketing: 'details' }; // map tab name to permission name
      // return MENU_ITEMS
      //   .slice(0)
      //   .filter((menuItem) => !menuItem.type || menuItem.type.includes(this.orgType));
      //   .filter((item) => {
      //     const permissionName = `CAMPAIGN_${tabPermissionMap[item.tab] ? tabPermissionMap[item.tab].toUpperCase() : item.tab.toUpperCase()}_VIEW`;
      //     // TODO: replace with campaign id after resource permission hookup
      //     if (this.$permissions[permissionName]) return this.$can(this.$permissions[permissionName], { __type: 'campaign', id: '1234' });
      //     return true;
      //   });
    },
    filteredCampaignMenu() {
      return this.showDropDown
        ? this.campaignMenu.filter((menuItem) => menuItem.tab !== this.activeTab)
        : this.campaignMenu;
    }
  },
  methods: {
    handleMenuItemClick({ name, index }) {
      this.emitOnTabChange({ name, index, activeTab: name });
    },
    handleDropdownItemClick({ name, index, activeTab }) {
      this.emitOnTabChange({ name, index, activeTab });
    },
    emitOnTabChange({ name, index, activeTab }) {
      const params = {};
      if (activeTab === 'reports') params.reportType = 'mtd';
      this.$emit('on-tab-change', { name, index, activeTab, params: params });
    }
  }
};
</script>
<style lang="scss">
.campaign-navigation-header {
  .el-tabs {
    &__header {
      @apply tw-m-0;
    }

    &__nav-wrap::after {
      @apply tw-bg-transparent;
    }

    &__active-bar {
      @apply tw-h-space-4px tw-bg-jb-indigo;
    }

    &__item {
      @apply tw-text-jb-grey-700 hover:tw-text-jb-ink tw-px-layout-1;

      &::before {
        content: '';

        @apply tw-w-0 tw-h-1/2 tw-absolute tw-top-1/4 tw-left-0 tw-border-0 tw-border-l tw-border-solid tw-border-jb-grey-100;
      }

      &.is-top,
      &.is-bottom {
        &:nth-child(2) {
          &::before {
            content: none;
          }
        }
      }

      &.is-active {
        @apply tw-text-jb-ink;
      }
    }
  }
}
</style>
