import _get from 'lodash/get';
import notificationHandler from './notifications';
import MiddlewareError from '@/routing/middleware-error';

/**
 * Force changing the organization for the user
 * @param orgId
 * @param store
 * @return {Promise<*>}
 */
const changeUserOrganization = async(orgId, store) => {
  let organization = store.getters.organizations.find(o => o.id === orgId);

  if (!organization) {
    organization = await store.dispatch('getOrganizations', { ids: [orgId] }).then(response => response.data.data[0]);
  }

  store.dispatch('selectOrganization', organization);
  return organization;
};

/**
 * Handle app redirects from external sources
 * @param {Object} context
 * @param {VueRouter} context.router
 * @param {Object} context.api
 * @param {Store} context.store
 * @param {Route} context.to
 * @param {Function} context.next
 * @return {Promise<*>}
 */
export default async(context) => {
  context.changeUserOrganization = changeUserOrganization;

  try {
    let redirectUrl = _get(context.to.query, 'to', '/');

    if (_get(context.to.query, 'notification', null)) {
      redirectUrl = await notificationHandler(context);
    }

    return Promise.resolve(redirectUrl);
  } catch (e) {
    if (e.hasOwnProperty('response')) {
      throw new MiddlewareError('Invalid redirect');
    }
    return Promise.reject(e);
  }
};
