const vhCheck = require('vh-check');

const MAX_FRAMES = 60;

export default function() {
  let vh = 0;
  const instance = vhCheck({
    redefineVh: true,
    onUpdate(updatedInstance) {
      let frameCount = 0;
      const recalc = currentInstance => {
        if (currentInstance.value === vh && frameCount++ < MAX_FRAMES) {
          return window.requestAnimationFrame(() => recalc(instance.recompute()));
        } else if (currentInstance.value !== vh && currentInstance.value > 0) {
          vh = currentInstance.value;
          document.documentElement.style.setProperty('--vh', vh + 'px');
        }
      };

      if (updatedInstance.isNeeded && updatedInstance.value === vh) { window.requestAnimationFrame(() => recalc(updatedInstance)); }
    }
  });
  vh = instance.value;
}
