<template>
  <div class="account-user-profile tw-mt-layout-3">
    <app-card :hover="false" class="tw-p-space-6 tw-mb-space-6 tw-bg-white tw-rounded">
      <app-text type="h5" weight="semi-bold" size="h5" color="headings">
        User Profile
      </app-text>
      <app-form ref="userProfileForm" :model="userProfileInput" :rules="rules" @submit.prevent.native="handleSubmit">
        <div class="account-form-container">
          <!-- Name -->
          <app-form-input
            prop="name"
            class="account-form__input"
            :value="userProfileForm.name.input"
            :label="userProfileForm.name.label"
            :placeholder="userProfileForm.name.placeholder"
            :error="userProfileForm.name.error"
            @input="userProfileForm.name.input = $event" />
          <!-- Last Name -->
          <app-form-input
            prop="lastName"
            class="account-form__input"
            :value="userProfileForm.lastName.input"
            :label="userProfileForm.lastName.label"
            :placeholder="userProfileForm.lastName.placeholder"
            :error="userProfileForm.lastName.error"
            @input="userProfileForm.lastName.input = $event" />
        </div>
        <div class="tw-border-jb-grey-100 md:tw-mt-0 lg:tw-mb-6 tw-mt-2 tw-mb-4 tw-border-b tw-border-solid" />
        <div class="account-form-container">
          <!-- Current Password -->
          <app-form-input
            toggle-password
            prop="currentPassword"
            class="account-form__input"
            :value="userProfileForm.currentPassword.input"
            :label="userProfileForm.currentPassword.label"
            :placeholder="userProfileForm.currentPassword.placeholder"
            :error="userProfileForm.currentPassword.error"
            @input="userProfileForm.currentPassword.input = $event" />
          <span class="tw-flex-1" />
        </div>
        <div class="account-form-container">
          <!-- New Password -->
          <app-form-input
            toggle-password
            prop="newPassword"
            class="account-form__input"
            :value="userProfileForm.newPassword.input"
            :label="userProfileForm.newPassword.label"
            :placeholder="userProfileForm.newPassword.placeholder"
            :error="userProfileForm.newPassword.error"
            @input="userProfileForm.newPassword.input = $event" />
          <!-- Confirm Password -->
          <app-form-input
            toggle-password
            prop="confirmNewPassword"
            class="account-form__input"
            :value="userProfileForm.confirmNewPassword.input"
            :label="userProfileForm.confirmNewPassword.label"
            :placeholder="userProfileForm.confirmNewPassword.placeholder"
            :error="userProfileForm.confirmNewPassword.error"
            @input="userProfileForm.confirmNewPassword.input = $event" />
        </div>
        <div class="tw-mt-space-4 md:tw-mt-space-2 lg:tw-mt-0 tw-flex tw-justify-end">
          <app-button
            type="submit"
            :loading="loading"
            class="tw-bg-jb-indigo hover:tw-bg-jb-indigo-dark xs:tw-w-auto xs:tw-mt-0 tw-w-full tw-font-medium"
            :class="{['!tw-bg-jb-indigo-disabled !tw-cursor-not-allowed']: !isSaveButtonEnabled }"
            :disabled="!isSaveButtonEnabled">
            <app-text size="sm" weight="medium" class="tw-text-white">
              Update User Profile
            </app-text>
          </app-button>
        </div>
      </app-form>
    </app-card>
  </div>
</template>

<script>
import AppCard from '@/components/AppCard';
import AppText from '@/components/AppText';
import AppForm from '@/components/AppForm.vue';
import AppFormInput from '@/components/AppFormInput.vue';
import AppButton from '@/components/AppButton.vue';

export default {
  name: 'AccountUserProfile',
  components: { AppCard, AppText, AppFormInput, AppForm, AppButton },
  data() {
    return {
      loading: false,
      userProfileForm: {
        name: {
          label: 'Name',
          placeholder: 'First Name',
          error: null,
          input: ''
        },
        lastName: {
          label: 'Last Name',
          placeholder: 'Last Name',
          error: null,
          input: ''
        },
        currentPassword: {
          label: 'Current Password',
          placeholder: 'Current Password',
          error: null,
          input: ''
        },
        newPassword: {
          label: 'New Password',
          placeholder: 'New Password',
          error: null,
          input: ''
        },
        confirmNewPassword: {
          label: 'Confirm New Password',
          placeholder: 'Confirm Password',
          error: null,
          input: ''
        }
      },
      rules: {
        name: [{ required: true, message: 'Please enter your first name.', trigger: 'blur' }],
        lastName: [{ required: true, message: 'Please enter your last name.', trigger: 'blur' }],
        newPassword: [
          {
            validator: async() => {
              const { currentPassword, newPassword } = this.userProfileInput;
              if (currentPassword.length) {
                if (newPassword.length < 6) {
                  throw new Error('New password must be at least 6 characters.');
                } else if (newPassword.length && newPassword === currentPassword) {
                  throw new Error('New password is the same as current password.');
                }
              }
            },
            trigger: 'blur'
          }],
        confirmNewPassword: [{
          validator: async() => {
            const { currentPassword, newPassword, confirmNewPassword } = this.userProfileInput;
            if (currentPassword.length && newPassword.length && newPassword !== confirmNewPassword) {
              throw new Error('Password does not match.');
            }
          },
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {
    userFirstName() {
      return this.$store.getters.user.first_name;
    },
    userLastName() {
      return this.$store.getters.user.last_name;
    },
    userProfileInput() {
      return Object.keys(this.userProfileForm).reduce((userInput, currentInput) => {
        userInput[currentInput] = this.userProfileForm[currentInput].input;
        return userInput;
      }, {});
    },
    hasErrors() {
      return Object.values(this.userProfileForm).some((inputField) => inputField.error);
    },
    isSaveButtonEnabled() {
      const { name, lastName, currentPassword, newPassword, confirmNewPassword } = this.userProfileInput;
      const nameChanged = name !== this.userFirstName || lastName !== this.userLastName;
      const passwordChanged = currentPassword && newPassword && confirmNewPassword;
      return nameChanged || passwordChanged;
    },
    profilePayload() {
      const payload = {};
      if (this.userProfileInput.name) payload.first_name = this.userProfileInput.name;
      if (this.userProfileInput.lastName) payload.last_name = this.userProfileInput.lastName;
      if (this.userProfileInput.currentPassword && this.userProfileInput.newPassword) {
        payload.current_password = this.userProfileInput.currentPassword;
        payload.new_password = this.userProfileInput.newPassword;
      }
      return payload;
    }
  },
  mounted() {
    this.userProfileForm.name.input = this.userFirstName;
    this.userProfileForm.lastName.input = this.userLastName;
  },
  methods: {
    clearAllInputErrors() {
      Object.keys(this.userProfileForm).forEach((key) => {
        this.userProfileForm[key].error = null;
      });
    },
    // eslint-disable-next-line complexity,sonarjs/cognitive-complexity
    async handleSubmit() {
      try {
        this.loading = true;
        this.clearAllInputErrors();
        await this.$refs.userProfileForm.$refs.form.validate();
        const res = await this.$api.account.updateProfile({ params: this.profilePayload });

        // persist updated user fields to store
        if (res.user && res.user.session_id) this.$store.commit('SET_USER_SESSION_ID', res.user.session_id);
        if (this.profilePayload.first_name) this.$store.commit('SET_USER_FIRST_NAME', this.profilePayload.first_name);
        if (this.profilePayload.last_name) this.$store.commit('SET_USER_LAST_NAME', this.profilePayload.last_name);

        this.$message({
          type: 'success',
          message: this.profilePayload.hasOwnProperty('new_password')
            ? 'Your password has been updated.'
            : 'Your profile has been updated.'
        });

        // clear passwords fields
        this.userProfileForm.currentPassword.input = '';
        this.userProfileForm.newPassword.input = '';
        this.userProfileForm.confirmNewPassword.input = '';
      } catch (error) {
        if (this.$api.isCancel(error)) return;
        if (this.$api.hasErrors(error)) {
          const invalidPassError = error.response.data._meta.errors.find((message) => message.search(/password/gi) !== -1);
          if (invalidPassError) this.userProfileForm.currentPassword.error = invalidPassError;
        }
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss">
  .account {
    &-form {
      &-container {
        @apply tw-flex tw-flex-wrap sm:tw-space-x-20;
      }

      /* !important overrides el-input's margin-bottom: 12px */
      &__input {
        @apply tw-mb-2 tw-w-full sm:tw-flex-1 md:tw-mb-4 lg:tw-mb-6 #{!important};
      }
    }
  }
</style>
