<template>
  <div :style="{height: iframeHeight}" class="app-iframe">
    <app-loader
      v-if="!loaded"
      :loading="!loaded"
      :loaders="[{ text: loadingText }]" />
    <iframe
      v-show="loaded"
      ref="iframe"
      :src="src"
      scrolling="no"
      @load="handleLoad"
      @error="handleLoad" />
  </div>
</template>

<script>
import AppLoader from '@/components/AppLoader';

export default {
  name: 'AppIframe',
  components: { AppLoader },
  props: {
    loadingText: {
      type: String,
      required: true
    },
    src: {
      type: String,
      required: true
    },
    loadDelay: {
      type: Number,
      default: 0
    },
    iframeHeight: {
      type: String,
      default: 'auto'
    }
  },
  data() {
    return {
      loaded: false
    };
  },
  methods: {
    handleLoad() {
      setTimeout(() => {
        this.loaded = true;
        this.$emit('load');
      }, this.loadDelay);
    }
  }
};
</script>
<style lang="scss">
.app-iframe {
  position: relative;
  min-height: calc(100vh - #{$--clb-app-header-height * 3});
  width: 100%;

  .app-loader {
    border-radius: 0 0 $--clb-border-radius $--clb-border-radius;
  }

  iframe {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 0 0 $--clb-border-radius $--clb-border-radius;
  }
}
</style>
