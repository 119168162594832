import _get from 'lodash/get';
import dayjs from 'dayjs';
import _uniqBy from 'lodash/uniqBy';
export default {
  state: {
    notifications: []
  },
  getters: {
    notifications(state) {
      return state.notifications;
    }
  },
  actions: {
    fetchNotificationsSuccess({ commit }, { response, payload }) {
      commit('SET_NOTIFICATIONS', { notifications: _get(response, 'data.data', []), resetData: payload.params.resetData });
      return Promise.resolve(_get(response, 'data', {}));
    },
    setNotificationSeen({ commit }, { id }) {
      commit('SET_NOTIFICATION_SEEN', id);
      return Promise.resolve();
    },
    setNotificationsSeen({ commit }) {
      commit('SET_NOTIFICATIONS_SEEN');
      return Promise.resolve();
    }
  },
  mutations: {
    SET_NOTIFICATIONS(state, { notifications, resetData }) {
      if (resetData) state.notifications = [...notifications];
      else state.notifications = _uniqBy([...state.notifications, ...notifications], 'id');
    },
    SET_NOTIFICATION_SEEN(state, id) {
      const index = state.notifications.findIndex(n => n.id === id);
      if (index !== false) {
        state.notifications[index].seen = dayjs().format('YYYY-MM-DD HH:mm:ss');
        this._vm.$set(state.notifications, index, state.notifications[index]);
      }
    },
    SET_NOTIFICATIONS_SEEN(state) {
      state.notifications.forEach(n => {
        n.seen = dayjs().format('YYYY-MM-DD HH:mm:ss');
      });
    }
  }
};
