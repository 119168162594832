<template>
  <div :class="size" class="app-country-icon tw-flex">
    <div
      v-for="(country, index) in normalizedCountries"
      :key="`country-icon-${index}`"
      class="wrapper">
      <span
        :class="[{globe: ['00', '01'].includes(country)}, `el-flagged-label__icon--${country}`]"
        class="el-flagged-label__icon" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppCountryIcon',
  props: {
    countries: {
      type: Array,
      required: false,
      default: () => []
    },
    limit: {
      type: Number,
      default: 3
    },
    size: {
      type: String,
      default: ''
    }
  },
  computed: {
    normalizedCountries() {
      return (this.countries || []).map(c => c.toLowerCase()).slice(0, this.limit);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@jumbleberry/el-tel-input/dist/elTelInput.css';

.app-country-icon {
  .wrapper {
    .el-flagged-label__icon {
      height: 26px;
    }

    /* fixes bug in safari: https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b */
    -webkit-mask-image: -webkit-radial-gradient(white, black);

    .globe {
      background: url('~@/assets/images/icons/globe-icon.png');
      background-size: 70%;
      height: 22px;

      @apply tw-w-layout-3 tw-mt-space-1 tw-bg-center tw-bg-no-repeat;
    }
  }
}
</style>
