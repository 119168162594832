export default {
  /**
   * Prepare the question answer to be sent to the api
   * @param {Object} question
   * @param {*} answer
   * @return {Object}
   */
  prepareApiPayload(question, answer) {
    return { question: question.key, answer: answer };
  },
  /**
   * Prepare the question answer to be used on the front end
   * @param {Object} question
   * @return {*}
   */
  getAnswer(question) {
    return question.answer === null ? '' : question.answer;
  },
  /**
   * Get the question answer summary
   * @param {Object} question
   * @param {Object} options
   * @return {*}
   */
  getAnswerSummary(question, options) {
    return question.answer;
  }
};
