import queryStringOptions from '@/services/query-string-options';
import _get from 'lodash/get';

/**
 * Sync the query string params with the vuex state
 * @param next
 * @param to
 * @param store
 * @param router
 * @return {Promise<*>}
 */
export default async({ next, to, store, router }) => {
  const reportType = to.params.reportType;
  const reportSubType = to.params.reportSubType || _get(to, 'meta.reportSubType');

  const options = queryStringOptions.parse(to.query);

  store.dispatch('setAffiliateReportOptions', { reportType, reportSubType, options });
  return next();
};
