const money = (value, preserveDecimals = true, prefix = '$', postfix = '') => {
  if (!isNaN(value)) {
    const decimalPlaces = preserveDecimals && (value + '').split('.').length > 1 ? 2 : 0;
    value = parseFloat(value).toFixed(decimalPlaces).replace(/\d(?=(\d{3})+(?:\.|$))/g, '$&,');
    return `${prefix}${value}${postfix}`;
  }
  return value;
};

export default money
;
