/**
 * Get options params for campaign metrics api requests
 * @param options
 * @return {Object}
 */
const reportOptions = function(options = {}) {
  const defaultOptions = {
    perPage: undefined,
    dateRange: 'CM',
    sortBy: 'sales',
    order: 'desc'
  };
  return {
    ...defaultOptions,
    ...options
  };
};

/**
 * @param {ApiContext} context
 * @return {object}
 */
export default ({ fetch, store }) => {
  return {
    async all(options) {
      return fetch(`/v2/org/${options.orgId}/campaigns`, options);
    },
    async single(options) {
      return fetch(`/v2/org/${options.orgId}/campaign/${options.campaignId}/details`, options);
    },
    async assets(options) {
      return fetch(`/v2/org/${options.orgId}/campaign/${options.campaignId}/assets`, options);
    },
    async inspirations(options) {
      return fetch(`/v2/org/${options.orgId}/campaign/${options.campaignId}/inspirations`, options);
    },
    async launches(options) {
      return fetch(`/v2/org/${options.orgId}/launches`, options);
    },
    async promotions(options) {
      return fetch(`/v2/org/${options.orgId}/promotions`, options);
    },
    async winners(options) {
      return fetch(`/v2/org/${options.orgId}/winners`, options);
    },
    async recommendations(options) {
      return fetch(`/v2/org/${options.orgId}/recommendations`, options);
    },
    async reporting(options) {
      return fetch(`/v2/org/${options.orgId}/campaigns/reporting`, {
        ...options,
        params: reportOptions(options.params)
      });
    }
  };
};
