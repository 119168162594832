<template>
  <div class="app-input tw-relative">
    <el-input
      ref="input"
      class="app-input"
      :size="size"
      :type="inputType"
      v-bind="$attrs"
      v-on="$listeners">
      <template #default>
        <slot />
      </template>

      <template #prefix>
        <slot name="prefix">
          <slot name="prefixIcon">
            <i v-if="prefixIcon" :class="prefixIcon" class="tw-text-jb-grey-400 tw-text-base" />
          </slot>
        </slot>
      </template>

      <template #suffix>
        <slot name="suffix">
          <slot v-if="showCancelIcon" name="cancelIcon">
            <cancel-icon class="tw-w-space-4 tw-h-space-4 tw-text-jb-grey-400" @mousedown.prevent @click="handleClearInput" />
          </slot>
          <slot v-else-if="isPasswordInput" name="passwordIcon">
            <component
              :is="passwordIcon"
              class="tw-w-space-4 tw-h-space-4 tw-text-jb-grey-400 hover:tw-cursor-pointer"
              @mousedown.prevent
              @click="toggleShowPassword" />
          </slot>
          <slot v-else name="suffixIcon">
            <i v-if="suffixIcon" :class="suffixIcon" class="tw-text-jb-grey-400 tw-text-base" />
          </slot>
        </slot>
      </template>

      <template #prepend>
        <slot name="prepend" />
      </template>

      <template #append>
        <slot name="append" />
      </template>
    </el-input>
  </div>
</template>

<script>
import CancelIcon from '@/assets/svg/cancel-icon.svg';
import ShowPasswordIcon from '@/assets/svg/show-icon.svg';
import HidePasswordIcon from '@/assets/svg/hide-icon.svg';

export default {
  name: 'AppInput',
  components: { CancelIcon, ShowPasswordIcon, HidePasswordIcon },
  props: {
    prefixIcon: {
      type: String,
      default: null
    },
    suffixIcon: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      showPassword: false
    };
  },
  computed: {
    inputType() {
      if (this.$attrs.hasOwnProperty('type')) return this.$attrs.type;
      if (this.isPasswordInput) return this.showPassword ? 'text' : 'password';
      return 'text';
    },
    showCancelIcon() {
      return this.$attrs.hasOwnProperty('clearable') && this.$attrs.clearable !== false &&
        this.$refs.input && this.$refs.input.focused && this.$attrs.value;
    },
    isPasswordInput() {
      return this.$attrs.hasOwnProperty('toggle-password');
    },
    passwordIcon() {
      if (this.isPasswordInput) {
        return this.showPassword ? HidePasswordIcon : ShowPasswordIcon;
      }
      return null;
    },
    size() {
      return this.$attrs.size || 'mini';
    }
  },
  methods: {
    handleClearInput() {
      this.$emit('input', '');
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    }
  }
};
</script>

<style lang="scss">
.app-input {
  @include el-input;
}
</style>
