import _get from 'lodash/get';
export default {
  /**
   * Prepare the question answer to be used on the front end
   * @param {Object} question
   * @return {*}
   */
  getAnswer(question) {
    return question.answer === null ? [] : question.answer;
  },
  /**
   * Get the question answer summary
   * @param {Object} question
   * @return {*}
   */
  getAnswerSummary(question) {
    if (Array.isArray(question.answer)) {
      if (!question.answer.length) {
        return `<ul><li>${_get(question, 'none_option', 'None')}</li></ul>`;
      }
      return `<ul>
      ${question.answer.map(o => `<li>${o}</li>`).join('')}
    </ul>`;
    }
    return '<ul></ul>';
  }
};
