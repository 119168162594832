/**
 * Check that the survey is completed before enter the summary
 * @param {Object} context
 * @param {Route} context.router
 * @param {Store} context.store
 * @param {Function} context.next
 */
export default async function({ store, router, next }) {
  const nextQuestionKey = store.getters.firstQuestionKey;
  if (nextQuestionKey !== null) {
    return router.replace(`/signup/survey/${nextQuestionKey}`);
  }
  return next();
}
