<template>
  <div class="app-profile-picture">
    <img v-if="imageUrl !== undefined" :src="imageUrl" class="app-profile-picture__image">
    <el-avatar>{{ initials }}</el-avatar>
  </div>
</template>

<script>
export default {
  name: 'AppProfilePicture',
  props: {
    user: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    },
    fullName: {
      type: String,
      required: false,
      default: ''
    },
    imageUrl: {
      type: String,
      default: undefined
    }
  },
  computed: {
    hasUser() {
      return Object.keys(this.user).length > 0;
    },
    initials() {
      if (this.hasUser) {
        return `${(this.user.first_name || '').charAt(0)}${(this.user.last_name || '').charAt(0)}`;
      }
      const [firstName, lastName] = this.fullName ? this.fullName.match(/(\S+)/g) : ['', ''];
      return `${(firstName || '').charAt(0)}${(lastName || '').charAt(0)}`.trim().toUpperCase();
    }
  }
};
</script>

<style lang="scss" scoped>
.app-profile-picture {
  width: 36px;
  min-width: 36px;
  height: 36px;
  min-height: 36px;
  border-radius: 50%;
  background-color: $--clb-color-accent__light;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .app-profile-picture__image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #fff;
    width: 50px;
    height: 40px;
  }

  &__prepend-icon {
    position: absolute;
    left: -20px;
  }

  .el-avatar {
    background-color: transparent;
    line-height: 42px;
  }
}
</style>
