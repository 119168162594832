export default function(VueInstance) {
  VueInstance.directive('keyboard-action', {
    bind(el, { value, arg }, vnode) {
      const eventType = arg || 'click';

      el.__keyboard_handler = (event) => {
        if (event.key !== 'Enter' && event.key !== ' ') return;

        if (event.target === el || (el.contains && el.contains(event.target))) {
          event.stopPropagation();
          event.preventDefault();

          event.target[eventType]
            ? event.target[eventType]()
            : event.target.dispatchEvent(new Event(eventType));
          value && value(event);
        }
      };

      window.addEventListener('keydown', el.__keyboard_handler);
    },
    unbind(el) {
      window.removeEventListener('keydown', el.__keyboard_handler);
    }
  });
};
