<template>
  <div class="app-notification">
    <header class="app-notification__header">
      Notifications
    </header>
    <app-search-list-v2
      group-by="group"
      :enable-search="false"
      :loading="fetchingNotifications"
      :entries="notifications"
      :show-content="visible"
      :group-by-headers="{ 'new': 'Recent', 'old': 'Earlier' }"
      @load-more="handleLoadMore">
      <notification-list-item
        :key="entry.id"
        slot-scope="{ entry }"
        :notification="entry"
        @notification-click="$emit('notification-click', $event)"
        @mark-read="setNotificationRead" />
      <template v-slot:inner-footer>
        <div class="scroll-to-load">
          <div v-if="notifications.length && loading">
            <i class="el-icon-loading" /> Loading
          </div>
          <div v-else-if="isLastPage">
            You're all caught up!
          </div>
          <div v-else>
            Scroll to load more notifications
          </div>
        </div>
      </template>
      <template v-slot:no-content>
        <div key="no-content" class="app-search-list-v2__entries-group">
          <div class="app-search-list-v2__entry disabled">
            You don’t have any notifications yet
          </div>
        </div>
      </template>
    </app-search-list-v2>
    <footer class="app-notification__footer">
      <template v-if="!hasNotificationPermission">
        <a @click="askPermission">Enable Push Notifications</a>
        <template v-if="notifications.length">
          ·
        </template>
      </template>
      <a v-if="notifications.length" @click="setNotificationsRead">Mark All As Read</a>
    </footer>
  </div>
</template>

<script>
import AppSearchListV2 from '@/components/AppSearchListV2';
import dayjs from 'dayjs';
import NotificationListItem from '@/components/notifications/NotificationListItem';
import OneSignalService from '@/services/one-signal';

export default {
  name: 'AppNotification',
  components: { NotificationListItem, AppSearchListV2 },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    organization: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      currentPage: 1,
      totalPages: 1
    };
  },
  computed: {
    hasNotificationPermission() {
      return this.$store.getters.hasNotificationPermission;
    },
    notifications() {
      const recent = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);

      // Make a copy of the response so marking them as read wont affect the UI
      return JSON.parse(JSON.stringify(this.$store.getters.notifications)).map(n => {
        n.group = dayjs(n.created).toDate() < recent ? 'old' : 'new';
        return n;
      });
    },
    fetchingNotifications() {
      return this.loading;
    },
    isLastPage() {
      return this.currentPage === this.totalPages;
    }
  },
  watch: {
    notifications(notifications) {
      this.$emit('notifications-update', { unseen: notifications.filter(n => !n.seen).length });
    }
  },
  created() {
    this.fetchNotifications({ page: 1, resetData: true });
  },
  methods: {
    handleLoadMore() {
      if (!this.fetchingNotifications && !this.isLastPage) {
        this.fetchNotifications({ page: this.currentPage + 1 });
      }
    },
    async fetchNotifications(data = { page: 1, resetData: false }) {
      try {
        this.loading = true;
        const response = await this.$api.notifications.all({
          orgId: this.organization.id,
          params: { page: data.page, perPage: 25, resetData: data.resetData },
          storeAction: 'fetchNotifications'
        });
        this.currentPage = response._meta.page;
        this.totalPages = response._meta.page_count;
      } finally {
        this.loading = false;
      }
    },
    setNotificationRead({ notification }) {
      this.$api.notifications.setNotificationSeen(notification);
      this.$store.dispatch('setNotificationSeen', notification);
    },
    setNotificationsRead() {
      this.$api.notifications.setNotificationsSeen({ orgId: this.organization.id });
      this.$store.dispatch('setNotificationsSeen');
    },
    askPermission() {
      OneSignalService.askPermission();
    }
  }
};
</script>

<style lang="scss">
.app-notification {
  border-radius: $--clb-border-radius;
  background: $--clb-color-primary__white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.12);
  width: 550px;
  max-width: 550px;

  &__title {
    background: $--clb-color-secondary__light;
    padding: $--clb-space-2;
    margin: 0;
    border-radius: $--clb-space-1;
  }

  .notification-list-item {
    border-bottom: 1px solid $--clb-border-color-base;
  }

  .app-search-list__wrapper {
    max-height: 500px !important;
  }

  .app-search-list-v2 {
    &__entries-fixed-header,
    &__entries-group-header {
      line-height: $--clb-layout-2;
      margin: 0;
      padding: 0 $--clb-space-3;
      background-color: $--clb-color-grey__white-ter;
      color: $--clb-body-font;
      font-size: $--clb-font-size-xs;
      font-weight: 500;
      border-bottom: 1px solid $--clb-border-color-base;
    }
  }

  &__header,
  &__footer {
    background-color: $--clb-color-accent__dark;
    line-height: $--clb-layout-3;
    text-align: right;
    padding: 0 $--clb-space-3;
    color: $--clb-skeleton-color;

    a {
      color: $--clb-skeleton-color !important;
      padding: 0;
      font-size: $--clb-font-size-xs;
      font-weight: 500;
      text-align: right;
      text-decoration: none;
    }

    a:hover {
      color: $--clb-color-primary__white !important;
      text-decoration: none;
    }
  }

  &__header {
    background-color: $--clb-color-primary;
    text-align: left;
    font-weight: 700;
    font-size: $--clb-font-size-base;
    padding: $--clb-space-2 $--clb-space-3;
  }

  &__footer {
    height: $--clb-layout-3 + 1px;
  }

  .scroll-to-load {
    background-color: theme('colors.bg-accent-color');

    @apply tw-h-layout-5 tw-flex tw-justify-center tw-items-center;
  }

  @media (max-width: $--sm) {
    width: calc(100vw - 32px);
  }

  @media (max-height: $--sm) {
    .app-search-list__wrapper {
      max-height: calc(100vh - #{$--clb-app-header-height + 100px}) !important;
      max-height: calc(100 * var(--vh, 1vh) - #{$--clb-app-header-height + 100px}) !important;
    }
  }
}
</style>
