<template>
  <div class="interstitial-layout">
    <router-view class="interstitial-layout__content" />
    <Morph />
  </div>
</template>
<script>
import Morph from '@/components/Morph';

export default {
  name: 'InterstitialLayout',
  components: {
    Morph
  }
};
</script>

<style lang="scss" scoped>
.interstitial-layout {
  display: flex;
  min-height: 100vh;
  min-height: calc(100 * var(--vh, 1vh));

  &__content {
    display: block;
    max-width: 100vw;
    padding: 0 $--clb-mobile-padding;
    width: 100%;
    min-width: 310px;
    position: relative;
    grid-area: content;
    margin-right: auto;
    margin-left: auto;
  }

  @media (min-width: $--sm) {
    &__content {
      width: 550px;
      max-width: none;
    }
  }
}
</style>
