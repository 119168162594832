export const ALLOWED_STATUSES = ['pending', 'active', 'expired'];
export const STAT_LABELS = {
  total_credits: 'Accrued Commission',
  pending_credits: 'Pending Commission',
  approved_referee: 'Active Referrals',
  pending_referee: 'Pending Referrals'
};
export const STATUS_PRIORITIES = {
  pending: 1,
  active: 2,
  expired: 3
};

export const FAQ_DATA = [
  {
    title: 'Who can I refer?',
    body: 'Any person or entity who has not already signed up on Jumbleberry with Carousel or Pipeline can be referred.',
    name: '1'
  },
  {
    title: 'How do I earn commission through referrals?',
    body: 'Once your referral has successfully signed up with Jumbleberry you will be eligible to earn 1% from every sale they make for the next year, up to a maximum of $10,000 USD.',
    name: '2'
  },
  {
    title: 'How much money can I make?',
    body: 'You are eligible to earn a maximum of $10,000 USD per referral.',
    name: '3'
  },
  {
    title: 'What is my timeframe to receive commission?',
    body: 'You may receive commission for one year from the signup date of each referral. Find the start and end dates of each referral commission period on your referral management table.',
    name: '4'
  },
  {
    title: 'Where can I see the commission generated per referral?',
    body: 'Easily view the commission you have accrued per referral in the management table. You may earn a maximum of $10,000 USD for every referral!',
    name: '5'

  },
  {
    title: 'What happens when the commission timeframe expires?',
    body: 'When your commission period ends for a referral you will no longer be able to collect commission from their activity and they will be displayed as expired in the management table.',
    name: '6'
  },
  {
    title: 'How do I know if my referral has signed up?',
    body: 'As soon as they click on your referral link and have signed up they will appear in your referral management table.',
    name: '7'
  }
];
