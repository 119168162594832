<template>
  <div class="account-contact-info tw-mt-layout-3">
    <app-card :hover="false" class="tw-p-space-6 tw-mb-space-6 tw-bg-white tw-rounded">
      <app-text type="h5" weight="semi-bold" size="h5" color="headings">
        Contact Information
      </app-text>
      <app-text type="p" weight="medium" size="sm" class="tw-text-jb-grey-700 md:tw-mb-6 tw-mb-4">
        Contact information requires verification. Your update will save after it has been verified.
      </app-text>
      <div class="account-form-container">
        <app-form-input
          ref="emailForm"
          v-model="email.input"
          inline
          prop="email"
          class="account-form__input account-form__suffix-button"
          :label="email.label"
          :error="email.error"
          :rules="email.validation"
          :placeholder="email.placeholder"
          @submit.native="handleEmailSubmit">
          <template #input="{props, listeners}">
            <app-input v-bind="props" v-on="listeners">
              <app-button
                slot="suffix"
                :class="{['!tw-bg-jb-indigo-disabled !tw-cursor-not-allowed']: !email.isValid }"
                class="tw-bg-jb-indigo hover:tw-bg-jb-indigo-dark xs:tw-w-auto xs:tw-mt-0 tw-w-full tw-text-sm tw-font-medium tw-text-white"
                weight="medium"
                :disabled="!email.isValid"
                :loading="email.loading"
                type="submit">
                Verify
              </app-button>
            </app-input>
          </template>
        </app-form-input>
        <app-form-input
          ref="phoneNumber"
          inline
          prop="phoneNumber"
          class="account-form__input account-form__suffix-button"
          :label="phoneNumber.label"
          :error="phoneNumber.error"
          :rules="phoneNumber.validation"
          @submit.native="sendVerificationCode">
          <app-phone-input slot="input" :phone-number-details="phoneNumber.details" @input-details="handleElTelInput">
            <app-button
              slot="suffix"
              class="tw-bg-jb-indigo hover:tw-bg-jb-indigo-dark xs:tw-w-auto xs:tw-mt-0 tw-w-full tw-text-sm tw-font-medium tw-text-white"
              weight="medium"
              :class="{['!tw-bg-jb-indigo-disabled !tw-cursor-not-allowed']: !phoneNumber.details.isValid }"
              :loading="phoneNumber.isLoadingSendCode"
              :disabled="!phoneNumber.details.isValid"
              type="submit">
              Verify
            </app-button>
          </app-phone-input>
        </app-form-input>
      </div>
    </app-card>
    <phone-verify-modal
      v-if="showVerifyModal"
      :visible.sync="showVerifyModal"
      :confirm-sent="confirmSendAgain"
      :loading-send="phoneNumber.isLoadingSendCode"
      :loading-verify="phoneNumber.isLoadingVerifyCode"
      @cancel="toggleVerifyModal"
      @send-again="sendVerificationCode"
      @submit-code="handleVerificationCode" />
  </div>
</template>

<script>
import AppCard from '@/components/AppCard';
import AppText from '@/components/AppText';
import AppFormInput from '@/components/AppFormInput.vue';
import AppButton from '@/components/AppButton.vue';
import AppInput from '@/components/AppInput.vue';
import PhoneVerifyModal from '@/components/account-settings/PhoneVerifyModal.vue';
import AppPhoneInput from '@/components/AppPhoneInput.vue';

export default {
  name: 'AccountContactInfo',
  components: {
    AppCard,
    AppText,
    AppFormInput,
    AppButton,
    AppInput,
    PhoneVerifyModal,
    AppPhoneInput
  },
  data() {
    return {
      confirmSendAgain: false,
      showVerifyModal: false,
      email: {
        isValid: false,
        loading: false,
        input: '',
        error: null,
        label: 'Email',
        placeholder: 'Contact Email',
        validation: [
          { required: true, message: 'Please input an email address', trigger: ['change', 'blur'] },
          { type: 'email', message: 'Please enter a valid email address', trigger: ['change', 'blur'] }
        ]
      },
      phoneNumber: {
        isLoadingSendCode: false,
        isLoadingVerifyCode: false,
        error: null,
        label: 'Phone Number',
        placeholder: 'Contact Phone Number',
        details: {
          countryCallingCode: '',
          country: '',
          nationalNumber: '',
          number: '',
          isValid: false
        }
      }
    };
  },
  computed: {
    userEmail() {
      return this.$store.getters.user.email;
    },
    userPhoneNumber() {
      return this.$store.getters.user.phone_number;
    },
    userCountryCode() {
      return this.$store.getters.user.country_code;
    }
  },
  watch: {
    'email.input': {
      deep: true,
      handler() {
        this.email.isValid = this.$refs.emailForm.$refs.formItem.validateState === 'success';
      }
    }
  },
  mounted() {
    this.email.input = this.userEmail;
    this.phoneNumber.details.nationalNumber = this.userPhoneNumber;
    this.phoneNumber.details.countryCallingCode = this.userCountryCode;
  },
  methods: {
    toggleVerifyModal() {
      this.showVerifyModal = !this.showVerifyModal;
    },
    handleElTelInput(value) {
      this.phoneNumber.details = value;
    },
    async handleEmailSubmit() {
      try {
        this.email.loading = true;
        await this.$refs.emailForm.$refs.formItem.validate();
        await this.$api.account.updateEmail({ params: { email: this.email.input } });
        // POST
        this.$message({
          message: `An email has been sent to ${this.email.input}. Please click the link in the email to verify and update the address`,
          type: 'success'
        });
      } catch (error) {
        if (error.message) this.email.error = error.message;
      } finally {
        this.email.loading = false;
      }
    },
    async sendVerificationCode() {
      try {
        this.phoneNumber.isLoadingSendCode = true;
        await this.$api.account.updatePhoneNumber({
          params: {
            phone: this.phoneNumber.details.nationalNumber,
            country_code: this.phoneNumber.details.countryCallingCode
          }
        });
        if (this.showVerifyModal) {
          this.confirmSendAgain = true;
          setTimeout(() => {
            this.confirmSendAgain = false;
          }, 2000);
        } else {
          this.toggleVerifyModal();
        }
      } catch (error) {
        if (error.message) this.phoneNumber.error = error.message;
      } finally {
        this.phoneNumber.isLoadingSendCode = false;
      }
    },
    async handleVerificationCode(verificationCode) {
      try {
        this.phoneNumber.isLoadingVerifyCode = true;
        await this.$api.account.verifyPhoneNumber({
          params: {
            phone_verification_code: verificationCode
          }
        });
        this.$message({
          message: 'Your phone number has been updated!',
          type: 'success'
        });
        this.toggleVerifyModal();
      } catch (error) {
        if (error.message) this.phoneNumber.error = error.message;
      } finally {
        this.phoneNumber.isLoadingVerifyCode = false;
      }
    }
  }
};
</script>

<style lang="scss">
  .account-form__suffix-button {
    .el-input--suffix {
      @apply tw-flex;

      .el-input__suffix {
        @apply tw-right-0;

        .el-input__suffix-inner {
          @apply tw-h-full;

          .app-button {
            @apply tw-h-full;
          }
        }
      }
    }
  }
</style>
