import referrals from '@/services/referrals';

/**
 * Run referral code middleware
 * @param {Object} context
 * @param {Route} context.to
 * @param {VueRouter} context.router
 * @param {Store} context.store
 * @param {Function} context.next
 */
export default async({ next, store, to, router }) => {
  if (store.getters.isAuthenticated) {
    await store.dispatch('signout');
  }

  const referralCode = referrals.getCodeFromPath(to.path);

  if (referrals.isValidReferralCode(referralCode)) {
    store.dispatch('setReferralCode', referralCode);
  }

  return router.replace('/signup');
};
