export default ({ fetch, storeHandler }) => {
  return {
    fetchPermissions(options = {}) {
      return fetch({ url: `/v2/org/${options.orgId}/permissions`, options });
    },
    fetchPartnerships(options = {}) {
      const orgId = storeHandler.get('organizationId');
      return fetch({ url: `/v2/org/${orgId}/partnerships`, options });
    },
    fetchPartnershipDetails({ partnershipId, ...options } = {}) {
      const orgId = storeHandler.get('organizationId');
      return fetch({ url: `/v2/org/${orgId}/partnership/${partnershipId}`, options });
    },
    updatePartnershipUsers({ partnershipId, ...options } = {}) {
      const orgId = storeHandler.get('organizationId');
      return fetch({ url: `/v2/org/${orgId}/partnership/${partnershipId}/users`, method: 'PUT', options });
    }
  };
};
