<template>
  <div v-if="user" :class="{ 'main-layout--no-header': !showHeader }" class="main-layout">
    <div v-if="!isMobileWidth && showHeader" class="main-layout__header">
      <app-header
        :key="organizationId"
        :user="user"
        :organization="organization"
        @logo-click="handleLogoClick" />
    </div>
    <sidebar-layout
      ref="sidebarLayout"
      :key="organizationId"
      class="main-layout__wrapper"
      @overlay-click="toggleSidebar"
      @sidebar-change="sidebarChange">
      <sidebar-layout-sidebar
        v-if="showHeader"
        slot="left"
        :width="leftSidebarWidth"
        :parallax="64"
        :fixed-width="992"
        :drawer="false"
        :z-index="1990"
        class="main-layout__left-sidebar tw-w-full tw-max-h-full">
        <transition-group name="el-fade-in" tag="div" class="app-left-sidebar-extension tw-h-full tw-max-h-full">
          <portal-target ref="app-left-sidebar-extension" key="app-left-sidebar-extension" name="app-left-sidebar-extension" slim>
            <app-global-sidebar key="app-global-sidebar" />
          </portal-target>
        </transition-group>
      </sidebar-layout-sidebar>
      <div v-if="isMobileWidth && showHeader" slot="header" class="main-layout__header">
        <app-header
          :user="user"
          :organization="organization"
          @logo-click="handleLogoClick" />
      </div>
      <template slot="content">
        <div class="main-layout__content" :class="{ fullWidth }">
          <div class="tw-z-1 tw-fixed tw-w-full tw-bg-white" :style="{'padding-right': !isMobileWidth ? leftSidebarWidth + 'px' : '0px'}">
            <app-banner />
          </div>
          <div :class="{ 'main-layout__content-body-underlap': underlapFooter, 'main-layout__content-body-full': fullContentWidth }" class="main-layout__content-body">
            <router-view :key="organizationId" />
          </div>
        </div>
      </template>
      <sidebar-layout-sidebar
        v-if="showHeader"
        slot="right"
        :width="rightSidebarWidth"
        :overlay="isMobileWidth"
        :drawer="false"
        :z-index="1990"
        class="main-layout__right-sidebar tw-border-jb-grey-50 md:tw-border-t md:tw-border-l tw-relative tw-w-full tw-max-h-full tw-border-solid">
        <transition-group name="slide-left" tag="div" class="app-right-sidebar-extension tw-h-full tw-max-h-full">
          <portal-target ref="app-right-sidebar-extension" key="app-right-sidebar-extension" name="app-right-sidebar-extension" class="tw-h-full tw-max-h-full" :transition="portalTransition" />
        </transition-group>
      </sidebar-layout-sidebar>
    </sidebar-layout>
    <app-right-sidebar class="main-layout__right-sidebar" />
    <survey-form v-if="surveyId && npsSurveyVisible" :survey-id="surveyId" />
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader';
import AppBanner from '@/components/AppBanner';
import AppGlobalSidebar from '@/components/AppGlobalSidebar';
import SidebarLayout from '@/layouts/SidebarLayout';
import SidebarLayoutSidebar from '@/layouts/SidebarLayoutSidebar';
import SurveyForm from '@/components/nps/SurveyForm';
import AppRightSidebar from '@/sections/AppRightSidebar';
import Breakpoints from '@/mixins/Breakpoints';
import '@/theme/jumbleberry/common/transition.scss';

export default {
  name: 'MainLayout',
  components: {
    AppGlobalSidebar,
    AppRightSidebar,
    AppBanner,
    AppHeader,
    SidebarLayout,
    SidebarLayoutSidebar,
    SurveyForm
  },
  mixins: [Breakpoints([992])],
  props: {
    showFooter: {
      type: Boolean,
      default: true
    },
    fullWidth: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      portalTransition: ((parent) => {
        return {
          functional: true,
          render(h, { slots }) {
            return (
              <TransitionGroup tag="div" name="slide-left" class="app-right-sidebar__extension-content-transition tw-h-full tw-max-h-full">
                { slots().default }
              </TransitionGroup>
            );
          }
        };
      })(this)
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    organizationId() {
      return this.$store.getters.organizationId;
    },
    organization() {
      return this.$store.getters.organization;
    },
    isAdvertiser() {
      return this.$store.getters.userIsAdvertiser;
    },
    isUserGeneral() {
      return this.$store.getters.userIsGeneral;
    },
    isEmployee() {
      return this.$store.getters.userIsInternal;
    },
    hideContextMenu() {
      if (this.isAdvertiser) return (this.$route.name === 'performance-dashboard' || this.$route.path.startsWith('/campaign')) || this.$route.path.startsWith('/referral');
      if (this.isEmployee) return this.$route.name === 'ab' || this.$route.path.startsWith('/referral');
      return this.isUserGeneral;
    },
    hideLeftBar() {
      return this.isUserGeneral;
    },
    leftSidebarWidth() {
      return this.$store.getters.leftSidebarWidth ? parseInt(this.$store.getters.leftSidebarWidth) : 256;
    },
    rightSidebarWidth() {
      return this.$store.getters.rightDrawerWidth ? parseInt(this.$store.getters.rightDrawerWidth) : 256;
    },
    showHeader() {
      return !this.$store.getters.embedded;
    },
    underlapFooter() {
      return this.$route.meta.sidebarContent !== false;
    },
    fullContentWidth() {
      return !!this.$route.meta.fullContentWidth;
    },
    isMobileWidth() {
      return this.windowBreakpoint < 992;
    },
    surveyId() {
      return this.$store.getters.userHasNpsSurvey ? this.$store.getters.user.nps_survey.nps_survey_id : null;
    },
    npsSurveyVisible() {
      return this.$store.getters.npsSurveyVisible;
    }
  },
  watch: {
    '$store.getters.leftSidebarVisible'(open) {
      this.toggleSidebar('left', open);
    },
    '$store.getters.rightDrawerVisible'(open) {
      this.toggleSidebar('right', open);
    },
    $route(route) {
      if (!route.matched || route.matched[0].name !== 'insights') {
        this.$store.dispatch('setLeftSidebarVisibility', false);
      }
    }
  },
  methods: {
    handleLogoClick() {
      if (this.isMobileWidth) {
        this.$store.dispatch('setLeftSidebarVisibility');
      } else {
        this.$router.push('/');
      }
    },
    toggleSidebar(sidebar, open) {
      this.$refs.sidebarLayout.toggle(sidebar, open);
    },
    sidebarChange(sidebar, open) {
      if (sidebar === 'left') {
        this.$store.dispatch('setLeftSidebarVisibility', open);
      } else if (sidebar === 'right') {
        this.$store.dispatch('setRightDrawerVisibility', open);
      }
    }
  }
};
</script>

<style lang="scss">
.main-layout {
  min-height: calc(100 * var(--vh, 1vh));
  transition: all $--clb-transition-time $--clb-transition-step;
  overflow-anchor: none;

  &__header {
    max-width: 100vw;
    width: 100%;
    z-index: 1992;
    position: fixed;
    top: 0;
  }

  &__right-sidebar {
    box-shadow: 0 2px 16px 0 rgba(0, 41, 132, 0.08);
  }

  &__left-sidebar,
  &__right-sidebar {
    height: 100%;
    position: fixed;
    z-index: $--clb-z-index-left-sidebar;

    .el-menu {
      width: $--clb-app-left-sidebar-width-collapsed;
    }

    .el-menu:not(.el-menu--collapse) {
      width: $--clb-app-left-sidebar-menu-width;
    }
  }

  &__content {
    max-width: 100%;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-height: calc(100 * var(--vh, 1vh));
    padding-top: $--clb-app-header-height;
    background: #f8f9fd;

    &-header {
      @apply tw-bg-white;
    }

    &-body {
      display: flex;
      flex: 1 1 auto;
      margin: 0 auto;
      width: 100%;

      > * {
        max-width: 100%;
        width: 100%;
        min-height: calc(100vh - #{$--clb-app-header-height});
        min-height: calc(100 * var(--vh, 1vh) - #{$--clb-app-header-height});
      }
    }

    &.fullWidth {
      .main-layout__content-body {
        max-width: 100% !important;
      }
    }
  }

  &__footer {
    max-width: 100vw;
    flex-shrink: 0;
  }

  &--no-header {
    .main-layout__content {
      padding-top: 0;
      min-height: 100vh;
      min-height: calc(100 * var(--vh, 1vh));
    }
  }
}

// MQ'S
@media (min-width: $--sm) {
  .main-layout {
    &__content {
      background: #f8f9fd;
    }
  }
}

@media (min-width: 992px) {
  .main-layout {
    &__content {
      min-height: calc(100vh - #{$--clb-app-header-height});
      min-height: calc(100 * var(--vh, 1vh) - #{$--clb-app-header-height});
      padding-top: 0;
    }

    &__wrapper {
      padding-top: #{$--clb-app-header-height};
    }

    &__left-sidebar,
    &__right-sidebar {
      height: calc(100% - 60px);
    }

    &--no-header {
      .main-layout__wrapper {
        padding-top: 0;
      }
    }
  }
}

@media (min-width: $--lg) {
  .main-layout {
    &__content {
      &-header {
        &-full {
          max-width: $--clb-app-content-width;
        }
      }

      &-body {
        &-full {
          max-width: $--clb-app-content-width;
        }
      }
    }
  }
}
</style>
