import jbxApi from '@/services/jbx-api';
import requestCaching from '@/services/request-caching';
import queryStringOptions from '@/services/query-string-options';
import ErrorHelper from '@/helpers/ErrorHelper';
import account from './account';
import campaigns from './campaigns';
import notifications from './notifications';
import npsSurvey from './npsSurvey';
import crowds from './crowds';
import organization from './organization';
import adApprovals from './adApprovals';
import referral from './referral';
import permissions from './permissions';

/**
 * Fetch factory
 * @param storeHandler
 * @return {function(...[*]=)}
 * @constructor
 */
export const FetchFactory = function(storeHandler) {
  /**
   * Actual fetch function for the api endpoints
   * @param url
   * @param options
   * @return {*}
   */
  return function(url, options = {}) {
    const params = Object.assign({
      page: 1,
      perPage: 12,
      filter: {},
      search: { q: options.q }
    },
      options.params
    );

    const requestPromise = requestCaching
      .get({
        url,
        params: queryStringOptions.apify(params, ['search', 'filter']),
        commit: response => storeHandler.onSuccess(options.storeAction, { response, payload: options }),
        cancellationKey: options.cancellationKey
      })
      .then(async response => {
        const actionResponse = await storeHandler.onSuccess(options.storeAction, { response, payload: options });
        const data = options.fullResponse ? response : response.data;
        return actionResponse || data;
      })
      .catch(e => {
        storeHandler.onError(options.storeAction, { payload: options, error: e });
        return Promise.reject(ErrorHelper(e));
      });

    storeHandler.onPreload(options.storeAction, { ...options, params, requestPromise });

    return requestPromise;
  };
};

/**
 * Fetch factory using JBX API
 * @param storeHandler
 * @return {function(...[*]=)}
 * @constructor
 */
export const JBXFetchFactory = function(storeHandler) {
  /**
   * Actual fetch function for the api endpoints
   * @param {String} url
   * @param {String} method
   * @param {Object} options
   * @return {*}
   */
  return async function({ url, method = 'GET', options = {} }) {
    const params = { ...options.params };
    if (options.q) params.search = { q: options.q };

    // method validation
    const methods = ['request', 'get', 'delete', 'post', 'put'];
    method = method.toLowerCase();
    method = method && methods.includes(method) && typeof jbxApi[method] === 'function' ? method : 'get';

    const requestPromise = method === 'get'
      ? requestCaching
        .get({
          url,
          params: queryStringOptions.apify(params, ['search', 'filter']),
          commit: (res) => storeHandler.onSuccess(options.storeAction, { response: res.data, payload: options }),
          cancellationKey: options.cancellationKey
        })
      : jbxApi[method](url, params);

    const init = await storeHandler.onInit(options.storeAction, { payload: options });
    const request = requestPromise
      .then(async(res) => {
        const response = res.data;
        init && init.resolve && init.resolve({ response, payload: options });
        const actionResponse = await storeHandler.onSuccess(options.storeAction, { response, payload: options });
        const data = options.fullResponse ? response : response.data;
        return actionResponse || data;
      })
      .catch((e) => {
        init && init.reject && init.reject({ payload: options, error: e });
        storeHandler.onError(options.storeAction, { payload: options, error: e });
        return Promise.reject(ErrorHelper(e));
      });

    storeHandler.onPreload(options.storeAction, { ...options, params, request });
    return request;
  };
};

/**
 * Helper methods to help dispatch actions to the store
 * @param {Store} store
 * @return {Object}
 * @constructor
 */
export const StoreHandler = (store) => {
  const registeredActions = Object.keys(store._actions);

  return {
    get(getterName) {
      return store.getters[getterName];
    },
    hasAction(storeAction) {
      return registeredActions.findIndex(k => k === storeAction) >= 0;
    },
    dispatchAction(storeAction, options) {
      return this.hasAction(storeAction) && store.dispatch(storeAction, options);
    },
    onPreload(storeAction, options) {
      return this.dispatchAction(`${storeAction}`, options);
    },
    onInit(storeAction, options) {
      return this.dispatchAction(`${storeAction}Init`, options);
    },
    onError(storeAction, options) {
      return this.dispatchAction(`${storeAction}Fail`, options);
    },
    onSuccess(storeAction, response) {
      return this.dispatchAction(`${storeAction}Success`, response);
    }
  };
};

/**
 * Context object given to every Api module
 * @typedef {Object} ApiContext
 * @property { function } fetch
 * @property { Store } store
 */

/**
 * Initialize api services
 * @param vueInstance
 * @param store
 */
export default (vueInstance, store) => {
  const fetch = FetchFactory(StoreHandler(store));
  const jbxFetch = JBXFetchFactory(StoreHandler(store));

  vueInstance.prototype.$api = {
    ...jbxApi,
    account: account({ fetch: jbxFetch, storeHandler: StoreHandler(store) }),
    campaigns: campaigns({ fetch }),
    notifications: notifications({ fetch }),
    npsSurvey: npsSurvey({ fetch, store }),
    crowds: crowds({ fetch: jbxFetch, storeHandler: StoreHandler(store) }),
    adApprovals: adApprovals({ fetch: jbxFetch, storeHandler: StoreHandler(store) }),
    organization: organization({ fetch: jbxFetch, storeHandler: StoreHandler(store) }),
    referral: referral({ fetch: jbxFetch, storeHandler: StoreHandler(store) }),
    permissions: permissions({ fetch: jbxFetch, storeHandler: StoreHandler(store) })
  };
};
