/**
 * Initialize a custom error response
 * @param e
 * @returns {{message: *, fields: null, error: *, response: null}}
 */
const initializeError = function(e) {
  return {
    message: e.message,
    fields: null,
    error: e,
    response: e.hasOwnProperty('response') ? e.response : {}
  };
};

/**
 * Add the first error message from the error _meta response data to the default message
 * @param e
 * @returns {*}
 */
const addMessageFromMeta = function(e) {
  const fieldsErrors = e.response.data._meta.errors.filter(e => e !== null && e !== '');
  if (fieldsErrors.length === 1) {
    e.message = fieldsErrors[0];
  }
  return e;
};

/**
 * Create a custom error object
 * @param e
 * @returns {{message: *, fields: null, error: *, response: null}}
 * @constructor
 */
const ErrorHelper = function(e) {
  let error = initializeError(e);
  if (error.response.hasOwnProperty('data')) {
    error = addMessageFromMeta(error);
  }
  return error;
};

/**
 * Format an error message to be used on the ui
 * @param errors
 * @param key
 * @param e
 * @returns {*}
 */
const addErrorToKey = function(errors, key, e) {
  // Only allow 1 error per key
  if (!errors.hasOwnProperty(key)) {
    errors[key] = e;
  }
  return errors;
};

/**
 * Todo: This method is temporary, api errors should be more explicit
 * Helper method for handling authentication errors
 * @param errors
 */
export const handleAuthResponseError = function(errors) {
  let mappedErrors = {};
  errors.map(e => {
    if (e.toLowerCase().includes('password')) {
      mappedErrors = addErrorToKey(mappedErrors, 'password', e);
    } else {
      mappedErrors = addErrorToKey(mappedErrors, 'email', e);
    }
  });
  return mappedErrors;
};

/**
 * Returns a more useful error response for UI visualization
 * @param {Error} e
 */
export default ErrorHelper;
