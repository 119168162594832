<template>
  <div class="app-survey-popup">
    <h2 class="app-survey-popup__title">
      {{ title }}
    </h2>
    <p class="app-survey-popup__question">
      {{ question }}
    </p>
    <div class="app-survey-popup__controls">
      <div class="app-survey-popup__controls-left">
        <app-button
          size="micro"
          class="app-survey-popup__controls-btn1"
          state="text"
          type="button"
          @click="handleNpsClick('notint')">
          Not Interested
        </app-button>
      </div>
      <div class="app-survey-popup__controls-right">
        <app-button
          size="micro"
          class="app-survey-popup__controls-btn2 hidden-xs-and-down"
          state="secondary"
          @click="handleNpsClick('later')">
          Later
        </app-button>
        <app-button size="micro" class="app-survey-popup__controls-btn3" @click="handleNpsClick('accept')">
          Let's Go
        </app-button>
      </div>
    </div>
  </div>
</template>

<script>
import AppButton from '@/components/AppButton';
import dayjs from 'dayjs';

export default {
  name: 'AppSurveyPopup',
  components: { AppButton },
  props: {
    surveyId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      title: 'Have a minute?',
      question:
        'Would you like to help us improve your experience with the platform? Complete a quick 4 question survey now, your feedback is highly valued.'
    };
  },
  methods: {
    // Handling the UI
    handleNpsClick(status) {
      this.$emit('survey-action');

      if (status === 'accept') {
        this.setActiveSurvey();
        this.$store.dispatch('setNpsSurveyVisibility', true);
      } else if (status === 'later') {
        this.setSurveyShowAfter(dayjs().add(1, 'day'));
      } else if (status === 'notint') {
        this.setSurveyShowAfter(dayjs().add(1, 'month'));
      }
    },
    setActiveSurvey() {
      this.$api.npsSurvey.sendSurveyData(this.surveyId, {
        status: 'active'
      });
    },
    setSurveyShowAfter(showAfter) {
      this.$api.npsSurvey.sendSurveyData(this.surveyId, {
        show_after: showAfter.format('YYYY-MM-DD HH:mm:ss')
      });
    }
  }
};
</script>

<style lang="scss">
.app-survey-popup {
  display: flex;
  flex-direction: column;

  &__title {
    font-size: $--clb-font-size-base !important;
    margin: 0 0 $--clb-space-3 0;
  }

  &__question {
    font-size: $--clb-font-size-sm !important;
    hyphens: none;
  }

  &__controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: $--clb-space-4;

    @include xs-up {
      justify-content: space-between;
    }

    .app-button {
      min-width: 90px;
    }

    &-btn1 {
      @include xs-up {
        padding: 0;
        min-width: 0 !important;
      }
    }

    &-btn2 {
      margin-right: $--clb-space-2;
    }

    &-right {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
