import _get from 'lodash/get';
import MiddlewareError from '@/routing/middleware-error';
import { STATUS_REDIRECTS } from '@/helpers/survey/QuestionNavigationHelper';

/**
 * Handle survey start endpoint
 * @param {Object} context
 * @param {Store} context.store
 * @param {VueRouter} context.router
 * @return {*}
 */
function handleSurveyStart({ store, router }) {
  const firstQuestionKey = store.getters.firstQuestionKey;
  if (firstQuestionKey === null) {
    return router.replace('/signup/survey/confirm');
  } else {
    return router.replace(`/signup/survey/${firstQuestionKey}`);
  }
}

/**
 * Routing helper for correct redirection depending on target route
 * @param {Object} context
 * @param {Route} context.to
 * @param {VueRouter} context.router
 * @param {Store} context.store
 * @param {Function} context.next
 */
async function surveyStepValidation({ to, next, store, router }) {
  if (to.matched.some(record => record.meta.survey)) {
    const surveyRules = to.matched.reduce(
      (rules, record) => (record.meta.hasOwnProperty('survey') ? record.meta.survey : rules),
      null
    );
    if (!surveyRules.allowedStatus.includes(store.getters.surveyStatus)) {
      return router.replace(STATUS_REDIRECTS[store.getters.surveyStatus]);
    }
  }
  return next();
}

/**
 * Handles the redirection of the survey depending on the current state of it
 * @param {Object} context
 * @param {Route} context.to
 * @param {Function} context.next
 * @param {Store} context.store
 * @param {VueRouter} context.router
 */
function handleSurveyStateRedirect({ to, store, next, router }) {
  if (to.path === '/signup/survey/start') {
    return handleSurveyStart({ store, router });
  } else {
    return surveyStepValidation({ to, next, store, router });
  }
}

/**
 * Run authentication middleware
 * @param {Object} context
 * @param {Route} context.to
 * @param {Function} context.next
 * @param {Store} context.store
 * @param {VueRouter} context.router
 */
export default async function({ to, store, next, router }) {
  try {
    await store.dispatch('initializeSurveyState', store.getters.user);
    return handleSurveyStateRedirect({ to, store, next, router });
  } catch (e) {
    if (e.hasOwnProperty('response')) {
      throw new MiddlewareError(_get(e.response, 'data._meta.errors', []).join(', '), '/signout');
    }
    return Promise.reject(e);
  }
}
