import { getEventTrackData, eventTrack } from '@/services/event-track';

export default function(VueInstance) {
  VueInstance.directive('event-track', {
    bind(el, { arg }) {
      const eventType = arg || 'click';
      const handler = `__track_${eventType}_handler__`;

      el[handler] = (event) => {
        // NOTE: this can be extended to allow tracking of any targetable element (ex. buttons, links, inputs, etc.)
        const selector = '[event-track], [event-track-platforms], [event-track-category], [event-track-action], [event-track-props], [event-track-value]';
        const el = event.target.closest(selector);
        if (!el) return;
        const { platforms, category, action, props, value } = getEventTrackData(el);
        eventTrack({ platforms, category, action, props, value });
      };

      el.addEventListener(eventType, el[handler]);
    },
    unbind(el, { arg }) {
      const eventType = arg || 'click';
      const handler = `__track_${eventType}_handler__`;
      el.removeEventListener(eventType, el[handler]);
    }
  });
};
