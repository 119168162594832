<template>
  <div class="app-accordion">
    <el-collapse
      v-bind="$attrs"
      :accordion="accordion"
      v-on="$listeners">
      <el-collapse-item v-for="(item, index) in data" :key="index" :class="{'hide-arrow': hideDefaultArrow}" :name="item[name]">
        <template slot="title">
          <slot :index="index" name="title" :item="item">
            {{ item.title || '' }}
          </slot>
        </template>
        <slot :index="index" name="body" :item="item">
          {{ item.body || '' }}
        </slot>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [{ title: '', body: '', name: '' }]
    },
    accordion: {
      type: Boolean,
      default: true
    },
    name: {
      type: [String, Number],
      default: 'name'
    },
    hideDefaultArrow: {
      type: Boolean,
      default: true
    }
  }
};
</script>
<style lang="scss">
.app-accordion {
  .el-collapse {
    border: 0;

    .hide-arrow {
      .el-collapse-item__arrow {
        display: none;
      }
    }
  }

  .el-collapse-item:last-child {
    margin-bottom: 0;
  }

  .el-collapse-item__header {
    color: $--clb-color__headings;
    font-size: $--clb-font-size-base;
    font-weight: $--clb-font-weight__semi-bold;
    line-height: $--clb-base__line-height;
    height: auto;
    padding: $--clb-space-5;
    border-radius: $--clb-border-radius;
    border-bottom-color: transparent;
  }

  .el-collapse-item__wrap {
    @apply tw-rounded tw-border-b-0;
  }

  .el-collapse-item__content {
    color: $--clb-color__headings;
    font-size: $--clb-font-size-sm;
    padding: 0 $--clb-space-5 $--clb-space-5 $--clb-space-5;
  }
}
</style>
