import Vue from 'vue';
import storage from '@/services/storage';
import ErrorHelper from '@/helpers/ErrorHelper';
import formatMapper from '@/mappers/metrics/format';

/**
 * Get options params for api requests
 * @param options
 * @return {any}
 */
const endpointOptions = function(options = {}) {
  const defaultOptions = {
    page: 1,
    dateRange: 'CM',
    sortBy: 'sales',
    order: 'desc'
  };
  return Object.assign(defaultOptions, options);
};

export default ({ jbxApi, requestCaching }) => {
  const baseOrganizationStateFactory = function() {
    return {
      reportingCampaigns: [],
      fetchingReportingCampaigns: false,
      fetchingReportingCampaignMetrics: false,
      fetchingReportingOverview: false,
      fetchingReportingCampaignOverview: false
    };
  };
  const baseStateFactory = function() {
    return {
      orgs: {},
      activeOrganizationState: {},
      metricKey: storage.getItem(process.env.VUE_APP_JB_PREFERENCES_STORAGE_KEY, 'sales'),
      dateRange: storage.getItem(process.env.VUE_APP_JB_DATE_RANGE_STORAGE_KEY, 'CM'),
      startDate: undefined,
      endDate: undefined
    };
  };
  const state = baseStateFactory();
  const getters = {
    reportingCampaigns(state) {
      return state.activeOrganizationState.reportingCampaigns;
    },
    fetchingReportingCampaigns(state) {
      return state.activeOrganizationState.fetchingReportingCampaigns;
    },
    reportingCampaignsIds(state, getters) {
      return getters.reportingCampaigns.map(item => item.id);
    },
    getMetricDateOptions(state) {
      const { dateRange, startDate, endDate } = state;
      return { dateRange, startDate, endDate };
    },
    getMetricDateRange(state) {
      return state.dateRange;
    },
    getMetricStartDate(state) {
      return state.startDate;
    },
    getMetricEndDate(state) {
      return state.endDate;
    },
    getMetricKey(state) {
      return state.metricKey;
    }
  };
  const actions = {
    /**
     * @param {Object} context
     * @param {number} orgId
     * @param {string} type
     * @param {Object} options
     * @param {string} filename
     * @return {Promise<void>}
     */
    async downloadReportingCampaignsCsv(context, { orgId, type, options, filename }) {
      return jbxApi.download(`/v2/org/${orgId}/campaigns/${type}/csv`, endpointOptions(options), filename);
    },

    /**
     * @param {Object} context
     * @param {number} orgId
     * @param {string} type
     * @param {number} campaignId
     * @param {Object} options
     * @param {string} filename
     * @return {Promise<void>}
     */
    async downloadReportingCampaignCsv(context, { orgId, type, campaignId, options, filename }) {
      return jbxApi.download(`/v2/org/${orgId}/campaign/${campaignId}/${type}/csv`, endpointOptions(options), filename);
    },

    fetchReportingCampaigns({ commit }) {
      commit('FETCH_REPORTING_CAMPAIGNS');
    },
    fetchReportingCampaignsSuccess({ commit }, { response }) {
      const formattedResponse = formatMapper.getFormattedReportingCampaigns(response);
      commit('FETCH_REPORTING_CAMPAIGNS_SUCCESS', formattedResponse);
      return {
        ...response.data,
        data: formattedResponse
      };
    },
    fetchReportingCampaignsFail({ commit }) {
      commit('FETCH_REPORTING_CAMPAIGNS_FAIL');
    },

    /**
     * @param context
     * @param context.state
     * @param context.commit
     * @param params
     * @param params.orgId
     * @param params.options
     * @return {Promise<* | never>}
     */
    async fetchReportingCampaignMetrics({ state, commit }, { orgId, campaignId, options }) {
      commit('FETCH_REPORTING_CAMPAIGN_METRICS');
      return requestCaching.get({
        url: `/v2/org/${orgId}/campaign/${campaignId}/reporting`,
        params: endpointOptions(options),
        cancellationKey: 'REPORTING_CAMPAIGN_METRICS'
      }).then(response => {
        const formattedData = formatMapper.getFormattedCampaignMetrics(response.data.data);
        commit('FETCH_REPORTING_CAMPAIGN_METRICS_SUCCESS', formattedData);
        return Promise.resolve(formattedData);
      }).catch(e => {
        commit('FETCH_REPORTING_CAMPAIGN_METRICS_FAIL');
        return Promise.reject(ErrorHelper(e));
      });
    },

    /**
     * @param context
     * @param context.commit
     * @param params
     * @param params.orgId
     * @param params.options
     * @return {Promise<* | never>}
     */
    async fetchReportingOverview({ commit }, { orgId, options }) {
      commit('FETCH_REPORTING_OVERVIEW');
      return requestCaching
        .get({
          url: `/v2/org/${orgId}/campaigns/overview`,
          params: endpointOptions(options),
          cancellationKey: 'REPORTING_OVERVIEW'
        })
        .then(response => {
          commit('FETCH_REPORTING_OVERVIEW_SUCCESS');
          return Promise.resolve(formatMapper.getFormattedReportingOverview(response.data.data));
        })
        .catch(e => {
          commit('FETCH_REPORTING_OVERVIEW_FAIL');
          return Promise.reject(ErrorHelper(e));
        });
    },

    /**
     * @param context
     * @param context.commit
     * @param params
     * @param params.orgId
     * @param params.options
     * @return {Promise<* | never>}
     */
    async fetchReportingCampaignOverview({ commit }, { orgId, campaignId, options }) {
      commit('FETCH_REPORTING_CAMPAIGN_OVERVIEW');
      return requestCaching
        .get({
          url: `/v2/org/${orgId}/campaign/${campaignId}/overview`,
          params: endpointOptions(options),
          cancellationKey: 'REPORTINIG_CAMPAIGN_OVERVIEW'
        })
        .then(response => {
          const formattedData = formatMapper.getFormattedReportingOverview(response.data.data);
          commit('FETCH_REPORTING_CAMPAIGN_OVERVIEW_SUCCESS', formattedData);
          return Promise.resolve(formattedData);
        })
        .catch(e => {
          commit('FETCH_REPORTING_CAMPAIGN_OVERVIEW_FAIL');
          return Promise.reject(ErrorHelper(e));
        });
    },

    /**
     * Set the current selected dateRange for api calls
     * @param context
     * @param context.state
     * @param context.commit
     * @param params
     * @param params.dateRange
     */
    setMetricDateRange({ state, commit }, { dateRange, startDate, endDate }) {
      commit('SET_METRIC_DATE_RANGE', dateRange);
      commit('SET_METRIC_DATE_RANGE_VALUES', { startDate, endDate });

      if (dateRange !== 'CUSTOM') {
        storage.setItem(process.env.VUE_APP_JB_DATE_RANGE_STORAGE_KEY, dateRange);
      }
    },

    /**
     * Set the current selected dateRange for api calls
     * @param context
     * @param context.state
     * @param context.commit
     * @param params
     * @param params.metricKey
     */
    setMetricKey({ state, commit }, { metricKey }) {
      commit('SET_METRIC_KEY', metricKey);
      storage.setItem(process.env.VUE_APP_JB_PREFERENCES_STORAGE_KEY, metricKey);
    }
  };
  const mutations = {
    SET_ACTIVE_ORGANIZATION(state, org) {
      if (!state.orgs.hasOwnProperty(org.id)) {
        state.orgs[org.id] = baseOrganizationStateFactory();
      }
      state.activeOrganizationState = state.orgs[org.id];

      if (org.type === 'affiliate' && state.metricKey === 'tests') {
        state.metricKey = 'sales';
      }
    },
    FETCH_REPORTING_CAMPAIGNS(state) {
      state.activeOrganizationState.fetchingReportingCampaigns = true;
    },
    FETCH_REPORTING_CAMPAIGNS_SUCCESS(state, reportingCampaigns) {
      state.activeOrganizationState.reportingCampaigns = [...reportingCampaigns];
      state.activeOrganizationState.fetchingReportingCampaigns = false;
    },
    FETCH_REPORTING_CAMPAIGNS_FAIL(state) {
      state.activeOrganizationState.fetchingReportingCampaigns = false;
    },
    FETCH_REPORTING_CAMPAIGN_METRICS(state) {
      state.activeOrganizationState.fetchingReportingCampaignMetrics = true;
    },
    FETCH_REPORTING_CAMPAIGN_METRICS_SUCCESS(state) {
      state.activeOrganizationState.fetchingReportingCampaignMetrics = false;
    },
    FETCH_REPORTING_CAMPAIGN_METRICS_FAIL(state) {
      state.activeOrganizationState.fetchingReportingCampaignMetrics = false;
    },
    FETCH_REPORTING_OVERVIEW(state) {
      state.activeOrganizationState.fetchingReportingOverview = true;
    },
    FETCH_REPORTING_OVERVIEW_SUCCESS(state) {
      state.activeOrganizationState.fetchingReportingOverview = false;
    },
    FETCH_REPORTING_OVERVIEW_FAIL(state) {
      state.activeOrganizationState.fetchingReportingOverview = false;
    },
    FETCH_REPORTING_CAMPAIGN_OVERVIEW(state) {
      state.activeOrganizationState.fetchingReportingCampaignOverview = true;
    },
    FETCH_REPORTING_CAMPAIGN_OVERVIEW_SUCCESS(state) {
      state.activeOrganizationState.fetchingReportingCampaignOverview = false;
    },
    FETCH_REPORTING_CAMPAIGN_OVERVIEW_FAIL(state) {
      state.activeOrganizationState.fetchingReportingCampaignOverview = false;
    },
    SET_METRIC_DATE_RANGE(state, dateRange) {
      state.dateRange = dateRange;
    },
    SET_METRIC_DATE_RANGE_VALUES(state, { startDate, endDate }) {
      state.startDate = startDate;
      state.endDate = endDate;
    },
    SET_METRIC_KEY(state, metricKey) {
      state.metricKey = metricKey;
    },
    CLEAR_STORE(state) {
      state = Object.assign(state, baseStateFactory());
    }
  };

  return {
    state,
    getters,
    actions,
    mutations
  };
};
