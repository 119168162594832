/**
 * Context passed by middlewares handlers
 * @typedef {Object} MiddlewareContext
 * @property {Route} to
 * @property {Route} from
 * @property {Function} next
 * @property {Function} abort
 * @property {Store} store
 * @property {Document} document
 * @property {VueRouter} router //Todo: replace instances of VueRouter with the direct use of `abort` function
 */
import qs from 'qs';
import _get from 'lodash/get';
import _uniq from 'lodash/uniq';

export const MiddlewareHelper = {
  addFromUrlToPath(location) {
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true }) || {};
    const queryString = location.search.replace(/([?&])api-token=([^&]*)&?/, (s, r) => r);
    return !location.pathname.includes('/signin')
      ? queryParams.from || encodeURIComponent(location.pathname + queryString)
      : '/';
  },
  /**
   * Get a meta option for the matched routes
   * @param route
   * @param option
   * @param defaultValue
   */
  getMetaOption(route, option, defaultValue) {
    return route.matched.reduce((previousValue, record) => {
      return _get(record.meta, option) || previousValue;
    }, defaultValue);
  }
};

/**
 * @param globalMiddlewares
 * @param availableMiddlewares
 * @return {Function}
 */
export default (globalMiddlewares, availableMiddlewares) => {
  return {
    /**
     * Check if the given route has a middleware attached
     * @param {Route} route
     * @returns {boolean}
     */
    routeHasMiddleware(route) {
      return route.matched.some(record => record.meta.middleware);
    },
    /**
     * Check if the given middleware name exists
     * @param {string} middlewareName
     * @return {boolean}
     */
    middlewareExists(middlewareName) {
      return globalMiddlewares.hasOwnProperty(middlewareName) || availableMiddlewares.hasOwnProperty(middlewareName);
    },
    /**
     * Get a middleware for either the global or available ones
     */
    getMiddleware(middlewareName) {
      return globalMiddlewares.hasOwnProperty(middlewareName)
        ? globalMiddlewares[middlewareName]
        : availableMiddlewares[middlewareName];
    },
    /**
     * Get a normalized list of middlewares from a route record
     * @param routeRecord
     * @return {Array}
     */
    getRouteRecordMiddlewares(routeRecord) {
      if (!routeRecord.meta.hasOwnProperty('middleware')) {
        return [];
      }
      return Array.isArray(routeRecord.meta.middleware) ? routeRecord.meta.middleware : [routeRecord.meta.middleware];
    },
    /**
     * Returns an array of the middlewares from a given route
     * @param route
     * @returns {Array}
     */
    getRouteMiddlewares(route) {
      return _uniq([
        ...Object.keys(globalMiddlewares),
        ...route.matched.reduce((middlewares, record) => {
          const recordMiddlewares = this.getRouteRecordMiddlewares(record);
          return middlewares.concat(recordMiddlewares);
        }, [])
      ]);
    },
    /**
     * Get the next middleware to run
     * @param {MiddlewareContext} context
     * @param routeMiddlewares
     * @param middlewareIndex
     * @return {*}
     */
    getNextMiddleware(context, routeMiddlewares, middlewareIndex) {
      const nextMiddlewareName = routeMiddlewares[middlewareIndex];
      if (!nextMiddlewareName) {
        return context.next;
      }
      const nextMiddleware = this.getMiddleware(nextMiddlewareName);
      return async(value) => {
        if (value === false) {
          return context.next(value);
        }

        return nextMiddleware({
          ...context,
          next: this.getNextMiddleware(context, routeMiddlewares, middlewareIndex + 1),
          middlewareHelper: MiddlewareHelper
        });
      };
    },
    /**
     * @param {MiddlewareContext} context
     * @param routeMiddlewares
     */
    async runMiddlewareValidation(context, routeMiddlewares) {
      const middlewareName = routeMiddlewares[0];
      if (this.middlewareExists(middlewareName)) {
        const middleware = this.getMiddleware(middlewareName);
        const nextMiddleware = this.getNextMiddleware(context, routeMiddlewares, 1);
        return middleware({ ...context, next: nextMiddleware, middlewareHelper: MiddlewareHelper });
      }
      return context.next();
    },
    /**
     * Check if the route has a middleware and run it's handler when needed
     * @param {MiddlewareContext} context
     * @returns {*}
     */
    handleNavigationMiddleware(context) {
      return this.runMiddlewareValidation(context, this.getRouteMiddlewares(context.to));
    }
  };
};
