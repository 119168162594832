import _get from 'lodash/get';

/**
 * Restores a user session from a session_id or a given auth-token
 * @param {Route} to
 * @param {Store} store
 */
async function restoreUserSession(store) {
  const sessionId = _get(store.getters.user, 'session_id', null);
  if (!sessionId) {
    return Promise.resolve(false);
  }

  if (process.env.BUILD_TARGET === 'mobile') {
    return store.dispatch('restoreSession', { user: store.getters.user });
  }

  return store.dispatch('signinWithApiToken', sessionId);
};

/**
 * Checks that the user has a valid user type for this route
 * @param to
 * @param store
 */
async function allowedUserType(to, store) {
  if (!to.matched.some(record => _get(record.meta, 'auth.allowedUserTypes'))) {
    return true;
  }

  return to.matched
    .reduce((userTypes, record) => {
      return userTypes.concat(_get(record.meta, 'auth.allowedUserTypes', []));
    }, [])
    .includes(store.getters.userType);
};

/**
 * Run authentication middleware
 * @param {Object} context
 * @param {Route} context.to
 * @param {VueRouter} context.router
 * @param {Store} context.store
 * @param {Function} context.next
 * @param {MiddlewareHelper} context.middlewareHelper
 */
// eslint-disable-next-line complexity
export default async function({ to, store, next, router, middlewareHelper }) {
  if (!store.getters.userSessionRestored) {
    try {
      await restoreUserSession(store);
    } catch (err) {}
  }

  if (!store.getters.isAuthenticated) {
    return router.replace({
      path: middlewareHelper.getMetaOption(to, 'authPath', '/signin'),
      query: {
        from: middlewareHelper.addFromUrlToPath(location)
      }
    });
  } else if (!allowedUserType(to, store)) {
    return router.replace(middlewareHelper.getMetaOption(to, 'authPath', '/signup'));
  }

  return next();
}
