<template>
  <div class="org-menu">
    <app-accordion v-bind="$attrs" :data="[{name: 'orgDropdown'}]" :header-icons="{}" v-on="$listeners">
      <template v-slot:title>
        <app-text weight="medium" class="tw-text-jb-grey-700 tw-text-2xs tw-mb-space-1">
          ORGANIZATION
        </app-text>
        <div class="tw-mt-space-1 tw-flex tw-items-center">
          <div class="tw-mr-space-2">
            <app-profile-picture class="tw-w-9 tw-h-9 tw-bg-jb-indigo-darker" :full-name="organization.name" />
          </div>
          <div class="tw-w-36 tw-leading-none">
            <app-text type="div" weight="medium" size="sm" class="tw-text-jb-ink tw--mb-space-1 tw-leading-6 tw-truncate">
              {{ organization.name }}
            </app-text>
            <app-text weight="medium" class="tw-text-jb-grey-400 tw-text-xs tw-capitalize tw-truncate">
              {{ organization.type }} (#{{ organization.hitpath_account_id || organization.id }})
            </app-text>
          </div>
          <div class="tw-mr-space-2">
            <chevron-previous-icon :class="{['is-active']: $attrs.value}" class="tw-w-space-2 tw-h-space-3 tw-text-jb-grey-400 chevron-icon" />
          </div>
        </div>
      </template>
      <template v-slot:body>
        <div v-if="searchable" class="tw-border-jb-grey-50 tw-pt-space-4 tw-border-t tw-border-solid">
          <org-selection-list
            :loading="loading"
            :entries="filteredEntries"
            :current-page="1"
            :total-pages="1"
            class="app-user-dropdown-menu__orgs"
            @handle-search-more="handleSearchMore"
            @handle-search="handleSearch"
            @handle-selection="handleSelection" />
        </div>
        <div v-if="hasManager" class="tw-border-jb-grey-50 tw-mt-space-2 tw-border-t tw-border-solid">
          <div class="tw-px-space-2">
            <app-text weight="medium" class="tw-text-jb-grey-700 tw-text-2xs tw-mb-space-1 tw-uppercase">
              {{ managerTitle }}
            </app-text>
            <div class="tw-mt-space-1 tw-flex tw-w-full">
              <div class="tw-mr-space-2">
                <app-profile-picture class="tw-w-9 tw-h-9 tw-bg-jb-indigo-darker" :full-name="managerFullName" :image-url="managerImage" />
              </div>
              <div class="tw-flex tw-flex-col tw-w-full">
                <app-text weight="medium" class="tw-text-jb-ink tw-mb-space-2px tw-text-sm tw-capitalize tw-truncate">
                  {{ managerFullName }}
                </app-text>
                <app-text v-if="manager.phone_number" weight="medium" class="tw-text-jb-grey-400 tw-text-xs tw-truncate">
                  +{{ manager.country_code }}{{ manager.phone_number }}
                </app-text>
                <app-text weight="medium" class="tw-text-jb-grey-400 tw-text-xs tw-break-all">
                  {{ manager.email }}
                </app-text>
                <app-text v-if="manager.im_name" weight="medium" class="tw-text-jb-grey-400 tw-text-xs tw-truncate">
                  {{ manager.im_name }}
                </app-text>
              </div>
            </div>
          </div>
        </div>
        <div class="tw-border-jb-grey-50 tw-mt-space-2 tw-border-t tw-border-solid">
          <app-global-sidebar-item
            label="Organization Settings"
            :route="{ name: 'organization-settings' }"
            :is-active="$route.name === 'organization-settings' || $route.name.includes('organization-settings-')"
            icon-component="gear-icon"
            class="!tw-rounded-none !tw-mb-0" />
        </div>
      </template>
    </app-accordion>
  </div>
</template>

<script>
import OrgSelectionList from '@/components/organizations/OrgSelectionList';
import AppText from '@/components/AppText';
import AppAccordion from '@/components/AppAccordion';
import AppGlobalSidebarItem from '../AppGlobalSidebarItem.vue';
import ChevronPreviousIcon from '@/assets/svg/chevron-previous-icon.svg';

import _uniqBy from 'lodash/uniqBy';
import AppProfilePicture from '@/components/AppProfilePicture';
import _isObject from 'lodash/isObject';
const UserImages = require.context('@/assets/images/users/', true, /\.png$/);

export default {
  name: 'OrgMenu',
  components: { OrgSelectionList, AppProfilePicture, AppAccordion, AppText, AppGlobalSidebarItem, ChevronPreviousIcon },
  data() {
    return {
      search: '',
      loading: true,
      entries: this.$store.getters.organizations.slice(0),
      currentPage: 1,
      totalPages: 1
    };
  },
  computed: {
    hasManager() {
      return _isObject(this.manager) && this.manager.first_name;
    },
    manager() {
      return this.$store.getters.manager;
    },
    managerTitle() {
      return this.manager.title ? this.manager.title : (!this.organization || this.organization.type === 'affiliate' ? 'Affiliate Manager' : 'Account Executive');
    },
    managerFullName() {
      return `${this.manager.first_name} ${this.manager.last_name}`;
    },
    organization() {
      return this.$store.getters.organization;
    },
    managerImage() {
      try {
        const managerImage = this.managerFullName.replace(' ', '-');
        return UserImages(`./${this.organization.type}/${managerImage.toLowerCase()}.png`);
      } catch (e) {
        return undefined;
      }
    },
    searchable() {
      return !this.$store.getters.organizations || this.$store.getters.organizations.length !== 1;
    },
    filteredEntries() {
      return (this.entries || []).slice(0).filter(entry => !this.organization || entry.id !== this.organization.id);
    },
    isAffiliate() {
      return this.$store.getters.userIsAffiliate;
    }
  },
  mounted() {
    this.handleSearch('');
  },
  methods: {
    async handleSearch(search) {
      if (search !== this.search) {
        this.currentPage = 1;
        this.entries = [];
      }

      this.loading = true;
      this.search = search;
      try {
        const response = await this.$store.dispatch('getOrganizations', {
          q: search,
          page: this.currentPage
        });
        this.entries = _uniqBy([...this.entries, ...response.data.data], 'id');
        this.totalPages = Math.min(response.data._meta.page_count, 3);
        this.currentPage = response.data._meta.page;
        this.loading = false;
      } catch (e) {
        if (this.$api.isCancel(e)) {
          return;
        };
      }
    },
    handleSearchMore() {
      if (!this.loading && this.currentPage < this.totalPages) {
        this.currentPage += 1;
        this.handleSearch(this.search);
      }
    },
    handleSelection(index) {
      const org = this.filteredEntries[index];
      this.$router.push({
        name: 'redirect',
        params: { org },
        query: { ...this.$route.query, to: this.$route.fullPath }
      });
    }
  }

};
</script>
<style lang="scss">
.org-menu {
  .el-collapse {
    @apply tw-mx-space-4 tw-mb-space-2 tw-border-jb-grey-50 tw-border tw-border-solid tw-rounded;

    &-item {
      @apply tw-mb-space-2px;

      &__header {
        @apply tw-block tw-p-0 tw-m-space-2 tw-border-none tw-leading-3;

        .chevron-icon {
          @apply tw-transform tw--rotate-90 tw-duration-300;

          &.is-active {
            @apply tw-rotate-90;
          }
        }
      }

      &__content {
        @apply tw-p-0 tw-border-none;

        .app-search-list-v2 {
          @apply tw-pt-0;

          &__input {
            @apply tw-mx-space-2 tw--mt-space-2;
          }

          .el-scrollbar {
            @apply tw-shadow-none tw-rounded-none tw-h-40 tw-max-h-40;
          }

          .el-input--mini .el-input {
            &__inner {
              max-height: theme('spacing.layout-2');
              min-height: theme('spacing.layout-2');

              @apply tw-border-jb-grey-50;
            }
          }
        }
      }
    }
  }

  .el-collapse-item__wrap {
    border-bottom: theme('spacing.0');
    background: none;
  }
}
</style>
