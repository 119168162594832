/**
 * Run guest middleware
 * @param {Object} context
 * @param {Store} context.store
 * @param {Function} context.next
 */
export default async function({ next, store, router }) {
  if (!store.getters.userIsAffiliate) {
    return router.replace('/');
  }
  return next();
}
