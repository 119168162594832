<template>
  <el-drawer
    :visible.sync="showDrawer"
    :direction="direction"
    :before-close="handleClose"
    :size="drawerSize"
    :show-close="false"
    class="app-right-sidebar">
    <template slot="title">
      <div class="drawer-title">
        <portal-target name="app-right-sidebar-title" />
        <i class="el-icon-error close-icon" @click="handleClose" />
      </div>
    </template>
    <div class="app-right-sidebar__wrapper">
      <portal-target name="app-right-sidebar" @change="handlePortalChange" />
    </div>
    <portal-target :style="{width: drawerSize}" name="app-right-sidebar-header" class="app-right-sidebar__header--portal" />
  </el-drawer>
</template>

<script>
export default {
  name: 'AppRightSidebar',
  data() {
    return {
      direction: 'rtl'
    };
  },
  computed: {
    drawerSize() {
      return this.$store.getters.windowWidth < 768 ? '100%' : '85%';
    },
    showDrawer() {
      return this.$store.getters.rightSidebarVisible;
    }
  },
  methods: {
    handleClose() {
      this.$store.dispatch('setRightSidebarVisibility', false);
    },
    handlePortalChange(hasContent) {
      if (!hasContent) {
        this.handleClose();
      }
    }
  }
};
</script>

<style lang="scss">
.app-right-sidebar {
  overflow-y: hidden;
  overflow-x: scroll;

  .el-drawer__body {
    margin-top: 90px;
  }

  &__header--portal {
    position: fixed;
    top: 59px;

    > * {
      border-bottom: 1px solid rgba(28, 24, 7, 0.1);
    }
  }

  .el-drawer {
    overflow-y: auto;
    max-width: $--clb-app-content-width + $--clb-layout-4 * 2;
    outline: transparent;
  }

  .el-drawer__header {
    width: 100%;
    max-width: $--clb-app-content-width + $--clb-layout-4 * 2;
    position: fixed;
    background: white;
    z-index: 1;

    .drawer-title {
      display: flex;
      justify-content: space-between;

      .close-icon {
        cursor: pointer;
        font-size: 24px;
        position: fixed;
        top: $--clb-space-4;
        right: $--clb-space-4;
        z-index: 1;
        float: right;
      }
    }
  }
}
</style>
