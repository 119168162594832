/**
 * Campaign Discover Route Group
 * @param page
 * @return {function(): *}
 */
export default page => {
  return () => {
    const pages = {
      signIn: import(/* webpackChunkName: "SigninView" */ '@/views/auth/SigninView'),
      signUp: import(/* webpackChunkName: "SignupView" */ '@/views/auth/SignupView'),
      surveyView: import(/* webpackChunkName: "SurveyView" */ '@/views/survey/SurveyView'),
      surveyConfirmation: import(/* webpackChunkName: "SurveyConfirmation" */ '@/views/survey/SurveyConfirmation'),
      surveyFinish: import(/* webpackChunkName: "SurveyFinish" */ '@/views/survey/SurveyFinish')
    };
    return pages[page];
  };
};
