const STORAGE_KEY_PREFIX = process.env.VUE_APP_JB_STORAGE_KEY_PREFIX;
export default {
  getKey(key) {
    return STORAGE_KEY_PREFIX + key;
  },
  hasItem(key) {
    try {
      return window.localStorage.getItem(this.getKey(key)) !== null;
    } catch (e) {
      return false;
    }
  },
  getItem(key, def) {
    return this.hasItem(key) ? window.localStorage.getItem(this.getKey(key)) : def;
  },
  setItem(key, value) {
    try {
      window.localStorage.setItem(this.getKey(key), value);
    } catch (e) {}
  },
  removeItem(key) {
    try {
      window.localStorage.removeItem(this.getKey(key));
    } catch (e) {}
  },
  getObject(key, def) {
    try {
      return this.hasItem(key) ? JSON.parse(this.getItem(key)) : def;
    } catch (e) {
      return null;
    }
  },
  setObject(key, value) {
    this.setItem(key, JSON.stringify(value));
  },
  key(index) {
    return window.localStorage.key(index);
  },
  length() {
    return window.localStorage.length;
  },
  clear(storageKey = STORAGE_KEY_PREFIX) {
    const storeSize = this.length();
    const keys = [];
    for (let i = 0; i < storeSize; i++) {
      const key = this.key(i);
      if (key.substring(0, storageKey.length) === storageKey) {
        keys.push(key.replace(storageKey, ''));
      }
    }
    keys.forEach(k => this.removeItem(k));
  }
};
