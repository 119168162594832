import { GENERAL } from '@/constants/orgTypes';

/**
 * Run General user middleware
 * @param {Object} context
 * @param {VueRouter} context.router
 * @param {Store} context.store
 * @param {Function} context.next
 */
export default async({ to, next, store, router }) => {
  if (store.getters.organization.type !== GENERAL) {
    return router.replace({
      path: '/'
    });
  }

  return next();
};
