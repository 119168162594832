/**
 * Campaign Discover Route Group
 * @param page
 * @return {function(): *}
 */
export default page => {
  return () => {
    const pages = {
      dashboard: import(/* webpackChunkName: "PerformanceDashboard" */'@/views/user/analytics/PerformanceDashboard'),
      discover: import(/* webpackChunkName: "CampaignDiscover" */ '@/views/campaign/CampaignDiscover'),
      details: import(/* webpackChunkName: "CampaignDetails" */ '@/views/campaign/CampaignDetails'),
      application: import(/* webpackChunkName: "CampaignApplication" */ '@/views/campaign/CampaignApplication'),
      marketing: import(/* webpackChunkName: "CampaignSetupMarketingInformation" */ '@/views/campaign/setup/CampaignSetupMarketingInformation'),
      pixels: import(/* webpackChunkName: "CampaignSetupPixels" */ '@/views/campaign/setup/CampaignSetupPixels'),
      links: import(/* webpackChunkName: "CampaignSetupLinks" */ '@/views/campaign/setup/CampaignSetupLinks'),
      suppression: import(/* webpackChunkName: "CampaignSetupSuppression" */ '@/views/campaign/setup/CampaignSetupSuppression'),
      creatives: import(/* webpackChunkName: "CampaignSetupCreatives" */ '@/views/campaign/setup/CampaignSetupCreatives'),
      adApproval: import(/* webpackChunkName: "AdApproval" */ '@/views/adApproval/AdApproval'),
      adApprovalLayout: import(/* webpackChunkName: "AdApproval" */ '@/views/adApproval/AdApprovalLayout')

    };
    return pages[page];
  };
};
