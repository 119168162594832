import _get from 'lodash/get';

/**
 * Run guest middleware
 * @param {Object} context
 * @param {Route} context.to
 * @param {Store} context.store
 * @param {Function} context.next
 */
export default async function({ next, to, store, router, api }) {
  if (!store.getters.campaign(to.params.id)) {
    api.campaigns.single({
      orgId: store.getters.organization.id,
      campaignId: to.params.id,
      storeAction: 'fetchCampaign'
    })
      .then(response => {
        if (!_get(response, 'data.id')) throw new Error('No Campaign');
      })
      .catch(e => {
        store.dispatch('showErrorMessage', `Campaign ${to.params.id} is not available`);
        return router.replace('/');
      });
  }

  return next();
}
