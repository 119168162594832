<template>
  <div class="drawer-layout">
    <div
      :class="animateClasses"
      :style="contentStyle.overlay"
      class="drawer-overlay"
      @click="handleOverlayClick" />
    <template v-for="name in ['left']">
      <div
        v-if="$slots[name]"
        :key="name"
        :ref="name"
        class="drawer-wrap"
        :class="Object.assign({}, animateClasses, { [`drawer-layout__${name}`]: true, 'active': name === activeSidebar || visibleSidebars.includes(name) })"
        :style="drawerStyle[name]">
        <slot :name="name" />
      </div>
    </template>
    <div
      ref="header"
      :class="animateClasses"
      :style="contentStyle.header"
      class="header-wrap">
      <slot name="header" />
    </div>
    <div
      v-if="$slots.content"
      ref="content"
      :class="animateClasses"
      :style="contentStyle.content"
      class="content-wrap">
      <slot name="content" />
    </div>
    <template v-for="name in ['right', 'drawer']">
      <div
        v-if="$slots[name] && rightSidebarHasContent"
        :key="name"
        :ref="name"
        class="drawer-wrap"
        :class="Object.assign({}, animateClasses, { [`drawer-layout__${name}`]: true, 'active': name === activeSidebar || visibleSidebars.includes(name) })"
        :style="{
          ...drawerStyle[name],
          visibility: rightSidebarHasContent ? 'visible' : null,
          pointerEvents: !rightSidebarHasContent ? 'none' : null,
          transitionDuration: !rightSidebarHasContent ? '0ms' : null
        }">
        <slot :name="name" />
        <portal-target
          v-if="rightSidebarHasContent"
          :ref="`app-${name}-sidebar-extension-handler`"
          :key="`app-${name}-sidebar-extension-handler`"
          :name="`app-${name}-sidebar-extension-handler`"
          :slot-props="{ name, open, toggle, click: () => toggle(name, open !== name) }"
          slim>
          <app-sidebar-handle :key="`app-${name}-sidebar-extension-handler-button`" @click="toggle(name, open !== name)" />
        </portal-target>
      </div>
    </template>
  </div>
</template>

<script>
import { Wormhole } from 'portal-vue';
import AppSidebarHandle from '@/components/AppSidebarHandle';

const positionMap = { left: 'left', right: 'right', drawer: 'right' };
const transformMap = {
  width: 'height',
  height: 'height',
  left: 'top',
  right: 'bottom',
  top: 'left',
  bottom: 'right',
  translateX: 'translateY',
  startX: 'startY',
  nowX: 'nowY',
  lastX: 'lastY',
  nowY: 'nowX',
  startY: 'startX'
};

export default {
  name: 'SidebarLayout',
  components: { AppSidebarHandle },
  props: {
    zIndex: {
      type: Number,
      default: 1990
    },
    enable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      axis: 'X',
      sidebars: {},
      sidebar: undefined,
      open: undefined,
      pos: 0,
      moving: false,
      willChange: false,
      canAnimate: this.$supportsTransitions
    };
  },
  computed: {
    activeSidebar() {
      return this.open || this.sidebar || undefined;
    },
    activeSidebarProperties() {
      return this.sidebars[this.activeSidebar];
    },
    opposite() {
      return [1, -1][+(this.activeSidebar !== 'left')];
    },
    width() {
      return this.activeSidebar ? this.activeSidebarProperties.width : 1;
    },
    parallax() {
      return this.activeSidebar ? this.activeSidebarProperties.parallax : 0;
    },
    drawer() {
      return this.activeSidebar ? this.activeSidebarProperties.drawer : false;
    },
    overlay() {
      return this.activeSidebar ? this.activeSidebarProperties.overlay : false;
    },
    overlayOpacityRange() {
      return this.activeSidebar ? this.activeSidebarProperties.overlayOpacityRange : [0, 0];
    },
    threshold() {
      return this.activeSidebar ? this.activeSidebarProperties.threshold : [0, 0];
    },
    overlayOpacity() {
      const { overlayOpacityRange, pos, width } = this;
      const [min, max] = overlayOpacityRange;
      return (min + max * pos / width) || 0;
    },
    moveRate() {
      const { width, parallax } = this;
      return parallax / width;
    },
    animateClasses() {
      const { moving, willChange } = this;
      return { moving: moving, 'will-change': willChange };
    },
    drawerStyle() {
      const { activeSidebar, sidebars, zIndex, opposite, moveRate, pos, transformWithDirection } = this;
      const styles = {};

      Object.keys(sidebars).forEach((sidebar) => {
        const { parallax, position, width, drawer, visible, zIndex: zIndexOverride } = sidebars[sidebar];

        styles[sidebar] = {
          position,
          width: width + 'px',
          minWidth: width + 'px',
          zIndex,
          transform: 'translateZ(0px)',
          [positionMap[sidebar]]: !visible
            ? `-${drawer ? parallax : width}px`
            : '0'
        };

        if (sidebar === activeSidebar) {
          Object.assign(styles[sidebar], {
            zIndex: drawer ? 0 : zIndex,
            transform: `${transformWithDirection('translateX')}(${Math.ceil(drawer ? pos * moveRate : pos) * opposite}px) translateZ(0px)`
          });
        }

        if (zIndexOverride) styles[sidebar].zIndex = zIndexOverride;
      });

      return styles;
    },
    contentStyle() {
      const { overlay, overlayOpacity, zIndex, activeSidebar, opposite, drawer, pos, transformWithDirection } = this;

      const base = drawer
        ? { transform: `${transformWithDirection('translateX')}(${pos * opposite}px) translateZ(0)` }
        : {};

      this.visibleSidebars.forEach((sidebar) => {
        const { slot, width } = this.sidebars[sidebar];
        base[`padding-${slot}`] = `${width}px`;
      });

      return {
        overlay: Object.assign({ opacity: overlayOpacity, zIndex: (overlay ? zIndex : 0) - 1 }, base),
        header: Object.assign({ zIndex: 3 }, base),
        content: Object.assign(activeSidebar ? { zIndex: 1 } : {}, base)
      };
    },
    refsArray() {
      const refsArray = [];
      Object.keys(this.$refs).forEach((name) => {
        const ref = this.$refs[name];
        Array.isArray(ref)
          ? refsArray.push(...ref)
          : refsArray.push(ref);
      });
      return refsArray;
    },
    hiddenSidebars() {
      return Object.keys(this.sidebars).filter((sidebar) => {
        return !this.sidebars[sidebar].visible;
      });
    },
    visibleSidebars() {
      return Object.keys(this.sidebars).filter((sidebar) => {
        return this.sidebars[sidebar].visible;
      });
    },
    rightSidebarHasContent() {
      return Wormhole.hasContentFor('app-right-sidebar-extension');
    }
  },
  watch: {
    hiddenSidebars: {
      handler(sidebars) {
        if (this.activeSidebar && !sidebars.includes(this.activeSidebar)) {
          this.toggle(this.activeSidebar, false);
        }
      }
    },
    open(newVal, oldVal) {
      if (oldVal) {
        this.$emit('sidebar-change', oldVal, false);
        this.$supportsTouch && document.body.style.removeProperty('overflow');
      }

      if (newVal) {
        this.$emit('sidebar-change', newVal, true);
        this.$supportsTouch && (document.body.style.overflow = 'hidden');
      }
    },
    width(newVal, oldVal) {
      if (this.open) {
        this.moving = this.canAnimate;
        this.pos = newVal;
      }
    },
    rightSidebarHasContent(newVal, oldVal) {
      if (!newVal && oldVal) this.toggle('right', false);
      this.moving = false;
    }
  },
  created() {
    this.$on('sidebar-update', this.sidebarUpdate);
    this.$on('sidebar-destroy', this.sidebarDestroy);
  },
  /*eslint-disable */
  mounted() {
    this.handledEvents = this.$supportsTouch
      ? { down: 'touchstart', move: 'touchmove', up: 'touchend' }
      : { down: 'mousedown', move: 'mousemove', up: 'mouseup' };

    const container = this.$el;
    const { $supportsTouch, $supportsPassive, handledEvents } = this;

    let t1,
      t2,
      speed,
      pos,
      startPos,
      canMove,
      metric = { startX: 0, startY: 0, nowX: 0, nowY: 0, lastX: 0, lastY: 0 };

    // Start dragging handler
    const initDrag = (event) => {
      if (!this.enable || !this.hiddenSidebars.length || event.__sidebarIgnore) return;
      else if ((!this.open && this.pos !== 0) || (this.open && this.pos !== this.width)) return removeDrag();

      canMove         = undefined;
      metric.nowX     = metric.startX = $supportsTouch ? event.changedTouches[0].clientX : event.clientX;
      metric.nowY     = metric.startY = $supportsTouch ? event.changedTouches[0].clientY : event.clientY;
      t2              = +new Date();
      startPos        = this.pos;

      document.addEventListener(handledEvents.move, drag);
      document.addEventListener(handledEvents.up, removeDrag, $supportsTouch && $supportsPassive ? { passive: true } : false);
    };

    // During dragging handler
    const drag = (event) => {
      const { width, opposite, transformWithDirection } = this;

      t1           = t2;
      t2           = +new Date();
      metric.lastX = metric.nowX;
      metric.lastY = metric.nowY;
      metric.nowX  = $supportsTouch ? event.changedTouches[0].clientX : event.clientX;
      metric.nowY  = $supportsTouch ? event.changedTouches[0].clientY : event.clientY;

      speed        = (opposite * (metric[transformWithDirection('nowX')] - metric[transformWithDirection('lastX')])) / (t2 - t1);
      pos          = startPos + opposite * (metric[transformWithDirection('nowX')] - metric[transformWithDirection('startX')]);
      pos          = Math.min(width, pos);
      pos          = Math.max(0, pos);

      if (canMove === undefined) {
        const mainAxis = metric[transformWithDirection('nowX')] - metric[transformWithDirection('startX')],
          mainAxisAbs  = Math.abs(mainAxis),
          crossAxisAbs = Math.abs(metric[transformWithDirection('nowY')] - metric[transformWithDirection('startY')]);

        if (!mainAxisAbs && !crossAxisAbs) {
          return;
        }

        const sidebar       = this.open || (mainAxis >= 0 ? 'left' : 'right');
        let activeSidebar = this.hiddenSidebars.includes(sidebar) ? sidebar : undefined;
        if (activeSidebar === 'right' && !this.rightSidebarHasContent) activeSidebar = undefined;

        canMove             = mainAxisAbs / crossAxisAbs < Math.sqrt(3) || !activeSidebar;
        this.sidebar        = !canMove ? activeSidebar : undefined;
      }

      if (canMove === false) {
        !($supportsTouch && $supportsPassive) && event.preventDefault();

        this.willChange = true;
        this.pos = pos;
        $supportsTouch && (document.body.style.overflow = 'hidden');
      } else {
        removeDrag();
      }
    };

    // Stop dragging handler
    const removeDrag = () => {
      if (canMove === false) {
        const { open, activeSidebar, width, pos, threshold } = this;
        const state = open
            ? width * (1 - threshold) <= pos
            : pos >= width * threshold;

        this.toggle(activeSidebar, state);
        !state && $supportsTouch && (document.body.style.removeProperty('overflow'));
      }

      canMove = undefined;
      document.removeEventListener(handledEvents.move, drag, $supportsTouch && $supportsPassive ? { passive: true } : false);
      document.removeEventListener(handledEvents.up, removeDrag, $supportsTouch && $supportsPassive ? { passive: true } : false);
    };

    // Check transitionend and stop
    ['transitionend', 'webkitTransitionEnd', 'msTransitionEnd', 'otransitionend', 'oTransitionEnd'].forEach(eventName => {
      container.addEventListener(
        eventName,
        (event) => {
          if (this.refsArray.indexOf(event.target || event.srcElement) !== -1) {
            this.moving = false;
            this.sidebar = this.open;
            removeDrag();
          }
        },
        false
      );
    });

    container.addEventListener(handledEvents.down, initDrag, $supportsTouch && $supportsPassive ? { passive: true } : false);
  },
  methods: {
    toggle(sidebar, open) {
      const { moving, canAnimate, activeSidebar, pos, sidebars, visibleSidebars } = this;
      sidebar = sidebar || activeSidebar;
      open    = open !== undefined ? open : !this.open;

      if (moving) {
        return false;

      // Unknown sidebar or no action to take
      } else if (!sidebars[sidebar] || (sidebar !== activeSidebar && !open)) {
        return this.$emit('sidebar-change', sidebar, false)
      };

      // Close existing sidebar if one is open
      if (activeSidebar && sidebar !== activeSidebar && open) {
        this.toggle(activeSidebar, false);
        this.$nextTick(() => this.toggle(sidebar, open));
        return;
      }

      const endPos = open ? sidebars[sidebar].width : 0;

      this.willChange = false;
      this.moving = canAnimate && pos !== endPos && !visibleSidebars.includes(sidebar);
      this.pos = endPos;
      this.open = open ? sidebar : undefined;
      this.sidebar = undefined;
    },
    handleOverlayClick() {
      if (this.moving) return;
      this.$emit('overlay-click');
    },
    transformWithDirection(text) {
      const { axis } = this;
      return axis === 'X' ? text : transformMap[text];
    },
    getSlot(el) {
      let position;
      Object.keys(this.$slots).some((slot) => {
        if (this.$slots[slot].indexOf(el) !== -1) {
          return (position = slot);
        }
      });
      return position;
    },
    sidebarUpdate(slot, props) {
      const slotName = this.getSlot(slot);
      slotName && this.$set(this.sidebars, slotName, Object.assign(props, { slot: slotName }));
    },
    sidebarDestroy(slot, props) {
      const slots = Object.keys(this.$slots);
      Object.keys(this.sidebars).forEach((sidebar) => {
        if (!this.$slots[sidebar])
            this.$delete(this.sidebars, sidebar);
      })
    }
  }
  /* eslint-enable */
};
</script>

<style lang="scss" scoped>
.moving {
  transition:
    opacity 0.3s ease,
    transform 0.3s cubic-bezier(0, 0, 0.2, 1),
    left 0.3s cubic-bezier(0, 0, 0.2, 1),
    padding 0.3s cubic-bezier(0, 0, 0.2, 1),
    width 0.3s cubic-bezier(0, 0, 0.2, 1),
    min-width 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.will-change {
  user-select: none !important;
  pointer-events: none !important;
}

.moving,
.will-change {
  > * {
    transition: none !important;
    will-change: none !important;
  }
}

.drawer-layout {
  position: relative;
  display: flex;
}

.drawer-wrap {
  position: absolute;
  transform: translateZ(0);
  height: 100%;
  visibility: hidden;
  display: flex;

  &.moving,
  &.will-change,
  &.active {
    visibility: visible;
  }

  &.will-change {
    will-change: transform;
  }
}

.drawer-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $--popup-modal-background-color;
  overscroll-behavior: contain;

  &.will-change {
    will-change: opacity, transform;
  }
}

.content-wrap {
  position: relative;
  width: 100%;
  box-shadow: 0 0 20px $--clb-shadow-color;

  &.will-change {
    will-change: transform;
  }
}

.header-wrap {
  position: fixed;
  transform: translateX(0);
  width: 100%;

  &.will-change {
    will-change: transform;
  }
}
</style>
