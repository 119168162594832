<template>
  <el-progress :percentage="progress" :show-text="false" class="topbar" />
</template>

<script>
export default {
  props: {
    initial: {
      type: Number,
      default: 5
    },
    current: {
      type: Number,
      default: 0
    },
    total: {
      type: Number,
      default: 1
    },
    fakeFactor: {
      type: Number,
      default: 0
    }
  },
  computed: {
    progress() {
      if (this.total === 0) {
        return 0;
      }

      let remain = this.current;
      // fake progress only if fake factor != 0
      if (this.fakeFactor > 0) {
        if (this.total === this.current) {
          remain = this.total;
        } else if (this.current === 0) {
          remain = 0;
        } else {
          remain = this.total;
          for (let i = 0; i < this.current; i++) {
            remain = remain - remain / this.fakeFactor;
          }
          remain = this.total - remain;
        }
      }

      return (remain * (100 - this.initial)) / this.total + this.initial;
    }
  }
};
</script>
<style lang="scss">
.topbar.el-progress {
  z-index: 10000;
  position: fixed;
  width: 100%;

  .el-progress-bar {
    vertical-align: top !important;

    &__outer {
      border-radius: 0 !important;
      height: 5px !important;
      background-color: #fafafa;
    }

    &__inner {
      border-radius: 0 !important;
      height: 5px !important;
    }
  }
}
</style>
