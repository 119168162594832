import _get from 'lodash/get';
/**
 * Add listeners to campaign fetching mutations
 * @param store
 * @return {Promise<unknown>}
 */
async function handleAsyncCampaignFetching(store) {
  if (!store.getters.fetchingCampaign) {
    return Promise.resolve();
  }

  return new Promise(resolve => {
    store.subscribe((mutation, state) => {
      if (mutation.type === 'FETCH_CAMPAIGN_SUCCESS') {
        resolve(_get(mutation.payload, 'campaign'));
      }
      if (mutation.type === 'FETCH_CAMPAIGN_FAIL') {
        resolve(null);
      }
    });
  });
}

/**
 * Run campaign status middleware
 * @param {Object} context
 * @param {Route} context.to
 * @param {Store} context.store
 * @param {Function} context.next
 */
export default async function({ to, next, store, router }) {
  handleAsyncCampaignFetching(store).then(() => {
    const campaign = store.getters.campaign(to.params.id);
    const campaignStatus = _get(to.meta, 'campaignStatus', 'approved');

    if (!campaign) {
      return router.replace('/');
    } else if (campaign.approval_status !== campaignStatus) {
      return router.replace(`/campaign/${to.params.id}`);
    }
  });

  return next();
}
