import Vue from 'vue';
import ErrorHelper from '@/helpers/ErrorHelper';
import Storage from '@/services/storage';
import _pick from 'lodash/pick';
import _uniqBy from 'lodash/uniqBy';
import _uniq from 'lodash/uniq';
import _get from 'lodash/get';

/**
 * @typedef NavigationStack
 * @type {Object}
 * @property {String} action - The store action to be called
 * @property {Object} payload - The payload for the action
 * @property {Object} totalPages - Total pages on the Navigation
 * @property {Array} items - Array with id's of the loaded items in the navigation
 */

/**
 * @param data
 * @return {*}
 */
const formatCampaignData = function(data) {
  return data.map(campaign => {
    campaign.metrics = _pick(campaign, ['cpa', 'pairing_cpa', 'bepc', 'demand', 'weekly_cap', 'daily_cap', 'status', 'launch_date']);
    return campaign;
  });
};

/**
 * @param {JBXApi} jbxApi
 * @param {RequestCaching} requestCaching
 * @return {any}
 */
export default ({ jbxApi, requestCaching }) => {
  const baseOrganizationStateFactory = function() {
    return {
      campaigns: [],
      advertiserCampaigns: [],
      recommendedCampaigns: [],
      promotedCampaigns: [],
      winningCampaigns: [],
      launchedCampaigns: [],
      campaignAssets: {},
      campaignInspirations: {},
      fetchingCampaign: false,
      fetchingRecommendedCampaigns: false,
      fetchingPromotedCampaigns: false,
      fetchingWinningCampaigns: false,
      fetchingLaunchedCampaigns: false,
      fetchingCampaignInspirations: false,
      submittingApplicationForm: false,
      navigationStack: null,
      filtersVisibility: 'hidden'
    };
  };

  const baseStateFactory = function() {
    return {
      orgs: {},
      activeOrganizationState: {},
      campaignFilters: {}
    };
  };

  const state = baseStateFactory();

  const getters = {
    campaign: (state) => id => {
      return state.activeOrganizationState.campaigns.find(campaign => campaign.id === id);
    },
    campaigns(state) {
      return state.activeOrganizationState.campaigns;
    },
    campaignAssets: (state) => id => {
      return Array.isArray(state.activeOrganizationState.campaignAssets[id]) && state.activeOrganizationState.campaignAssets[id].length > 0
        ? state.activeOrganizationState.campaignAssets[id]
        : undefined;
    },
    campaignFilters(state) {
      return state.campaignFilters;
    },
    fetchingCampaignAssets: (state) => id => {
      return id && (!!(state.activeOrganizationState.campaignAssets[id] && state.activeOrganizationState.campaignAssets[id].then));
    },
    campaignHasAssets: (state, getters) => id => {
      return id && (state.activeOrganizationState.campaignAssets.hasOwnProperty(id) || getters.campaignAssets(id));
    },
    campaignInspirationsIds: (state) => id => {
      return _get(state.activeOrganizationState.campaignInspirations, id, []);
    },
    campaignInspirations: (state) => id => {
      return _get(state.activeOrganizationState.campaignInspirations, id, []).map(campaignId => {
        return state.activeOrganizationState.campaigns.find(c => c.id === campaignId);
      });
    },
    /**
     * @param state
     * @return {NavigationStack}
     */
    navigationStack(state) {
      return state.activeOrganizationState.navigationStack;
    },
    recommendedCampaigns(state) {
      return state.activeOrganizationState.recommendedCampaigns.map(id => {
        return state.activeOrganizationState.campaigns.find(c => c.id === id);
      });
    },
    recommendedCampaignsIds(state) {
      return state.activeOrganizationState.recommendedCampaigns;
    },
    promotedCampaigns(state) {
      return state.activeOrganizationState.promotedCampaigns.map(pc => {
        return state.activeOrganizationState.campaigns.find(c => c.id === pc);
      });
    },
    promotedCampaignIds(state) {
      return state.activeOrganizationState.promotedCampaigns;
    },
    winningCampaigns(state) {
      return state.activeOrganizationState.winningCampaigns.map(wc => {
        return state.activeOrganizationState.campaigns.find(c => c.id === wc);
      });
    },
    winningCampaignIds(state) {
      return state.activeOrganizationState.winningCampaigns;
    },
    launchedCampaigns(state) {
      return state.activeOrganizationState.launchedCampaigns;
    },
    fetchingCampaign(state) {
      return state.activeOrganizationState.fetchingCampaign;
    },
    fetchingPromotedCampaigns(state) {
      return state.activeOrganizationState.fetchingPromotedCampaigns;
    },
    fetchingWinningCampaigns(state) {
      return state.activeOrganizationState.fetchingWinningCampaigns;
    },
    fetchingLaunchedCampaigns(state) {
      return state.activeOrganizationState.fetchingLaunchedCampaigns;
    },
    fetchingRecommendedCampaigns(state) {
      return state.activeOrganizationState.fetchingRecommendedCampaigns;
    },
    fetchingCampaignInspirations(state) {
      return state.activeOrganizationState.fetchingCampaignInspirations;
    },
    submittingApplicationForm(state) {
      return state.activeOrganizationState.submittingApplicationForm;
    },
    filtersVisibility() {
      return state.activeOrganizationState.filtersVisibility;
    },
    showFilters(state) {
      return state.activeOrganizationState.filtersVisibility !== 'hidden';
    }
  };

  const actions = {
    /**
     * Set global campaign lists filters
     * @param {Object} context
     * @param {Function} context.commit
     * @param {Object} filters
     */
    setCampaignFilters({ commit }, filters) {
      commit('SET_CAMPAIGNS_FILTERS', filters);
    },
    clearCampaignFilters({ commit }) {
      commit('SET_CAMPAIGNS_FILTERS', {});
    },
    fetchCampaignsSuccess({ commit }, { response }) {
      commit('FETCH_ORG_CAMPAIGNS_SUCCESS', response.data.data);
      return response.data;
    },
    fetchCampaign({ commit }) {
      commit('FETCH_CAMPAIGN');
    },
    fetchCampaignSuccess({ commit }, { response }) {
      commit('FETCH_CAMPAIGN_SUCCESS', response.data.data);
      return response.data;
    },
    fetchCampaignFail({ commit }, { error }) {
      commit('FETCH_CAMPAIGN_FAIL', error);
    },
    fetchInspirations({ commit }, { campaignId, params }) {
      commit('FETCH_INSPIRATIONS', { campaignId, page: params.page });
    },
    fetchInspirationsSuccess({ commit }, { response, payload }) {
      commit('FETCH_INSPIRATIONS_SUCCESS', {
        campaigns: response.data.data,
        campaignId: payload.campaignId,
        orgId: payload.orgId
      });
      return response.data;
    },
    fetchInspirationsFail({ commit }, { error }) {
      commit('FETCH_INSPIRATIONS_FAIL', error);
    },
    fetchPromotions({ commit }) {
      commit('FETCH_PROMOTIONS');
    },
    fetchPromotionsSuccess({ commit }, { response }) {
      commit('FETCH_PROMOTIONS_SUCCESS', response.data.data);
      return response.data;
    },
    fetchPromotionsFail({ commit }, { error }) {
      commit('FETCH_PROMOTIONS_FAIL', error);
    },
    fetchWinners({ commit }) {
      commit('FETCH_WINNERS');
    },
    fetchWinnersSuccess({ commit }, { response }) {
      commit('FETCH_WINNERS_SUCCESS', response.data.data);
      return response.data;
    },
    fetchWinnersFail({ commit }, { error }) {
      commit('FETCH_WINNERS_FAIL', error);
    },
    fetchLaunches({ commit }) {
      commit('FETCH_LAUNCHES');
    },
    fetchLaunchesSuccess({ commit }, { response }) {
      commit('FETCH_LAUNCHES_SUCCESS', response.data.data);
      return response.data;
    },
    fetchLaunchesFail({ commit }, { error }) {
      commit('FETCH_LAUNCHES_FAIL', error);
    },
    fetchRecommendations({ commit }, { params }) {
      commit('FETCH_RECOMMENDATIONS', params);
    },
    fetchRecommendationsSuccess({ commit }, { response }) {
      commit('FETCH_RECOMMENDATIONS_SUCCESS', response.data.data);
      return response.data;
    },
    fetchRecommendationsFail({ commit }, { error }) {
      commit('FETCH_RECOMMENDATIONS_FAIL', error);
    },
    async fetchCampaignAssets({ state, getters, commit }, options) {
      commit('SET_CAMPAIGN_ASSETS', { campaignId: options.campaignId, resource: options.requestPromise });
    },
    fetchCampaignAssetsSuccess({ commit }, { payload, response }) {
      if (_get(response, 'data.data').length <= 0) {
        return {};
      }
      const resource = response.data.data.map(item => {
        return {
          title: item.title,
          url: item.url
        };
      });
      commit('SET_CAMPAIGN_ASSETS', { campaignId: payload.campaignId, resource });
      return response.data;
    },
    fetchCampaignAssetsFail({ commit }, { payload }) {
      commit('SET_CAMPAIGN_ASSETS', { campaignId: payload.campaignId, resource: false });
    },
    /**
     * Sends campaign application form
     * @param dispatch
     * @param commit
     * @param orgId
     * @param campaignId
     * @param data
     */
    async submitApplicationForm({ dispatch, commit }, { orgId, campaignId, data }) {
      commit('SUBMIT_APPLICATION_FORM');
      return jbxApi
        .post(`/v2/org/${orgId}/campaign/${campaignId}/apply`, data)
        .then(response => {
          commit('SUBMIT_APPLICATION_FORM_SUCCESS', { campaignId });
          return response;
        })
        .catch(e => {
          commit('SUBMIT_APPLICATION_FORM_FAIL', e);
          if (e.hasOwnProperty('response')) {
            dispatch('showErrorMessage', _get(e.response, 'data._meta.errors', []).join(', '));
          }
        });
    },

    /**
     * Update current navigation stack
     * @param commit
     * @param response
     * @return {Promise<*>}
     */
    async updateNavigationStack({ commit }, response) {
      commit('UPDATE_NAVIGATION_STACK', response);
    },

    /**
     * Sets current navigation stack
     * @param commit
     * @param {NavigationStack} payload
     */
    setNavigationStack({ commit }, payload) {
      commit('SET_NAVIGATION_STACK', payload);
    },

    /**
     * Get suppression details for a campaign
     * @param context
     * @param {object} payload
     * @param {string} payload.orgId
     * @param {number} payload.campaignId
     * @return {Promise<never>}
     */
    async getSuppressionData(context, { orgId, campaignId }) {
      return jbxApi.get(`/v2/org/${orgId}/campaign/${campaignId}/supression`)
        .then((response) => {
          return response.data.data;
        })
        .catch(e => {
          return Promise.reject(ErrorHelper(e));
        });
    },

    /**
     * Sends feedback about expected campaigns on discovery page
     * @param dispatch
     * @param commit
     * @param orgId
     * @param data
     */
    async submitFeedback({ dispatch, commit }, { orgId, data }) {
      return jbxApi
        .post(`/v2/org/${orgId}/campaign/request`, data)
        .then(response => {
          return response;
        })
        .catch(e => {
          return Promise.reject(ErrorHelper(e));
        });
    },

    /**
     * Sets the visibility of the campaign filters
     * @param commit
     * @param payload
     */
    setFiltersVisibility({ commit }, payload) {
      commit('SET_FILTERS_VISIBILITY', payload);
    }
  };

  const mutations = {
    SET_ACTIVE_ORGANIZATION(state, org) {
      // Clear or restore campaign filters
      if (Object.keys(state.activeOrganizationState).length > 0) {
        state.campaignFilters = {};
        Storage.setObject('campaigns-filters', {});
      } else {
        state.campaignFilters = Storage.getObject('campaigns-filters') || {};
      }

      if (!state.orgs.hasOwnProperty(org.id)) {
        state.orgs[org.id] = baseOrganizationStateFactory();
      }
      state.activeOrganizationState = state.orgs[org.id];
    },
    FETCH_ORG_CAMPAIGNS_SUCCESS(state, campaigns) {
      campaigns = formatCampaignData(campaigns);
      state.activeOrganizationState.campaigns = _uniqBy([...state.activeOrganizationState.campaigns, ...campaigns], 'id');
    },
    SET_CAMPAIGN_ASSETS(state, { campaignId, resource }) {
      Vue.set(state.activeOrganizationState.campaignAssets, campaignId, resource);
    },
    FETCH_CAMPAIGN(state) {
      state.activeOrganizationState.fetchingCampaign = true;
    },
    FETCH_CAMPAIGN_SUCCESS(state, campaign) {
      campaign = formatCampaignData([campaign])[0];
      campaign.details_loaded = true;
      state.activeOrganizationState.fetchingCampaign = false;
      const index = state.activeOrganizationState.campaigns.findIndex((c) => c.id === campaign.id);
      if (index >= 0) {
        state.activeOrganizationState.campaigns.splice(index, 1, campaign);
      } else {
        state.activeOrganizationState.campaigns = [...state.activeOrganizationState.campaigns, ...[campaign]];
      }
    },
    FETCH_CAMPAIGN_FAIL(state) {
      state.activeOrganizationState.fetchingCampaign = false;
    },
    FETCH_INSPIRATIONS(state, { campaignId, page }) {
      state.activeOrganizationState.fetchingCampaignInspirations = true;
      if (page === 1 && state.activeOrganizationState.campaignInspirations.hasOwnProperty(campaignId)) {
        Vue.set(state.activeOrganizationState.campaignInspirations, campaignId, []);
      }
    },
    FETCH_INSPIRATIONS_SUCCESS(state, { campaigns, campaignId }) {
      campaigns = formatCampaignData(campaigns);
      if (!state.activeOrganizationState.campaignInspirations.hasOwnProperty(campaignId)) {
        Vue.set(state.activeOrganizationState.campaignInspirations, campaignId, []);
      }
      state.activeOrganizationState.fetchingCampaignInspirations = false;
      state.activeOrganizationState.campaignInspirations[campaignId] = _uniq([...state.activeOrganizationState.campaignInspirations[campaignId], ...campaigns.map(c => c.id)]);
      state.activeOrganizationState.campaigns = _uniqBy([...state.activeOrganizationState.campaigns, ...campaigns], 'id');
    },
    FETCH_INSPIRATIONS_FAIL(state) {
      state.activeOrganizationState.fetchingCampaignInspirations = false;
    },
    FETCH_PROMOTIONS(state) {
      state.activeOrganizationState.fetchingPromotedCampaigns = true;
      state.activeOrganizationState.promotedCampaigns = [];
    },
    FETCH_PROMOTIONS_SUCCESS(state, campaigns) {
      campaigns = formatCampaignData(campaigns);
      state.activeOrganizationState.fetchingPromotedCampaigns = false;
      state.activeOrganizationState.promotedCampaigns = _uniq([...state.activeOrganizationState.promotedCampaigns, ...campaigns.map(c => c.id)]);
      state.activeOrganizationState.campaigns = _uniqBy([...state.activeOrganizationState.campaigns, ...campaigns], 'id');
    },
    FETCH_PROMOTIONS_FAIL(state) {
      state.activeOrganizationState.fetchingPromotedCampaigns = false;
    },
    FETCH_WINNERS(state) {
      state.activeOrganizationState.fetchingWinningCampaigns = true;
      state.activeOrganizationState.winningCampaigns = [];
    },
    FETCH_WINNERS_SUCCESS(state, campaigns) {
      campaigns = formatCampaignData(campaigns);
      state.activeOrganizationState.fetchingWinningCampaigns = false;
      state.activeOrganizationState.winningCampaigns = _uniq([...state.activeOrganizationState.winningCampaigns, ...campaigns.map(c => c.id)]);
      state.activeOrganizationState.campaigns = _uniqBy([...state.activeOrganizationState.campaigns, ...campaigns], 'id');
    },
    FETCH_WINNERS_FAIL(state) {
      state.activeOrganizationState.fetchingWinningCampaigns = false;
    },
    FETCH_LAUNCHES(state) {
      state.activeOrganizationState.fetchingLaunchedCampaigns = true;
      state.activeOrganizationState.launchedCampaigns = [];
    },
    FETCH_LAUNCHES_SUCCESS(state, campaigns) {
      campaigns = formatCampaignData(campaigns);
      state.activeOrganizationState.fetchingLaunchedCampaigns = false;
      state.activeOrganizationState.launchedCampaigns = campaigns;
      state.activeOrganizationState.campaigns = _uniqBy([...state.activeOrganizationState.campaigns, ...campaigns], 'id');
    },
    FETCH_LAUNCHES_FAIL(state) {
      state.activeOrganizationState.fetchingLaunchedCampaigns = false;
    },
    FETCH_RECOMMENDATIONS(state, params) {
      state.activeOrganizationState.fetchingRecommendedCampaigns = true;
      if (params.page === 1) {
        state.activeOrganizationState.recommendedCampaigns = [];
      }
    },
    FETCH_RECOMMENDATIONS_SUCCESS(state, campaigns) {
      campaigns = formatCampaignData(campaigns);
      state.activeOrganizationState.fetchingRecommendedCampaigns = false;
      state.activeOrganizationState.recommendedCampaigns = _uniq([...state.activeOrganizationState.recommendedCampaigns, ...campaigns.map(c => c.id)]);
      state.activeOrganizationState.campaigns = _uniqBy([...state.activeOrganizationState.campaigns, ...campaigns], 'id');
    },
    FETCH_RECOMMENDATIONS_FAIL(state) {
      state.activeOrganizationState.fetchingRecommendedCampaigns = false;
    },
    SUBMIT_APPLICATION_FORM(state) {
      state.activeOrganizationState.submittingApplicationForm = true;
    },
    SUBMIT_APPLICATION_FORM_SUCCESS(state, { campaignId }) {
      state.activeOrganizationState.submittingApplicationForm = false;
      const campaign = state.activeOrganizationState.campaigns.find(c => c.id === campaignId);
      if (campaign) {
        campaign.approval_status = 'pending';
      }
    },
    SUBMIT_APPLICATION_FORM_FAIL(state) {
      state.activeOrganizationState.submittingApplicationForm = false;
    },
    /**
     * @param state
     * @param {NavigationStack} navigationStack
     */
    SET_NAVIGATION_STACK(state, navigationStack) {
      state.activeOrganizationState.navigationStack = navigationStack;
    },
    UPDATE_NAVIGATION_STACK(state, response) {
      state.activeOrganizationState.navigationStack.payload.params.page = response._meta.page;
      state.activeOrganizationState.navigationStack.totalPages = response._meta.page_count;
      state.activeOrganizationState.navigationStack.items.push(...response.data.map(c => c.id));
    },
    SET_CAMPAIGNS_FILTERS(state, filters) {
      state.campaignFilters = filters;
      Storage.setObject('campaigns-filters', filters || {});
    },
    CLEAR_STORE(state) {
      state = Object.assign(state, baseStateFactory());
    },
    SET_FILTERS_VISIBILITY(state, payload) {
      state.activeOrganizationState.filtersVisibility = payload;
    }
  };

  return {
    state,
    getters,
    actions,
    mutations
  };
};
