import addressMapper from './question-address';
import dropdownMapper from './question-dropdown';
import multiselectMapper from './question-multiselect';
import checkboxesMapper from './question-checkboxes';
import orderMapper from './question-order';
import phone_numberMapper from './question-phone-number';
import phone_verificationMapper from './question-phone-verification';

const questionsMappers = {
  addressMapper,
  dropdownMapper,
  multiselectMapper,
  checkboxesMapper,
  orderMapper,
  phone_numberMapper,
  phone_verificationMapper
};

export default questionsMappers;
