<template>
  <section class="org-selection-view">
    <h3>Select Organization</h3>
    <org-selection-list
      :loading="loading"
      :entries="organizations"
      class="org-selection-view__list"
      @handle-search-more="handleSearchMore"
      @handle-search="handleSearch"
      @handle-selection="handleSelection" />
  </section>
</template>

<script>
import OrgSelectionList from '@/components/organizations/OrgSelectionList';
import jbxApi from '@/services/jbx-api';
import _uniqBy from 'lodash/uniqBy';
import { EMPLOYEE } from '@/constants/orgTypes';

export default {
  name: 'OrgSelectionView',
  components: { OrgSelectionList },
  data() {
    return {
      search: '',
      loading: false,
      entries: this.$store.getters.organizations.concat([]),
      currentPage: 1,
      totalPages: 1,
      allowedOrganizations: []
    };
  },
  computed: {
    organizations() {
      return this.allowedOrganizations.length
        ? this.entries.filter(o => this.allowedOrganizations.includes(o.id))
        : this.entries;
    }
  },
  created() {
    this.allowedOrganizations = this.$route.query.ids ? this.$route.query.ids.split(',') : [];
    this.handleSearch(this.search);
  },
  methods: {
    async handleSearch(search) {
      if (search !== this.search) {
        this.currentPage = 1;
        this.entries = [];
      }

      this.loading = true;
      this.search = search;
      try {
        const response = await this.$store.dispatch('getOrganizations', { q: search, page: this.currentPage, ids: this.allowedOrganizations });
        this.entries = _uniqBy([...this.entries, ...response.data.data], 'id');
        this.totalPages = response.data._meta.page_count;
        this.currentPage = response.data._meta.page;
        this.loading = false;
      } catch (e) {
        if (!jbxApi.isCancel(e.response)) {
        }
      }
    },
    handleSearchMore() {
      if (!this.loading && this.currentPage < this.totalPages) {
        this.currentPage += 1;
        this.handleSearch(this.search);
      }
    },
    async handleSelection(index) {
      await this.$store.dispatch('selectOrganization', this.organizations[index]);
      const redirectTo = this.$route.query.from || '/';
      this.$router.push({ path: decodeURIComponent(redirectTo) });
    }
  }
};
</script>

<style lang="scss">
.org-selection-view {
  h3 {
    text-align: center;
  }

  margin-top: 15vh;
  margin-top: calc(15 * var(--vh, 1vh));

  &__list {
    .app-search-list-v2 {
      &__input {
        .el-input--mini .el-input__inner {
          @apply tw-bg-white;
        }
      }
    }

    box-shadow: 0 4px 7px -5px $--clb-shadow-color;

    .app-search-list {
      height: 100%;

      &__wrapper {
        max-height: 60vh;
        max-height: calc(60 * var(--vh, 1vh));
      }
    }
  }

  @media (min-width: $--sm) {
    .org-selection-view {
      margin-top: 20vh;
      margin-top: calc(20 * var(--vh, 1vh));

      .app-search-list {
        &__wrapper {
          max-height: 40vh;
          max-height: calc(40 * var(--vh, 1vh));
        }
      }
    }
  }
}
</style>
