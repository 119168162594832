<template>
  <app-button class="tw-h-layout-3 tw-px-layout-1 tw-top-64 md:tw-top-48 tw--translate-x-layout-3 tw-bg-jb-indigo hover:tw-bg-jb-indigo-dark tw-absolute tw-left-0 tw-flex tw-items-center tw-justify-center tw-max-w-full tw-py-0 tw-font-medium tw-text-white tw-origin-top-left tw-transform tw--rotate-90 tw-rounded-b-none tw-cursor-pointer" @click="$emit('click')">
    <slot><menu-icon class="hamburger tw-w-layout-2 tw-h-layout-2 tw-text-current" /></slot>
  </app-button>
</template>

<script>
import AppButton from '@/components/AppButton';
import MenuIcon from '@/assets/svg/menu-icon.svg';

export default {
  name: 'SidebarLayout',
  components: { AppButton, MenuIcon }
};
</script>
