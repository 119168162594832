<template>
  <Component
    :is="route ? 'router-link' : 'div'"
    :to="route || null"
    class="app-global-sidebar-item tw-group tw-p-space-2 tw-mb-space-1px tw-h-layout-3 tw-relative tw-flex tw-items-center tw-text-sm tw-no-underline tw-transition tw-rounded"
    :class="{
      ['tw-cursor-pointer hover:tw-bg-jb-indigo-lighter !tw-bg-opacity-70']: hasClick,
      ['tw-font-medium !tw-text-jb-ink']: !isActive,
      ['tw-bg-jb-indigo-lighter !tw-bg-opacity-100 tw-font-semibold !tw-text-jb-indigo']: isActive,
    }">
    <slot name="prepend" />

    <slot name="icon">
      <Component
        :is="iconComponent || 'i'"
        class="tw-mr-space-2 tw-w-layout-1 tw-h-layout-1 tw-flex tw-items-center tw-justify-center"
        :class="{
          [iconClass]: iconClass,
          ['tw-text-jb-grey-400']: !isActive,
          ['tw-text-current']: isActive
        }" />
    </slot>

    <slot>
      <app-text
        v-if="label"
        size="sm"
        :weight="isActive ? 'semi-bold' : 'medium'"
        :title="label"
        class="tw-whitespace-nowrap tw-overflow-ellipsis tw-max-w-full tw-overflow-hidden tw-leading-none tw-text-current">
        {{ label }}
      </app-text>
    </slot>

    <slot name="append" />
  </Component>
</template>

<script>
import AppText from './AppText.vue';
import HomeIcon from '@/assets/svg/home-icon.svg';
import LightbulbIcon from '@/assets/svg/lightbulb-icon.svg';
import BarchartIcon from '@/assets/svg/barchart-icon.svg';
import ExchangeIcon from '@/assets/svg/exchange-icon.svg';
import MegaphoneIcon from '@/assets/svg/megaphone-icon.svg';
import ReferralIcon from '@/assets/svg/referral-icon.svg';
import GearIcon from '@/assets/svg/gear-icon.svg';

export default {
  name: 'AppGlobalSidebarItem',
  components: { AppText, HomeIcon, LightbulbIcon, BarchartIcon, ExchangeIcon, MegaphoneIcon, GearIcon, ReferralIcon },
  props: {
    route: {
      type: Object,
      required: false,
      default: undefined
    },
    label: {
      type: String,
      required: false,
      default: undefined
    },
    iconComponent: {
      type: String,
      required: false,
      default: undefined
    },
    iconClass: {
      type: String,
      required: false,
      default: undefined
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasClick() {
      return this.route || this.$listeners.click;
    }
  }
};
</script>
