export const NAVIGATION_STACKS = {
  reporting: {
    label: 'Live',
    name: 'reporting',
    apiEndpoint: 'reporting',
    storeAction: 'fetchReportingCampaigns'
  },
  recommendations: {
    label: 'Recommended',
    name: 'recommendations',
    apiEndpoint: 'recommendations',
    storeAction: 'fetchRecommendations'
  },
  inspirations: {
    label: 'Similar',
    name: 'inspirations',
    apiEndpoint: 'inspirations',
    storeAction: 'fetchInspirations'
  },
  launches: {
    label: 'New',
    name: 'launches',
    apiEndpoint: 'launches',
    storeAction: 'fetchCampaigns'
  },
  promotions: {
    label: 'Featured',
    name: 'promotions',
    apiEndpoint: 'promotions',
    storeAction: 'fetchCampaigns'
  }
};
