/**
 * Run referral middleware
 * @param {Object} context
 * @param {Function} context.next
 * @param {Route} context.to
 * @param {Route} context.from
 * @param {Store} context.store
* @param {Object} api
* @param {VueRouter} router
 */
// eslint-disable-next-line
export default async ({ next, to, from, store, api, router }) => {
  // Prevent admins from accessing referrals
  if (store.getters.userIsAdmin && !store.getters.userIsSuperAdmin) {
    return next('/');
  }

  if (to.name === 'referralMarketingLayout' && store.getters.isSetupComplete) {
    return next({ name: 'referralManagement' });
  }

  return next();
};
