<template>
  <div :class="['app-number-picker', value?'active':'']">
    <div
      v-for="v in values"
      :key="v"
      :class="['number',v==value?'selected':'']"
      :style="{ width: `${100 / values.length}%`}"
      @click="pickNumber(v)">
      {{ v }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppNumberPicker',
  props: {
    min: {
      type: Number,
      default: 1
    },
    max: {
      type: Number,
      default: 10
    },
    step: {
      type: Number,
      default: 1
    },
    value: {
      type: Number,
      default: null
    }
  },
  computed: {
    values() {
      // Calculate values to show on the picker based on min, max and step props
      const valuesArray = [];
      let v = this.min;
      while (v <= this.max) {
        valuesArray.push(v);
        v += this.step;
      }
      // Add in max value if it doesn't exist already
      if (!valuesArray.includes(this.max)) {
        valuesArray.push(this.max);
      }
      return valuesArray;
    }
  },
  methods: {
    pickNumber(val) {
      this.$emit('input', val);
    }
  }
};
</script>

<style lang="scss" scoped>
.app-number-picker {
  padding: 0;
  display: flex;
  justify-content: space-evenly;
}

.number {
  padding: $--clb-layout-2 0;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.25rem;
  min-width: 1.25rem;
  text-align: center;
  color: $--clb-color-grey__grey-darker;
  cursor: pointer;
  transition: font-size 100ms linear, color 100ms linear;
  display: inline-block;

  @include md-up {
    padding: $--clb-layout-3 0;
  }

  &:hover {
    font-size: 1.75rem;

    @include md-up {
      font-size: 2.5rem;
    }
  }

  .active > & {
    color: $--clb-color-grey__grey-lighter;

    &.selected {
      font-size: 1.75rem;
      color: $--clb-color-primary__dark;

      @include md-up {
        font-size: 2.5rem;
      }
    }
  }

  :hover > & {
    color: $--clb-color-grey__grey-darker;
  }
}
</style>
