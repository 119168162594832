import axios from 'axios';
import axiosRetry from 'axios-retry';
import config from '@/helpers/config';
const cancellationTokens = {};
const requestPromises = {};
const dbHeader = config.VUE_APP_PLATFORM_API_DB_HEADER;

/**
 * Check if it's a successful jbx api response
 * @param response
 */
function isSuccessResponse(response) {
  if (![200, 201, 202].includes(response.status)) {
    return false;
  }
  return response.headers['content-type'] !== 'application/json' || response.data._meta.success;
}

/**
 * Handle cancellation and request promises for get requests
 * @param config
 * @return {*}
 */
function handleGetRequests(config) {
  handleRequestCancellation(config);
  if (isCancellableRequest(config)) {
    return defaultAdapter(config);
  }
  if (hasRequestPromise(config)) {
    return requestPromises[config.url];
  }
  requestPromises[config.url] = defaultAdapter(config).finally(handleRequestPromiseCompletion(config));
  return requestPromises[config.url];
}

/**
 * Handle the completion of a request to make sure is removed from the promises object
 * @param config
 * @return {function(...[*]=)}
 */
const handleRequestPromiseCompletion = (config) => () => {
  hasRequestPromise(config) && delete requestPromises[config.url];
};

/**
 * Check if a request config is cancellable
 * @param config
 * @return {boolean}
 */
function isCancellableRequest(config) {
  return config.hasOwnProperty('cancellationKey');
}

/**
 * Handles the cancelation of requests
 * @param config
 */
function handleRequestCancellation(config) {
  if (isCancellableRequest(config)) {
    abortPreviousRequests(config);
    addCancelToken(config);
  }
}

/**
 * Abort previous requests for the same cancellation toke
 * @param options
 */
function abortPreviousRequests(options) {
  if (cancellationTokens.hasOwnProperty(options.cancellationKey)) {
    cancellationTokens[options.cancellationKey]('Request cancelled');
    delete cancellationTokens[options.cancellationKey];
  }
}

/**
 * Add a cancel token for given request options
 * @param {Object} options
 */
function addCancelToken(options) {
  options.cancelToken = new axios.CancelToken(function(cancelRequest) {
    cancellationTokens[options.cancellationKey] = cancelRequest;
  });
}

/**
 * Check if there is an active request promise
 * @param {AxiosRequestConfig} config
 */
function hasRequestPromise(config) {
  return requestPromises.hasOwnProperty(config.url);
}

/**
 * Axios default adapter to handle the actual request
 * @type {AxiosAdapter}
 */
const defaultAdapter = axios.defaults.adapter;
/**
 * Create new axios instance with default baseURL and custom headers
 */
const customHeaders = {
  'X-Client': process.env.VUE_APP_X_CLIENT
};
if (dbHeader) {
  customHeaders['jbx-db'] = dbHeader;
}

const jbxAxios = axios.create({
  baseURL: config.VUE_APP_PLATFORM_API_URL,
  headers: customHeaders,
  adapter: function(config) {
    if (config.method === 'get') {
      return handleGetRequests(config);
    }
    return defaultAdapter(config);
  }
});
axiosRetry(jbxAxios, { retries: 3 });

/**
 * Redirect to signout on unauthorized response
 */
jbxAxios.interceptors.response.use(
  /**
   * @param {AxiosResponse} response
   * @return {*}
   */
  function(response) {
    if (!isSuccessResponse(response)) {
      const e = new Error('Invalid request');
      e.response = response;
      return Promise.reject(e);
    }
    return response;
  },
  /**
   * @param {AxiosError} error
   * @return {*}
   */
  function(error) {
    return Promise.reject(error);
  }
);

export default jbxAxios;
