const MAIN_REDIRECTOR_TYPE = 'affiliate';

export default {
  state: {
    countries: [],
    verticals: [],
    domains: [],
    isFetchingDomains: false
  },
  getters: {
    countries: state => state.countries,
    verticals: state => state.verticals,
    organizationDomains: state => state.domains,
    organizationPrimaryDomain(state) {
      return state.domains.find(d => d.type === MAIN_REDIRECTOR_TYPE);
    },
    fetchingOrganizationDomains(state) {
      return state.isFetchingDomains;
    }
  },
  mutations: {
    SET_COUNTRIES(state, countries) {
      state.countries = countries;
    },
    SET_VERTICALS(state, verticals) {
      state.verticals = verticals;
    },
    SET_ORGANIZATION_DOMAINS(state, domains) {
      state.domains = domains;
    },
    SET_ORGANIZATION_DOMAINS_FETCHING_STATE(state, fetchingState) {
      state.isFetchingDomains = fetchingState;
    }
  },
  actions: {
    fetchCountriesSuccess({ commit }, { response }) {
      commit('SET_COUNTRIES', response.data);
    },
    fetchVerticalsSuccess({ commit }, { response }) {
      commit('SET_VERTICALS', response.data);
    },
    fetchOrganizationPixelDomains({ commit }) {
      commit('SET_ORGANIZATION_DOMAINS_FETCHING_STATE', true);
    },
    fetchOrganizationPixelDomainsSuccess({ commit }, { response }) {
      commit('SET_ORGANIZATION_DOMAINS', response.data);
      commit('SET_ORGANIZATION_DOMAINS_FETCHING_STATE', false);
    },
    fetchOrganizationPixelDomainsFail({ commit }) {
      commit('SET_ORGANIZATION_DOMAINS_FETCHING_STATE', false);
    }
  }
};
