export default ({ fetch, storeHandler }) => {
  return {
    async updatePhoneNumber(options = {}) {
      const user = storeHandler.get('user');
      return fetch({ url: `/v2/user/${user.user_id}/account-settings/phone`, method: 'PUT', options });
    },
    async verifyPhoneNumber(options = {}) {
      const user = storeHandler.get('user');
      return fetch({ url: `/v2/user/${user.user_id}/account-settings/phone/verify`, method: 'PUT', options });
    },
    async updateProfile(options = {}) {
      const user = storeHandler.get('user');
      return fetch({ url: `/v2/user/${user.user_id}/account-settings/profile`, method: 'PUT', options });
    },
    updateEmail(options = {}) {
      const user = storeHandler.get('user');
      return fetch({ url: `/v2/user/${user.user_id}/account-settings/email`, method: 'PUT', options });
    },
    verifyEmail(options = {}) {
      return fetch({ url: '/v2/auth/verify-email', method: 'POST', options });
    }
  };
};
