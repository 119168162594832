<template>
  <div class="campaign-application tw-mx-space-4 md:tw-mx-layout-3">
    <transition :duration="500" name="el-fade-in" mode="out-in">
      <div v-if="applicationSuccess" key="success" class="campaign-application__container campaign-application__container--success md:tw-flex-row tw-flex tw-flex-col tw-items-start">
        <app-lottie-animation
          v-if="applicationSuccess"
          :play-animation="applicationSuccess"
          :play-delay="1250"
          class="blobby" />
        <div class="tw-relative tw-w-full tw-text-center">
          <h3>Application Submitted!</h3>
          <p class="tw-mb-layout-3 tw-leading-6">
            Your Application is currently being reviewed by our team. <br> You will recieve an email if and when you are approved.
          </p>
          <app-button class="tw-my-space-3 xs:tw-max-w-xs tw-w-full tw-mx-auto" @click="backToDiscover">
            <i class="el-icon-arrow-left" /> Discover More Campaigns
          </app-button>
        </div>
      </div>
      <div v-else key="application" class="campaign-application__container">
        <campaign-application-form
          :campaign="campaign"
          :loading="submittingApplicationForm"
          class="campaign-application-form"
          @submit="handleApplicationFormSubmit" />
      </div>
    </transition>
  </div>
</template>

<script>
import CampaignApplicationForm from '@/components/campaigns/CampaignApplicationForm';
import AppButton from '@/components/AppButton';

export default {
  name: 'CampaignApplication',
  components: {
    CampaignApplicationForm,
    AppButton,
    AppLottieAnimation: () => import('@/components/AppLottieAnimation')
  },
  data() {
    return {
      applicationSuccess: false
    };
  },
  computed: {
    campaign() {
      return this.$store.getters.campaign(this.$route.params.id);
    },
    fetchingCampaign() {
      return this.$store.getters.fetchingCampaign;
    },
    submittingApplicationForm() {
      return this.$store.getters.submittingApplicationForm;
    },
    recommendedCampaignsIds() {
      return this.$store.getters.recommendedCampaignsIds;
    },
    showSkeletons() {
      return this.fetchingCampaign;
    }
  },
  methods: {
    backToDiscover() {
      this.$router.push('/discover');
    },
    handleViewCampaign(campaign) {
      this.$router.push(`/campaign/${campaign.id}`);
    },
    handleApplicationFormSubmit(data) {
      this.$store
        .dispatch('submitApplicationForm', {
          orgId: this.$store.getters.organization.id,
          campaignId: this.campaign.id,
          data
        })
        .then(response => {
          if (response && response.status === 200) {
            // Change screen to show success message
            this.applicationSuccess = true;
            scrollTo &&
              scrollTo({
                top: 80,
                behavior: 'smooth'
              });
          }
        });
    }
  }
};
</script>

<style lang="scss">
.campaign-application {
  @include stage-layout;

  @apply tw-mt-0;

  &__container {
    &--success {
      min-height: 440px;

      @apply tw-items-center tw--mb-layout-4 tw-justify-center;

      .app-lottie-animation-cont {
        @apply tw-absolute tw-w-full tw-bottom-0 tw-left-0;
      }
    }
  }
}

.active-page-campaign-application {
  .app-banner {
    @apply tw-pt-layout-4;
  }
}
</style>
